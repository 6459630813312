class CollectionItemModel {
    static entityType = 20;

    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.collectionId = json.collection_id;
        this.userId = json.user_id;
        this.entityId = json.entity_id;
        this.entityType = json.entity_type;
        this.name = json.name;
        this.description = json.description;
        this.metaData = json.meta_data;
        
        this.created = json.created;
        this.modified = json.modified;
    }
    
    toJson() {
        return {
            collection_id: this.collectionId,
        user_id: this.userId,
        entity_id: this.entityId,
        entity_type: this.entityType,
        name: this.name,
        description: this.description,
        meta_data: this.metaData,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new CollectionItemModel(json));
    }
}

export default CollectionItemModel;
