import React, {useEffect, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBank, faCode, faDeleteLeft, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import InvoicePaymentForm from "../../../components/invoicing/ui/InvoicePaymentForm";
import InvoiceService from "../../../components/billing/services/InvoiceService";
import HeaderButton from "../../../components/common/ui/HeaderButton";
import InvoiceModel from "../../../components/billing/models/InvoiceModel";
import CommentForm from "../../../components/messaging/ui/CommentForm";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import InvoicePreview from "../../../components/billing/ui/InvoiceSendPreview";
import CompanyInvoiceSubMenu from "../../../components/companies/ui/CompanyInvoiceSubMenu";
import DateTime from "../../../components/common/ui/DateTime";
import '../../../components/billing/ui/Invoice.css';
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";
import ActivityTable from '../../../components/activity/ui/ActivityTable';
import NavigationHistoryService from '../../../components/activity/services/NavigationHistoryService';
import InvoiceAttachmentsPanel from '../../../components/invoicing/ui/InvoiceAttachmentsPanel';
import CopyText from "@jadecharles/pi-react-packages/dist/common/ui/copy-text/CopyText";
import WebVisitorTable from "../../../components/activity/ui/WebVisitorTable";
import ConfigModel from "../../../components/common/models/ConfigModel";

const InvoiceDetailsScreen = (props) => {
    let { companyId, invoiceId } = useParams();
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [invoice, setInvoice] = useState(null);
    let [redirectUrl, setRedirectUrl] = useState("");
    const [paymentJson, setPaymentJson] = useState({ amount: 0, payment_type: 0, transaction_id: null, message: "Unset", order_identifier: null });
    let [receivers, setReceivers] = useState({ sendRecipients: [], isReminder: false});

    const paymentController = useState(new DialogBoxController("Add Payment"))[0];
    const resendController = useState(new DialogBoxController("Resend Invoice"))[0];
    const deleteController = useState(new DialogBoxController("Delete Invoice"))[0];
    const jsonController = useState(new DialogBoxController("Invoice Raw Json"))[0];
    
    const getInvoiceAsync = async (force = false) => {
        if (!invoiceId || (!force && !!invoice?.id)) return invoice;

        console.warn("Getting Invoice: " + invoiceId);
        return await InvoiceService.instance.getInvoiceAsync(invoiceId).then((inv) => {
            if (!!inv?.id) { 
                setInvoice(inv);
                return inv;
            }

            return invoice;
        });
    };

    const onRecipientChange = (recipient) => {
        let recips = [];
        if (recipient.viaEmail && !!recipient.email) recips.push(recipient.email);
        if (recipient.viaPhone && !!recipient.phone) recips.push(recipient.phone);

        const newState = {
            ...receivers,
            sendRecipients: recips,
            isReminder: recipient.reminder
        };
        
        console.log(JSON.stringify(newState, null, 4));
        
        setReceivers(newState);
    };
    
    const onPaymentChange = (json) => {
        if (!json) console.error("No Json: ", json);
        else setPaymentJson(json);
    };
    
    const createPaymentAsync = async (e) => {
        suppress(e);
        
        console.log("Creating Payment:");
        console.log(paymentJson);

        await InvoiceService.instance.createPaymentAsync(invoiceId, paymentJson);
        await getInvoiceAsync(true);
        
        return false;
    };

    const suppress = (e) => {
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        if (typeof e?.stopPropagation === "function")
            e.stopPropagation();
    };
    
    const refreshAsync = async () => { 
        console.log("Refresh");
        await getInvoiceAsync(true);
    };
    
    const deleteInvoiceAsync = async (e) => {
        suppress(e);
        console.log('Delete Invoice.');
        //deleteController.open();
        return await InvoiceService.instance.deleteInvoiceAsync(invoiceId, companyId).then((rsp) => {
            setTimeout(() => {
                setRedirectUrl("/companies/" + companyId + "/invoices");
            }, 2000);
            return 1500;
        });
    };
    
    const showPaymentForm = async (e) => {
        suppress(e);
        console.log('Show Payment Form');
        //paymentController.open();
        paymentController.open(null, "Create Payment");
    };
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };
    
    const promptForSend = async () => {
        resendController.open();
    };
    
    const promptForDelete = async () => {
        deleteController.open();
    };
    
    const resendInvoiceAsync = async () => {
        return await InvoiceService.instance.resendInvoiceAsync(invoiceId, receivers.sendRecipients, receivers.isReminder).then((inv) => {
            return 500;
        });
    };

    const showJsonAsyc = () => { 
        jsonController.open();
    };
    
    const viewInvoice = () => { 
        const path = window.location.hostname.indexOf("localhost") >= 0 ?
            "http://localhost:3000/invoice/" + invoiceId :
            "https://payments.penumbralabs.io/invoice/" + invoiceId;
        window.open(path, "_blank");
    };

    useEffect(() => {
        getInvoiceAsync();
    }, []);

    useEffect(() => {
        if (!!invoice?.id && !!company?.id) {
            NavigationHistoryService.instance.addDelayedItem(company.name + " Invoice " + invoice.invoiceNumberDisplay, 1000);
        }

     }, [company, invoice]);

    if (!!redirectUrl) {
        return (<Navigate to={redirectUrl} />);
    }

    const invoiceItemElements = invoice?.items.map((item) => {
        return (
            <tr className={"invoice-line-item"} key={item.id}>
                <td><span>{item.name}</span></td>
                <td>{item.price.formatCurrency(2)}</td>
                <td>{item.qty.formatNumber(0)}</td>
                <td>{(item.getTaxAmount() || 0).formatCurrency(2)}</td>
                <td>{item.getAmount().formatCurrency()}</td>
            </tr>
        );
    });

    const paidStatus = invoice?.paidDate ? "Paid " + invoice.paidDate.formatDate("MMM DAY, yyyy HOUR:mm ap") : "Unpaid";
    const statusClassName = invoice?.paidDate ? "paid" : "unpaid";
    const dueDateClassName = !invoice?.dueDate ? "not-due" : (invoice.dueDate.getTime() < new Date().getTime() ? "overdue" : "due-date");
    
    const subtitle = (<> { company?.name }
        <span className={"subtitle"}>Invoice #{invoice?.invoiceNumberId?.toString() || "..."}</span>
        </>);
    
    const subMenu = (<CompanyInvoiceSubMenu company={company} invoice={invoice} selection={"invoices"} />);
    const resendActions = [(<HeaderButton persist={true} icon={faPaperPlane} onClick={promptForSend}>Re-Send</HeaderButton>)];
    const invoiceAttachments = invoice?.documents?.length > 0 ? (<InvoiceAttachmentsPanel invoice={invoice} />) : null;

    const summaryElements = invoice?.summary?.map((item, index) => {
        const cn = item.price < 0 ? "discount" : "summary-item";
        return (<tr key={"summary-item-" + index} className={cn}>
            <td>{item.name}:</td>
            <td colSpan={3}></td>
            <td>{(item.price * item.qty).formatCurrency(2)}</td>
            </tr>);
    });

    const webVisitorTable = !!invoice?.created ? (<div className="activity-table">
        <WebVisitorTable excludeColumns={["path"]} startDate={invoice?.created} domain={ConfigModel.paymentsDomain} paths={[ "invoice/" + invoiceId, invoiceId]} />
    </div>) : null;

    const paymentElement = !!invoice?.id && invoice?.paidDate === null ?
        (<li>
            <span className={"shrink"} style={{marginTop: "8px"}}>
                <a onClick={showPaymentForm} className={"flex8 black"}>
                    <FontAwesomeIcon icon={faBank} />
                    <span>Add Payment</span>
                </a>
            </span>
        </li>) :
        null;
    
    return (
        <BillingMasterPage actions={resendActions} onCompany={onCompany} onRefresh={ refreshAsync } selection={"invoices"} title={subtitle} subMenu={subMenu}>
            <div className="details-panel">
                <div id={"invoice-status"} className={"rounded-box"}>
                    <ul>
                        <li><DateTime value={invoice?.sentDate} time={true} prefix={"Sent on: "} defaultValue={"Not Sent Yet"} /></li>
                        <li>Status: <span className={statusClassName}><a onClick={() => viewInvoice()} target="_blank" rel="noreferrer">{ paidStatus }</a></span></li>
                        <li className={dueDateClassName}>Due: <span><DateTime value={invoice?.dueDate} time={true} /></span></li>
                        {paymentElement}
                    </ul>
                </div>
                <p id={"invoice-address"}>
                    <strong>Address: </strong> <Link to={"/companies/" + companyId + "/edit/address"}>{company?.address?.street + ", " + company?.address?.city + ", " + company?.address?.state + " " + company?.address?.zip}</Link><br/>
                    <strong>Contact: </strong> <Link to={"/companies/" + companyId + "/people/" + company?.primaryUserId}>{company?.primaryUser?.fullName}</Link><br/>
                    Invoice recipient on file for <strong>{company?.name}</strong> is <a href={"mailto:" + company?.email}>{company?.email}</a>
                </p>
                <div id={"invoice-actions"} className={"action-panel"}>
                    <ul>
                        <li><a onClick={promptForDelete} className={"action-button delete"}>Delete Invoice <FontAwesomeIcon icon={faDeleteLeft} /></a></li>
                        <li></li>
                    </ul>
                </div>
            </div>

            <div className={"invoice-items"}>
                <table width="100%" className={"invoice"}>
                    <thead>
                    <tr>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Tax</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>

                    {invoiceItemElements}

                    <tr>
                        <td colSpan={4} className={"no-td"}>&nbsp;</td>
                    </tr>

                        {summaryElements}
                    </tbody>
                </table>

                <div className={"total"}>
                    <span>Total:</span>
                    <strong>{invoice?.total.formatCurrency()}</strong>
                </div>
            </div>

            <div className="invoice-attachments">
                { invoiceAttachments }
            </div>

            {webVisitorTable}
            
            <div className="activity-table">
                <ActivityTable entityId={invoiceId} entityType={InvoiceModel.entityType} />
            </div>
            
            <div className={"comments"}>
                <CommentForm entityId={invoiceId} entityType={InvoiceModel.entityType} />
            </div>

            <div className="meta-details">
                <ul className="meta-details">
                    <li>
                        <CopyText text={invoiceId}>
                            <span>Id:</span>
                            <span>{invoiceId}</span>
                        </CopyText>
                    </li>
                    <li>
                        <CopyText text={invoice?.companyId}>
                            <span>CompanyId:</span>
                            <span>{invoice?.companyId}</span>
                        </CopyText>
                    </li>
                    <li>
                        <CopyText text={invoice?.userId}>
                            <span>UserId:</span>
                            <span>{invoice?.userId}</span>
                        </CopyText>
                    </li>
                    <li className="dialog-click" onClick={() => showJsonAsyc() }>
                        <div>
                            <span>JSON <FontAwesomeIcon icon={ faCode } /></span>
                        </div>
                    </li>
                </ul>
            </div>

            <DialogBox controller={jsonController} title="Invoice Raw Json">
                <CopyText text={JSON.stringify(invoice, null, 4)}><div className="code override">{JSON.stringify(invoice, null, 4)}</div></CopyText>
            </DialogBox>

            <DialogBox controller={resendController} onOkay={resendInvoiceAsync} okCaption={(<><FontAwesomeIcon icon={faPaperPlane} /> Resend</>)}>
                <InvoicePreview company={company} invoice={invoice} onChange={onRecipientChange} />
            </DialogBox>

            <DialogBox controller={paymentController} onOkay={createPaymentAsync} okCaption={(<><FontAwesomeIcon icon={faBank} /> Create</>)}>
                <InvoicePaymentForm onChange={onPaymentChange} invoice={invoice} options={{}} />
            </DialogBox>

            <DialogBox className={"confirm-delete"} controller={deleteController} onOkay={deleteInvoiceAsync} okCaption={(<><FontAwesomeIcon icon={faDeleteLeft} /> Delete</>)}>
                Are you sure you want to delete this invoice?<br/>
                It will become unavailable for future use.
            </DialogBox>
        </BillingMasterPage>        
    );

};

export default InvoiceDetailsScreen;

