import React, {useEffect, useState} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faCalendarDays, faList} from "@fortawesome/free-solid-svg-icons";
import CompanyService from "../../../components/companies/services/CompanyService";
import {useParams} from "react-router-dom";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import CompanyProjectsSubMenu from "../../../components/companies/ui/CompanyProjectsSubMenu";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import RoadmapCalendar from "../../../components/projects/ui/roadmap/RoadmapCalendar";
import Controller from "../../../components/common/services/Controller";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import ProjectService from "../../../components/projects/services/ProjectService";
import RoadmapWeekly from "../../../components/projects/ui/roadmap/RoadmapWeekly";
import HeaderButton from "../../../components/common/ui/HeaderButton";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";

const RoadmapScreen = (props) => {
    const {} = props;

    let {companyId} = useParams();
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [isList, setIsList] = useState(true);
    let [projects, setProjects] = useState(null);

    let calendarController = new Controller();
    let _;

    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const onProjectItemClick = (project, e) => {
        //
    };

    const getProjectsAsync = async (force) => {
        if (!force && (projects?.length || 0) > 0) {
            return;
        }

        await ProjectService.instance.getProjectsByCompanyAsync(companyId, true).then((ps) => {
            setProjects(ps);
        });

    };
    
    useEffect(() => {
        _ = getProjectsAsync();
    }, []);

    let viewSelector = { list: "", calendar: "" };

    if (isList) viewSelector["list"] = "selected";
    else viewSelector["calendar"] = "selected";

    let minStartDate = (new Date()).addDays(-2);

    calendarController.refreshAsync = (e) => {
        console.log("Roadmap Refresh");
        _ = getProjectsAsync(true);
    };
    
    let roadmapBody = projects === null ? (<LoadingBox />) :
        (
            isList ?
                (<RoadmapWeekly controller={calendarController} projects={projects ?? []} company={company} onClick={(item, e) => onProjectItemClick(item)} startDate={minStartDate} />) :
                (<RoadmapCalendar controller={calendarController} projects={projects ?? []} company={company} onClick={(item, e) => onProjectItemClick(item)} />)
        );
    
    return (
        <CompanyMasterPage onRefresh={(e) => getProjectsAsync(true)} menuSelection="projects" onCompany={onCompany} title={"Company Project Roadmap"}>
            <CompanyProjectsSubMenu company={company} selection={"roadmap"} />

            <p className={"full"}>
                <span className={"right"}>
                    <span className={"view-selector"}>
                        <a className={viewSelector.calendar} onClick={(e) => setIsList(false)}>
                            <FontAwesomeIcon icon={faCalendarDays} />
                        </a>
                        <a className={viewSelector.list} onClick={(e) => setIsList(true)}>
                            <FontAwesomeIcon icon={faList} />
                        </a>
                    </span>
                </span>
        
                TODO: Describe how the roadmap display works
            </p>
        
            <div>
                {roadmapBody}
            </div>
        </CompanyMasterPage>
    );

};

export default RoadmapScreen;