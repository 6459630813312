import React from 'react';
import {Link} from "react-router-dom";

const ProjectItemSubMenu = (props) => {
    let {project, projectItem, company, selection, companyId, projectId, projectItemId} = props;

    if (!companyId) companyId = company?.id || "00-00";
    if (!projectId) projectId = project?.id || "00-00";
    if (!projectItemId) projectItemId = projectItem?.id || "00-00";
    
    let sel = selection || 'main';
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";

    selections[sel] = 'selected';

    return (
        <div className={"submenu project-item" + cn}>
            <Link className={selections['main']} to={'/companies/' + companyId + '/projects/' + projectId + "/item/" + projectItemId}>Main</Link>
            <Link className={selections['content']} to={'/companies/' + companyId + '/projects/' + projectId + "/item/" + projectItemId + "/content"}>Content</Link>
            <Link className={selections['activity']} to={'/companies/' + companyId + '/projects/' + projectId + "/activity"}>Activity</Link>
        </div>
    );
};

export default ProjectItemSubMenu;