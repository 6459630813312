import React from 'react';
import {Link} from "react-router-dom";
import UserModel from "../models/UserModel";
import './UserBadge.css';

const UserBadge = (props) => {
    const {user, userLite, caption, children, bodyPlacement, className} = props;

    let u = (user || userLite);
    
    if (!u) return (<></>);

    let imageUrl = (u instanceof UserModel) ? u.getImageUrl("256x256") : userLite?.imageUrl;
    
    let name = (u.firstName + " " + u.lastName).trim();
    let userCaption = caption || u.userTypeName;
    
    const getInitials = () => { 
        let initials = "";
        if (u.firstName) initials += u.firstName[0];
        if (u.lastName) initials += u.lastName[0];
        
        if (initials.length === 0) initials = "☻";
        
        return (initials).toUpperCase();
    }
    
    let profileImage = (<span className={"user-badge-initials"}><label>{ getInitials() }</label></span>);
    
    if (imageUrl.length > 0) { 
        profileImage = (<img className={"user-badge-image"} src={imageUrl} alt={name} />);
        //profileImage = (<span className={"img"} style={{backgroundImage: "url('" + imageUrl + "')"}}>&nbsp;</span>);
    }
    
    let path = "/companies/" + u.companyId + "/people/" + u.id;
    if (!u.companyId) path = "/people/" + u.id;
    
    let bottomBody = null;
    let topBody = null;
    
    switch(bodyPlacement) {
        case "bottom":
            bottomBody = children || null;
            break;
        case "top":
            topBody = children || null;
            break;
    }
    
    let cn = (typeof className === 'string') ? " " + className : "";
    
    return (<div className={"user-badge" + cn}>
        {topBody}
        <div className={"user-badge-user"}>
            <span className={"user-badge-image"}><Link to={path}>{ profileImage }</Link></span>
            <span className={"user-badge-body"}>
                <ul>
                    <li><Link to={path}>{name}</Link></li>
                    <li>{userCaption}</li>
                </ul>
            </span>
        </div>
        {bottomBody}
    </div>);
};

export default UserBadge;
