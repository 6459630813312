class MessageSendLogModel { 
    static create = (json) => new MessageSendLogModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.messageTemplateId = json.message_template_id;
        this.entityId = json.entity_id;
        this.userId = json.user_id;
        this.entityType = json.entity_type;
        this.entityTypeName = json.entity_type_name || "";
        this.remoteId = json.remote_id;
        this.recipient = json.recipient;
        this.message = json.message;
        this.messageSendType = json.message_send_type;
        this.success = json.success;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            message_template_id: this.messageTemplateId,
            entity_id: this.entityId,
            user_id: this.userId,
            entity_type: this.entityType,
            remote_id: this.remoteId,
            recipient: this.recipient,
            message: this.message,
            message_send_type: this.messageSendType,
            success: this.success,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray) || !jsonArray) return [];
        return jsonArray.map(json => MessageSendLogModel.create(json));
    }
}

export default MessageSendLogModel;
