import React, {useEffect, useRef} from 'react';

const ProjectItemDates = (props) => {
    let {value, onChange, showEstimatedDates} = props;
    
    let estimatedStartDate = useRef();
    let durationRef = useRef();
    let startDayRef = useRef();
    
    useEffect(() => {
        if (value?.estimated_start_date && estimatedStartDate?.current)
            estimatedStartDate.current.value = value.estimated_start_date.toFormDate();
        
        if (durationRef?.current)
            durationRef.current.value = value?.duration.toString() || "1";
        
        if (startDayRef?.current)
            startDayRef.current.value = value?.start_day.toString() || "1";
        
    }, []);

    const onDateChange = (field, e) => {
        if (typeof onChange === 'function') {
            let d = parseInt(durationRef.current?.value);
            if (isNaN(d)) d = null;

            let sd = parseInt(startDayRef?.current?.value || "0");
            if (isNaN(sd)) sd = null;
            
            let esd = estimatedStartDate?.current?.value || null;
            
            let durationJson = {
                duration: d,
                estimated_start_date: esd ? new Date(esd) : null,
                start_day: sd,
                id: value?.id
            };
            
            onChange(durationJson, e);
        }
    };
    
    const getDate = (days) => {
        let d = new Date();

        if (typeof days === 'number') {
            if (days > 0) d = d.addDays(days);
        }

        if (typeof days === 'string') {
            switch(days) { 
                case "next-month":
                    d = d.toFirstOfTheMonth().addMonths(1);
                    break;
                case "next-week":
                    let diff = 7 - d.getDay();
                    d = d.addDays(diff);
                    break;
                case "week":
                    d = d.addDays(7);
                    break;
                case "month":
                    d = d.addMonths(1);
                    break;
            }
            
            let dd = d.getDay();
            if (dd === 6) d = d.addDays(2);
            if (dd === 0) d = d.addDays(1);
        }
     
        return d;
    }

    const setStartDate = (days, e) => {
        let d = getDate(days);
        estimatedStartDate.current.value = d.toFormDate();
    };
    
    const setDurationValue = (value, e) => {
        durationRef.current.value = value;
    };
    
    const setStartDayValue = (value, e) => {
        startDayRef.current.value = value;
    };
    
    let estimatedStartDateElements = showEstimatedDates ? (<><label>
        Estimated Start Date
        <a className={"action"} onClick={setStartDate.bind(this, 0)}>Today</a>
        <a className={"action"} onClick={setStartDate.bind(this, "next-week")}>Monday</a>
        <a className={"action"} onClick={setStartDate.bind(this, "next-month")}>Next Month</a>
    </label>
    <input type={"date"} onChange={onDateChange.bind(this, "start_date")} ref={estimatedStartDate} /></>) : null;
    
    return (<div className={"dialog form medium"}>
        {estimatedStartDateElements}
        <label className={"spaced"}>
            Day to Start
        </label>
        <div className={"double"}>
            <div className={"small"}>
                <input type={"number"} onChange={onDateChange.bind(this, "start_day")} ref={startDayRef} />
            </div>
            <div className={"small"}>Day</div>
        </div>
        
        <label className={"spaced"}>
            Duration (in days):
            <a className={"action"} onClick={setDurationValue.bind(this, "1")}>One Day</a>
            <a className={"action"} onClick={setDurationValue.bind(this, "5")}>One Week</a>
            <a className={"action"} onClick={setDurationValue.bind(this, "10")}>One Sprint</a>
            <a className={"action"} onClick={setDurationValue.bind(this, "20")}>Two Sprints</a>
        </label>
        
        <div className={"double"}>
            <div className={"small"}>
                <input type={"number"} onChange={onDateChange.bind(this, "duration")} ref={durationRef} />
            </div>
            <div className={"small"}>Days</div>
        </div>
        
    </div>);
};

export default ProjectItemDates;
