import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyMasterPage from "../../companies/ui/CompanyMasterPage";
import CompanyService from "../../companies/services/CompanyService";
import BillingSubMenu from "./BillingSubMenu";

const BillingMasterPage = (props) => {
    const { onRefresh, onCompany, selection, children, title, actions, bodyClassName } = props;
    const { companyId } = useParams();

    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);

    const onGetCompany = (c) => {
        if (!!c) setCompany(c);
    }

    useEffect(() => {
        if (typeof onCompany === 'function' && !!company?.id) onCompany(company);
    }, [company]);
    
    const submenu = !!company ? (<BillingSubMenu selection={selection || ""} company={company} />) : null;
    
    return (
        <CompanyMasterPage actions={actions} onRefresh={onRefresh} onCompany={onGetCompany} selection={"billing"} bodyClassName={bodyClassName} title={title || "Billing Information"}>
            { submenu }
            { children }
        </CompanyMasterPage>
    );
};

export default BillingMasterPage;

