/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import HttpService from '../../../components/common/services/HttpService';
import NumberDisplay from '../../../components/common/ui/NumberDisplay';
import InvoiceService from '../../billing/services/InvoiceService';
import DropTarget from '../../common/ui/drop-target/DropTarget';
import DocumentModel from '../../content/models/DocumentModel';
import FormButton from '../../common/ui/FormButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const InvoiceAttachmentsPanel = ({ invoice, onUpload, title, onDrop }) => {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [docState, setDocState] = useState({ view: 0 });

    const toggleView = () => { 
        const newState = docState.view === 0 ? 1 : 0;
        setDocState({ view: newState });
    };

    useEffect(() => { 
        
    }, []);

    const getDocUrl = (document) => {
        //collectionId
        if (document.id === "000-001") return "/api/invoice/" + invoice.id + "/invoice-pdf";
        if (document.id === "000-002") return "/api/invoice/" + invoice.id + "/receipt-pdf";
        
        return "/api/invoice/" + invoice.id + "/document/" + document.id + "/download";
    };

    const onFilesDropped = (fileList, itemList) => {
        console.log(fileList);
        let newFiles = [...filesToUpload];
        for (let i = 0; i < fileList.length; i++) {
            newFiles.push(fileList[i]);
        }

        setFilesToUpload(newFiles);
        
        // console.warn("------");
        // console.log(fileList);
    };

    const uploadFilesAsync = async (files) => { 
        if (filesToUpload.length === 0) return;
        
        const rsp = await InvoiceService.instance.uploadInvoiceDocumentsAsync(invoice.id, filesToUpload);
        const newDocuments = Array.isArray(rsp?.data) ? DocumentModel.fromJsonArray(rsp.data) : [];

        setFilesToUpload([]);

        if (newDocuments.length > 0)
            invoice.documents.push(...newDocuments);

        if (typeof onUpload === "function") { 
            const rsp = onUpload(filesToUpload);
        }
    }

    const deleteDocumentAsync = async (document) => { 
        console.log("Deleting: " + document?.id);

        invoice.documents = invoice.documents.filter((doc) => doc.id !== document.id);
        setDocState({ view: 0 });

        if (!document?.id || document.id.startsWith("000-")) { 
            return await InvoiceService.instance.clearPdfFilesAsync(invoice.id);
        }
        
    };

    const downloadFileAsync = async (document) => {
        const documentId = document.id;

        console.log("DocumentId: " + document.id);
        console.log("InvoiceId: " + invoice.id);

        await InvoiceService.instance.downloadPdfAsync(documentId, invoice.id, true).catch((ex) => { 
            const message = ex?.response?.data?.message || (ex?.message || "Failed to download PDF.");
            console.error("Failed: " + message);
            //setErrors({ top: message });
        });
        
    };

    const downloadFileXAsync = async (document) => {
        if (document?.id.length <= 6) return;

        const apiPath = getDocUrl(document);
        if (!apiPath) return;
        
        let fileName = document.name.substr(document.name.length - 4) === document.fileName.substr(document.fileName.length - 4) ?
            document.name :
            document.fileName;

        if (document.id === "000-001") fileName = "invoice-" + invoice.invoiceNumberId + ".pdf";
        if (document.id === "000-002") fileName = "receipt-" + invoice.invoiceNumberId + ".pdf";
        
        console.log("Api Path: " + apiPath);

        await HttpService.instance.getBlobAsync(apiPath, { fileName: null, download: true }).then((model) => {
            console.log(model.fileName);
            console.warn(model.headers);

        }).catch((ex) => { 
            //
            console.error("Failed to download blob.");
            throw ex;
        });

    };

    const attachments = [...invoice.documents, ...filesToUpload.map((f, i) => new DocumentModel({ fileName: f.name, name: f.name, id: (i.toString().padStart(6))}))];
    const fileElements = attachments.map((doc, index) => { 
        const cn = doc.id.length <= 6 ? "new-file" : "file";
        const applyAction = docState.view === 1 ? deleteDocumentAsync : downloadFileAsync;
        return (<a onClick={() => applyAction(doc)} key={"file-" + index} className={cn}>
            <FontAwesomeIcon icon={faClose} className="delete-icon" />
            {doc.name || doc.fileName}
        </a>);
    });

    const titleElement = typeof title === "string" ? (<>{title}</>) : (<>Attachments (<NumberDisplay value={invoice.documents.length} />)</>);
    const uploadButton = filesToUpload.length > 0 ?
        (<span className="upload-invoice-documents-button"><FormButton onClick={() => uploadFilesAsync()}>Upload</FormButton></span>) :
        null;

    return (<DropTarget className="invoice-attachments-panel" onDrop={onFilesDropped}>
        <span className="top-right hover-view">
            <a onClick={() => toggleView()}>Edit</a>
        </span>
        <h3>{titleElement}</h3>
        <div className={"files " + (docState.view === 1 ? "edit" : "view")}>
            {fileElements}
        </div>
        {uploadButton}
    </DropTarget>);
}

export default InvoiceAttachmentsPanel;
