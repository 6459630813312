import React, {useEffect, useState} from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt, faFaceSmile} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import UserBadge from "../../components/people/ui/UserBadge";
import MyAccountMasterPage from "../../components/my-account/ui/MyAccountMasterPage";

const MyAccountScreen = (props) => {
    let [user, setUser] = useState(AuthenticationService.instance.session?.user || null);

    const onUser = (u) => {
        setUser(u);
    };
    
    let name = user?.firstName || 'Fellow Traveller';
    let welcomeMessage = "Hello, " + name;
    
    return (
        <MyAccountMasterPage title={"My Account"} onUser={onUser} subTitle={welcomeMessage}>
            <p>Toast</p>
        </MyAccountMasterPage>
    )
    
};

export default MyAccountScreen;
