import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyService from "../../companies/services/CompanyService";
import UserService from "../../people/services/UserService";
import CompanyEffect from "../../companies/services/CompanyEffect";
import UserEffect from "../../people/services/UserEffect";
import HeaderButton from "../../common/ui/HeaderButton";
import {faPeopleGroup, faSync} from "@fortawesome/free-solid-svg-icons";
import CommentForm from "../../messaging/ui/CommentForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserSubMenu from "../../people/ui/UserSubMenu";
import MasterScreen from "../../../MasterScreen";
import AuthenticationService from "../../authentication/services/AuthenticationService";
import ConfigModel from "../../common/models/ConfigModel";

const MyAccountMasterPage = (props) => {
    const {
        onUser,
        onCompany,
        onRefresh,
        userModel,
        companyModel,
        selection,
        selector,
        children,
        controller,
        data,
        noSubMenu,
        title,
        subTitle,
        entityId,
        entityType,
        icon,
        commentEntityType,
        altMenu
    } = props;

    const params = useParams();

    const companyId = ConfigModel.companyId;
    const userId = (userModel?.id || AuthenticationService.instance.session?.userId) || "";
    
    const [company, setCompany] = useState(companyModel || (CompanyService.instance.companyMap[companyId] || null));
    const [user, setUser] = useState(AuthenticationService.instance.session?.user || null);

    const onSessionChange = (session) => {
        if (!!session?.user) {
            console.warn("MyMaster Session Got: ");
            setUser(session.user);
        }
        //else setMyState(myState + 1);
    };

    const getCompanyAsync = async (force) => {
        if (!!company?.id && !force) return;
        if (!companyId) return;
        
        await CompanyService.instance.getCompanyAsync(companyId).then((c) => {
            if (!!c) setCompany(c);
        });
    };

    const getUserAsync = async (force) => {
        if (!!user?.id && !force) return;
        if (!userId) return;
        
        await UserService.instance.getUserAsync(userId).then((u) => {
            if (!!u) setUser(u);
        });
    };

    const refreshAsync = async (e) => {
        const rf = (typeof onRefresh === "function") ? onRefresh : async (r) => true;
        
        const rsp = await Promise.all([
            getCompanyAsync(true),
            getUserAsync(true),
            rf(true),
        ]);
    };

    const notify = (e) => {
        if (!!company && typeof onCompany === 'function') {
            onCompany(company);
        }

        if (!!user && typeof onUser === 'function') {
            onUser(user);
        }
    }

    useEffect(() => {
        notify();
    }, [company, user]);

    useEffect(() => {
        CompanyEffect.create(setCompany, companyId);
        let _ = getUserAsync();
        
        notify();
    }, []);

    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this CompanyMasterPage. If you meant to have this blank, try passing a null value to the "company" property.</p>)
    const refreshButton = (typeof onRefresh === 'function') ? (<HeaderButton icon={faSync} onClick={refreshAsync}>Refresh</HeaderButton>) : null;
    const comments = typeof commentEntityType === 'number' && typeof entityId === 'string' && entityId.length > 10 ?
        (<CommentForm entityType={commentEntityType} entityId={entityId} />) :
        null;

    const h1 = (<><FontAwesomeIcon icon={icon || faPeopleGroup}/>{title}</>);

    const subMenu = !!user?.id ? (<UserSubMenu isMe={true} selection={selection || "main"} user={user} />) : null;

    return (
        <MasterScreen selector={selector || ""} title={h1} onSessionChange={onSessionChange}>
            <div id="users-main" className="pad">
                <div className="details-body">
                    { subMenu }

                    <div className="pad x-large">
                        <h2>
                            {typeof title === 'undefined' ? "Users Page" : subTitle}
                            {refreshButton}
                        </h2>
                        { altMenu }
                        { body }
                        { comments }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );

};

export default MyAccountMasterPage;

