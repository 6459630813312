import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const InvoiceItemForm = (props) => {
    const { item, onItemAdded } = props;

    let itemName = useRef();
    let itemPrice = useRef();
    let itemQty = useRef();
    let itemTax = useRef();
    let itemIsTaxPercent = useRef();
    
    let fields = [itemName, itemPrice, itemQty, itemTax, itemIsTaxPercent];
    
    const onChange = (fieldId, e) => {
        if (e.key) {
            console.log('Key: ' + e.key);
            if (e.key === "Enter") {
                let index = fields.indexOf(fieldId);
                
                if (index < fields.length - 1) {
                    let nextField = fields[index + 1];
                    nextField?.current?.focus();
                } else {
                    // Add item to invoice
                    onAddItem(e);
                }
            }
            
            return;
        }
        
        if (fieldId === "name") {
            //
        }
    };
    
    const onAddItem = (e) => {
        if (typeof onItemAdded === 'function') { 
            let newItem = {
                name: itemName.current?.value || "",
                price: parseFloat(itemPrice.current?.value || "0") || 0.0,
                qty: parseInt(itemQty.current?.value || "") || 1,
                tax: parseFloat(itemTax.current?.value || "") || 0.0,
                is_tax_percent: (itemIsTaxPercent?.current?.value || "false").toLowerCase() === "true",
            };
            
            console.warn(JSON.stringify(newItem));
            
            if (!isNaN(newItem.price) && !isNaN(newItem.qty)) {
                if (!newItem.name) newItem.name = "Service Fee";
                onItemAdded(newItem);
                
                if (itemName?.current) itemName.current.value = "";
                if (itemPrice?.current) itemPrice.current.value = "";
                if (itemQty?.current) itemQty.current.value = "";
                if (itemTax?.current) itemTax.current.value = "";
                
                itemName.current?.focus();
            } else { 
                
                if (isNaN(newItem.price)) {
                    itemPrice.current?.focus();
                } else if (isNaN(newItem.qty)) {
                    itemQty.current?.focus();
                }
            }
        }
        
    };
    
    return (<div className={"invoice-edit-item"}>
        <span className={"name"}>
            <label>Item Name</label>
            <input id={"invoice-item-form-item-name"} type={"text"} ref={itemName} onKeyDownCapture={onChange.bind(this, itemName)} onChange={onChange.bind(this, itemName)} />
        </span>

        <span className={"price"}>
            <label>Price Ea.</label>
            <input id={"invoice-item-form-item-price"} type={"text"} ref={itemPrice} onKeyDownCapture={onChange.bind(this, itemPrice)} onChange={onChange.bind(this, itemPrice)} />
        </span>

        <span className={"price"}>
            <label>Tax</label>
            <input id={"invoice-item-form-item-tax"} type={"text"} ref={itemTax} onKeyDownCapture={onChange.bind(this, itemTax)} onChange={onChange.bind(this, itemTax)} />
        </span>

        <span className={"price"}>
            <label>Tax Unit</label>
            <select id={"invoice-item-form-item-is-tax-percent"} ref={itemIsTaxPercent} onKeyDownCapture={onChange.bind(this, itemIsTaxPercent)} onChange={onChange.bind(this, itemIsTaxPercent)}>
                <option value={"false"}>$</option>
                <option value={"true"}>%</option>
            </select>
        </span>

        <span className={"qty"}>
            <label>Qty</label>
            <input id={"invoice-item-form-item-qty"} type={"text"} ref={itemQty} onKeyDownCapture={onChange.bind(this, itemQty)} />
        </span>
        
        <span className={"done"}>
            <a className={"add"} onClick={onAddItem.bind(this)}><FontAwesomeIcon icon={faCheckCircle} /> Add</a>
        </span>
    </div>);
};

export default InvoiceItemForm;
