import HttpService from "../../common/services/HttpService";
import SubscriptionModel from "../models/SubscriptionModel";
import UserSubscriptionModel from "../models/UserSubscriptionModel";

class SubscriptionService { 
    static instance = new SubscriptionService();

    constructor() {
        this.subscriptionsMap = {};
        this.subscriptions = [];
        this.subscriptionMap = {};
    }

    async getSubscriptionAsync(subscriptionId) {
        const path = '/api/subscription/' + subscriptionId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.subscriptionMap[subscriptionId] = new SubscriptionModel(response.data);
            return me.subscriptionMap[subscriptionId];
        });
    }

    async getSubscriptionsAsync() {
        const path = "/api/subscription";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.subscriptions = response.data.map((subscription) => {
                return new SubscriptionModel(subscription);
            });

            return me.subscriptions;
        });
    }
    
    async getUsersSubscriptionsByCompanyAsync(companyId) { 
        const path = "/api/company/" + companyId + "/user-subscription";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            if (Array.isArray(rsp?.data))
                return UserSubscriptionModel.fromJsonArray(rsp.data);
            
            return null;
        });
    }
    
    async updateUserSubscriptionAmountAsync(userSubscriptionId, amount) {
        const path = "/api/subscription/" + userSubscriptionId + "/amount";
        const me = this;
        const json = { name: null, amount: amount };
        
        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            return UserSubscriptionModel.create(rsp.data);
        });
    }
}

export default SubscriptionService;
