import React, {useEffect, useRef, useState} from 'react';
import {Link, Navigate} from "react-router-dom";
import piLogo from "./images/logo-white.png";

import AuthenticationService from "./components/authentication/services/AuthenticationService";
import NotificationService from "./components/messaging/services/NotificationService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowTrendUp,
    faGears,
    faHouseChimneyCrack,
    faBell,
    faSignOutAlt,
    faBriefcase,
    faCreditCardAlt,
    faPeopleGroup,
    faGhost,
    faPeopleCarryBox,
    faBookSkull,
    faSearch, faPaperPlane

} from "@fortawesome/free-solid-svg-icons";
import LoginScreen from "./routes/authentication/LoginScreen";

import NotificationsDropdown from "./components/messaging/ui/NotificationsDropdown";
import { Tooltip} from "@mui/material";
import DialogBox from "./components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "./components/common/ui/dialog-box/DialogBoxController";
import SignalRMessageModel from "./components/messaging/models/SignalRMessageModel";
import NavigationHistory from "./components/activity/ui/NavigationHistory";
import ConfigModel from "./components/common/models/ConfigModel";

const MasterScreen = (props) => {
    let { selector, onSessionChange, pageKey, onClick, title, noInitialScroll } = props;
    const [ connection, setConnection ] = useState(NotificationService.instance.connection);
    
    let selections = {};
    let _;
    
    AuthenticationService.instance.init(pageKey || "NULL");

    let [navigateTo, setNavigateTo] = useState(null);
    
    const [notificationState, setNotificationState] = useState(0);
    const [session, setSession] = useState(AuthenticationService.instance.session);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchDialogController, setSearchDialogController] = useState(new DialogBoxController("Search"));
    const [lastNotificationTime, setLastNotificationTime] = useState(0);
    const [notifications, setNotifications] = useState(NotificationService.instance.notifications || []);

    const searchContainerRef = useRef();
    
    if (selector) selections[selector] = 'selected';

    AuthenticationService.instance.onSessionUpdate = (session, message) => {
        if (session !== null) {
            setSession(session);
        }
    };

    const getSessionAsync = async (force) => {
        if (force !== true) force = false;
        
        await AuthenticationService.instance.getSessionAsync(force).then((sh) => {
            let isNew = (sh !== session);
            setSession(sh);
        });
    };
    
    // Always scroll to the top of the page when the page changes
    const resetScroll = () => {
        window.scrollTo(0, 0);
    };

    const getNotificationsAsync = async () => {
        const c = notifications?.length || 0;
        console.log("Getting notifications [" + c + "]...");
        
        await NotificationService.instance.getNotificationsAsync().then((ns) => { 
            console.log("Got notifications: " + ns.length);
            
            setTimeout(() => {
                setNotifications(ns);
            }, 100);
        });
        
    };

    const handleSignalRMessage = (messageModel) => {
        if (!messageModel) {
            console.warn("Invalid Message Model: " + messageModel);
            return;
        }
        
        console.log("SignalR: " + messageModel.typeName);
        console.log("Message: " + messageModel.message);

        _ = getNotificationsAsync();
    };

    const signOut = (e) => {
        AuthenticationService.instance.signOutAsync().then(() => {
            setSession(null);
            if (typeof onSessionChange === 'function') onSessionChange(null);
        }).catch((error) => console.log('Ex: ' + error));
    };

    const initSignalRConnection = () => {
        const newConnection = NotificationService.instance.setConnection(connection);
        setConnection(newConnection);
    };
    
    useEffect(() => {
        console.log("Notifications good.");
    }, [notifications]);
    
    useEffect(() => {
        console.log("Connection State: " + connection?.state);
        
        if (connection) {
            if (connection.state.toString() === "Disconnected") connection.start()
                .then(result => {
                    connection.on('onMessage', message => {
                        console.log("Incoming SignalR Message: " + message);
                        handleSignalRMessage(new SignalRMessageModel(message));
                    });
                    
                    connection.on("onConnect", connectionId => {
                        _ = NotificationService.instance.registerConnectionAsync(connectionId);
                    });
                    
                    connection.on("onDisconnect", (notification) => {
                        console.log("Disconnected from real-time server");
                    });
                })
                .catch(e => console.warn('Connection failed: ', e));
            else if (connection.state.toString() !== "Connected") { 
                console.error("Connection State BAD: " + connection.state);
            }
        }
    }, [connection]);
    
    useEffect(() => { 
        if (!!session?.id && typeof onSessionChange === 'function')
            onSessionChange(session);

    }, [session]);

    useEffect(() => {
        if (noInitialScroll !== true) resetScroll();
        
        initSignalRConnection();

        getSessionAsync();
        getNotificationsAsync();
    }, []);
    
    if (navigateTo) return (<Navigate to={navigateTo?.toString()} />);
    
    if (session === null) { 
        if (!AuthenticationService.instance.isLoggedIn()) return (<LoginScreen />);
        return (
            <div style={{padding: '32px'}}>Refreshing session { session?.id } ...</div>
        );
    }

    let user = session?.user;

    const onNotificationClick = (notification) => {
        let routeUri = notification?.uri;
        
        if (routeUri) {
            console.log(routeUri);

            if (routeUri !== window.location.pathname) {
                setNavigateTo(notification.uri);
            }
        }
    };
    
    const onPageClick = (e) => {
        if (typeof onClick === 'function') {
            onClick(e);
        }

        closeNotificationBox("main", e);
    };
    
    const onSearchClick = (e) => {
        console.log("Slide");
        searchDialogController.open();
    };
    
    const onNotificationsClick = (e) => {
        //if (notifications.length === 0 && notificationState === 0) return;
        console.log('Clicked notification: ' + notificationState);

        let newState = notificationState;
        
        if (newState === 0) newState = 1;
        else newState = 0;

        setNotificationState(newState);
    };

    const closeNotificationBox = (key, e) => {
        e?.preventDefault();
        if (!e) return;
        
        if (notificationState === 0) return;
        
        if (e.target.tagName === 'path' || (e.target.className && e.target.className?.indexOf('uin-box') >= 0) || (e.target.className && e.target.className.indexOf('boc-span date-time-text') >= 0)) {
            return;
        }

        setNotificationState(0);
    };

    const imageUrl = user?.getImageUrl('64x64') || '';

    const profileDetails = !!user?.firstName ?
        (<ul><li key={"user-name-key"}><Link to={"/me"}>{user?.firstName + ' ' + user?.lastName}</Link></li><li key={"master-name-key"}>Master User</li></ul>) :
        (<ul><li key={"user-name-loading-key"}>Loading...</li><li>.</li></ul>);

    const profileImage = imageUrl.length > 5 ?
        (<img src={imageUrl} alt="My Logo" />) :
        (<FontAwesomeIcon icon={faGhost} />);

    const historyItemsHeader = (<li key={"history-items-header"} className={"header"}>Recent Locations</li>);
    
    const userMenuElement = (
        <div className="user-menu">
            <NavigationHistory length={6} onChange={(key) => setNotificationState(notificationState + 1)} className={"nav-history"} />
        </div>
    );

    const notificationsBox = (notificationState === 0) ?
        (<></>) :
        (<NotificationsDropdown onClick={onNotificationClick} notifications={notifications} />);
    
    const tooltipClasses = { 
        tooltip: "notifications-tooltip",
        popper: "notifications-tooltip-popper"
    };
    
    let notificationCount = notifications.filter((n) => !n.readDate).length;
    let notificationBadge = notificationCount > 0 ?
        (
            <Tooltip key={"x-key-0"} classes={tooltipClasses} arrow={true} open={notificationState === 1} placement="bottom" title={notificationsBox}>
                <span className="badge" id="notification-004" onClick={onNotificationsClick}>
                    <span className="badge-content" id="main-notification-badge">{ notificationCount.formatNumber(0)}</span>
                </span>
            </Tooltip>
        ) : (<></>);

    const searchButton = notificationCount < -10 ? (<span key={"x-key-1"} className="section" id="main-search-section" onClick={onSearchClick}>
            <span className="content circle" id="search-001">
                <span className={"top-circle"} id="search-top" onClick={onSearchClick}>
                    <FontAwesomeIcon icon={faSearch} id="search-003" />
                </span>
            </span>
        </span>) : null;
    
    return (<div id="main" onClick={onPageClick.bind(this)}>
        <div id="my-header">
            <div className={"inner"}>
                <span id={"title-h1"}>
                    <h1>{ title || ConfigModel.companyName}</h1>
                </span>
                
                <span id={"my-header-account-panel"}>
                    { searchButton }
                    <span className="section" id="main-notification-section" onClick={onNotificationsClick}>
                        <span className="content circle" id="notification-001">
                            <span id="notifications-top" onClick={onNotificationsClick}>
                                <FontAwesomeIcon icon={faBell} id="notification-003" />
                            </span>
                            {notificationBadge}
                        </span>
                    </span>
                    
                    <Tooltip title={userMenuElement} placement={"bottom"} arrow={true}>
                        <span className="section">
                                <span id="my-profile-top-menu-item">
                                    <span className="default-image">{profileImage}</span>
                                    <span id="my-profile-account-details-top">
                                            {profileDetails}
                                    </span>
                                </span>
                        </span>                            
                    </Tooltip>
                </span>
            </div>
        </div>

        <div id="main-column">
            <div id={"main-column-scroller"}>
                <div id="logo">
                    <Link to="/"><img src={piLogo} id={"logo-image"} alt={"Toast"} /></Link>
                    <label>{ ConfigModel.companyName }</label>
                </div>
                
                <ul>
                    <li key={"mx-key-10"} className={selections['home']} id="menu-home"><Link to="/"><FontAwesomeIcon icon={faHouseChimneyCrack} /> Home</Link></li>
                    <li key={"mx-key-11"}  className={selections['companies']} id="menu-companies"><Link to="/companies"><FontAwesomeIcon icon={faBriefcase} /> Companies</Link></li>
                    <li key={"mx-key-14"}  className={selections['metrics']} id="menu-metrics"><Link to="/metrics"><FontAwesomeIcon icon={faArrowTrendUp} /> Growth Metrics</Link></li>
                    <li key={"mx-key-13"}  className={selections['tools']} id="menu-tools"><Link to="/tools"><FontAwesomeIcon icon={faPeopleCarryBox} /> Tools and Productivty</Link></li>
                    <li key={"mx-key-16"}  className={selections['contact-requests']} id="menu-contact-requests"><Link to="/contact-requests"><FontAwesomeIcon icon={faPaperPlane} /> Contact Requests</Link></li>
                    <li key={"mx-key-15"}  className={selections['users']} id="menu-users"><Link to="/people"><FontAwesomeIcon icon={faPeopleGroup} /> Personnel</Link></li>
                    <li key={"mx-key-17"}  className={selections['documentation']} id="menu-documentation"><Link to="/documentation"><FontAwesomeIcon icon={faBookSkull} /> Documentation</Link></li>

                    <li key={"mx-key-12"}  style={{display: "none"}} className={selections['invoices']} id="menu-invoices"><Link to="/invoices"><FontAwesomeIcon icon={faCreditCardAlt} /> Invoicing</Link></li>
                    <li key={"mx-key-18"}  style={{display: "none"}} className={selections['settings']} id="menu-settings"><Link to="/settings"><FontAwesomeIcon icon={faGears} /> Settings</Link></li>
                </ul>

                <ul id="mini-menu">
                    <li key={"x-mini-key-0"}>
                        <a onClick={signOut}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
                        </a>
                    </li>
                    <li key={"x-mini-key-1"}>
                        <a href={"https://bocgventures.com"} target={"_blank"} rel="noreferrer">
                            <FontAwesomeIcon icon={faSignOutAlt} /> Penumbra Labs
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div id="main-body" className={selector}>
            { props.children }
        </div>
        <DialogBox controller={searchDialogController}>
            Toast
        </DialogBox>
    </div>);
};

export default MasterScreen;