import HttpService from "../../common/services/HttpService";
import ProjectModel from "../models/ProjectModel";
import ProjectItemModel from "../models/ProjectItemModel";
import ProjectItemDayModel from "../models/ProjectItemDayModel";
import DocumentModel from "../../content/models/DocumentModel";
import RoadmapMetaModel from "../models/RoadmapMetaModel";
import ProjectItemListItemModel from "../models/ProjectItemListItemModel";
import ProjectDashboardModel from "../models/ProjectDashboardModel";
import WorkflowViewModel from "../models/WorkflowViewModel";

class ProjectService {
    static instance = new ProjectService();
    static projectColorsCount = 6;

    constructor() {
        this.projectsMap = {};
        this.projects = [];
        this.projectMap = {};
        this.projectItemMap = {};
        this.contentMap = {};

        this.userProjectsDueMap = {};
        this.userProjectsMap = {};
        this.companyProjectsMap = {};
    }

    /**
     * Gets a project from the server*
     * @param projectId
     * @returns {Promise<ProjectModel>}
     */
    async getProjectAsync(projectId) {
        const path = '/api/project/' + projectId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.projectMap[projectId] = new ProjectModel(response.data);
            return me.projectMap[projectId];
        });
    }

    async getProjectItemAsync(projectItemId) {
        const path = '/api/project-item/' + projectItemId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.projectItemMap[projectItemId] = new ProjectItemModel(response.data);
            return me.projectItemMap[projectItemId];
        });
    }
    
    async getProjectContentAsync(projectId) {
        const path = '/api/project/' + projectId + "/content";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const docs = DocumentModel.fromJsonArray(response.data);
            me.contentMap[projectId] = docs;
            
            return docs;
        });
    };
    
    async getProjectItemContentAsync(projectItemId, companyId = null) {
        const path = typeof companyId === "string" && companyId.length > 30 ?
            "/api/company/" + companyId + "/project-item/" + projectItemId + "/content" :
            "/api/project-item/" + projectItemId + "/content";
        
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            if (!response?.data) return null;
            
            const docs = DocumentModel.fromJsonArray(response.data);
            me.contentMap[projectItemId] = docs;

            return docs;
        });
    };

    async getProjectWorkflowViewAsync(companyId) {
        let path = "/api/company/" + companyId + "/project-view";
        const me = this;

        return await HttpService.instance.getAsync(path, false).then((response) => {
            return WorkflowViewModel.fromJsonArray(response?.data?.workflows);
        });
    }
    
    async getProjectsByCompanyAsync(companyId, includeItems = false, sid = null) {
        let path = "/api/company/" + companyId + "/project";
        const me = this;

        if (includeItems === true) path = "/api/company/" + companyId + "/project-item";
        
        let h = null;
        if (!!sid) h = { 'session-id': sid };
        
        return await HttpService.instance.getAsync(path, false, h).then((response) => {
            let list = ProjectModel.fromJsonArray(response.data);
            me.companyProjectsMap[companyId] = list;

            return list;
        });
    }
    
    // async getProjectsByUserAsync(userId) {
    //     let path = "/api/project";
    //
    //     if (!!userId && userId.length > 10) path = "/api/user/" + userId + "/project";
    //     else userId = null;
    //
    //     const me = this;
    //
    //     return await HttpService.instance.getAsync(path).then((response) => {
    //         let list = ProjectModel.fromJsonArray(response.data);
    //
    //         if (!!userId) me.userProjectsMap[userId] = list;
    //
    //         return list;
    //     });
    // }

    async getProjectItemsDueByUserAsync(userId = null) {
        const path = (!!userId) ? "/api/user/project" : "/api/project";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const list = ProjectItemListItemModel.fromJsonArray(response.data);
            me.userProjectsDueMap[userId] = list;

            console.warn("Got My Due Projects: " + list.length);
            return list;
        });
    }

    async getProjectDashboardAsync(ids = []) { 
        const path = "/api/project-dashboard";
        let qs = "";

        if (Array.isArray(ids) && ids.length > 0) {
            const idstring = ids.join(',');
            qs = "?ids=" + idstring;
        }

        return await HttpService.instance.getAsync(path + qs).then((rsp) => ProjectDashboardModel.create(rsp?.data));
    };

    async saveProjectAsync(projectJson) {
        let path = '/api/project';
        
        if (projectJson.id && projectJson.id.length > 30) { 
            path += '/' + projectJson.id;
            delete projectJson.id;
        }
        
        const me = this;
        console.warn('Saving: ' + path);
        
        return await HttpService.instance.postAsync(path, projectJson).then((response) => {
            let project = new ProjectModel(response.data);

            setTimeout(() => {
                me.getProjectsByCompanyAsync(project.id);
            }, 50);

            return project;
        });
    }

    async saveProjectItemAsync(projectItemJson, projectId) {
        if (!projectId) {
            projectId = projectItemJson.project_id;
            delete projectItemJson.project_id;
        }

        let projectItemId = projectItemJson.id || projectItemJson.duration?.id;
        
        delete projectItemJson.project_id;
        delete projectItemJson.duration?.id;
        delete projectItemJson.id;
        
        let path = '/api/project/' + projectId + '/item';
        if (projectItemId && projectItemId.length > 10) path = "/api/project-item/" + projectItemId;
        
        const me = this;

        return await HttpService.instance.postAsync(path, projectItemJson).then((response) => {
            return new ProjectItemModel(response.data);
        });
    }
    
    async updateProjectDescriptionAsync(projectId, description, name = null) {
        let errors = [];
        if (!projectId) errors.push('Missing project id.');
        if (!description) errors.push('Missing description.');
        if (errors.length > 0) throw errors.join(' ');

        const path = '/api/project/' + projectId + '/description';
        const me = this;
        const data = { description, name };

        console.log("Updating ProjectItem Description");

        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new ProjectModel(response.data);
        });
    };

    async uploadProjectContentAsync(projectId, formFiles) {
        const path = '/api/project/' + projectId + '/content/upload';
        const me = this;

        return await HttpService.instance.uploadAsync(path, formFiles).then((response) => {
            const docs = me.contentMap[projectId] || [];
            const newDocs = DocumentModel.fromJsonArray(response.data);
            
            if (newDocs) {
                docs.concat(newDocs);
                me.contentMap[projectId] = docs;
            }
            
            return me.contentMap[projectId];
        });
        
    };

    async uploadProjectItemContentAsync(projectItemId, formFiles) {
        if (!formFiles || formFiles.length === 0) return [];
        
        const path = '/api/project-item/' + projectItemId + '/content/upload';
        const me = this;

        return await HttpService.instance.uploadAsync(path, formFiles).then((response) => {
            const newDocs = DocumentModel.fromJsonArray(response.data);

            if (!!me && newDocs) {
                const docs = me.contentMap[projectItemId] || [];
                docs.concat(newDocs);
                
                me.contentMap[projectItemId] = docs;
            }

            return newDocs;
        });

    };
    
    async createProjectItemContentAsync(projectItemId, textItems, documentType = null) {
        if (!textItems || textItems.length === 0) return [];
        
        const path = '/api/project-item/' + projectItemId + '/content';
        const me = this;

        const data = textItems.map((item) => {
            return {
                document_type: documentType,
                content_type: !!item.uri ? 36 : 1,
                file_name: "",
                name: item.name || "",
                description: null,
                content: item.text,
                uri: item.uri || null
            };
        });
        
        console.log(JSON.stringify(data));
        //return;
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            const newDocs = DocumentModel.fromJsonArray(response.data);

            if (!!me && newDocs) {
                const docs = me.contentMap[projectItemId] || [];
                docs.concat(newDocs);

                me.contentMap[projectItemId] = docs;
            }

            return newDocs;
        });

    };

    async createProjectContentAsync(projectId, textItems, documentType = null) {
        if (!textItems || textItems.length === 0) return [];

        const path = '/api/project/' + projectId + '/content';
        const me = this;

        const data = textItems.map((item) => {
            return {
                document_type: documentType,
                content_type: !!item.uri ? 36 : 1,
                file_name: "",
                name: item.name || "",
                description: null,
                content: item.text,
                uri: item.uri || null
            };
        });

        console.log(JSON.stringify(data));
        //return;

        return await HttpService.instance.postAsync(path, data).then((response) => {
            const newDocs = DocumentModel.fromJsonArray(response.data);

            if (!!me && newDocs) {
                const docs = me.contentMap[projectId] || [];
                docs.concat(newDocs);

                me.contentMap[projectId] = docs;
            }

            return newDocs;
        });

    };

    async deleteProjectAsync(projectId) {
        if (!projectId) return false;
        
        const path = "/api/project/" + projectId;
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return true;
        });
    };


    async deleteProjectItemAsync(projectItemId) {
        const path = "/api/project-item/" + projectItemId;
        const me = this;
        
        return await HttpService.instance.deleteAsync(path).then((response) => {
            return true;
        });
    };

    /**
     * Updates the stage of a project item and returns the newly updated ProjectModel
     * @param projectId
     * @param stageType
     * @returns {Promise<ProjectModel>}
     */
    async updateProjectStageAsync(projectId, stageType) {
        const path = '/api/project/' + projectId + '/stage/' + stageType;
        console.warn(path);
        const me = this;

        return await HttpService.instance.postAsync(path).then((response) => {
            let p = new ProjectModel(response.data);
            me.projectMap[p.id] = p;
           
            return p;
        });
    }

    /**
     * Updates the stage of a project item and returns the newly updated ProjectItemModel
     * @param projectItemId
     * @param projectId
     * @param stageType
     * @returns {Promise<ProjectItemModel>}
     */
    async updateProjectItemStageAsync(projectItemId, projectId, stageType) {
        let errors = [];
        if (!projectId) errors.push('Missing project id');
        if (!projectItemId) errors.push('Missing project item id');
        if (typeof stageType !== 'number') errors.push('Invalid or mossing StageType');
        if (errors.length > 0) throw new Error(errors.join(', '));

        const path = '/api/project/' + projectItemId + '/item/' + projectItemId + '/stage/' + stageType;
        const me = this;

        return await HttpService.instance.postAsync(path).then((response) => {
            return new ProjectItemModel(response.data);
        });
    }

    async updateProjectItemDescriptionAsync(projectItemId, description, name = null) {
        let errors = [];
        if (!projectItemId) errors.push('Missing project item id.');
        if (!description) errors.push('Missing description.');
        if (errors.length > 0) throw errors.join(' ');

        const path = '/api/project-item/' + projectItemId + '/description';
        const me = this;
        const data = { description, name };
        
        console.log("Updating ProjectItem Description");
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new ProjectItemModel(response.data);
        });
    }
    
    async updateProjectItemDateEstimatesAsync(durationJson, projectId) {
        let projectItemId = durationJson.id;
        if (!projectItemId) { 
            const errorMessage = "Missing project item id in durationJson object. Set [durationJson.id] to the project item id before calling updateProjectItemDateEstimatesAsync()";
            console.error(errorMessage);
            throw errorMessage;
        }
        
        delete durationJson.id;
        
        if (!projectId) projectId = projectItemId;
        if (!durationJson) return null;
        
        const path = '/api/project/' + projectId + '/item/' + projectItemId + '/duration';

        console.warn(JSON.stringify(durationJson));
        
        return await HttpService.instance.postAsync(path, durationJson).then((response) => {
            return new ProjectItemModel(response.data);
        });
    }

    createdHeadedItemsList(p, index) {
        if (!p?.items || p.items.length === 0) return p.items;

        let items = [...p.items];
        let item = items[0];
        let projectName = p.name;
        let i = p.index || (index || 0);
        let idx = (i % ProjectService.projectColorsCount);

        let json = {
            name: projectName,
            project_id: item.projectId,
            description: "project-index-header-" + (idx || 0).toString(),
            start_day: 0,
            duration: 0,
            id: null
        };

        let newItem = new ProjectItemModel(json);

        items.splice(0, 0, newItem);

        return items;
    };
    
    async updateProjectItemDayAsync(projectItemDayId, day) { 
        if (typeof day !== 'number') throw ("Invalid day value. Must be a number.");
        if (!projectItemDayId) throw ("Missing projectItemDayId");
        
        const path = '/api/project-item-day/' + projectItemDayId + '/day/' + day;
        const me = this;
        
        return await HttpService.instance.postAsync(path).then((response) => {
            return new ProjectItemDayModel(response.data);
        });
    }

    /**
     * Calculates all of the necessary dates and meta data for a projects, in order to create a roadmap
     * @param projects
     * @param isSingle
     * @param workDaysInAWeek
     * @returns {RoadmapMetaModel}
     */
    createProjectRoadmapMetaData(projects, isSingle, workDaysInAWeek = 5) {
        if (typeof isSingle === 'undefined') isSingle = (projects.length === 1);
        /**
         * If there's a project start date (time: ticks), then we will map actual dates onto the calendar.
         * Let's leave this out for now.
         * @type {number}
         */
        let startTime = Math.min(...projects.filter((psd) => (psd.startDate?.getTime() || 0) > 1000).map(p => p.startDate.getTime()));

        const firstProjectDate = startTime > 10 ? new Date(startTime) : null;
        let projectStartDate = firstProjectDate;
        
        let weekDayOffset = (!!projectStartDate) ? projectStartDate.getDay() : 0;
        if (weekDayOffset > 0) projectStartDate = projectStartDate.addDays(-weekDayOffset);

        let projectMap = {};    // Used to quickly find a project by name, given a project id.
        let weekDaysOff = 7 - workDaysInAWeek;

        let dayCellWidth = 100; // Width of a day cell in pixels (2nd to the top header row)
        let weekCellWidth = dayCellWidth * workDaysInAWeek; // Width of a week cell in pixels (top header row)

        let items = projects.map((p, index) => this.createdHeadedItemsList(p, index)).flat();

        let maxProjectStartDay = Math.max(...projects.map((x) => x.startDay)) || 0;
        let totalDays = Math.max(...items.map((item) => maxProjectStartDay + item.startDay + item.getDuration()));
        let totalWeeks = Math.max(Math.ceil(totalDays / workDaysInAWeek), 1);

        // Adjust so that the last day falls on the Friday of the last week
        totalDays = Math.max(totalDays, totalWeeks * workDaysInAWeek);

        //const meta = {
        const meta = {
            items: items,
            roadmapStartDate: firstProjectDate, // First date of all the projects involved (if any start dates exist)
            startDate: startTime > 10 ? new Date(startTime) : null, // Actual date the project started. Null if it has not started yet
            firstDate: projectStartDate, // First date displayed in the calendar. Null if there is no start date
            weekCount: totalWeeks || 0,
            dayCount: totalDays || 0,
            weekHeaderCells: [],
            dayHeaderCells: [],
        };
        
        //console.log(JSON.stringify(meta));
        return new RoadmapMetaModel(meta);
    }
}

export default ProjectService;
