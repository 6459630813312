import React from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt, faPoo, faPoop} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import {piForbiddenWorm} from "../../components/common/ui/svgs/LogoSvgs";

const Error404Screen = (props) => {
    const {} = props;

    let backPath = window.document.referrer || "/";
    
    return (
        <CommonMasterPage title={"Error 404"} icon={piForbiddenWorm} subTitle={"Page was not found"}>
            <p>
                Yeah, sorry.<br/><br/>

                <Link to={backPath}>Go Back</Link><br/>
                <Link to={"/"}>Go home</Link>
            </p>

            <div>

            </div>
        </CommonMasterPage>
    );
    


};

export default Error404Screen;