import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnglesLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Tooltip} from "@mui/material";

const BackButton = (props) => {
    const { to, link, href, tooltip, tooltipTitle, tooltipPlacement, placement } = props;

    let location = to || (link || href);
    if (!location) location = "/";
    
    let pl = (tooltipPlacement || (placement || "top"));
    let title = (tooltip || tooltipTitle) || "Back";
    
    return (
        <Tooltip title={title} placement={pl} arrow={true}>
            <Link className={"back-button"} to={location}><FontAwesomeIcon icon={faAnglesLeft} /></Link>
        </Tooltip>
    );
};

export default BackButton;
