import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import DateTime from "../../common/ui/DateTime";
import NotificationService from "../services/NotificationService";
import {Link} from "react-router-dom";

const NotificationsDropdown = (props) => {
    let _;
    const { notifications, onClick } = props;
    const svc = NotificationService.instance;
    
    const onNotificationPress = (notification, e) => {
        if (typeof e?.preventDefault === "function") e.preventDefault();
        if (typeof e?.stopPropagation === "function") e.stopPropagation();
        
        if (typeof onClick === "function") {
            let result = onClick(notification);
            
            if (result !== false && !!notification.id) {
                _ = svc.markNotificationAsReadAsync(notification.id).then((n) => { 
                    _ = svc.getNotificationsAsync();
                });
            }
            
        } else { 
            console.log('No onclick event');
        }
        
        return false;
    };
    
    const defaultPath = window.location.pathname;
    
    const notificationsList = notifications.map((notification, index) => {
        const imageElement = (<FontAwesomeIcon icon={faUser} />);
        const cn = notification.readDate ? "uin-box" : "uin-box un-read";
        
        return (<li onClick={onNotificationPress.bind(this, notification)} key={'notifications-item-' + index.toString()} className={cn} title={notification.path || "d:" + defaultPath}>
            <Link to={notification.path || defaultPath}>
                <span className={"uin-box"}>{imageElement}</span>
                <span className={"uin-box"}>
                    <strong className={"uin-box"}>{notification.title}</strong>
                    <span className={"uin-box"}>{notification.text}</span>
                    <span className={"uin-box"}><DateTime value={notification.created} useRelative={true} time={true} /></span>
                </span>
            </Link>
        </li>);
    });

    return (<div id="notifications-box">
            <div className="arrow-holder" id="notifications-box-arrow-holder">
                <div className="arrow-up" id="notifications-box-arrow-up"></div>
            </div>
            <div className="content" id="notifications-box-content">
                <ul id="notifications-item-list">
                    { notificationsList }
                </ul>
            </div>
        </div>);
};

export default NotificationsDropdown;