import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import CompanyModel from "../../companies/models/CompanyModel";
import ProjectModel from "../../projects/models/ProjectModel";
import DocumentModel from "../../content/models/DocumentModel";
import {Link} from "react-router-dom";
import UserModel from "../../people/models/UserModel";
import InvoiceModel from "../../billing/models/InvoiceModel";

const SearchResult = (props) => {
    const { result, image } = props;

    const getDefaultData = () => {
        if (result.imageUrl) { 
            return (<img src={result.imageUrl} alt={result.name} />);
        }
        
        let svg = faSearch;
        let path = result?.path;

        switch (result.entityType) {
            case UserModel.entityType:
                svg = UserModel.icon;
                break;
            case CompanyModel.entityType:
                svg = CompanyModel.icon;
                break;
            case ProjectModel.entityType:
                if (!path) path = "/companies/" + result.companyId + "/projects/" + result.entityId;
                svg = ProjectModel.icon;
                break;
            case DocumentModel.entityType:
                svg = DocumentModel.icon;
                if (!path) path = "/companies/" + result.companyId + "/documents"; // + result.entityId;
                break;
            case InvoiceModel.entityType:
                if (!path) path = "/companies/" + result.companyId + "/invoices/" + result.entityId;
                svg = InvoiceModel.icon;
                break;
        }

        return { 
            icon: (<><FontAwesomeIcon icon={svg} /></>),
            path: path,
        };
        
    }
    
    const previewElement = result.preview ? (<span className="preview">{result.preview}</span>) : null;
    const data = getDefaultData();
    
    return (<Link to={data.path} key={result.id}>
        <span>{ data.icon }</span>
        <span>
            <h4>{ result?.title }</h4>
            {previewElement}
            <p>{result?.description}</p>
        </span>
    </Link>);
};

export default SearchResult;
