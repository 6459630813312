class ProjectItemDayModel { 
    static json = {
        "project_item_id": "bcecb850-2c53-4b70-bac0-bf8506f77670",
        "user_id": "44040757-f652-4347-9d49-c4eea71437d2",
        "day": 5,
        "stage_type": 0,
        "name": null,
        "description": null,
        "color": null,
        "status": 1,
        "id": "0e12e16d-d324-4984-94a3-0beb56985123",
        "created": "2022-09-12T04:25:07.977",
        "modified": null
    };

    constructor(json) {
        if (!json) json = {};
        
        this.projectItemId = json.project_item_id;
        this.userId = json.user_id;
        this.day = json.day;
        this.stageType = json.stage_type;
        this.name = json.name;
        this.description = json.description;
        this.color = json.color;
        this.status = json.status;
        this.id = json.id;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ProjectItemDayModel(json));
    }
}

export default ProjectItemDayModel;
