class Controller { 
    
    constructor(props) {
        if (typeof props === 'function') { 
            props = { onClick: props };
        }
        
        this.callbacks = {};

        this.id = props?.id || 'controller-' + Math.floor(Math.random() * 1000000).toString();
        
        this.onClick = (typeof props?.onClick === 'function') ? props.onClick : (e) => { return false; };
        this.onChange = (typeof props?.onChange === 'function') ? props.onChange : (e) => { return false; };
        this.onKeyPress = (typeof props?.onKeyPress === 'function') ? props.onKeyPress : (e) => { return false; };
        this.onSubmit = (typeof props?.onSubmit === 'function') ? props.onSubmit : (e) => { return false; };
        
        this.open = (typeof props?.open === 'function') ? props.open : (e) => { return false; };
        this.close = (typeof props?.close === 'function') ? props.close : (e) => { return false; };
        this.cancel = (typeof props?.cancel === 'function') ? props.cancel : (e) => { return false; };
        this.submit = (typeof props?.submit === 'function') ? props.submit : (e) => { return false; };
        this.refresh = (typeof props?.refresh === 'function') ? props.refresh : (e) => { return false; };
        this.refreshAsync = (typeof props?.refreshAsync === 'function') ? props.refreshAsync : async (e) => { return false; };
        this.getData = (key, defaultValue) => typeof this.callbacks[key] === "function" ? this.callbacks[key]() : (typeof defaultValue === "undefined" ? null : defaultValue);
    }

    setGetDataCallback(key, callback) {
        if (typeof key !== "string" || !key) throw new Error("Key must be a string.");
        if (typeof callback !== "function" && callback !== null) throw new Error("Callback must be a function or null.");

        this.callbacks[key] = callback;
    }

    setOpenCallback(callback) {
        this.open = callback;
    };
    
    setCloseCallback(callback) {
        this.close = callback;
    }
    
    /**
     * Copies text to the clipboard
     * @param text {string} - The text to copy
     * @returns {Promise<boolean>}
     */
    static async copyText(text) {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Page Text: ' + text);
            return true;
        } catch (err) {
            console.error('Failed to copy: ', err);
            throw err;
        }
    }

}

export default Controller;
