import React, { useState } from 'react';
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import ContactRequestDetails from "../../components/messaging/ui/ContactRequestDetails";
import BackButton from "../../components/common/ui/BackButton";
import ContactRequestService from "../../components/messaging/services/ContactRequestService";

const ContactRequestDetailsScreen = () => {
    const { contactRequestId } = useParams();

    const contactRequest = ContactRequestService.instance.contactRequestMap[contactRequestId] || null;
    const [subtitle, setSubtitle] = useState(contactRequest?.name || "Request Details...");
    
    const onLoad = (request) => {
        setSubtitle(request.name);
    };
    
    const sub = (<><BackButton to={"/contact-requests"} />  <span className={"pre-subtitle"}>Contact Requests: </span>  { subtitle }</>)
    
    return (<CommonMasterPage selector={"contact-requests"} title={"Contact Requests"} icon={faPaperPlane} subTitle={sub} bodyClassName={"x-large"}>
        <ContactRequestDetails contactRequestId={contactRequestId} onLoad={onLoad} />
    </CommonMasterPage>);
};

export default ContactRequestDetailsScreen;
