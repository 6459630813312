import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const StageDropdown = (props) => {
    const { onChange, initialStage, value, startDate } = props;
    let [stageType, setStageType] = useState(value || initialStage);
    
    const onStageChange = (e) => { 
        setStageType(e.target.value);
        
        if (typeof onChange === 'function')
            onChange(e.target.value, e);
    };
    
    useEffect(() => {
        //
    }, [stageType]);
    
    let isDate = (startDate instanceof Date && !isNaN(startDate.valueOf()));
    let dateDisplay = isDate ? "on " + startDate.formatDate() : "";
    
    return (<span className={"stage-dropdown"}>
        <select onChange={onStageChange} value={stageType?.toString()} onClick={(e) => e.preventDefault()}>
            <option value="0">Not Started</option>
            <option value="1">Started {dateDisplay}</option>
            <option value="2">In-Progress</option>
            <option value="3">Transitioning</option>
            <option className={"stage-option-4"} value="4">Completed &nbsp; &#10004;</option>
            <option value="-1">Blocked</option>
        </select>        
    </span>);
};

export default StageDropdown;
