import React, {useEffect, useState} from 'react';
import "../../../components/projects/ui/roadmap/LightRoadmap.css";
import {useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import Controller from "../../../components/common/services/Controller";
import ProjectService from "../../../components/projects/services/ProjectService";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import RoadmapWeekly from "../../../components/projects/ui/roadmap/RoadmapWeekly";
import LightSimpleMasterScreen from "../../../LightSimpleMasterScreen";

const PublicRoadmapScreen = (props) => {
    const {} = props;

    let {companyId, sessionId} = useParams();
    let [minStartDate, setMinStartDate] = useState((new Date()).addDays(1));
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [isList, setIsList] = useState(true);
    let [projects, setProjects] = useState(null);

    let calendarController = new Controller();
    let _;

    const onProjectItemClick = (project, e) => {
        //
    };

    const getProjectsAsync = async (force) => {
        if (!force && (projects?.length || 0) > 0) {
            console.warn("Projects already loaded: " + projects?.length);
            return;
        }

        console.warn("Getting Projects");
        await ProjectService.instance.getProjectsByCompanyAsync(companyId, true, sessionId).then((ps) => {
            console.warn("Got Projects: " + ps.length);
            setProjects(ps);
        });

    };

    useEffect(() => {
        CompanyEffect.create(setCompany, companyId, sessionId);
        _ = getProjectsAsync();
    }, []);

    let viewSelector = { list: "", calendar: "" };

    if (isList) viewSelector["list"] = "selected";
    else viewSelector["calendar"] = "selected";


    calendarController.refreshAsync = (e) => {
        console.log("Roadmap Refresh");
        _ = getProjectsAsync(true);
    };

    const onDateChangePress = (value, e) => { 
        let dateTime = Date.parse(e.target.value || value);
        if (!isNaN(dateTime)) { 
            setMinStartDate(new Date(dateTime));
        }
    }
    
    return !!company ? (<LightSimpleMasterScreen onDateChangePress={onDateChangePress} defaultDate={minStartDate.formatDate("YYYY-MM-DD")} title={(company?.name || "") + " Roadmap"}>
        <RoadmapWeekly controller={calendarController} projects={projects ?? []} company={company} onClick={(item, e) => onProjectItemClick(item)} startDate={minStartDate} />
    </LightSimpleMasterScreen>) :
        (<></>);
};

export default PublicRoadmapScreen;

