import React, {useEffect, useState} from 'react';
import DateTime from "../../common/ui/DateTime";
import ActivityService from "../services/ActivityService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

const MessageSendLogs = (props) => {
    const { entityId, entityType, companyId, defaultRecipient } = props;
    const [logs, setLogs] = useState([]);
    
    let _;
    
    const getMessageLogsAsync = async (e) => {
        return await ActivityService.instance.getMessageSendLogsAsync(entityId, entityType, companyId).then((sendLogs) => {
            setLogs(sendLogs);
        });
    };
    
    const createEntityElement = (messageSendLog) => {
        if (!!entityType || messageSendLog.entityType === 0) return null;
        return (<span className={"entity-log entity-type-" + messageSendLog.entityType + ""}>{messageSendLog.entityTypeName}</span>);
    };
    
    useEffect(() => {
        _ = getMessageLogsAsync();
    }, []);
    
    let noRecipient = defaultRecipient || "No Recipient";
    
    let logElements = logs.map((messageSendLog, index) => {
        let icon = messageSendLog.success ? faCheckCircle : faCircleExclamation;
        let status = messageSendLog.success ? "Success" : (messageSendLog.message || "Failed");
        const entityElement = createEntityElement(messageSendLog);
        
        return (<tr key={index} className={"send-item-" + messageSendLog.success.toString()}>
            <td>
                <FontAwesomeIcon icon={icon} />
                {messageSendLog.recipient || noRecipient}
            </td>
            <td>{status}{entityElement}</td>
            <td><DateTime value={messageSendLog.created} time={true} /></td>
        </tr>);
    });
    
    return (<div className={"message-send-logs"}>
        <table width={"100%"}>
            <thead>
                <tr>
                    <th>Recipient</th>
                    <th>Status/Message</th>
                    <th>Date/Time</th>
                </tr>
            </thead>
            
            <tbody>
                {logElements}
            </tbody>
        </table>
    </div>);
};

export default MessageSendLogs;
