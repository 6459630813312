import React, {useEffect, useRef} from 'react';
import DateTime from "../../common/ui/DateTime";
import PagerController from "../../common/pager/PagerController";
import ContactRequestService from "../services/ContactRequestService";
import Pager from "../../common/pager/Pager";
import {Link} from "react-router-dom";
import "./ContactRequests.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faGlobe, faHelicopter, faMessage, faPoop} from "@fortawesome/free-solid-svg-icons";

const ContactRequestsTable = (props) => {
    const { contactRequests, companyId, showRelativeDate, relativeDate, controller } = props;
    const mapped = !!companyId && companyId.length > 30 ? ContactRequestService.instance.contactRequestsMap[companyId] : ContactRequestService.instance.contactRequests || [];
    const [requests, setRequests] = React.useState(contactRequests || mapped);
    const [currentPage, setCurrentPage] = React.useState(0);
    const pageController = useRef(new PagerController(currentPage, 24)).current;
    let _;
    
    const getContactRequests = async (force) => {
        if (!force && requests?.length > 0) return;
        
        await ContactRequestService.instance.getContactRequestsAsync(companyId).then((requests) => {
            setRequests(requests);
        });
    };

    if (!!controller) controller.refreshAsync = async (force) => {
        console.warn("Getting Refresh 2002, Good");
        return await getContactRequests(force);
    };

    useEffect(() => {
        getContactRequests();
    }, []);
    
    const createPreview = (message, len = 36) => { 
        if (!message) return "No Message";
        if (message.length < len) return message;
        
        return message.substring(0, len) + "...";
    };
    
    const getIcon = (requestType) => {
        switch (requestType) {
            case 1:
                return faBriefcase;
            case 2:
                return faHelicopter;
            case 3:
                return faPoop;
            case 4:
                return faGlobe
            default:
                return null;
        }
    };
    
    
    const elements = pageController.mapLineItems(requests, (req, index) => {
        const preview = createPreview(req.message);
        const path = !!companyId ? "/companies/" + companyId + "/contact-requests/" + req.id : "/contact-requests/" + req.id;
        const cn = !!req.readDate ? "" : "unread";
        const icon = getIcon(req.contactRequestType) || faMessage;
        
        return (<tr key={"request-" + index} className={cn}>
            <td><Link to={path}><FontAwesomeIcon icon={icon} /> {req.name || "No Name"}</Link></td>
            <td className={"preview"}><Link to={path}>{preview}</Link></td>
            <td>{req.messageSource}</td>
            <td><DateTime value={req.created} time={true} showRelative={true} /></td>
        </tr>);
    });
    
    return (<div>
        <table width={"100%"} className={"table contact-requests-table table-x-large"}>
            <thead>
            <tr>
                <th>From</th>
                <th>Message</th>
                <th>Source</th>
                <th>Date</th>
            </tr>
            </thead>
            
            <tbody>
            { elements }
            </tbody>
        </table>
        
        <Pager controller={pageController} items={requests} />
    </div>);
};

export default ContactRequestsTable;

