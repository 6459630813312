class ApiKeyAuthModel { 
    static create = (json) => new ApiKeyAuthModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        this.companyId = json.company_id || null;
        this.userId = json.user_id || null;
        this.apiKey = json.api_key || "";
        this.name = json.name || "";
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return { name: this.name };
    }
    
    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => ApiKeyAuthModel.create(json));
    }
}

export default ApiKeyAuthModel;
