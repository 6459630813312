import react, {useEffect, useState} from 'react';
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import MetricsMasterScreen from "./MetricsMasterScreen";
import ActivityService from "../../components/activity/services/ActivityService";
import Pager from "../../components/common/pager/Pager";
import PagerController from "../../components/common/pager/PagerController";
import DateTime from "../../components/common/ui/DateTime";
import {Link} from "react-router-dom";

const WebSessionLabelsScreen = () => {
    const [webSessionLabels, setWebSessionLabels] = useState(ActivityService.instance.webSessionLabels || null);
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useState(new PagerController({}, 24, setCurrentPage))[0];
    
    const handleError = (ex) => { 
        const message = ex?.response?.data?.message || ex?.message || "Unknown Error";
        console.error(message);
        
        alert(message);
    };
    
    const getWebSessionLabelsAsync = async (force = false) => {
        if (force !== true && Array.isArray(webSessionLabels))
            return webSessionLabels;
        
        const labels = await ActivityService.instance.getWebSessionLabelsAsync().catch((ex) => handleError(ex));
        
        if (Array.isArray(labels)) {
            setWebSessionLabels(labels);
        }
        
        return labels;
    };

    useEffect(() => {
        let _ = getWebSessionLabelsAsync(true);
    }, []);
    
    const elements = Array.isArray(webSessionLabels) ? webSessionLabels.map((label) => {
        const link = "/metrics/web-visitors/web-session/" + label.webSessionId;
        
        return (<tr key={label.id}>
            <td><Link to={link}>{label.name || "Untitled"}</Link></td>
            <td><Link to={link}>{label.webSessionId}</Link></td>
            <td><DateTime value={label.created} /></td>
        </tr>);
    }) : null;
    
    return (<MetricsMasterScreen selector={"metrics"} selection={"web-sessions"} icon={faArrowTrendUp} title={"Growth Metrics"} subTitle={"Saved Web Sessions"} onRefresh={async () => await getWebSessionLabelsAsync(true)}>
        <p>
            Saved Web Sessions
        </p>

        <div>
            <table width={"100%"} className={"table table-striped table-hover web-visitors no-wrap"}>
                <thead>
                <tr>
                    <th>Toast</th>
                    <th>WebSession</th>
                    <th>Date</th>
                </tr>
                </thead>

                <tbody>{elements}</tbody>
            </table>

            <div>
                <Pager items={webSessionLabels} controller={pagerController} />
            </div>
        </div>
    </MetricsMasterScreen>);
};

export default WebSessionLabelsScreen;
