import React, {useEffect, useState} from 'react';
import {faCalendarDays, faFileInvoiceDollar, faList} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import ProjectService from "../../../components/projects/services/ProjectService";
import AddNewHeaderButton from "../../../components/common/ui/AddNewHeaderButton";
import DateTime from "../../../components/common/ui/DateTime";
import CompanyProjectsSubMenu from "../../../components/companies/ui/CompanyProjectsSubMenu";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import NavigationHistoryService from "../../../components/activity/services/NavigationHistoryService";
import ViewSelector from "../../../components/common/ui/view-selector/ViewSelector";
import ViewSelectionModel from "../../../components/common/ui/view-selector/ViewSelectionModel";
import DragBox from "../../../components/common/ui/drag-drop/DragBox";
import DropBox from "../../../components/common/ui/drag-drop/DropBox";
import {Work} from "@mui/icons-material";

const ProjectsScreen = () => {
    let _;
    const { companyId } = useParams();
    const [projects, setProjects] = useState([]);
    const [company, setCompany] = useState();
    
    const [viewState, setViewState] = useState(0);
    const [workflowState, setWorkflowState] = useState(null);

    const [skeleton, setSkeleton] = useState({ columnId: null, index: 0});
    
    const viewSelections = [
        new ViewSelectionModel("List View", faList, "List", 0),
        new ViewSelectionModel("Workflow View", faCalendarDays, "Workflow", 1),
    ];
    
    const onViewSelectionChange = (viewSelectionModel) => {
        const index = viewSelectionModel?.index;
        
        console.log("View Selection Changed: " + index);
        setViewState(index);
    };
    
    const onCompany = (c) => {
        if (!!c?.name) {
            NavigationHistoryService.instance.addDelayedItem(c.name + " Projects");
            setCompany(c);
        }
    };
    
    const getProjectsAsync = async (force) => {
        console.log("Getting projects");
        
        if (!force && projects.length > 0) { 
            return;
        }
        
        await ProjectService.instance.getProjectsByCompanyAsync(companyId).then((ps) => { 
            setProjects(ps);
        });
    };

    const getWorkflowStateAsync = async (force) => {
        console.log("Getting workflow state");

        if (!force && workflowState !== null) {
            return;
        }

        await ProjectService.instance.getProjectWorkflowViewAsync(companyId).then((ws) => {
            console.log("Got em.");
            const m = { workflows: ws };
            setWorkflowState(m);
        });
    };

    const refreshAsync = async (force) => {
        if (viewState === 1) _ = getWorkflowStateAsync(force === true);
        else _ = getProjectsAsync(force === true);
    };
    
    useEffect(() => { 
        _ = refreshAsync();
    },[viewState]);

    const getListView = () => {
        let projectElements = projects.map((p) => {
            let startDisplay = p.startDate ? p.startDate.formatDate() + " (Day " + p.startDay + ")" : "Day " + p.startDay;
            return (
                <tr key={p.id}>
                    <td><Link to={"/companies/" + p.companyId + "/projects/" + p.id}>{p.name || "No Name"}</Link></td>
                    <td>{p.stageName}</td>
                    <td><DateTime value={p.created} /></td>
                    <td>{startDisplay}</td>
                </tr>
            );
        });
        
        return (
            <table width={"100%"}>
                <thead>
                <tr>
                    <th>Project Name</th>
                    <th>Status/Stage</th>
                    <th>Created</th>
                    <th>Start On</th>
                </tr>
                </thead>

                <tbody>
                {projectElements}
                </tbody>
            </table>            
        );
    };

    let rc = 0;
    const dragBoxIdentifier = "drag-box-";
    const dropColumnKey = "drop-column-";
    
    const createColumnId = (wsNumber) => {
        if (wsNumber === null || typeof wsNumber === 'undefined') return null;
        return dropColumnKey + wsNumber;
    }

    const findParentElement = (element, term) => {
        if (!element || !element.parentElement) return null;
        
        if (!term) term = "";
        
        if (!!element?.id && element.id.indexOf(term) === 0) {
            return element;
        }

        return findParentElement(element.parentElement, term);
    }

    const onCardMove = (e, model) => {
        //const element = findParentElement(e.target, dragBoxIdentifier);
        const columnElement = findParentElement(e.target, dropColumnKey);
        
        if (!!columnElement) {
            const ws = model.data?.workflowStateNumber;
            const columnId = columnElement.id;
            const modelColumnId = createColumnId(ws);
            
            let sk = {
                columnId: columnId === modelColumnId ? null : columnId?.toString() || "",
                index: skeleton.index
            };

            if (sk.columnId !== skeleton.columnId || sk.index !== skeleton.index) {
                console.log("ColumnId: " + columnId);
                setSkeleton(sk);
            }
            
        } else { 
            console.log("No column element: " + e.target.className);
        }
    };
    
    const onDragCancel = (e) => { 
        console.log("Cancel Drag.");
        setSkeleton({ columnId: null, index: 0});
    };
    
    const onCardRelease = (e, model) => {
        console.log("Card released: " + model.data?.name);
        console.log("Receiver: ");
        
        let receiver = findParentElement(e.target, dropColumnKey);
        let wsNumber = parseInt(receiver?.id?.replace(dropColumnKey, "") || "x");
        
        if (!isNaN(wsNumber)) {
            console.warn("Workflow Number: " + wsNumber);
            console.log(receiver);
        }
        
        // model.data is the projectItem that's been dropped.
        setSkeleton({ columnId: null, index: 0});
    };
    
    const getWorkflowView = () => {
        
        const columns = workflowState?.workflows?.map((w, index) => {
            const workflowState = w.workflowState;
            const projectItems = w.projectItems;
            const wsNumber = workflowState.workflowStateNumber.toString();
            const columnId = createColumnId(wsNumber);
            
            const cardElements = projectItems?.map((projectItem, idx) => {
                const project = projectItem.project;
                
                return (
                    <DragBox key={"project-item-col-item-" + idx} className={"project-item-card"} ignore={columnId} data={projectItem} id={dragBoxIdentifier + projectItem.id}>
                        <div className={"title"}>
                            <Link to={"/companies/" + companyId + "/projects/" + projectItem.projectId + "/item/" + projectItem.id}>{projectItem?.name || "Project Item Name Empty"}</Link>
                        </div>
                        <div className={"footer"}>
                            Project: {project?.name}
                        </div>
                    </DragBox>
                );
            });
            
            const skull = skeleton?.columnId === columnId ? 
                (<div className={"card-skeleton"}>
                    <span>Drop Here</span>
                </div>) : null;
            
            return (<DropBox key={"workflow-key-" + index} className={"workflow-column"} data={workflowState} onCancel={(e) => onDragCancel(e)} onDrop={(e, model) => onCardRelease(e, model)} onMove={(e, model) => onCardMove(e, model)}>
                <h3>
                    {workflowState.name} : Index={ w.index }
                    <span style={{display: "block", fontSize: "12px"}}>{columnId}</span>
                </h3>
                
                <div className={"workflow-column-items"} id={columnId}>
                    {skull}
                    {cardElements}
                </div>
            </DropBox>);
            
        }) || (<div>NO DICE</div>);
        
        return (
            <>
                <div className={"workflow-body"}>
                    <div>Workflow View</div>
                    <div className={"workflow-view"}>
                        { columns }
                    </div>
                </div>
            </>
        );
    };
    
    const body = viewState === 1 ? getWorkflowView() : getListView();
    const subtitle = (<>Projects <AddNewHeaderButton to={"/companies/" + companyId + "/projects/000-000/edit"} id={faFileInvoiceDollar}>Create Project</AddNewHeaderButton></>);

    return (
        <CompanyMasterPage onRefresh={() => getProjectsAsync(true)} bodyClassName={"x-x"} menuSelection="projects" onCompany={onCompany} title={subtitle}>
            <div className={"submenu-flexer"}>
                <CompanyProjectsSubMenu company={company} selection={"main"} />
                <ViewSelector items={viewSelections} onChange={onViewSelectionChange} index={viewState} />
            </div>
            
            <p>
                This is where all the current and upcoming projects will be listed.<br/><br/>
                Project Types:<br/>
                <strong>Remote Project Map:</strong> Project linked to a Shortcut "Project" entity<br/>
                <strong>Remote Tag Map:</strong> Project linked to a Shortcut "tag" or "tags" entity<br/>
            </p>

            <div>{body}</div>
            
        </CompanyMasterPage>
    );
    
};

export default ProjectsScreen;