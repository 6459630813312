import React, {useEffect, useRef, useState} from 'react';
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import FormButton from "../../../components/common/ui/FormButton";
import MessagingService from "../../../components/messaging/services/MessagingService";
import {useParams} from "react-router-dom";

const CompanyUserMessaging = (props) => {
    const {} = props;
    const { companyId } = useParams();
    const initialState = { state: 0, cursor: 0, previewHtml: null, userCount: -1 };
    const [pageState, setPageState] = useState(initialState);
    
    const tags = MessagingService.tags;
    const sections = MessagingService.sections;
    
    const subjectRef = useRef();
    const messageRef = useRef();
    const linkRef = useRef();
    
    const getAsync = async (force) => {
        console.log("CompanyUserMessaging.getAsync");
    };
    
    const onCompany = (company) => {
        console.log("CompanyUserMessaging");
    };
    
    const createJson = (companyId = null) => {
        return {
            subject: subjectRef.current?.value ?? null,
            message: (messageRef.current?.value || "").trim().replaceAll("\n", "<br/>"),
            link: linkRef.current?.value || null,
            companyId: companyId || null,
            message_template_type: 24,
            recipient_name: null,
            short_link: true,
            recipient: null,
            user_id: null,
        };
    };

    const previewMessageAsync = async (e) => {
        const json = createJson();

        const previewModel = await MessagingService.instance.getMessagePreviewAsync(companyId, json);
        const newState = {...pageState};

        newState.state = 1;
        newState.previewHtml = (previewModel?.body || "").replaceAll(" body {", " .message-preview {");
        newState.subject = previewModel.subject || null;
        newState.userCount = previewModel?.count || 0;

        setPageState(newState);
    };
    
    const sendMessagesAsync = async (e) => {
        const json = createJson();

        const job = await MessagingService.instance.sendMessageToCompanyUsersAsync(companyId, json).catch((ex) => {
            alert(ex?.response?.data?.message || ex?.message || "Unknown error");
            return null;
        });
        
        if (!job) return;
        
        const newState = {
            ...pageState,
            state: 0,
            message: job.message || "Job Started",
            name: job.name,
            jobId: job.id 
        };

        setPageState(newState);
    };
    
    const suppress = (e) => {
        if (typeof e?.stopPropagation === "function") e.stopPropagation();
        if (typeof e?.preventDefault === "function") e.preventDefault();
        
    }
    
    const onClick = (e, tagName, isSection = false) => {
        suppress(e);
        let tokenName = (tagName || (e?.target?.innerHTML || ""));
        if (!tokenName) return;
        
        tokenName = isSection === true ? "<pi:" + tokenName + "></pi:" + tokenName + ">" : "<pi:" + tokenName + " />";
        
        console.log("onClick: " + tokenName);
        messageRef.current.value = messageRef.current.value.substring(0, pageState.cursor) + tokenName + messageRef.current.value.substring(pageState.cursor);
        messageRef.current.focus();
        
        setTimeout(() => {
            messageRef.current.selectionStart = pageState.cursor + tokenName.length;
            messageRef.current.selectionEnd = pageState.cursor + tokenName.length;
        }, 100);
    };
    
    const onBlur = (e) => {
        suppress(e);
        const cursorPosition = e?.target?.selectionStart;
        let text = e?.target?.value;
        
        setPageState({...pageState, cursor: cursorPosition});
    };

    const reset = (e) => {
        suppress(e);
        setPageState(initialState);
    }

    useEffect(() => {
        //
    }, []);
    
    const subtitle = "Send Bulk Messages to Users";
    const buttonCaption = pageState.state === 1 ? "Send" : (pageState.state === 2 ? "Working..." : "Preview");
    
    const s = (pageState.userCount === 1 ? "" : "s");
    const previewElement = !!pageState.previewHtml && !pageState.message ? 
        (<div>
            <h3>Preview: Will be sent to { pageState.userCount + " User" + s }</h3>
            <div className={"message-preview"} dangerouslySetInnerHTML={{__html: pageState.previewHtml}} />
            <div><FormButton onClick={sendMessagesAsync}>Send Message to {pageState.userCount} User{s}</FormButton></div>
        </div>) :
        null;
    
    const tagElements = tags.map((tag, index) => {
        return (<span key={index} onClick={(e) => onClick(e, tag.name, false)} title={tag.description}>&lt;pi:{tag.name} /&gt;</span>);
    });
    
    const sectionElements = sections.map((section, index) => {
        return (<span key={index} onClick={(e) => onClick(e, section.name, true)} title={section.description}>&lt;pi:{section.name}&gt;...&lt;/pi:{section.name}&gt;</span>);
    });

    const sendForm = !pageState.message ? (<div className={"form medium"}>
        <div className={"form-group"}>
            <label>Subject:</label>
            <input type={"text"} id={"subject"} ref={subjectRef} />
        </div>

        <div className={"form-group"} id={"message-element"}>
            <label>Message:</label>
            <textarea id={"message"} ref={messageRef} onBlur={onBlur} />
        </div>

        <div className={"form-group"}>
            <label>Link:</label>
            <input type={"url"} id={"link"} ref={linkRef} />
        </div>

        <div className={"buttons"}>
            <FormButton onClick={previewMessageAsync}>Preview Message</FormButton>
        </div>
    </div>) : (<div><h2>Job Started {pageState.name}</h2><p>{pageState.message}</p><br/><br/><br/><a onClick={(e) => reset(e)}>Send Another Message</a></div>);
    
    return (<CompanyMasterPage selection={"messages"} menuSelection="edit" onCompany={onCompany} title={subtitle} onRefresh={() => getAsync(true)}>
        <p>
            Send messages to users with the following tokens:
            <span className={"tokens"}>
                {tagElements}
                {sectionElements}
            </span>
        </p>

        {sendForm}
        {previewElement}

    </CompanyMasterPage>);
};

export default CompanyUserMessaging;
