import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PasswordSet from "./routes/PasswordSet";
import Verify from "./routes/Verify";
import CompaniesScreen from "./routes/companies/CompaniesScreen";
import CompanyDetailsScreen from "./routes/companies/CompanyDetailsScreen";
import GrowthMetricsScreen from "./routes/metrics/GrowthMetricsScreen";
import UsersScreen from "./routes/people/UsersScreen";
import InvoicesScreen from "./routes/companies/billing/InvoicesScreen";
import ProjectsScreen from "./routes/companies/projects/ProjectsScreen";
import CompanyEditScreen from "./routes/companies/CompanyEditScreen";
import CompanyEditUserScreen from "./routes/companies/people/CompanyEditUserScreen";
import CompanyEditAddressScreen from "./routes/companies/CompanyEditAddressScreen";
import UserEditScreen from "./routes/people/UserEditScreen";
import UserDetailsScreen from "./routes/people/UserDetailsScreen";
import UserActivityScreen from "./routes/people/UserActivityScreen";
import InvoiceEditScreen from "./routes/companies/billing/InvoiceEditScreen";
import InvoiceDetailsScreen from "./routes/companies/billing/InvoiceDetailsScreen";
import InvoiceSendLogsScreen from "./routes/companies/billing/InvoiceSendLogsScreen";
import ActivityScreen from "./routes/activity/ActivityScreen";
import CompanyIntegrationsScreen from "./routes/companies/CompanyIntegrationsScreen";
import ProjectEditScreen from "./routes/companies/projects/ProjectEditScreen";
import ProjectDetailsScreen from "./routes/companies/projects/ProjectDetailsScreen";
import ProjectRoadmapScreen from "./routes/companies/projects/ProjectRoadmapScreen";
import DocumentationScreen from "./routes/documentation/DocumentationScreen";
import CompanyDocumentationScreen from "./routes/companies/docs/CompanyDocumentationScreen";
import ProjectItemDetailsScreen from "./routes/companies/projects/ProjectItemDetailsScreen";
import ProjectContentScreen from "./routes/companies/projects/ProjectContentScreen";
import AzureScreen from "./routes/documentation/AzureScreen";
import PlatformDocsScreen from "./routes/documentation/PlatformDocsScreen";
import GoogleScreen from "./routes/documentation/GoogleScreen";
import RoadmapScreen from "./routes/companies/projects/RoadmapScreen";
import ToolsScreen from "./routes/tools/ToolsScreen";
import ImageSizerScreen from "./routes/tools/ImageSizerScreen";
import Error404Screen from "./routes/errors/Error404Screen";
import ProjectItemEditScreen from "./routes/companies/projects/ProjectItemEditScreen";
import FlutterToolsScreen from "./routes/tools/FlutterToolsScreen";
import DocumentationDetailsScreen from "./routes/companies/docs/DocumentationDetailsScreen";
import DocumentConfigs from "./config/DocumentConfigs";
import MyAccountScreen from "./routes/my-account/MyAccountScreen";
import MyIntegrationsScreen from "./routes/my-account/MyIntegrationsScreen";
import CompanyUsersScreen from "./routes/companies/people/CompanyUsersScreen";
import CompanyUserHistoryScreen from "./routes/companies/people/CompanyUserHistoryScreen";
import PublicRoadmapScreen from "./routes/public/projects/PublicRoadmapScreen";
import MyAccountEditScreen from "./routes/my-account/MyAccountEditScreen";
import MyActivityScreen from "./routes/my-account/MyActivityScreen";
import GlossaryScreen from "./routes/documentation/GlossaryScreen";
import ProjectItemContentScreen from "./routes/companies/projects/ProjectItemContentScreen";
import QrCodeToolScreen from "./routes/tools/QrCodeToolScreen";
import ShortUrlDomainsScreen from "./routes/companies/short-urls/ShortUrlDomainsScreen";
import ShortUrlDomainScreen from "./routes/companies/short-urls/ShortUrlDomainScreen";
import ContactRequestsScreen from "./routes/contact-requests/ContactRequestsScreen";
import ContactRequestDetailsScreen from "./routes/contact-requests/ContactRequestDetailsScreen";
import PaymentMethodsScreen from "./routes/companies/billing/PaymentMethodsScreen";
import BillingScreen from "./routes/companies/billing/BillingScreen";
import TransactionsScreen from "./routes/companies/billing/TransactionsScreen";
import PaymentEditScreen from "./routes/companies/billing/PaymentEditScreen";
import PaymentDetailsScreen from "./routes/companies/billing/PaymentDetailsScreen";
import UserAccessScreen from "./routes/people/UserAccessScreen";
import PaymentScreen from "./routes/tools/PaymentScreen";
import UserCardOnFileScreen from "./routes/people/UserCardOnFileScreen";
import CompanyActivityScreen from "./routes/companies/activity/CompanyActivityScreen";
import CompanyErrorLogsScreen from "./routes/companies/activity/CompanyErrorLogsScreen";
import CompanyUserErrorsScreen from "./routes/companies/people/CompanyUserErrorsScreen";
import CompanyUserIntegrationsScreen from "./routes/companies/people/CompanyUserIntegrationsScreen";
import EmailMessagesScreen from './routes/companies/docs/EmailMessagesScreen';
import EmailMessageDetailsScreen from "./routes/companies/docs/EmailMessageDetailsScreen";
import CardTransactionsScreen from "./routes/metrics/CardTransactionsScreen";
import ProjectDashboardScreen from './routes/tools/ProjectDashboardScreen';
import CalendarScreen from './routes/companies/calendar/CalendarScreen';
import TextToolsScreen from './routes/tools/TextToolsScreen';
import CompanyMessageSendLogsScreen from "./routes/companies/activity/CompanyMessageSendLogsScreen";
import CompanyUserMessages from "./routes/companies/people/CompanyUserMessages";
import CompanyUserSessionsScreen from "./routes/companies/people/CompanyUserSessionsScreen";
import WebVisitorDetails from "./routes/metrics/WebVisitorDetails";
import WebSessionLabelsScreen from "./routes/metrics/WebSessionLabelsScreen";
import WebVisitorsScreen from "./routes/metrics/WebVisitorsScreen";
import SystemErrorsScreen from "./routes/metrics/SystemErrorsScreen";
import UserLandingPagesScreen from "./routes/companies/custom/qrblocks/UserLandingPagesScreen";
import CompanyUserMessaging from "./routes/companies/messaging/CompanyUserMessaging";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/passwordset/:authCodeId" element={<PasswordSet />} />
            <Route path="/verify/:authCodeId" element={<Verify />} />
            <Route path={"/public-roadmap/:companyId"} element={<PublicRoadmapScreen />} />
            <Route path={"/public-roadmap/:companyId/with-session/:sessionId"} element={<PublicRoadmapScreen />} />
            
            <Route path="/me" element={<MyAccountScreen />} />
            <Route path="/me/integrations" element={<MyIntegrationsScreen />} />
            <Route path="/me/edit" element={<MyAccountEditScreen />} />
            <Route path="/me/activity" element={<MyActivityScreen />} />
            
            <Route path={"/companies"} element={<CompaniesScreen />} />
            <Route path={"/companies/:companyId"} element={<CompanyDetailsScreen />} />

            <Route path={"/companies/:companyId/billing"} element={<BillingScreen />} />
            <Route path={"/companies/:companyId/billing/payment-methods"} element={<PaymentMethodsScreen />} />
            <Route path={"/companies/:companyId/billing/transactions"} element={<TransactionsScreen />} />
            <Route path={"/companies/:companyId/billing/payments/:paymentId"} element={<PaymentDetailsScreen />} />
            <Route path={"/companies/:companyId/billing/payments/:paymentId/edit"} element={<PaymentEditScreen />} />
            
            <Route path={"/companies/:companyId/invoices"} element={<InvoicesScreen />} />
            <Route path={"/companies/:companyId/invoices/:invoiceId"} element={<InvoiceDetailsScreen />} />
            <Route path={"/companies/:companyId/invoices/:invoiceId/edit"} element={<InvoiceEditScreen />} />
            <Route path={"/companies/:companyId/invoices/:invoiceId/send-logs"} element={<InvoiceSendLogsScreen />} />
            
            <Route path={"/companies/:companyId/people"} element={<CompanyUsersScreen />} />
            <Route path={"/companies/:companyId/people/:userId"} element={<UserDetailsScreen />} />
            <Route path={"/companies/:companyId/people/:userId/access"} element={<UserAccessScreen />} />
            <Route path={"/companies/:companyId/people/:userId/messages"} element={<CompanyUserMessages />} />
            <Route path={"/companies/:companyId/people/:userId/history"} element={<CompanyUserHistoryScreen />} />
            <Route path={"/companies/:companyId/people/:userId/integrations"} element={<CompanyUserIntegrationsScreen />} />
            <Route path={"/companies/:companyId/people/:userId/sessions"} element={<CompanyUserSessionsScreen />} />
            <Route path={"/companies/:companyId/people/:userId/errors"} element={<CompanyUserErrorsScreen />} />
            <Route path={"/companies/:companyId/people/:userId/edit"} element={<CompanyEditUserScreen />} />
            
            <Route path={"/companies/:companyId/documentation"} element={<CompanyDocumentationScreen />} />
            <Route path={"/companies/:companyId/documentation/decks"} element={<DocumentationDetailsScreen docKey={DocumentConfigs.PRESENTATION_DECK} />} />
            <Route path={"/companies/:companyId/documentation/technical-docs"} element={<DocumentationDetailsScreen docKey={DocumentConfigs.TECHNICAL_DOCUMENT} />} />
            <Route path={"/companies/:companyId/documentation/agreements"} element={<DocumentationDetailsScreen docKey={DocumentConfigs.LEGAL_DOCUMENT} />} />
            <Route path={"/companies/:companyId/documentation/taxes"} element={<DocumentationDetailsScreen docKey={DocumentConfigs.TAX_DOCUMENT} />} />

            <Route path={"/companies/:companyId/messaging/email-messages"} element={<EmailMessagesScreen />} />
            <Route path={"/companies/:companyId/messaging/email-messages/:templateId"} element={<EmailMessageDetailsScreen />} />
            <Route path={"/companies/:companyId/messaging"} element={<CompanyUserMessaging />} />

            <Route path={"/companies/:companyId/calendar"} element={<CalendarScreen />} />

            <Route path={"/companies/:companyId/activity"} element={<CompanyActivityScreen />} />
            <Route path={"/companies/:companyId/activity/errors"} element={<CompanyErrorLogsScreen />} />
            <Route path={"/companies/:companyId/activity/messages"} element={<CompanyMessageSendLogsScreen />} />
            
            <Route path={"/companies/:companyId/projects"} element={<ProjectsScreen />} />
            <Route path={"/companies/:companyId/projects/roadmap"} element={<RoadmapScreen />} />
            <Route path={"/companies/:companyId/projects/content"} element={<ProjectContentScreen />} />
            <Route path={"/companies/:companyId/projects/integrations"} element={<CompanyIntegrationsScreen slug={"projects"} />} />
            <Route path={"/companies/:companyId/projects/:projectId"} element={<ProjectDetailsScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/content"} element={<ProjectContentScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/edit"} element={<ProjectEditScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/roadmap"} element={<ProjectRoadmapScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/item/:projectItemId"} element={<ProjectItemDetailsScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/item/:projectItemId/edit"} element={<ProjectItemEditScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/item/:projectItemId/content"} element={<ProjectItemContentScreen />} />
            <Route path={"/companies/:companyId/projects/:projectId/roadmap/:projectItemId"} element={<ProjectItemDetailsScreen slug={"roadmap"} />} />
            
            <Route path={"/companies/:companyId/integrations"} element={<CompanyIntegrationsScreen />} />
            <Route path={"/companies/:companyId/edit"} element={<CompanyEditScreen />} />
            <Route path={"/companies/:companyId/edit/address"} element={<CompanyEditAddressScreen />} />
            <Route path={"/companies/:companyId/edit/owner"} element={<CompanyEditUserScreen />} />

            <Route path={"/companies/:companyId/domains"} element={<ShortUrlDomainsScreen />} />
            <Route path={"/companies/:companyId/domains/:domainId"} element={<ShortUrlDomainScreen />} />

            <Route path={"/companies/:companyId/landing-pages"} element={<UserLandingPagesScreen />} />
            
            <Route path={"/metrics"} element={<GrowthMetricsScreen />} />
            <Route path={"/metrics/web-visitors"} element={<WebVisitorsScreen />} />
            <Route path={"/metrics/web-visitors/ip/:ipAddress"} element={<WebVisitorsScreen />} />
            <Route path={"/metrics/web-visitors/domain-name/:domainName"} element={<WebVisitorsScreen />} />
            <Route path={"/metrics/web-visitors/web-session/:webSession"} element={<WebVisitorsScreen />} />
            <Route path={"/metrics/web-visitors/session/:sessionId"} element={<WebVisitorsScreen />} />
            <Route path={"/metrics/web-visitor/:webVisitorId"} element={<WebVisitorDetails />} />
            <Route path={"/metrics/web-sessions"} element={<WebSessionLabelsScreen />} />
            <Route path={"/metrics/transactions"} element={<CardTransactionsScreen />} />
            <Route path={"/metrics/errors"} element={<SystemErrorsScreen />} />

            <Route path={"/documentation"} element={<DocumentationScreen />} />
            <Route path={"/documentation/azure"} element={<AzureScreen />} />
            <Route path={"/documentation/google"} element={<GoogleScreen />} />
            <Route path={"/documentation/docs"} element={<PlatformDocsScreen />} />
            <Route path={"/documentation/glossary"} element={<GlossaryScreen />} />
            <Route path={"/documentation/glossary/:letter"} element={<GlossaryScreen />} />

            <Route path={"/tools"} element={<ToolsScreen />} />
            <Route path={"/tools/payments"} element={<PaymentScreen />} />
            <Route path={"/tools/payments/:customerId"} element={<PaymentScreen />} />
            <Route path={"/tools/image-re-shaper"} element={<ImageSizerScreen />} />
            <Route path={"/tools/flutter"} element={<FlutterToolsScreen />} />
            <Route path={"/tools/qr-codes"} element={<QrCodeToolScreen />} />
            <Route path={"/tools/freelance"} element={<TextToolsScreen />} />
            <Route path={"/tools/project-dashboard"} element={<ProjectDashboardScreen />} />
            
            <Route path={"/people"} element={<UsersScreen />} />
            <Route path={"/people/:userId"} element={<UserDetailsScreen />} />
            <Route path={"/people/:userId/access"} element={<UserAccessScreen />} />
            <Route path={"/people/:userId/activity"} element={<UserActivityScreen />} />
            <Route path={"/people/:userId/edit"} element={<UserEditScreen />} />
            <Route path={"/people/:userId/card"} element={<UserCardOnFileScreen />} />
            
            <Route path={"/activity"} element={<ActivityScreen />} />

            <Route path={"/contact-requests"} element={<ContactRequestsScreen />} />
            <Route path={"/contact-requests/:contactRequestId"} element={<ContactRequestDetailsScreen />} />
            
            <Route path={"*"} element={<Error404Screen />} />
        </Routes>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
