class ShortUrlClickModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.shortUrlId = json.short_url_id;
        this.path = json.path;
        this.redirectTo = json.redirect_to;
        this.ipAddress = json.ip_address;
        this.userAgent = json.user_agent;
        this.location = json.location;
        this.lat = json.lat;
        this.lon = json.lon;
        this.referer = json.referer;
        this.trackingId = json.tracking_id;
        
        this.created = json.created;
        this.modified = json.modified;
    }
    
    toJson() {
        return {
            short_url_id: this.shortUrlId,
        path: this.path,
        redirect_to: this.redirectTo,
        ip_address: this.ipAddress,
        user_agent: this.userAgent,
        location: this.location,
        lat: this.lat,
        lon: this.lon,
        referer: this.referer,
        tracking_id: this.trackingId,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new ShortUrlClickModel(json));
    }
}

export default ShortUrlClickModel;
