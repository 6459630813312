import HttpService from "../../common/services/HttpService";

class QrService { 
    static instance = new QrService();

    /**
     * Creates a QR code image from given textData. This returns an actual image (not json)
     * @param textData
     * @param fileName
     * @returns {Promise<AxiosResponse<any>|*>}
     */
    async createQrCodeAsync(textData, fileName) { 
        const path = "/api/qr/base64";
        const me = this;
        
        const data = {
            file_name: fileName,
            text: textData
        };
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            return response.data;
        });
    }
}

export default QrService;
