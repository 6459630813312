import HttpService from "../../common/services/HttpService";
import MessageSendLogModel from "../../activity/models/MessageSendLogModel";
import JobModel from "../../activity/models/JobModel";

class MessagingService { 
    static instance = new MessagingService();
    static tags = [
        { name: "name", description: "Full Name" },
        { name: "first-name", description: "First Name" },
        { name: "last-name", description: "Last Name" },
        { name: "email", description: "Email Address" },
        { name: "image", description: "Image Tag" },
        { name: "image-url", description: "Image URL" },
        { name: "username", description: "Username" },
        { name: "link", description: "Link" },
    ];

    static sections = [
        { name: "has-user-image", description: "Appears if user has a profile photo. Invisible otherwise" },
        { name: "has-no-user-image", description: "Appears if user does not have a profile photo. Invisible otherwise" },
        { name: "has-link", description: "Appears if link is not empty. Invisible otherwise" },
        { name: "has-no-link", description: "Appears if link is empty. Invisible otherwise" },
        { name: "has-message", description: "Appears if message is not empty. Invisible otherwise" },
    ];

    constructor() {
        //
    }   

    async sendMessageAsync(json) {
        console.log("sendMessageAsync...");
        
        const path = "/api/messaging/send-message";
        
        return await HttpService.instance.postAsync(path, json).then((rsp) => { 
            return MessageSendLogModel.create(rsp?.data);
        });
    }
    
    async sendMessageToCompanyUsersAsync(companyId, json) {
        const path = "/api/messaging/send-message/" + companyId + "";
        
       return await HttpService.instance.postAsync(path, json).then((rsp) => {
            if (!rsp?.data) {
                console.error("No Response from Bulk Message Send Job");
                return null;
            }
            
            return JobModel.create(rsp.data);
        }).catch((ex) => { 
            console.error(ex?.response?.data?.message || ex?.message || "An error occurred while sending the message.");
            throw ex;
        });
    }
    
    async getMessagePreviewAsync(json, companyId) {
        if (!companyId) throw new Error("Missing companyId");
        
        const path = "/api/messaging/send-message/" + companyId + "/preview";

        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            if (!rsp?.data) return null;
            
            return {
                count: rsp.data.count,
                body: rsp.data.message,
                subject: rsp.data.subject,
                success: rsp.data.success || false,
            }
        });
    }
}

export default MessagingService;
