import HttpService from "../../common/services/HttpService";
import GlossaryTermModel from "../models/GlossaryTermModel";

class GlossaryTermService { 
    static instance = new GlossaryTermService();
    static letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    
    constructor() {
        this.glossaryTermsMap = {};
        this.glossaryTerms = [];
        this.glossaryTermMap = {};
    }

    async getGlossaryTermAsync(glossaryTermId) {
        const path = '/api/glossary/' + glossaryTermId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.glossaryTermMap[glossaryTermId] = new GlossaryTermModel(response.data);
            return me.glossaryTermMap[glossaryTermId];
        });
    }

    async getGlossaryTermsAsync() {
        const path = "/api/glossary";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.glossaryTerms = GlossaryTermModel.fromJsonArray(response.data);
            
            return me.glossaryTerms;
        });
    }
    
    async createGlossaryTermAsync(jsonData) {
        const path = "/api/glossary";
        const me = this;
        
        if (!jsonData?.term) throw new Error("Invalid term given: " + jsonData?.term);
        
        return await HttpService.instance.postAsync(path, jsonData).then((response) => {
            return new GlossaryTermModel(response.data);
        });
    }
}

export default GlossaryTermService;
