import HttpService from "../../common/services/HttpService";

class GoogleService { 
    static instance = new GoogleService();
    
    constructor() {
        this.initUrl = null;
        let _ = this.getOAuthInitAsync();
    }

    /**
     * Initializes the Google OAuth process with the Pi ApiKey/Secret
     * @returns {Promise<string>}
     */
    async getOAuthInitAsync(returnUrl, companyApiId) {
        if (!companyApiId) { 
            console.error("No companyApiId was given to init Google OAuth");
            return "";
        }
        
        if (!!returnUrl) {
            returnUrl = "?return=" + encodeURI(returnUrl);
        } else {
            console.error("No return url");
            return null;
        }
        
        const url = "/api/google/" + companyApiId + "/oauth" + returnUrl;
        const me = this;

        return await HttpService.instance.getAsync(url).then((rsp) => {
            me.initUrl = rsp.data.init_url;
            return rsp.data.init_url;
        });
    }
}

export default GoogleService;
