import ProjectModel from "./ProjectModel";

class ProjectDashboardModel { 
    static create = (json) => new ProjectDashboardModel(json);

    constructor(json) {
        if (!json) json = {};
        this.projects = ProjectModel.fromJsonArray(json.projects);
        this.count = json.count || this.projects.length;
        this.nonce = 0;
    }
}

export default ProjectDashboardModel;
