import SearchResultModel from "./SearchResultModel";
import CompanyModel from "../../companies/models/CompanyModel";
import DocumentModel from "../../content/models/DocumentModel";
import ProjectModel from "../../projects/models/ProjectModel";

class SearchResultCategoryModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || "";
        this.name = json.name || "";
        this.entityType = json.entity_type || "";
        this.entityTypeName = json.entity_type_name || "";
        
        this.score = json.score || 0;
        this.description = json.description || "";
        this.path = json.path || "";

        this.results = SearchResultModel.fromJsonArray(json.results);
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        
        let results = [];
        
        jsonArray.map((json) => {
            results.push(new SearchResultCategoryModel(json));
        });
        
        return results;
    }
}

export default SearchResultCategoryModel;
