import CompanyModel from "../models/CompanyModel";
import HttpService from "../../common/services/HttpService";
import AddressModel from "../../geo/models/AddressModel";
import UserModel from "../../people/models/UserModel";
import UserService from "../../people/services/UserService";
import DocumentModel from "../../content/models/DocumentModel";
import ProjectService from "../../projects/services/ProjectService";
import ApiAuthModel from "../../authentication/models/ApiAuthModel";
import CompanyTypeViewModel from "../models/CompanyTypeViewModel";

class CompanyService { 
    static instance = new CompanyService();

    constructor() {
        this.companyMap = {};
        this.usersMap = {};
        this.apisMap = {};
        this.companies = null; // []
    }

    async getCompaniesAsync() { 
        const path = "/api/company/view";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return me.setCompaniesJson(rsp.data);
        });
    }

    async getCompanyAsync(companyId, headers = null) {
        const path = "/api/company/" + companyId;
        const me = this;
        
        return await HttpService.instance.getAsync(path, false, headers).then((response) => {
            const company = new CompanyModel(response.data);
            me.companyMap[company.id] = company;
            return company;
        });
    }
    
    async getCompanyByDomainAsync(domain) {
        if (!domain || domain.length === 0) domain = window.location.host;

        const path = "/api/company-domain?domain=" + domain;
        const me = this;
        
        return await HttpService.instance.getAsync(path, true).then((response) => {
            return new CompanyModel(response.data);
        });
    }
    
    async saveCompanyAsync(companyId, data) {
        if (!companyId || companyId.length < 10) companyId = "";
        
        const path = "/api/company/" + companyId;
        const me = this;
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            const company = new CompanyModel(response.data);
            companyId = company.id;
            
            me.companyMap[companyId] = company;
            
            return company;
        });
    }

    /**
     * Updates company address
     * @param companyId (guid) - company id
     * @param address (AddressModel) - address to update
     * @returns {Promise<AxiosResponse<AddressModel>>}
     */
    async updateAddressAsync(companyId, address) { 
        const path = "/api/company/" + companyId + "/address";
        const me = this;
        
        return await HttpService.instance.postAsync(path, address.toJson()).then((response) => {
            address = new AddressModel(response.data);
            if (me.companyMap[companyId]) me.companyMap[companyId].address = address;
            
            return address;
        });
    }
    
    async updateCompanyDescriptionAsync(companyId, description, name = null) {
        const path = "/api/company/" + companyId + "/description";
        const me = this;

        const data = {
            description: description,
            name: name
        };
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            const company = new CompanyModel(response.data);
            me.companyMap[company.id] = company;

            return company;
        });
    };

    /**
     * Updates the company's stage, and returns the new company model* 
     * @param companyId
     * @param stage
     * @returns {Promise<CompanyModel>}
     */
    async updateCompanyStageAsync(companyId, stage) {
        const path = "/api/company/" + companyId + "/stage/" + stage;
        const me = this;
        
        return await HttpService.instance.postAsync(path, {}).then((response) => {
            const company = new CompanyModel(response.data);
            me.companyMap[company.id] = company;
            
            return company;
        });
        
    }

    /**
     * Updates the company's status, and returns the new company model. 0=Prospective, 1=Active/Under Management*
     * @param companyId
     * @param status
     * @returns {Promise<CompanyModel>}
     */
    async updateCompanyStatusAsync(companyId, status) {
        if (status !== 0 && status !== 1) throw new Error("Invalid status value: " + status + ". Only 0 or 1 are accepted.");
        
        const path = "/api/company/" + companyId + "/status/" + status;
        const me = this;

        return await HttpService.instance.postAsync(path, {}).then((response) => {
            const company = new CompanyModel(response.data);
            me.companyMap[company.id] = company;

            return company;
        });

    }
    
    async getContentAsync(companyId) {
        const path = '/api/company/' + companyId + "/content";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const docs = DocumentModel.fromJsonArray(response?.data);
            ProjectService.instance.contentMap[companyId] = docs;

            return docs;
        }).catch((err) => {
            console.error(err?.response);
        });
    };
    
    async saveCompanyApiAsync(companyId, data) {
        if (!companyId || companyId.length < 30) {
            throw new Error("No companyId when trying to save company api");
        }

        const path = "/api/company/" + companyId + "/api";
        const me = this;

        console.log("Posting to Path: " + path);
        console.log(JSON.stringify(data));
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new ApiAuthModel(response.data);
        });
    };
    
    setCompaniesJson(jsonArray) {
        this.companyTypeViews = CompanyTypeViewModel.fromJsonArray(jsonArray);
        
        const me = this;
        
        this.companyTypeViews.forEach((v) => {
            if (v.companies && Array.isArray(v.companies)) {
                v.companies.forEach((c) => {
                    me.companyMap[c.id] = c;
                });
            }
        });

        return this.companyTypeViews;
    }

    async getCompanyApiAuthCredentialsAsync(companyId) {
        const url = "/api/company/" + companyId + "/api-authentication";
        const me = this;

        return await HttpService.instance.getAsync(url).then((rsp) => {
            let models = ApiAuthModel.fromJsonArray(rsp.data);

            me.apisMap[companyId] = ApiAuthModel.toMap(models);

            return models;
        });
    }
    
    async getUsersAsync(companyId, status = "") { 
        if (!!status) status = "?status=" + status;
        else status = "";
        
        const path = "/api/company/" + companyId + "/user" + status;
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            me.usersMap[companyId] = UserModel.fromJsonArray(response.data);
            UserService.instance.appendUserMap(me.usersMap[companyId]);
            return me.usersMap[companyId];
        });
    }

    /**
     * Gets users that are not associated with the companyId, but have a UserAuth record that is. Basically, users of one company who have auth access to the given companyId
     * @param companyId
     * @param status
     * @returns {Promise<*>}
     */
    async getAuthUsersAsync(companyId, status) {
        if (!!status) status = "?status=" + status;

        const path = "/api/company/" + companyId + "/user-auth" + status;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return UserModel.fromJsonArray(response.data);
        });
    }
    
}

export default CompanyService;
