import React from 'react';
import {faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import ToolsMasterPage from "./ToolsMasterPage";

const ToolsScreen = () => {

    return (
        <ToolsMasterPage icon={faPeopleCarryBox} subTitle={"Tools"}>
            <p>
                Here are some tools for you to use.
            </p>

            <div>
                
            </div>
        </ToolsMasterPage>
    );
    
};

export default ToolsScreen;

