import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import HeaderButton from "../../common/ui/HeaderButton";
import {faBriefcase, faPeopleGroup, faSync} from "@fortawesome/free-solid-svg-icons";
import CommentForm from "../../messaging/ui/CommentForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterScreen from "../../../MasterScreen";
import UserSubMenu from "./UserSubMenu";
import CompanyEffect from "../../companies/services/CompanyEffect";
import CompanyService from "../../companies/services/CompanyService";
import UserEffect from "../services/UserEffect";
import UserService from "../services/UserService";

const UserMasterPage = (props) => {
    const {
        onUser,
        onCompany,
        onRefresh,
        onSessionChange,
        userModel,
        companyModel,
        menuSelection,
        selection,
        selector,
        children,
        controller,
        data,
        noSubMenu,
        title,
        subTitle,
        entityId,
        entityType,
        icon,
        bodyClassName,
        commentEntityType,
        altMenu
    } = props;

    const params = useParams();

    const { companyId, userId } = useParams();

    let [company, setCompany] = useState(companyModel || (CompanyService.instance.companyMap[companyId] || null));
    let [user, setUser] = useState(userModel || (UserService.instance.userMap[userId] || null));

    const getCompanyAsync = async () => {
        await CompanyService.instance.getCompanyAsync(companyId).then((c) => {
            if (!!c) setCompany(c);
        });
    };
    
    const getUserAsync = async () => {
        await UserService.instance.getUserAsync(userId).then((u) => {
            if (!!u) setUser(u);
        });
    };
    
    const onSessionMasterChange = (sh) => {
        if (typeof onSessionChange !== 'function') return;
        onSessionChange(sh);
    };

    const refreshAsync = async (e) => {
        const rf = (typeof onRefresh === "function") ? onRefresh : async (r) => true;

        const rsp = await Promise.all([
            getCompanyAsync(true),
            getUserAsync(true),
            rf(true),
        ]);
    };

    const notify = (e) => {
        if (!!company && typeof onCompany === 'function') {
            onCompany(company);
        }
        
        if (!!user && typeof onUser === 'function') {
            onUser(user);
        }
    }

    useEffect(() => {
        notify();
    }, [company, user]);

    useEffect(() => {
        CompanyEffect.create(setCompany, companyId);
        UserEffect.create(setUser, userId);
        notify();
    }, []);

    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this CompanyMasterPage. If you meant to have this blank, try passing a null value to the "company" property.</p>)
    const refreshButton = (typeof onRefresh === 'function') ? (<HeaderButton icon={faSync} onClick={refreshAsync}>Refresh</HeaderButton>) : null;
    const comments = typeof commentEntityType === 'number' && typeof entityId === 'string' && entityId.length > 10 ?
        (<CommentForm entityType={commentEntityType} entityId={entityId} />) :
        null;

    const h1 = (<><FontAwesomeIcon icon={icon || faPeopleGroup}/>
        {title}
    </>);

    const subMenu = (!user || noSubMenu) ? null : (<UserSubMenu user={user} selection={selection} />);
    const cn = !!bodyClassName && typeof bodyClassName === 'string' && bodyClassName.length > 0 ? " " + bodyClassName : "";
    
    return (
        <MasterScreen selector={selector || "users"} title={h1} onSessionChange={onSessionMasterChange}>
            <div id="users-main" className={"pad" + cn}>
                <div className="details-body">
                    { subMenu }

                    <div className="pad x-large">
                        <h2>
                            {typeof title === 'undefined' ? "Users Page" : subTitle}
                        </h2>
                        { altMenu }
                        { body }
                        { comments }
                    </div>
                </div>
                
            </div>
        </MasterScreen>
    );

};

export default UserMasterPage;