import React, { useRef, useState, useEffect } from 'react';
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import {faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import ToolsSubMenu from "../../components/tools/ui/ToolsSubMenu";
import FormButton from "../../components/common/ui/FormButton";
import ToolsMasterPage from "./ToolsMasterPage";
import QrService from "../../components/tools/services/QrService";

const QrCodeToolScreen = (props) => {
    const {} = props;
    const [qrCodeImageData, setQrCodeImageData] = useState(null);
    const urlRef = useRef();

    const generateQrCodeAsync = async (e) => { 
        const textData = urlRef.current?.value || "";
        if (!textData) return;
        
        await QrService.instance.createQrCodeAsync(textData, "file64.png").then((qr) => {
            if (qr?.data) setQrCodeImageData(qr.data);
            else console.error("No Data: " + qr);
        });
    }

    useEffect(() => { 
        if (qrCodeImageData) console.log("Image Data Set: " + qrCodeImageData);
        else console.warn("No qr image data: ");
        
    }, [qrCodeImageData]);
    
    const subtitle = (<><span className={"pre-subtitle"}>Tools:</span> QR Codes</>);
    const qrImage = qrCodeImageData ? (<img className={"qr-code size256"} src={"data:image/png;base64," + qrCodeImageData} alt={"QR Code"} />) : null;
    
    return (
        <ToolsMasterPage icon={faPeopleCarryBox} selection={"qr-codes"} subTitle={subtitle}>
            <p>
                Enter a URL to generate a QR Code
            </p>

            <div className={"form"}>
                <label htmlFor={"qr-data"}>Enter Url:</label>
                <input ref={urlRef} type={"text"} id={"qr-data"} name={"qr-data"} placeholder={"http://"} />
                
                <div className={"buttons"}>
                    <FormButton onClick={generateQrCodeAsync}>Generate QR Code</FormButton>
                </div>
                
                <br/><br/>{qrImage}
            </div>
        </ToolsMasterPage>
    );

};

export default QrCodeToolScreen;

