class RoadmapMetaModel { 
    
    constructor(json) {
        if (!json) json = {};
        
        this.items = json?.items;
        this.roadmapStartDate = json?.roadmapStartDate;
        this.startDate = json?.startDate;
        this.firstDate = json?.firstDate;
        this.weekCount = json?.weekCount;
        this.dayCount = json?.dayCount;
        this.weekHeaderCells = json?.weekHeaderCells;
        this.dayHeaderCells = json?.dayHeaderCells;
    }

}

export default RoadmapMetaModel;
