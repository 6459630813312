class AuthModel {
    constructor(json) {
        if (!json) json = {};

        this.ttl = json.ttl;
        this.username = json.username;
        this.authType = json.auth_type;
        this.roleId = typeof json.role_id !== 'number' ? json.role_id : 0;
        this.roleName = json.role_name;
        
        this.id = json.id;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

}

export default AuthModel;
