import React, { useState, useEffect } from 'react';
import {faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import BlankMasterPage from '../public/BlankMasterPage';
import ProjectService from '../../components/projects/services/ProjectService';
import DateTime from "@jadecharles/pi-react-packages/dist/common/ui/formatting/DateTime";
import NumberDisplay from '../../components/common/ui/NumberDisplay';

const ProjectDashboardScreen = () => {
    const [dashboard, setDashboard] = useState(null);
    const [pageTitle, setPageTitle] = useState("");
    const [dateTitle, setDateTitle] = useState("");
    
    const getDashboardAsync = async (force = false) => {
        if (!force && Array.isArray(dashboard?.projects)) return dashboard;

        console.log(force === true ? "Refreshing Dashboard (" + dashboard?.nonce + ")..." : "Getting Dashboard...");
        const db = await ProjectService.instance.getProjectDashboardAsync();
        
        if (!!db) { 
            console.log("Dashboard Good.");
            setDashboard(db);
        }
    };

    useEffect(() => {
        getDashboardAsync();
    }, []);

    useEffect(() => { 
        if (!!dashboard?.projects) { 
            const interval = setInterval(() => getDashboardAsync(true), 120000);
            return () => clearInterval(interval);
        }
    }, [dashboard]);

    const projectElements = dashboard?.projects?.sort((a, b) => (a.startDate.getTime() || -1) < b.startDate.getTime() || -1)?.map((p, i) => { 
        const project = p;
        project.items = project.items.sort((a, b) => (a.startDate?.getTime() || -1) > b.startDate?.getTime() || 0);

        let subElements = project.items.length > 0 ? project.items.map((item, j) => {
            const cn = !!item.completeDate ? "status-1" : "";
            
            return (<span className={cn} key={"sub-item-" + j}>
                {item.name}
            </span>);
        }) : null;

        subElements = subElements != null ? (<span className="dashboard-project-items">{subElements}</span>) : null;

        const priceElement = project.price > -10 ? (<span className="price-element">
            <NumberDisplay type="currency" value={project.price} decimalPlaces={0} />
        </span>) : null;

        return (<li key={"project-" + i}>
            <span>
                <span title={"id: " + project.id}>{project.name}</span>
                {subElements}
                <span className="date" title={project.startDate}>Created: <DateTime time={true} value={project.startDate} /></span>
            </span>
            {priceElement}
        </li>);
    }) ?? (<div>No Items</div>);

    const headerStyle = {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "2.75em",
        borderBottom: "1px dashed #ccc",
        paddingBottom: "24px",
        marginBottom: "32px"
    };

    const dateTitleElement = dateTitle || (<DateTime value={new Date()} format={"WEEK-DAY, MMMM DAY, YYYY"} />);

    return (
        <BlankMasterPage icon={faPeopleCarryBox} style={{ padding: "32px" }}>
            <div id="project-dashboard-total">
                <span>Toast</span>
            </div>

            <h1 onClick={(e) => getDashboardAsync(true)} style={headerStyle}>
                <span>{pageTitle || "Projects:"}</span>
                <span>{dateTitleElement}</span>
            </h1>

            <ul className="dashboard-items">
                {projectElements}
            </ul>

            <div>
                
            </div>
        </BlankMasterPage>
    );
    
};

export default ProjectDashboardScreen;

