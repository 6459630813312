import React, {useEffect, useRef, useState} from 'react';
import {
    faPeopleGroup
} from "@fortawesome/free-solid-svg-icons";
import CompanyService from "../../components/companies/services/CompanyService";
import ConfigModel from "../../components/common/models/ConfigModel";
import Controller from "../../components/common/services/Controller";
import UsersTable from "../../components/people/ui/UsersTable";
import CompanyEffect from "../../components/companies/services/CompanyEffect";
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import LoadingBox from "../../components/common/ui/LoadingBox";

const UsersScreen = (props) => {
    const companyId = process.env.REACT_APP_HOUSE_ID;
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    let _;
    
    const controller = useRef(new Controller()).current;
    
    const refreshUsersAsync = async (e) => { 
        return await controller?.refreshAsync(true);
    }

    useEffect(() => {
        console.warn("Company HouseId: " + companyId);
    }, [company]);
    
    useEffect(() => {
        CompanyEffect.create(setCompany, companyId);
    }, []);
    
    let usersTable = company ?
        (<UsersTable isPlatformUsers={true} company={company} companyId={companyId} controller={controller} />) : 
        (<LoadingBox />);
    
    return (
        <CommonMasterPage selector={"users"} icon={faPeopleGroup} title={"Personnel"} subTitle={ConfigModel.companyName + " Users"} onRefresh={refreshUsersAsync}>
            <p>
                Users for { company?.name || companyId }.
            </p>

            <div>
                { usersTable }
            </div>
        </CommonMasterPage>
    );

};


export default UsersScreen;