import UserModel from "../models/UserModel";
import HttpService from "../../common/services/HttpService";
import ActivityItemModel from "../../activity/models/ActivityItemModel";
import BankAccountModel from "../../finance/models/BankAccountModel";
import PaymentMethodModel from "../../finance/models/PaymentMethodModel";
import AuthModel from "../../authentication/models/AuthModel";
import {LineGraphSeriesModel} from "../../analytics/ui/line-graph/LineGraph";
import LandingPageModel from "../../landingpages/models/LandingPageModel";

class UserService { 
    static instance = new UserService();
    
    constructor() {
        this.userMap = {};
        this.userHistoryMap = {};
        this.paymentMethodMap = {};
    }

    async getUserAsync(userId, companyId = null) {
        if (!userId) { 
            throw new Error("User Id is required: " + userId);
        }
        
        const me = this;
        const path = !!companyId && companyId.length > 30 ?
            "/api/company/" + companyId + "/user/" + userId :
            "/api/user/" + userId;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            let user = new UserModel(rsp.data);
            if (!user?.id) { 
                console.error('No User Found');
                console.error(JSON.stringify(rsp.data));
                return null;
            }
            me.userMap[userId] = user;
            
            return user;
        });
    }
    
    async getLandingPagesAsync(userId, companyId = null) {
        if (!userId) { 
            throw new Error("User Id is required: " + userId);
        }
        
        const path = typeof companyId === "string" && companyId.length > 30 ?
            "/api/company/" + companyId + "/user/" + userId + "/landing-page" :
            "/api/user/" + userId + "/landing-page";
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return LandingPageModel.fromJsonArray(rsp.data);
        });
    }
    
    async getUserHistoryAsync(userId) {
        if (!userId) { 
            throw new Error("User Id is required: " + userId);
        }
        
        const me = this;
        const path = "/api/user/" + userId + "/activity";
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            const history = ActivityItemModel.fromJsonArray(rsp.data);
            me.userHistoryMap[userId] = history;
            
            return history;
        });
    }
    
    /**
     * Creates or updates a user of a company. If the user.companyId is blank, it will use the House Account
     * @param user {UserModel} - The user to create or update. If the user.companyId is blank, it will use the House Account
     * @returns {Promise<UserModel>}
     */
    async updateUserAsync(user) {
        const me = this;
        let userId = user.id;
        let path = "/api/company/" + user.companyId + "/user" + (userId ? "/" + userId : "");
        
        return await HttpService.instance.postAsync(path, user.toJson()).then((rsp) => {
            let u = new UserModel(rsp.data);
            me.userMap[u.id] = u;
            
            return u;
        });
    }

    /**
     * Updates the user's card on file in a specific company context
     * /Finance/PaymentsController.cs
     * @param userId
     * @param data
     * @param companyId
     * @returns {Promise<PaymentMethodModel>}
     */
    async updateCardOnFileAsync(userId, data, companyId) {
        const path = !!companyId && companyId.length > 30 ?
            "/api/company/" + companyId + "/user/" + userId + "/credit-card" :
            "/api/user/" + userId + "/credit-card";
        
        const me = this;

        return await HttpService.instance.postAsync(path, data).then((rsp) => {
            const paymentModel = new PaymentMethodModel(rsp.data);
            me.paymentMethodMap[userId] = paymentModel;

            return paymentModel;
        });
    }
    
    async updateUserRoleAsync(userId, roleId) {
        if (typeof roleId !== 'number') console.warn("Missing RoleId: " + roleId);
        
        const path = "/api/user-auth/" + userId + "/role/" + (roleId || "0");

        return await HttpService.instance.postAsync(path, {}).then((rsp) => {
            return new AuthModel(rsp.data);
        });
    }

    async activateUserAuthAsync(userId, username, roleId, companyId = null) {
        const path = "/api/user-auth/" + userId + "/activate";
        const data = {
            role_id: roleId || null,
            username: username || null,
            company_id: companyId || null,
        };
        
        return await HttpService.instance.postAsync(path, data).then((rsp) => { 
            return new AuthModel(rsp.data);
        });
    }

    async deleteUserAsync(userId) {
        const path = "/api/user/" + userId;
        const me = this;
        
        return await HttpService.instance.deleteAsync(path).then((rsp) => {
            delete me.userMap[userId];
            console.log("Deleted user: " + userId);
            return rsp?.data;
        });
    }

    async getUserPaymentMethodAsync(userId, companyId) {
        const path = !!companyId && companyId.length > 30 ?
            "/api/company/" + companyId + "/user/" + userId + "/credit-card" :
            "/api/user/" + userId + "/credit-card";
        
        const me = this;

        return await HttpService.instance.getAsync(path, true).then((rsp) => {
            const pm = new PaymentMethodModel(rsp.data);
            
            if (!!userId && pm?.mask) me.paymentMethodMap[userId] = pm;
            else return null;
            
            return pm;
        }).catch((ex) => {
            throw ex;
        });
    }
    
    async getUserSeriesByDayAsync(companyId, startDate, endDate) {
        const url = "/api/user-report" + (!!companyId && companyId.length > 30 ? "/" + companyId : "");
        
        return await HttpService.instance.getWithDateRangeAsync(url, startDate, endDate).then((rsp) => {
            const seriesModel = new LineGraphSeriesModel(rsp.data, "Users", "red");
            if (!seriesModel.items) return null;
            return seriesModel;
        });
    }
    
    appendUserMap(users, overwrite = false) {
        users.forEach((user) => {
            let uid = user.id;
            if (overwrite || !this.userMap[uid]) {
                console.log('Added User: ' + user.firstName);
                if (!this.userMap[uid]?.thumbs) {
                    this.userMap[uid] = user;
                }
            }
        });
    }
    
    concatUserMap(userMap, overwrite = false) {
        for(let uid in userMap) {
            if (overwrite || !this.userMap[uid]) { 
                console.log('Added User: ' + userMap[uid].firstName);
                this.userMap[uid] = userMap[uid];
            }
        }
    }
}

export default UserService;
