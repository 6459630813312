import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCreditCard,
    faCreditCardAlt,
    faDiagramProject,
    faArrowTrendUp,
    faUserAlt, faUsers, faGlobe, faTractor, faBomb, faBug, faBugs
} from "@fortawesome/free-solid-svg-icons";

const MetricsSubMenu = (props) => {
    const { selection } = props;

    const sel = selection || 'main';
    const selections = {};
    
    selections[sel] = 'selected';

    return (<div className="sub-column">
        <ul>
            <li className="sub-menu-header">
                More
            </li>
            <li className={selections['main']}><Link to={'/metrics'}><FontAwesomeIcon icon={faArrowTrendUp} /> Overview</Link></li>
            <li className={selections['web-visitors']}><Link to={'/metrics/web-visitors'}><FontAwesomeIcon icon={faGlobe} /> Web Visitors</Link></li>
            <li className={selections['web-sessions']}><Link to={'/metrics/web-sessions'}><FontAwesomeIcon icon={faUsers} /> Web Sessions</Link></li>
            <li className={selections['transactions']}><Link to={'/metrics/transactions'}><FontAwesomeIcon icon={faCreditCardAlt} /> Card Transactions</Link></li>
            <li className={selections['errors']}><Link to={'/metrics/errors'}><FontAwesomeIcon icon={faBug} /> Platform Errors</Link></li>
        </ul>
    </div>);

};

export default MetricsSubMenu;
