import React from 'react';

const CompletedPanel = (props) => {
    const { title, onContinue, children } = props;
    
    const titleElement = !!title ? (<h2>{title}</h2>) : null;
    return (
        <span className="completed-panel">
            { titleElement }
            { children }
        </span>
    );
};

export default CompletedPanel;
