import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBuildingColumns,
    faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {Link } from "react-router-dom";
import CompanyService from "../../components/companies/services/CompanyService";
import EmptyBox from "../../components/common/ui/EmptyBox";
import HeaderButton from "../../components/common/ui/HeaderButton";
import LoadingBox from "../../components/common/ui/LoadingBox";
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import SearchFilter from "../../components/search/ui/search-filter/SearchFilter";
import StringDisplay from "../../components/common/ui/StringDisplay";
import CompanyTypeViewModel from "../../components/companies/models/CompanyTypeViewModel";

const CompaniesScreen = (props) => {
    const {} = props;
    let _;
    
    let [companyViews, setCompanyViews] = useState(CompanyService.instance.companies || null);
    let [filterText, setFilterText] = useState('');
    
    const refreshCompaniesAsync = async () => {
        console.log('Getting companies...');
        
        return await CompanyService.instance.getCompaniesAsync().then((cs) => {
            console.log('Got companies GOOD: ' + cs.length);
            setCompanyViews(cs);
        }).catch((error) => {
            console.log('Ex: ' + error);
        });
    };
    
    useEffect(() => {
        _ = refreshCompaniesAsync();
    }, []);

    useEffect(() => {
        //
    }, [companyViews]);

    const mapperFunction = (x, index) => {
        const contact = x.primaryUser?.fullName ?
            (<Link to={"/people/" + x.id}>{x.primaryUser?.fullName}</Link>) :
            (<Link className={"faded"} to={"/companies/" + x.id + "/edit/owner"}>[add user]</Link>);
        
        const websiteElement = x.website ?
            (<a href={x.website} target={"_blank"} rel="noreferrer">{x.websiteDisplay}</a>) :
            (<Link className={"faded"} to={"/companies/" + x.id + "/edit"}>[n/a dot com]</Link>);
        
        const emailElement = x.email ?
            (<a href={"mailto:" + x.email} target={"_blank"} rel="noreferrer">{x.email}</a>) :
            (<Link className={"faded"} to={"/companies/" + x.id + "/edit"}>[no email yet]</Link>);

        return (
            <tr key={x.id + "-" + index.toString()} className={"full"}>
                <td><Link to={"/companies/" + x.id}>{x.name}</Link></td>
                <td>{ contact }</td>
                <td>{ websiteElement }</td>
                <td>{ emailElement }</td>
                <td>{ new Date().formatDate()}</td>
            </tr>
        )
    };

    const tableHeader = (companyView) => {
        const isNone = companyView.type === 0;
        const isHouse = companyView.type === -1;
        const title = isNone ? "Uncategorized" : companyView.name;
        const icon = CompanyTypeViewModel.icons[companyView.type?.toString() || "0"];
        
        return (
            <thead key={"header-" + companyView.type}>
            { !!title ? (<tr className={"h3"}><th colSpan={6}><h3><FontAwesomeIcon icon={icon} /> <StringDisplay plural={!isNone && !isHouse}>{ title }</StringDisplay></h3></th></tr>) : null }
            <tr>
                <th><a>Name</a></th>
                <th>Contact</th>
                <th>Website</th>
                <th>Email</th>
                <th><a>Modified</a></th>
            </tr>
            </thead>
        );
    }

    const createCompanySectionElements = (companyView) => {
        const companies = companyView?.companies?.filter((c) => c.searchFor(filterText)) || [];
        const sortFunction = (a, b) => {
            return (a.modified || a.created).getTime() > (b.modified || b.created).getTime() ? -1 : 1;
        };
        
        if (companies.length > 0) {
            companies.sort((a, b) => sortFunction(a, b));
            
            const elements = companies.map((x, index) => {
                return mapperFunction(x, index);
            });

            return (
                <React.Fragment key={"t-body-" + companyView.type}>
                    {tableHeader(companyView)}
                    <tbody>
                    {elements}
                    <tr>
                        <td colSpan={6} className={"spacer"}>&nbsp;</td>
                    </tr>
                    </tbody>
                </React.Fragment>
            );
        }
        
        return (<></>);
    };
    
    let body = companyViews?.length > 0 ? (<table width={"100%"} id={"companies-table"}>
            { companyViews.map((x) => createCompanySectionElements(x)) }
    </table>) : 
        companyViews === null ? (<LoadingBox size={32} />) : 
            (<EmptyBox>No Companies Under Management, which is a bit of a bummer.<br/><Link to={"/companies/00-00/edit"}>Add One Here</Link></EmptyBox>);

    const subtitle = (<>
        <FontAwesomeIcon icon={faUserTie} />
        Companies Under Management

        <Link className={"add"} to={"/companies/00-00/edit"}>
            <FontAwesomeIcon icon={faBuildingColumns} />
            Add Company
        </Link>
        <HeaderButton onClick={refreshCompaniesAsync}>Refresh</HeaderButton>
        <SearchFilter onFilter={setFilterText} />
    </>);
    
    return (
        <CommonMasterPage selector={"companies"} title={"Companies"} subtitle={"Companies Under Management"} subTitle={subtitle}>
            <p className={"with-filter"}>
                No new companies as of now.
            </p>

            <div>
                { body }
            </div>
        </CommonMasterPage>    
    );
};

export default CompaniesScreen;
