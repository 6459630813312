import HttpService from "../../common/services/HttpService";
import CalendarEventModel from "../models/CalendarEventModel";

class CalendarService { 
    static instance = new CalendarService();
    static viewRangeWeek = 0;
    static viewRangeDay = 1;
    static viewRangeMonth = 2;

    constructor() { 
        this.id = null;
        this.calendarMap = {};
    };

    getStartDate(startDate, viewRange = 0) {
        if (!(startDate instanceof Date))
            startDate = new Date();
        
        switch(viewRange) {
            case 1:
                return startDate;
            case 2:
                const dayIndex = startDate.getDate() - 1;
                if (dayIndex <= 0) return startDate;
                
                return startDate.addDays(dayIndex);
            default:
                const weekDayIndex = startDate.getDay();
                if (weekDayIndex === 0) return startDate;
                
                return new Date(startDate.addDays(-weekDayIndex));
        }
    }
    
    getEndDate(startDate, viewRange = 0) {
        if (typeof viewRange !== "number" ||  viewRange < 0 || viewRange > 2)
            viewRange = 0;
        
        if (!(startDate instanceof Date))
            startDate = new Date();
        
        const d1 = this.getStartDate(startDate, viewRange);
        
        switch(viewRange) { 
            case 1:
                return d1.addDays(1);
            case 2:
                return d1.addMonths(1);
            default:
                return d1.addDays(7);
        }
    }
    
    async getCalendarAsync(companyId, startDate, endDate, viewRange = 0) { 
        if (!startDate) startDate = this.getStartDate(new Date());
        if (!endDate) endDate = this.getEndDate(startDate, viewRange);
        
        const path = "/api/company/" + companyId + "/calendar?start-date=" + startDate.toISOString() + "&end-date=" + endDate.toISOString() + "&view-range=" + viewRange;
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((rsp) => { 
            const events = CalendarEventModel.fromJsonArray(rsp?.data);
            
            if (Array.isArray(events)) { 
                me.calendarMap[companyId] = events;
                return events;
            }

            return [];
        });
    }

}

export default CalendarService;
