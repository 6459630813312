import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CompanyService from '../../../components/companies/services/CompanyService';
import MessageTemplateService from '../../../components/messaging/services/MessageTemplateService';
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import DateTime from "@jadecharles/pi-react-packages/dist/common/ui/formatting/DateTime";
import StringDisplay from "@jadecharles/pi-react-packages/dist/common/ui/formatting/StringDisplay";
import MessageTemplateModel from '../../../components/messaging/models/MessageTemplateModel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMenuModal from '@jadecharles/pi-react-packages/dist/common/ui/modals/react/ReactMenuModal';
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const EmailMessagesScreen = (props) => {
    const [emailMessages, setEmailMessages] = useState([]);
    const [templateTypes, setTemplateTypes] = useState(MessageTemplateService.instance.templateTypes);
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    
    const onRefresh = async (force = false) => {
        if (emailMessages?.length > 0 && !force) return emailMessages;

        console.log("Getting email messages...");
        const messages = await MessageTemplateService.instance.getMessageTemplatesAsync(companyId);
        
        if (Array.isArray(messages)) {
            setEmailMessages(messages);
            return messages;
        }

        return null;
    }
    
    const getTypesAsync = async (force = false) => {
        if (templateTypes?.length > 9 && !force) return templateTypes;

        return await MessageTemplateService.instance.getTemplateTypesAsync().then((types) => {
            if (types?.length > 0) {
                setTemplateTypes(types);
                return types;
            }
         });
    };

    const onCompany = (c) => { 
        setCompany(c);
    }

    useEffect(() => { 
        onRefresh();
        getTypesAsync();
    }, []);

    const messageElements = emailMessages?.length > 0 ? emailMessages.map((m, i) => {
        const htmlPreview = MessageTemplateModel.getHtmlPreview(m.body);
        const link = "/companies/" + companyId + "/messaging/email-messages/" + m.id;

        return (<tr className="clickable" key={"message-element-" + i}>
            <td><Link key={"message-element-" + i} to={link}>{m.name}</Link></td>
            <td><Link key={"message-element-" + i} to={link}><StringDisplay maxLength={48}>{m.subject}</StringDisplay></Link></td>
            <td><Link key={"message-element-" + i} to={link}><StringDisplay maxLength={64}>{htmlPreview}</StringDisplay></Link></td>
            <td><Link key={"message-element-" + i} to={link}><DateTime value={m.created || m.modified} /></Link></td>
        </tr>);

    }) : null;

    const showCreateMenuAsync = async (e) => { 
        const ids = emailMessages?.map((m) => m.messageTemplateType) || [];
        if (ids.length === 0) return;

        const dialog = new ReactMenuModal();
        
        const menuItems = templateTypes.filter((tt) => !ids.includes(tt.value)).map((t, i) => {
            const link = "/companies/" + companyId + "/messaging/email-messages/" + t.value.toString();
            
            return (<Link key={"create-menu-item-" + i} className="dropdown menu-item" to={link}>
                <FontAwesomeIcon icon={faEnvelope} />
                { t.name }
            </Link>)
        });

        await dialog.openDropdownAsync(e, menuItems, { title: "Create Message Template" });
    }

    const addButton = (<a className={"add"} onClick={showCreateMenuAsync}>
        <FontAwesomeIcon icon={faEnvelope} />
        Add Template
    </a>);

    const subtitle = (<>Messages Templates { addButton }</>);

    return (<CompanyMasterPage onRefresh={() => onRefresh(true)} onCompany={onCompany} selection={"message-templates"} title={subtitle}>
        <p>
            Email messages that get sent out to users for various reasons.
        </p>
        
        <table className="table wide table-striped table-hover">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Subject</th>
                    <th>Body</th>
                    <th>Modified</th>
                </tr>
            </thead>
            <tbody>
                {messageElements}
            </tbody>
        </table>
        
    </CompanyMasterPage>);
}

export default EmailMessagesScreen;
