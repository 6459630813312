import React from 'react';
import Header from "../components/common/ui/Header";
import CompletedPanel from "../components/common/ui/CompletedPanel";
import Spinner from "../components/common/ui/Spinner";
import axios from 'axios';
import {useParams} from "react-router-dom";

const Verify = (props) => {
    const [status, setStatus] = React.useState({ isWorking: false, isComplete: false });
    const [title, setTitle] = React.useState('Verifying Your Account...');
    const [message, setMessage] = React.useState('Please wait while we verify your account. It shouldn\'t take long.');
    const { authCodeId } = useParams();

    const setComplete = (title, message) => {
        setStatus({ isWorking: false, isComplete: true });
        setTitle(title || 'Verification Complete');
        setMessage(message ||'You have successfully verified your account.');
    };

    let spinner = (<Spinner isWorking={status.isWorking} />);
    
    if (!status.isWorking && !status.isComplete) {
        setStatus({ isWorking: true, isComplete: false });
        
        let path = '/api/auth/verify/' + authCodeId;
        
        axios.post(path, {}).then(response => {
            setComplete();
        }).catch(error => {
            let message = error.response.data.message || error.message;
            let code = error.response.data.error_code;
            
            if (!code) {
                code = error.response.status;
                console.log('Error code Updated: ' + code);
            } else { 
                console.log('Error Code is good.')
            }
            
            let title = code !== 9 ? 'Verification Failed' : null;
            
            setComplete(title, message);
        });
    }
    
    return (
        <div>
            <Header title="Account Verification" />
            <CompletedPanel title={title} message={message} leftHeaderElement={spinner} />

            <div hidden={!status.isWorking} className="middle-column">
                <button className="button" onClick={setComplete}>Click to continue</button>
            </div>
        </div>
    );
};

export default Verify;
