import React, {useEffect, useState} from 'react';
import DateTime from "../../common/ui/DateTime";
import ApiKeyAuthService from "../services/ApiKeyAuthService";
import EmptyBox from "../../common/ui/EmptyBox";
import LoadingBox from "../../common/ui/LoadingBox";
import AuthenticationService from "../services/AuthenticationService";
import FormButton from "../../common/ui/FormButton";

const ApiKeysTable = (props) => {
    let _;
    const componentId = useState("api-keys-table-" + (Math.random() * 9999999999).toString(16).toLowerCase())[0];
    const {options, userId} = props;
    const [viewState, setViewState] = useState({id: 0, data: {}, trigger: null, items: null, componentId: componentId});

    const getNewViewState = (key, data) => {
        const dataRef = viewState.data !== null && typeof viewState.data !== "undefined" ? viewState.data : {};
        const newState = {...viewState, data: null};

        newState.data = dataRef;

        if (typeof key === "string" && !!key)
            newState.data[key] = data;

        return newState;
    }

    const getItemsAsync = async (force = false) => {
        if (force !== true && viewState.items?.length > 0)
            return viewState.items;
        
        const apiKeys = await ApiKeyAuthService.instance.getApiKeyAuthsByUserAsync(userId);
        
        if (Array.isArray(apiKeys)) {
            const ns = {...viewState, items: apiKeys };
            setViewState(ns);
            
            return apiKeys;
        }

        return null;
    };
    
    const suppress = (e) => { 
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        if (typeof e?.stopPropagation === "function")
            e.stopPropagation();
    };
    
    const deleteApiKeyAsync = async (e, apiKeyId) => {
        suppress(e);
        await ApiKeyAuthService.instance.deleteApiKeyAuthAsync(apiKeyId);
        await getItemsAsync(true);
    };
    
    const generateApiKeyAsync = async (e) => {
        suppress(e);
        const name = AuthenticationService.instance.session?.user?.name || "Generated";
        const apiKey = await ApiKeyAuthService.instance.createApiKeyAuthAsync(name, userId);
        await getItemsAsync(true);
    };

    useEffect(() => {
        //
    }, [viewState]);

    useEffect(() => {
        console.warn("Entering: " + componentId);
        _ = getItemsAsync();
    }, []);

    const elements = Array.isArray(viewState.items) ?
        viewState.items.map((apiKey, index) => { 
            return (<div key={apiKey.id} className={"api-key-row"}>
                <div>
                    <span>{apiKey.apiKey}</span>
                    <a className="delete right hover" onClick={(e) => deleteApiKeyAsync(e, apiKey.id)}>Delete</a>
                </div>
                
                <div className={"date-footer"}>
                    <span>
                        <strong>Name: </strong>
                        <span>{apiKey.name || "(Untitled)"}</span>
                    </span>
                    <DateTime value={apiKey.created} />
                </div>
            </div>);
        }) : null;

    if (!Array.isArray(elements))
        return (<LoadingBox>Loading...</LoadingBox>);

    if (elements.length === 0)
        return (<EmptyBox>
            <div className={"flex"}>
                <span>No API Keys</span>
                <span><FormButton onClick={generateApiKeyAsync}>Generate</FormButton> </span>
            </div>
        </EmptyBox>);
    
    return (<div className={"api-keys-table"}>
        <h3>API Keys</h3>
        {elements}
    </div>);
};

ApiKeysTable.defaultOptions = () => {
    return {
        title: "",
        onClick: (e, options) => true,
        onComplete: (e, options) => true,
        getData: (e, options) => undefined,
        setData: (key, data) => false,
    };
};

export default ApiKeysTable;

