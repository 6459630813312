import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const AddNewHeaderButton = (props) => {
    let {url, addUrl, to, icon, text, caption, onClick} = props;

    if (!url) url = addUrl;
    if (!url) url = to; //"/companies/" + companyId + "/billing/000-000/edit"
    
    if (!icon) icon = faFileInvoiceDollar;
    if (!caption) caption = text;
    if (!caption) caption = props.children;
    if (!caption) caption = "Add New";
    
    let body = (<><FontAwesomeIcon icon={icon} /> {caption}</>);
    
    if (typeof onClick === 'function') return (<a className={"add"} onClick={onClick}>{body}</a>);
    
    return (<Link className={"add"} to={url}>
        {body}
    </Link>);
};

export default AddNewHeaderButton;

