import React, {useState, useRef, useEffect} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import CompanyEditSubMenu from "../../../components/companies/ui/CompanyEditSubMenu";
import PersonForm from "../../../components/people/ui/PersonForm";
import UserService from "../../../components/people/services/UserService";
import CompanyUserSubMenu from "../../../components/companies/ui/CompanyUserSubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import Controller from "../../../components/common/services/Controller";
import CompleteDialog from "../../../components/common/ui/dialog-box/CompleteDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp} from "@fortawesome/free-solid-svg-icons";
import CompanyService from "../../../components/companies/services/CompanyService";

/**
 * There are several entrances into this screen.
 * 1. From the CompanyUsersScreen, the user clicks on the "Edit" button.
 * 2. From the CompanyEditScreen, the user clicks on the "Owner Details" sub-submenu button.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyEditUserScreen = (props) => {
    let _;
    const { companyId, userId } = useParams();
    const [company, setCompany] = useState();
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const [user, setUser] = useState(UserService.instance.userMap[userId || ""] || null);
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[user?.companyId || ""] || null);

    const [pageState, setPageState] = useState(0);
    const [newUserId, setNewUserId] = useState(null);
    const controller = useRef(new Controller()).current;
    
    const isCompanyUser = !!userId;
    const isNewUser = !!userId && userId.length < 30;
    const isCompanyPrimaryUser = !userId && !!companyId;
    
    const updateProfileAsync = async (profile) => {
        let userId = company?.primaryUserId;
        if (!userId) return;
        
        await UserService.instance.updateUserAsync(profile).then((user) => {
            let title = "Updated Successfully";
            if (isNewUser) { 
                title = "Created Successfully";
                setNewUserId(user.id);
            } else if (company?.primaryUserId === user?.id) {
                company.primaryUser = user;
                setCompany(company);
            }
            
            controller.open(title);
            return user;
        });
    };

    const getUserAsync = async (e) => {
        if (isNewUser) return;
        
        return await UserService.instance.getUserAsync(userId).then((user) => {
            if (!user) return;
            setUser(user);

            if (user.companyId !== companyId && !userCompany) {
                console.log("Getting User's Company: " + user.companyId);
                _ = CompanyService.instance.getCompanyAsync(user.companyId).then((c) => {
                    if (!!c) setUserCompany(c);
                });
            }

        });
    };
    
    useEffect(() => {
        if (isCompanyUser) _ = getUserAsync();
    }, []);

    if (!!newUserId && newUserId.length > 30) { 
        return (<Navigate to={"/companies/" + companyId + "/people/" + newUserId} />);
    }
    
    const u = isCompanyUser ? user : company?.primaryUser;
    const userForm = u || isNewUser ? (<PersonForm user={u} onSubmit={updateProfileAsync} companyId={companyId} />) : null;

    const editMenu = isCompanyUser ?
        (user ? (<CompanyUserSubMenu user={user} company={company} selection={"edit"} />) : null) :
        (company ? (<CompanyEditSubMenu company={company} selection={"owner"} />) : null);
    
    const h2titleText = isNewUser ? "Create New User" : "Edit " + (user?.firstName || "User") + "'s Details";
    const h2title = (<>{ h2titleText }</>);

    const menuSelector = isCompanyUser ? "people" : "edit";
    const backButton = isCompanyUser ? (<BackButton to={"/companies/" + companyId + "/people"} />) : null;
    
    const subtitle = (<>
        {backButton}
        {h2title}
    </>);

    const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
    const companyElement = (isDifferentCompany) ?
        (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
        null;

    return (
        <CompanyMasterPage selection={menuSelector} menuSelection="edit" onCompany={onCompany} title={subtitle}>

            { editMenu }

            <p>
                The owner of the company is simply the primary contact. It's not some sort of legal title.
                { companyElement }
            </p>

            <div className={"form medium"}>
                { userForm }
            </div>

            <CompleteDialog controller={controller} />
            
        </CompanyMasterPage>
    );
};

export default CompanyEditUserScreen;