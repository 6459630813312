import React, {useState} from 'react';
import CompanyActivityMasterScreen from "./CompanyActivityMasterScreen";
import CompanyService from "../../../components/companies/services/CompanyService";
import {useParams} from "react-router-dom";
import ActivityTable from "../../../components/activity/ui/ActivityTable";
import CompanyModel from "../../../components/companies/models/CompanyModel";

const CompanyActivityScreen = (props) => {
    const {} = props;
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);

    const onCompany = (c) => {
        if (!!c) {
            setCompany(c);
        }
    };

    const onActivityLoad = (a) => {
        
    };
    
    const activityTable = !!company?.id ? (<ActivityTable entityType={CompanyModel.entityType} entityId={companyId} onLoad={onActivityLoad} />) : null;
    
    return (
        <CompanyActivityMasterScreen onCompany={onCompany} title={"Company Activity History"}>
            <p>Good.</p>

            { activityTable }
        </CompanyActivityMasterScreen>
    );
};

export default CompanyActivityScreen;

