class NavigationHistoryItemModel {

    /**
     * Tracks navigation history. If a key is included, it will overwrite the current item with the same key.
     * @param props {{title: string, key: string, description: string, path: string, date: Date, time: number, data: any }|any|null}
     * @param key { string|null } - If a key is included, it will overwrite the current item with the same key.
     */
    constructor(props = null, key = null) {
        if (!props) props = {};
        
        this.title = document.title;
        this.score = 0;
        
        if (typeof props === 'string') { 
            this.title = props;
            props = {};
        } else if (typeof props !== 'object') props = { data: props };
        else if (!!props.title) this.title = props.title;

        this.key = key || (props.key || null);
        this.description = props.description || "";
        this.path = props.path || window.location.pathname;
        this.time = props.time || 0;
        this.data = props.data || null;
        
        this.date = new Date();
    }
    
    static createKey() { 
        return window.location.pathname?.toLowerCase().replace(/[^a-z0-9]/g, "-") || null;
    }
}

export default NavigationHistoryItemModel;
