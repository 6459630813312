import React, { useState } from 'react';
import {useParams} from "react-router-dom";
import AddNewHeaderButton from "../../../components/common/ui/AddNewHeaderButton";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";

const PaymentEditScreen = () => {
    const { companyId, paymentId } = useParams();
    const [company, setCompany] = useState(null);

    const onCompany = (c) => {
        if (!!c) setCompany(c);
    }

    const subtitle = !!paymentId && paymentId.length > 30 ? "Edit Payment" : "Create Payment";

    return (
        <BillingMasterPage onCompany={onCompany} selection={"transactions"} title={subtitle}>
            <p>
                This is the billing screen.
            </p>
            
            <div>

            </div>
        </BillingMasterPage>
    );

};

export default PaymentEditScreen;

