import CompanyService from "./CompanyService";
import ConfigModel from "@jadecharles/pi-react-packages/dist/common/models/ConfigModel";

class CompanyEffect {
    static create(setFunction, companyId, sessionId = null) {
        let _ = new CompanyEffect(setFunction, companyId, sessionId);
    }
    
    constructor(setFunction, companyId, sessionId) {
        this.setCompany = setFunction;
        this.companyId = companyId;
        this.sessionId = sessionId || null;
        this.updateScreen();
    }
    
    updateScreen() {
        if (!this.companyId || (this.companyId === ConfigModel.emptyId)) return;
        const me = this;
        
        let h = null;
        if (!!this.sessionId) h = { 'session-id': this.sessionId };
        
        CompanyService.instance.getCompanyAsync(this.companyId, h).then((company) => {
            me.setCompany(company);
        });
    }
    
}

export default CompanyEffect;
