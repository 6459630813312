import ApiAuthModel from "../../authentication/models/ApiAuthModel";

class CompanyApiModel {
    static entityType = 24;

    /**
     * Mapped to ApiTypeEnum
     * @type {number}
     */
    static typeTwilioSms = 10;
    static typeSendGrid = 11;
    static typePushyMe = 12;
    static typePaylaniGateway = 13;
    static typeShortcut = 14;
    static typeTwilioVoice = 15;
    static typeTrello = 16;
    static typeGoogleServiceAccount = 17;
    static typeGoogleOAuth = 18;
    static typePlaid = 22;
    static typePaymentGateway = 23;
    
    constructor(json) {
        if (!json) json = {};

        this.id = json.id;
        
        this.name = json.name || (json.api_type_name || null);
        this.apiType = json.api_type;
        this.apiTypeName = json.api_type_name;
        this.entityType = json.entity_type || 0;
        this.entityTypeName = json.entity_type_name;

        this.description = json.description;
        this.domain = json.domain;
        this.identifier = json.identifier || "";
        this.companyId = json.company_id || "";
        
        this.data = null;
        this.apiAuthId = json.api_auth_id || null;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
        this.metaData = {};
        
        const md = json.meta_data;
        
        if (!!md) {
            try {
                if (typeof md === 'string') this.metaData = JSON.parse(md);
                else this.metaData = { data: md, type: (typeof md) };
            } catch (e) {
                this.metaData = { data: md, type: 'string' };
            }
        }
    }
    
    isGoogle() { 
        return (this.apiType?.toString() === ApiAuthModel.googleOAuthKey);
    }
    
    isInstagram() {
        return (this.apiType?.toString() === ApiAuthModel.instagramOAuthKey);
    }
    toJson() {
        return {
            api_type: this.apiType,
            description: this.description,
            domain: this.domain,
        };
    }
    
    toApiAuthData(accountName, name = null) {
        return {
            api_type: this.apiType || this.api_type,
            api_auth_type: this.apiAuthType || this.api_auth_type,
            entity_type: this.entityType || this.entity_type,
            api_key: this.data,
            name: (name || this.name) || (this.apiTypeName || "Company Api") ,
            account_name: accountName
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new CompanyApiModel(json));
    }
}

export default CompanyApiModel;
