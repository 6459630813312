class UserNotificationModel {
    static entityType = 22;
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.userId = json.user_id;
        this.notificationId = json.notification_id;
        this.text = json.text || null;
        this.title = json.title || null;

        this.readDate = !!json.read_date ? new Date(json.read_date) : null;
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            notification_id: this.notificationId,
            title: this.title || undefined,
            read_date: this.readDate,
            user_id: this.userId,
            text: this.text,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new UserNotificationModel(json));
    }
}

export default UserNotificationModel;
