import HttpService from "../../common/services/HttpService";
import PaymentModel from "../models/PaymentModel";

class PaymentService { 
    static instance = new PaymentService();

    constructor() {
        this.paymentsMap = {};
        this.paymentMap = {};
        this.payments = null;
    }

    async getPaymentAsync(paymentId) {
        const path = '/api/payment/' + paymentId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.paymentMap[paymentId] = new PaymentModel(response.data);
            return me.paymentMap[paymentId];
        });
    }
    
    async capturePaymentAsync(paymentJson) {
        const path = "/api/payment";
        const me = this;

        return await HttpService.instance.postAsync(path, paymentJson).then((response) => {
            const p = new PaymentModel(response.data);
            me.payments.push(p);
            return p;
        });
    }

    async getPaymentsAsync(startDate = null, endDate = null) {
        const path = "/api/payment";
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            const ps = PaymentModel.fromJsonArray(response.data);
            me.payments = ps;

            return ps;
        });
    }

    async getPaymentsByCompanyAsync(companyId) {
        const path = "/api/company/" + companyId + "/payment";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const ps = PaymentModel.fromJsonArray(response.data);
            me.paymentsMap[companyId] = ps;
            return ps;
        });
    }
    
}

export default PaymentService;
