/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useEffect, useState} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faBriefcase, faCreditCardAlt, faSync} from "@fortawesome/free-solid-svg-icons";
import {Link, Navigate, useParams} from "react-router-dom";
import CompanyEffect from "../services/CompanyEffect";
import CompanyService from "../services/CompanyService";
import CompanySubMenu from "./CompanySubMenu";
import HeaderButton from "../../common/ui/HeaderButton";
import CommentForm from "../../messaging/ui/CommentForm";
import ConfigModel from "../../common/models/ConfigModel";

const CompanyMasterPage = (props) => {
    const {
        onCompany,
        onRefresh,
        onSessionChange,
        onClick,
        companyModel, 
        menuSelection,
        actions,
        selection,
        selector,
        children,
        controller,
        data,
        subMenu,
        title,
        subTitle,
        subTitleClassName,
        bodyClassName,
        emptyCompanyId,
        commentEntityType
    } = props;
    
    const { companyId } = useParams();

    let [company, setCompany] = useState(companyModel || (CompanyService.instance.companyMap[companyId] || null));

    const getCompanyAsync = async () => {
        if (!companyId || companyId.length < 10) return;
        console.warn("Getting CompanyId: " + companyId);
        
        return await CompanyService.instance.getCompanyAsync(companyId).then((c) => {
            if (!!c) setCompany(c);
            
            return c;
        });
    };
    
    const refreshAsync = async (e) => {
        if (typeof onRefresh === 'function') {
            return await Promise.all([
                getCompanyAsync(),
                onRefresh(),
            ]);
        } else console.error("No refresh function was given to the CompanyMasterPage.");
        
        await getCompanyAsync();
    };

    const notifyCompany = (e) => {
        if (!!company && typeof onCompany === 'function') {
            onCompany(company);
        }
    }
    
    useEffect(() => {
        notifyCompany();
    }, [company]);
    
    useEffect(() => {
        const cid = (!companyId || companyId === ConfigModel.emptyId) ? emptyCompanyId : companyId;
        CompanyEffect.create(setCompany, cid);
        notifyCompany();
    }, []);
    
    // In the situation where no companyId has been passed, we can take the "emptyCompanyId" (usually from the user record) and replace it
    if (!!emptyCompanyId && emptyCompanyId !== companyId && (!companyId || companyId.length < 30 || companyId === ConfigModel.emptyId)) {
        const path = (window.location.pathname || "").replace("/companies/" + ConfigModel.emptyId, "/companies/" + emptyCompanyId);
        return (<Navigate to={path} />);
    }
    
    const subMenuSelection = (selection || menuSelection) || ("main");
    const companySubMenu = company ? (<CompanySubMenu company={company} selection={subMenuSelection} />) : null;
    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this CompanyMasterPage. If you meant to have this blank, try passing a null value to the "company" property.</p>)

    const refreshButton = company ? (<HeaderButton key={"header-button-x"} icon={faSync} onClick={refreshAsync}>Refresh</HeaderButton>) : null;
    const comments = company && typeof commentEntityType === 'number' ? (<CommentForm entityType={commentEntityType} entityId={company.id} />) : null;
    
    const companyThumbImage = company?.image?.thumbMap["128x128"]?.url;
    const companyIcon = !!companyThumbImage ? (<img src={companyThumbImage} className={"company-h1-image"} alt={"Company Image"} />) : (<FontAwesomeIcon icon={faBriefcase}/>);
    
    let h1 = (<>{ companyIcon }
        <span className={"subtitle"}>
            <Link to={"/companies"}>Companies:</Link>
        </span>
        {company?.name}
    </>);
    
    let actionElements = !!actions && Array.isArray(actions) ? actions.map((a, i) => {
        return (<React.Fragment key={"action-" + i}>{a}</React.Fragment>);
    }) : [];
    
    actionElements.splice(0, 0, refreshButton);
    
    return (
        <MasterScreen selector={"companies"} title={h1} onClick={onClick} onRefresh={onRefresh} onSessionChange={onSessionChange}>
            <div id="company-main" className="pad">

                <div className="details-body">
                    { companySubMenu }
                    
                    <div className={"pad " + (bodyClassName || "x-large")}>
                        <h2 className={subTitleClassName || ""}>
                            {typeof title === 'undefined' ? "Company Information Page" : title}
                            {actionElements}
                        </h2>
                        { subMenu }
                        { body }
                        { comments }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );

};

export default CompanyMasterPage;
