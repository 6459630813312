import React, {useEffect, useRef, useState} from 'react';
import {Navigate, useParams} from "react-router-dom";
import CompanyService from "../../components/companies/services/CompanyService";
import FormButton from "../../components/common/ui/FormButton";
import CompanyEditSubMenu from "../../components/companies/ui/CompanyEditSubMenu";
import CompanyMasterPage from "../../components/companies/ui/CompanyMasterPage";
import CompanyModel from "../../components/companies/models/CompanyModel";
import Controller from "../../components/common/services/Controller";
import CompleteDialog from "../../components/common/ui/dialog-box/CompleteDialog";

const CompanyEditScreen = (props) => {
    let _;
    let { companyId } = useParams();
    let [company, setCompany] = useState(null);
    let [pageState, setPageState] = useState({ count: 0, didKey: false});
    let [redirectPath, setRedirectPath] = useState(null);
    
    const [errorMessage, setErrorMessage] = useState(null);
    const controller = useRef(new Controller()).current;

    let isNewCompany = !(companyId && companyId.length > 10);

    let companyName = useRef();
    let companyEin = useRef();
    let companyEmail = useRef();
    let companyPhone = useRef();
    let companyWebsite = useRef();
    let companyType = useRef();
    let companyUnit = useRef();
    let companyKey = useRef();

    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const setCompanyJson = (c) => {
        if (!c) return;
        
        if (companyName?.current) companyName.current.value = c?.name || "";
        if (companyEin?.current) companyEin.current.value = c?.ein || "";
        if (companyEmail?.current) companyEmail.current.value = c?.email || "";
        if (companyPhone?.current) companyPhone.current.value = c?.phone || "";
        if (companyWebsite?.current) companyWebsite.current.value = c?.website || "";
        if (companyKey?.current) companyKey.current.value = c?.companyKey || "";
        if (companyType?.current) companyType.current.value = c?.companyType || "0";
        if (companyUnit?.current) companyUnit.current.value = c?.companyUnit || "";
    };
    
    const onPageStateChange = (e) => {
        setPageState({ count: pageState.count + 1, didKey: pageState.didKey});
    };

    useEffect(() => {
        if (!!company) setCompanyJson(company);
    }, [company]);

    useEffect(() => {

    }, []);
    
    if (redirectPath !== null) {
        return (<Navigate to={redirectPath?.toString()} />);
    }

    const regexAlphaNumeric = /^[a-zA-Z0-9]*$/;
    const onChange = (fieldId, event) => {
        if (fieldId === "company_key") { 
            console.warn("CompanyKey: " + event.target.value);
        } else if (isNewCompany && fieldId === "name") {
            //setPageState({ count: pageState.count, didKey: true});
            let strippedValue = companyName.current.value.toLowerCase().replaceAll('  ', ' ').replaceAll(' ', '-');
            companyKey.current.value = strippedValue.replace(/[\W_]+/g,"");
        }
    };

    const onCompanyUpdateAsync = async (e) => {
        let json = {
            name: companyName.current.value,
            ein: companyEin.current.value,
            email: companyEmail.current.value,
            phone: companyPhone.current.value,
            website: companyWebsite.current.value,
            company_type: companyType.current.value,
            company_key: companyKey.current.value,
            company_unit: null
        };

        await CompanyService.instance.saveCompanyAsync(companyId, json).then((c) => {
            console.log('Done');
            setCompany(c);
            
            if (isNewCompany) setRedirectPath('/companies/' + c.id + "/edit/address");
            else controller.open("Updated Successfully");
        }).catch((e) => {
            const em = e?.data?.message || (e?.response?.message || e?.response?.data?.message) || (e?.toString() || "Unknown error");
            setErrorMessage(em);
        });
    };

    let pageTitleElement = "Add New Company";
    let buttonCaption = "Add Company";
    
    if (!isNewCompany) {
        pageTitleElement = (<>{ company?.name } <span className={"subtitle"}>Company Info</span></>);
        buttonCaption = (<>Update Company Details</>);
    }
    
    const errorElement = errorMessage ? (<div className={"error-box"}>{errorMessage}</div>) : null;
    const companyTypeOptions = CompanyModel.companyTypes.filter((x) => x.id > 0 || x.id === company?.companyType).map((ct) => (<option key={"ctd-" + ct.id} value={ct.id}>{ct.name}</option>));
    
    let formBody = isNewCompany || (company?.id) ? (<div className={"form medium"}>
        <div className={"double"}>
            <div className={"majority"}>
                <label>Company Name:</label>
                <input type={"text"} ref={companyName} onChange={onChange.bind(this, "name")} />
            </div>
            <div className={"minority"}>
                <label>Company Unit:</label>
                <input type={"text"} placeholder={"LLC, Inc, LLP, etc"} ref={companyUnit} onChange={onChange.bind(this, "company_unit")} />
            </div>
        </div>

        <div className={"double"}>
            <div className={"majority"}>
                <label>Company Type:</label>
                <select ref={companyType} onChange={onChange.bind(this, "company_type")}>
                    { companyTypeOptions }
                </select>
            </div>
            <div className={"minority"}>
                <label>Unique Key:</label>
                <input disabled={!isNewCompany} type={"text"} ref={companyKey} onChange={onChange.bind(this, "company_key")} />
            </div>
        </div>

        <label>EIN:</label>
        <input type={"text"} ref={companyEin} onChange={onChange.bind(this, "ein")} />

        <label>Company Email Address:</label>
        <input type={"text"} ref={companyEmail} onChange={onChange.bind(this, "email")} />

        <label>Phone Number:</label>
        <input type={"text"} ref={companyPhone} onChange={onChange.bind(this, "phone")} />

        <label>Website:</label>
        <input type={"text"} ref={companyWebsite} onChange={onChange.bind(this, "website")} />

        { errorElement }
        
        <div className={"buttons"}>
            <FormButton onClick={onCompanyUpdateAsync}>{buttonCaption}</FormButton>
        </div>
    </div>) : null;
    
    return (
        <CompanyMasterPage menuSelection="edit" onCompany={onCompany} title={pageTitleElement} commentEntityType={CompanyModel.entityType}>
            <CompanyEditSubMenu company={company} />

            <p>
                The basics of this company goe here. You can add to it later, so don't stress.
            </p>

            { formBody }
            <CompleteDialog controller={controller} />
        </CompanyMasterPage>
    );

};

export default CompanyEditScreen;
