class ShortcutProjectModel { 
    static json = {
        "app_url": "https://app.shortcut.com/penumbralabs/project/102",
        "description": "",
        "archived": false,
        "entity_type": "project",
        "days_to_thermometer": 5,
        "color": "#7ce8cf",
        "workflow_id": 500000005,
        "name": "BocG",
        "start_time": "2022-05-26T17:53:57Z",
        "updated_at": "2022-05-26T17:54:50Z",
        "follower_ids": [],
        "external_id": null,
        "id": 102,
        "show_thermometer": true,
        "team_id": 1,
        "iteration_length": 2,
        "abbreviation": null,
        "stats": {
            "num_stories": 0,
            "num_points": 0,
            "num_related_documents": 0
        },
        "created_at": "2022-05-26T17:53:57Z"
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.appUrl = json.app_url || "";
        this.description = json.description || "";
        this.archived = json.archived || false;
        this.entityType = json.entity_type || "";
        this.daysToThermometer = json.days_to_thermometer || 0;
        this.color = json.color || "blue";
        this.workflowId = json.workflow_id || 0;
        this.name = json.name || "No-Name Project";
        this.followerIds = json.follower_ids || [];
        this.externalId = json.external_id || null;
        this.id = json.id || -1;
        this.showThermometer = json.show_thermometer || false;
        this.teamId = json.team_id || 0;
        this.iterationLength = json.iteration_length || 0;
        this.abbreviation = json.abbreviation || null;
        this.stats = json.stats || {};
        
        this.createdAt = json.created_at ? new Date(json.created_at) : null;
        this.updatedAt = json.updated_at ? new Date(json.updated_at) : null;
        this.startTime = json.start_time ? new Date(json.start_time) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ShortcutProjectModel(json));
    }
}

export default ShortcutProjectModel;
