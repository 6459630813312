import React from 'react';
import {Link} from "react-router-dom";

const CompanyEditSubMenu = (props) => {
    const { company, selection } = props;
    
    let companyId = company?.id || "00-00";
    let sel = selection || 'info';
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";
    
    selections[sel] = 'selected';

    const getPath = (sel) => {
        if (companyId.length < 10) return "";
        
        if (!sel) sel = "";
        return '/companies/' + companyId + '/edit' + sel;
    };
    
    return (
        <div className={"submenu company" + cn}>
            <Link className={selections['info']} to={getPath('')}>Company Info</Link>
            <Link className={selections['address']} to={getPath('/address')}>Company Address</Link>
            <Link className={selections['owner']} to={getPath('/owner')}>Owner Details</Link>
        </div>
    );    
};

export default CompanyEditSubMenu;
