import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyService from "../../components/companies/services/CompanyService";
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";
import CompanySubMenu from "../../components/companies/ui/CompanySubMenu";
import CompanyEditSubMenu from "../../components/companies/ui/CompanyEditSubMenu";
import AddressForm from "../../components/geo/ui/AddressForm";
import FormButton from "../../components/common/ui/FormButton";
import CompanyModel from "../../components/companies/models/CompanyModel";
import CompanyMasterPage from "../../components/companies/ui/CompanyMasterPage";
import CompleteDialog from "../../components/common/ui/dialog-box/CompleteDialog";
import Controller from "../../components/common/services/Controller";

const CompanyEditAddressScreen = (props) => {
    let _;
    const [company, setCompany] = useState();
    const controller = useRef(new Controller()).current;
    
    const onCompany = (c) => {
        console.error("onCompany Good: " + c?.name);
        if (!!c) setCompany(c);
    };

    const updateAddressAsync = async (address) => {
        console.log('Press');

        await CompanyService.instance.updateAddressAsync(company.id, address).then((a) => {
            console.log('Address updated');
            company.address = a;
            setCompany(company);
            controller.open("Address Updated");
        });
    };
    
    useEffect(() => {
        //
    }, []);

    const subtitle = (<>{ company?.name } <span className={"subtitle"}>Address</span></>);
    const addressForm = !!company ? (<AddressForm address={company?.address} onSubmit={updateAddressAsync} />) : null;
    
    return (
        <CompanyMasterPage menuSelection="edit" onCompany={onCompany} title={subtitle}>

            <CompanyEditSubMenu company={company} selection={"address"} />

            <p>
                Typically the primary location or mailing address.
            </p>

            <div className={"form medium"}>
                { addressForm }
            </div>
            
            <CompleteDialog controller={controller} />
        </CompanyMasterPage>
    );

};

export default CompanyEditAddressScreen;
