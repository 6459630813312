import React from 'react';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import "./LineGraph.css";

/**
 * 
 * @param props { {title:string, className:string, options:object, series:LineGraphSeriesModel|[LineGraphSeriesModel], controls:object, onMouseMove:function, subTitle:string} }
 * @returns {JSX.Element}
 * @constructor
 */
const LineGraph = (props) => {
    const {title, className, options, series, controls, onMouseMove, subTitle, empty} = props;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );
    
    const createDefaultScales = (data) => {
        return {
            x: {
                grid: {
                    drawBorder: false,
                    color: 'rgba(255, 255, 255, 0.05)',
                }
            },
            y: {
                grid: {
                    drawBorder: false,
                    color: 'rgba(255, 255, 255, 0.05)',
                },
            }
        };
    };

    let lineOptions = options || {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 12,
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false
            },
        },
    };

    if (!lineOptions.scales) lineOptions.scales = createDefaultScales();

    let header = (<></>);
    let footer = (<></>);

    let ctrl = controls ? (<span className="line-controls">{controls}</span>) : null;

    if (title || ctrl) {
        header = (<h3 className="line-graph-header">
            <span>{title}</span>
            {ctrl}
        </h3>);
    }

    if (subTitle) footer = (<div className="line-graph-footer">{subTitle}</div>);

    const lineSeries = ( (!Array.isArray(series)) ? [series] : series ).filter((x) => !!x && typeof x.toDataSet === 'function');
    
    if (lineSeries?.length === 0 || !lineSeries[0]?.items.length) 
        return empty || (<strong>No Data in Line Series</strong>);
    
    const dataSets = lineSeries.map((s) => s.toDataSet());
    const lineData = {
        labels: lineSeries[0].items.map((item) => item.xAxisValue),
        datasets: dataSets,
    };
    
    return (<div className={("line-graph " + (className || "")).trim()}>
        <h3>{title}: { lineSeries[0]?.items?.length + ""}</h3>
        <div className={"line-graph-body"}>
            <Line options={lineOptions} data={lineData} onMouseMove={onMouseMove} />
        </div>
    </div>);
};

LineGraph.createLineChartSeries = function(items, name, color, borderWidth, pointRadius) {
    if (typeof borderWidth !== 'number') borderWidth = 1;
    if (typeof pointRadius !== 'number') pointRadius = 1.5;

    if (!items) return {};

    return LineGraph.createLineChartSeries(items, name || "Line Series", color, borderWidth, pointRadius);
};

LineGraph.createLineChartSeries = function(numbers, name, color, borderWidth, pointRadius) {
    if (typeof borderWidth !== 'number') borderWidth = 1;
    if (typeof pointRadius !== 'number') pointRadius = 1.5;
    if (typeof color !== 'string') color = 'rgba(255, 255, 255, 0.8)';

    return {
        label: name,
        data: numbers || [],
        borderColor: color,
        backgroundColor: color,
        borderWidth: borderWidth,
        pointRadius: pointRadius
    };
}

LineGraph.createMockTrendData = (days) => {
    if (typeof days !== 'number') days = 10;

    const items = LineGraph.createMockItems(days);
    const dataSet =  new LineGraphSeriesModel(items, "Random Data", "red", 1, 1.5);
    
    return {
        labels: items.map((item) => item.xAxisValue),
        datasets: [
            dataSet.toDataSet(),
        ]
    };
}

LineGraph.createMockItems = (days) => {
    let cursor = new Date().addDays(-days);
    const now = new Date().getTime();
    const dateFormat = { month: 'short', day: 'numeric' };

    let items = [];

    while(cursor.getTime() < now) {
        cursor = cursor.addDays(1);
        const value = Math.floor(Math.random() * 400);
        items.push(new LineGraphItemModel(value, cursor.formatDate("MM-DAY"), "Random Label"));
    }

    return items;
};

export default LineGraph;

/**
 * Element that holds a "value" of the graph item, and the x-axis label
 */
export class LineGraphItemModel {
    /**
     * 
     * @param value {number|object}
     * @param xAxisValue {string}
     * @param label {string}
     */
    constructor(value, xAxisValue, label) {
        if (typeof value === 'object') { 
            xAxisValue = value.date;
            label = value.name || value.label;
            value = value.value || 0;
        }
        
        let x = xAxisValue;
        if (!isNaN(Date.parse(x))) x = new Date(x).formatDate("MM-DAY");
        
        this.value = value;
        this.xAxisValue = x;
        this.label = label;
    }
    
    static fromJsonArray(jsonArray, xAxisField = "date", valueField = "value", labelField = "name") {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new LineGraphItemModel(json[valueField], json[xAxisField], json[labelField]));
    }
}

export class LineGraphSeriesModel {
    /**
     * @param lineGraphItems {[LineGraphItemModel]|object}
     * @param name {string} - The name that will show up on the legend
     * @param color {string} - The color of the line
     * @param borderWidth {number} - The width of the line
     * @param pointRadius {number} - The radius of the points/dots
     * @param legendBoxFillColor {string|null} - The data set object that will be used to create the line graph
     */
    constructor(lineGraphItems, name, color = "red", borderWidth = 1, pointRadius = 3, legendBoxFillColor = null) {
        if (typeof lineGraphItems === 'object' && !Array.isArray(lineGraphItems)) {
            name = lineGraphItems.name;
            color = lineGraphItems.color || color;
            lineGraphItems = lineGraphItems.items || [];
            borderWidth = lineGraphItems.borderWidth || borderWidth;
            pointRadius = lineGraphItems.pointRadius || pointRadius;
            legendBoxFillColor = lineGraphItems.legendBoxFillColor || null;
        }
        
        this.items = LineGraphItemModel.fromJsonArray(lineGraphItems);
        this.name = name;
        this.color = color || "red";
        this.backgroundColor = legendBoxFillColor || null;
        this.borderWidth = borderWidth;
        this.pointRadius = pointRadius;
    }

    toDataSet() {
        const color = this.color || "red";
        return {
            label: this.name || (color + " Line Series").trim(),
            data: this.items.map((item) => item.value),
            borderColor: color,
            backgroundColor: this.backgroundColor || "rgba(0, 0, 0, 0)",
            borderWidth: this.borderWidth,
            pointRadius: this.pointRadius
        };
    }
    
}
