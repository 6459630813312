import ProjectModel from "./ProjectModel";
import CompanyModel from "../../companies/models/CompanyModel";
import UserLiteModel from "../../people/models/UserLiteModel";

class ProjectItemListItemModel { 

    constructor(json) {
        if (!json) json = {};
        this.daysPastDue = json.days_past_due || 0;
        this.companyId = json.company_id;

        this.project = new ProjectModel(json.project);
        this.company = new CompanyModel(json.company);
        this.user = new UserLiteModel(json.user);

        this.projectId = json.project_id;
        this.userId = json.user_id;
        this.imageId = json.image_id;
        this.sorting = json.sorting;
        this.apiAuthId = json.api_auth_id;
        this.mediaCollectionId = json.media_collection_id;
        this.name = json.name;
        this.description = json.description;
        this.remoteId = json.remote_id;
        this.uri = json.uri;
        
        this.projectItemType = json.project_item_type;
        this.projectItemTypeName = json.project_item_type_name;
        
        this.days = json.days;
        this.users = json.users;
        this.stageType = json.stage_type;
        this.stageName = json.stage_type_name;
        this.estimatedStartDate = json.estimated_start_date;
        this.startDate = json.start_date;
        this.startDay = json.start_day;
        this.dueDate = json.due_date;
        this.duration = json.duration;
        this.durationUnits = json.duration_units;
        this.completeDate = json.complete_date;
        this.status = json.status;
        this.dayList = json.day_list;
        this.id = json.id;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ProjectItemListItemModel(json));
    }
}

export default ProjectItemListItemModel;
