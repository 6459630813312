import React, {useEffect, useState, useRef} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faBriefcase, faCreditCardAlt, faEdit, faPenFancy} from "@fortawesome/free-solid-svg-icons";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import {Navigate, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import ProjectService from "../../../components/projects/services/ProjectService";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import FormButton from "../../../components/common/ui/FormButton";
import ProjectSubMenu from "../../../components/projects/ui/ProjectSubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import '../../../components/projects/ui/Projects.css';

import MarkdownTextarea from "../../../components/common/ui/form-elements/MarkdownTextarea";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import AuthenticationService from "../../../components/authentication/services/AuthenticationService";
import ConfigModel from "../../../components/common/models/ConfigModel";
import ShortcutService from "../../../components/projects/services/ShortcutService";

const ProjectEditScreen = (props) => {
    const { companyId, projectId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    const [project, setProject] = useState(ProjectService.instance.projectMap[projectId] || null);
    const [shortcutProjects, setShortcutProjects] = useState([]);
    const [apis, setApis] = useState([]);
    const [remoteTitle, setRemoteTitle] = useState('');
    const [redirectUrl, setRedirectUrl] = useState("");
    
    let _;

    const pName = useRef();
    const pDescription = useRef();
    const pRemoteId = useRef();
    const pRemoteUri = useRef();
    const pApiAuth = useRef();
    const pMapType = useRef();
    
    let description = project?.description || "";
    
    const getApiAuthsAsync = async (force) => {
        await AuthenticationService.instance.getApiAuthsByCompaniesAsync([companyId, ConfigModel.companyId]).then((a) => {
            if (a) setApis(a);
        });
    };
    
    const onSourceChange = (id, e) => {
        setRemoteTitle("Shortcut Remote URL: " + id);
    };
    
    const setProjectJson = (projectModel) => {
        if (!projectModel) return;

        if (pName.current) pName.current.value = projectModel.name;
        if (pDescription.current) pDescription.current.value = projectModel.description;
        if (pRemoteId.current) pRemoteId.current.value = projectModel.remoteId;
        if (pRemoteUri.current) pRemoteUri.current.value = projectModel.remoteUri;
    };
    
    const getProjectAsync = async () => {
        if (projectId.length < 30) { 
            return;
        }
        
        await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            setProject(p);
        });
    };

    const onFieldChange = (field, e) => {
        if (field === "description") description = e.target.value;
        console.log(field);
        console.warn(description);
    };
    
    const updateProjectAsync = async (e) => {
        let p = {};
        
        console.warn("Update Desc: " + description);
        
        p.name = pName.current?.value || "";
        p.description = description; //pDescription.current?.value || "";
        p.remote_id = pRemoteId.current?.value || "";
        p.remote_uri = pRemoteUri.current?.value || "";
        p.company_id = companyId;
        p.api_auth_id = pApiAuth.current?.value || null;
        p.map_type = pMapType.current?.value || null;
        p.id = projectId;
        
        await ProjectService.instance.saveProjectAsync(p).then((pm) => {
            setRedirectUrl("/companies/" + companyId + "/projects/" + pm.id);
        });
    };

    const getShortcutProjectsAsync = async (force) => {
        await ShortcutService.instance.getShortcutProjectsAsync(null).then((ps) => {
            if (ps) setShortcutProjects(ps);
        });
    };
    
    useEffect(() => {
        CompanyEffect.create(setCompany, companyId);
        _ = getProjectAsync();
        _ = getApiAuthsAsync();
        _ = getShortcutProjectsAsync();
    }, []);

    useEffect(() => {
        if (project) setProjectJson(project);
    }, [project]);
    
    if (!!redirectUrl) {
        return (<Navigate to={redirectUrl} />);
    }
    
    const isNew = !projectId || projectId.length < 30;
    const pp = isNew ? (<p>Create a new stand-alone project, or map one to a project or task that exists in a different system.</p>): null;
    
    let subtitle = (<><BackButton to={"/companies/" + companyId + "/projects"} />
        { project?.name }
        <span className={"subtitle"}>{ isNew ? "Create New Project" : "Edit Project"}</span></>);
    
    const submenu = isNew ? null : (<ProjectSubMenu project={project} projectId={projectId} company={company} companyId={companyId} selection={"edit"} />);
    const h3 = isNew ? (<h3>Details</h3>) : null;
    
    const apiAuthDropdown = apis?.length > 0 ?
        apis.filter((a) => a.apiType === 14).map((api, index) => { 
            return (<option key={"api-index-" + index} value={api.id}>{ api.name }</option>);
        }) :
        null;
    
    const mapTypeDropdown = (<>
        <option key={"map-type-0"} value={0}>Project Id</option>
        <option key={"map-type-1"} value={1}>Tag</option>
        <option key={"map-type-2"} value={2}>Text Token</option>
    </>);
    
    let remoteIdElement = (<input ref={pRemoteId} type={"text"} onChange={onFieldChange.bind(this, "remoteId")} />);
    
    // apiAuthDropdown
    const selectedApi = apis.find((a) => a.apiType.toString() === pApiAuth.current?.value?.toString());
    
    if (!selectedApi || selectedApi?.apiType === 14) { // Always true 
        if (pMapType.current?.value === "0") {  // Project Id
            const options = shortcutProjects.map((p, index) => {
                return (<option key={"shortcut-project-" + index} value={p.id}>{ p.name }</option>);
            });
            
            remoteIdElement = (<select ref={pRemoteId} onChange={onFieldChange.bind(this, "remoteId")}>
                <option key={"shortcut-project-none"} value={""}>None</option>
                {options}
            </select>);
        }
    } else { 
        console.warn("Selecgted API is no good: " + selectedApi?.apiType);
    }
    
    return (
        <CompanyMasterPage title={subtitle} subtitle={subtitle} selection={"projects"} subMenu={submenu}>
            <div className={"form medium"}>
                <label>Project Name</label>
                <input ref={pName} type={"text"} onChange={onFieldChange.bind(this, "name")} />

                <MarkdownTextarea className={"edit-project"} label={"Optional Description"} onChange={onFieldChange.bind(this, "description")}>
                    {project?.description}
                </MarkdownTextarea>

                <div className={"optional-fields"}>
                    <label>Remote Project Source</label>
                    <select ref={pApiAuth} id={"api-auth"} onChange={onSourceChange.bind(this, "api-auth")}>
                        <option key={"api-index-x"} value={""}>None</option>
                        { apiAuthDropdown }
                    </select>

                    <label>Remote Map Type</label>
                    <select ref={pMapType} id={"map-type"} onChange={onSourceChange.bind(this, "map-type")}>
                        <option key={"map-type-index-x"} value={""}>None</option>
                        { mapTypeDropdown }
                    </select>
                    
                    <label>RemoteId</label>
                    { remoteIdElement }

                    <label>Remote Uri</label>
                    <input ref={pRemoteUri} type={"text"} onChange={onFieldChange.bind(this, "remoteUri")} />
                </div>

                <div className={"buttons"}>
                    <FormButton onClick={updateProjectAsync}>Save Project</FormButton>
                </div>
            </div>
        </CompanyMasterPage>
    );
    
};

export default ProjectEditScreen;

