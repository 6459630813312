import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import InvoiceService from "../../../components/billing/services/InvoiceService";
import MessageSendLogs from "../../../components/activity/ui/MessageSendLogs";
import CompanyInvoiceSubMenu from "../../../components/companies/ui/CompanyInvoiceSubMenu";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";

const InvoiceSendLogsScreen = (props) => {
    let { companyId, invoiceId } = useParams();
    
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [invoice, setInvoice] = useState(null);

    let _;

    const getInvoiceAsync = async () => {
        await InvoiceService.instance.getInvoiceAsync(invoiceId).then((inv) => {
            setInvoice(inv);
        });
    };

    const getCompanyAsync = async (force, e) => {
        if (!force && company) {
            setCompany(company);
            return;
        }

        return await CompanyService.instance.getCompanyAsync(companyId).then((c) => {
            setCompany(c);
            return company;
        });
    };
    
    const onCompany = (c) => {
        setCompany(c);
    };

    useEffect(() => {
        _ = getInvoiceAsync();
        _ = getCompanyAsync();
    }, []);

    const subMenu = (<CompanyInvoiceSubMenu company={company} invoice={invoice} selection={"logs"} />);
    const subtitle = (<>{ company?.name }
        <span className={"subtitle"}>Invoice #{invoice?.invoiceNumberId?.toString() || "..."}</span></>);
    
    return (
        <CompanyMasterPage onCompany={onCompany} selection={"billing"} title={subtitle} subMenu={subMenu}>
            <p>
                Below is the history of the attempts to send out this invoice.
            </p>

            <div className={"invoice-items"}>
                <MessageSendLogs entityId={invoiceId} entityType={"invoice"} />
            </div>
        </CompanyMasterPage>
    );
    

};

export default InvoiceSendLogsScreen;