import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import DocumentService from "../../../components/content/services/DocumentService";
import DropTarget from "../../../components/common/ui/drop-target/DropTarget";
import FileUploader from "../../../components/common/ui/file-uploader/FileUploader";
import DocumentConfigs from "../../../config/DocumentConfigs";
import FormButton from "../../../components/common/ui/FormButton";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import SearchBox from "../../../components/search/ui/SearchBox";
import DocumentModel from "../../../components/content/models/DocumentModel";
import Controller from "../../../components/common/services/Controller";

const CompanyDocumentationScreen = (props) => {
    let { companyId } = useParams();
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [documentsSummary, setDocumentsSummary] = useState(DocumentService.instance.documentSummaryMap[companyId] || null);
    let [filePreviewElements, setFilePreviewElements] = useState({});
    const [searchController, setSearchController] = useState(new Controller());
    
    let _;
    
    const docTypes = [
        DocumentConfigs.PRESENTATION_DECK,
        DocumentConfigs.TECHNICAL_DOCUMENT,
        DocumentConfigs.LEGAL_DOCUMENT,
        DocumentConfigs.TAX_DOCUMENT
    ];

    const getDocumentsSummaryAsync = async (force) => {
        if (!force && !!documentsSummary) {
            return;
        }
        
        await DocumentService.instance.getDocumentsSummaryAsync(companyId).then((summary) => {
            if (summary) setDocumentsSummary(summary);
        });
    };
    
    const onFilesDrop = (files, docType) => {
        console.log("Company Documents Files: " + docType);
        if (!filePreviewElements[docType]) filePreviewElements[docType] = {};
        if (!filePreviewElements[docType].files) filePreviewElements[docType].files = [];

        FileUploader.readFileContents(files, (fileModels) => {
            filePreviewElements[docType].files = fileModels;
            filePreviewElements[docType].elements = fileModels.map((fm, index) => { 
                //
                
                return (<span className={"document-preview-item"}>
                    <label>{fm.name || "FileName"}</label>
                </span>);
            });
            
            console.log("File Readers Good");
            setFilePreviewElements({...filePreviewElements});
        });
    };
    
    const cancelFileUpload = (e) => {
        e.preventDefault();
        setFilePreviewElements({});
    };
    
    const uploadNewDocuments = async (docType, e) => {
        e.preventDefault();
        let fileModels = filePreviewElements[docType]?.files;
        let docTypeKeyInt = DocumentConfigs.data.docTypeKeys[docType];
        console.log("Key Int: " + docTypeKeyInt);
        
        await DocumentService.instance.uploadDocumentsAsync(companyId, docTypeKeyInt, fileModels).then((docs) => {
            _ = getDocumentsSummaryAsync(true).then(() => {
                cancelFileUpload(e);
            }).catch((ex) => {
                console.error("Failed to re-get the documents summary: " + ex);
                cancelFileUpload(e);
            });
        });
    };

    const onCompany = (c) => {
        setCompany(c);
    };
    
    useEffect(() => {
        //
    },[filePreviewElements]);
    
    useEffect(() => {
        _ = getDocumentsSummaryAsync();
    }, []);
    
    let sectionElements = docTypes.map((docTypeKey, index) => {
        let doc = DocumentConfigs.data.docTypeValues[docTypeKey];
        if (!doc) return (<li>N/A {docTypeKey } at {index}</li>);
        let preview = filePreviewElements[docTypeKey];
        let fileCount = (documentsSummary?.document_type_counts[docTypeKey] || 0);
        let sizeClass = "";
        if (fileCount > 999) sizeClass = "med";
        else if (fileCount > 9999) sizeClass = "sm";
        
        let badge = (preview?.files?.length > 0) ?
            (<span className={"document-badge-upload-button"} onClick={(e) => e.preventDefault()}>
                <span>
                    <FormButton onClick={uploadNewDocuments.bind(this, docTypeKey)}>Upload</FormButton>
                </span>
                <span><a onClick={cancelFileUpload}>Cancel</a></span>
            </span>) :
            (<span className={"badge"}>
                <label className={sizeClass}>{fileCount.formatNumber(0) }</label>
            </span>);
        
        return (
            <DropTarget key={"drop-target-" + index} elementType={"li"} onDrop={(files) => onFilesDrop(files, docTypeKey)} className={"company-docs-drop-target"}>
                <Link to={"/companies/" + companyId + "/documentation/" + doc.path}>
                    <span className={"icon"}>
                        <FontAwesomeIcon icon={doc.icon} />
                    </span>
                    <span className={"content"}>
                        <h4>{doc.title}</h4>
                        <p>{filePreviewElements[docTypeKey]?.elements || (<>{doc.description}</>) }</p>
                    </span>
                    { badge }
                </Link>
            </DropTarget>
        );
    })
    
    return (
        <CompanyMasterPage bodyClassName={"large"} onCompany={onCompany} selection={"docs"} title={"Documentation and Knowledge Base"} onClick={(e) => searchController.close(e)}>

            <p>
                All documents and documentation lived here. You can drag-drop files into the boxes below to upload them, or click through to view the contents of the folders.
            </p>

            <div>
                <SearchBox controller={searchController} company={company} placeholder={"Search " + company?.name + " Documents..."} entities={[DocumentModel.entityType]} />

                <ul className={"list-sections"}>
                    {sectionElements}
                </ul>

            </div>
        </CompanyMasterPage>
    );
    
};

export default CompanyDocumentationScreen;