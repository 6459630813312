import ProjectService from "./ProjectService";

class ProjectEffect {
    static create(setFunction, projectId) {
        return new ProjectEffect(setFunction, projectId);
    }

    constructor(setFunction, projectId) {
        this.setProject = setFunction;
        this.projectId = projectId;
        let _  = this.updateScreenAsync();
    }

    async updateScreenAsync() {
        console.warn("ProjectEffect: Update Screen");
        const me = this;
        await ProjectService.instance.getProjectAsync(this.projectId).then((project) => {
            me.setProject(project);
            console.log("ProjectEffect: Set Project OK " + project.name);
        });
    }

}

export default ProjectEffect;
