import HttpService from "../../common/services/HttpService";
import CommentModel from "../models/CommentModel";

class CommentService { 
    static instance = new CommentService();

    /**
     * All Entities
     * 
    None = 0,
    Address = 1,
    AuthCode = 2,
    Comment = 3,
    Company = 4,
    DeviceToken = 5,
    Image = 6,
    ImageThumb = 7,
    Invoice = 8,
    InvoiceItem = 9,
    MessageTemplate = 10,
    Role = 11,
    Session = 12,
    User = 13,
    UserAuth = 14,
    */
    
    constructor() {
        this.commentsMap = {};
        this.comments = [];
        this.commentMap = {};
    }

    async getCommentAsync(commentId) {
        const path = '/api/comment/' + commentId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.commentMap[commentId] = new CommentModel(response.data);
            return me.commentMap[commentId];
        });
    }

    /**
     * Post an entity comment.
     * @param comment {CommentModel} The comment to post.
     * @returns {Promise<CommentModel>}
     */
    async postCommentAsync(comment) { 
        const path = '/api/comment';
        const me = this;

        let commentJson = comment.toJson();
        
        if (!commentJson.entity_id) { 
            console.warn("Did not post comment (" + (typeof comment).toString() + ") with entity_id: " + commentJson.entity_id);
        }

        if (!commentJson.entity_type) {
            console.warn("Did not post comment (" + (typeof comment).toString() + ") with entity_type: " + commentJson.entity_type);
        }
        
        return await HttpService.instance.postAsync(path, commentJson).then((response) => {
            return new CommentModel(response.data);
        });
    }

    /**
     * Delete a comment. That's it. 
     * @param commentId {string} The comment id.
     * @returns {Promise<boolean>}
     */
    async deleteCommentAsync(commentId) {
        if (!commentId) return false;
        
        const path = '/api/comment/' + commentId;
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return true;
        });
    }
    
    /**
     * Get all comments for an entity.
     * @param entityId {number|string} The entity id. If entity type is 'string', the api url will used the named path. Otherwise it will use
     * the standard /api/entity-type/{type}/entity/{id}/comment global path name convention
     * @param entityTypeKey
     * @returns {Promise<[CommentModel]>}
     */
    async getCommentsAsync(entityId, entityTypeKey) {
        if (!entityId || !entityTypeKey) {
            console.warn("Did not get comments for entity id: " + entityId + ", entity type key: " + entityTypeKey);
            return [];
        }
        
        let path = "/api/" + entityTypeKey + "/" + entityId + "/comment";

        let entityType = parseInt(entityTypeKey);
        if (!isNaN(entityType)) path = "/api/entity-type/" + entityType + "/entity/" + entityId + "/comment";
        
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            if (!response?.data) { 
                console.warn("Failed to get comments: ");
                console.warn(response);
                return [];
            }
            
            me.comments = response.data.map((comment) => {
                return new CommentModel(comment);
            });

            return me.comments;
        });
    }
}

export default CommentService;
