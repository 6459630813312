class NotificationModel {
    static entityType = 21;

    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id;
        this.entityId = json.entity_id;
        this.altEntityId = json.alt_entity_id;
        
        this.entityType = json.entity_type;
        this.entityName = json.entity_type_name;
        
        this.altEntityType = json.alt_entity_type;
        this.altTypeName = json.alt_entity_type_name;

        this.path = json.path;
        this.title = json.title;
        this.data = json.data || null;
        this.message = json.message || "";
        
        this.readDate = json.read_date ? new Date(json.read_date) : null;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            entity_id: this.entityId,
            alt_entity_id: this.altEntityId,
            entity_type: this.entityType,
            alt_entity_type: this.altEntityType,
            title: this.title,
            text: this.text,
            uri: this.uri,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new NotificationModel(json));
    }
}

export default NotificationModel;
