import HttpService from "../../common/services/HttpService";
import ActivityItemModel from "../models/ActivityItemModel";
import MessageSendLogModel from "../models/MessageSendLogModel";
import UserModel from "../../people/models/UserModel";
import SystemErrorModel from "../models/SystemErrorModel";
import WebVisitorModel from "../models/WebVisitorModel";
import WebSessionLabelModel from "../models/WebSessionLabelModel";
import GraphItemModel from "../../metrics/models/GraphItemModel";

class ActivityService { 
    static instance = new ActivityService();

    constructor() {
        this.activityItemsMap = {};
        this.activityItems = [];
        this.activityItemMap = {};
        this.activeConnections = [];
        this.messageSendLogsMap = {};
        this.errorsMap = {};
    }

    async getUserActivityAsync(userId) {
        if (!userId) throw new Error("Invalid activity items user id: " + userId);
        const path = "/api/user-activity/" + userId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const items = response.data.map((activityItem) => {
                return new ActivityItemModel(activityItem);
            });

            me.activityItemsMap[userId] = items;
            return items;
        });
    }

    async getCompanyActivityAsync(companyId, entityId, startDate = null, endDate = null) {
        if (!companyId) throw new Error("Invalid activity items user id: " + companyId);
        const path = "/api/company/" + companyId + "/activity" + (typeof entityId === "string" && entityId.length > 30 ? "/" + entityId : "");
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            const items = rsp.data.map((activityItem) => {
                return new ActivityItemModel(activityItem);
            });

            me.activityItemsMap[companyId] = items;
            
            return items;
        });
    }
    
    /**
     * Gets Activity Items for the given entityId and optional entityType
     * @param entityId {string} The entity id
     * @param entityType {int|null} The entity type. If null, all types will be returned, but it's highly unlikely there will be different types with the given entityId, given it's guid.
     * @param startDate {Date|null} The start date. If null, all items will be returned.
     * @param endDate {Date|null} The end date. If null, all items will be returned.
     * @returns {Promise<[ActivityItemModel]>}
     */
    async getActivityItemsAsync(entityId, entityType, startDate = null, endDate = null) {
        let path = "/api/activity" + (typeof entityId === "string" && entityId.length > 30 ? "/" + entityId : "")
        
        if (typeof entityType === 'number' && entityType >= 0) path += "/type/" + entityType;
        else entityType = 0;
        
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            const items = ActivityItemModel.fromJsonArray(response.data);
            const key = ActivityItemModel.createKey(entityId || "", entityType || 0);
            
            me.activityItemsMap[key] = items;
            
            return items;
        });
    }
    
    async getLeaderBoardAsync(columnName, options = { startDate: null, endDate: null, count: 5 }) {
        if (typeof columnName !== "string" || !columnName) throw new Error("Missing valid column name");
        
        const path = "/api/web-visitor/leaderboard/" + columnName + "?count=" + (options?.count || 5);
        return await HttpService.instance.getWithDateRangeAsync(path, options?.startDate, options?.endDate).then((response) => {
            return GraphItemModel.fromJsonArray(response.data);
        });
    }

    async getSystemErrorsAsync(companyId, startDate = null, endDate = null) {
        const hasCompanyId = (!!companyId && companyId.length > 30);
        const path = hasCompanyId ? "/api/company/" + companyId + "/system-error" : "/api/system-error";
        const key = hasCompanyId ? companyId : "system-errors";
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            const items = SystemErrorModel.fromJsonArray(response.data);

            me.activityItemsMap[key] = items;

            return items;
        });
    }

    async getCompanyUserErrorsAsync(userId, companyId) {
        if (!companyId) throw new Error("Invalid company id: " + companyId);
        if (!userId) throw new Error("Invalid user id: " + userId);
        
        const path = "/api/company/" + companyId + "/user/" + userId + "/system-error";
        const me = this;
        const key = userId + "-" + companyId;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            const items = SystemErrorModel.fromJsonArray(rsp.data);

            me.errorsMap[key] = items;

            return items;
        });
    }

    async getMessageSendLogsAsync(entityId, entityTypeKey, companyId = null) {
        let basePath = "/api/";
        let path = basePath + entityTypeKey + "/" + entityId + "/message-send-log";

        let entityType = parseInt(entityTypeKey);
        
        if (!isNaN(entityType)) path = basePath + "entity-type/" + entityType + "/entity/" + entityId + "/message-send-log";
        else if (typeof companyId === "string" && companyId.length > 30) path = basePath + "company/" + companyId + "/message-send-log";
        
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            let logs = response.data.map((messageSendLog) => {
                return MessageSendLogModel.create(messageSendLog);
            });

            me.messageSendLogsMap[entityId] = logs;
            return logs;
        });
    }

    async getUserMessageSendLogsAsync(userId) {
        let path = "/api/user/" + userId + "/message-send-log";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return response.data.map((messageSendLog) => {
                return MessageSendLogModel.create(messageSendLog);
            });
        });
    }
    
    async getActiveConnectionsAsync() {
        const path = "/api/activity/connection";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            return UserModel.fromJsonArray(response.data);
        });
    }
    
    async getWebVisitorAsync(webVisitorId) { 
        if (!webVisitorId || webVisitorId.length < 30) throw new Error("Invalid web visitor id: " + webVisitorId);
        
        const path = "/api/web-visitor/" + webVisitorId;
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return WebVisitorModel.create(rsp.data);
        });
    }
    
    async getWebVisitorsAsync(options) {
        if (!options || typeof options !== "object") return [];

        const query = [];
        if (typeof options?.domain === "string" && options.domain.length > 0) query.push("domain=" + options.domain);
        if (Array.isArray(options?.domains)) query.push("domains=" + options.domains.map(d => encodeURI(d)).join(","));
        
        if (typeof options?.path === "string" && options.path.length > 0) query.push("path=" + options.path);
        if (Array.isArray(options?.paths)) query.push("paths=" + options.paths.map(p => encodeURI(p)).join(","));
        
        if (typeof options?.ipAddress === "string" && options.ipAddress.length > 0) query.push("ip=" + options.ipAddress);
        if (typeof options?.domainId === "string") query.push("domain-id=" + options.domainId);
        
        const path = "/api/web-visitor" + (query.length > 0 ? "?" + query.join("&") : "");
        console.warn(path);
        
        return await HttpService.instance.getWithDateRangeAsync(path, options?.startDate, options?.endDate).then((rsp) => {
            if (!!rsp?.data) return WebVisitorModel.fromJsonArray(rsp.data);
            
            return null;
        });
    }

    async getWebUsersBySessionAsync(sessionId, options) {
        if (typeof sessionId !== "string" || sessionId.length < 30) throw new Error("Invalid SessionId");
        
        const path = "/api/web-visitor/session/" + sessionId;

        return await HttpService.instance.getWithDateRangeAsync(path, options?.startDate, options?.endDate).then((rsp) => {
            if (!!rsp?.data) return WebVisitorModel.fromJsonArray(rsp.data);

            return null;
        });
        
    }

    async getWebSessionLabelAsync(id) {
        const path = '/api/web-session-label/' + id;
        const me = this;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            return new WebSessionLabelModel(rsp.data);
        });
    }

    async getWebSessionLabelsAsync() {
        const path = "/api/web-session-label";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.webSessionLabels = response.data.map((webSessionLabel) => {
                return new WebSessionLabelModel(webSessionLabel);
            });

            return me.webSessionLabels;
        });
    }
    
    async createWebSessionLabelAsync(json) {
        const path = "/api/web-session-label";
        const me = this;
        
        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            return WebSessionLabelModel.create(rsp.data);
        });
    }

    async updateSessionLabelAsync(labelId, json) {
        if (!labelId || labelId.length < 30) throw new Error("Invalid label id: " + labelId);
        
        const path = "/api/web-session-label/" + labelId;
        const me = this;

        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            return WebSessionLabelModel.create(rsp.data);
        });
    }    
    
    async deleteWebVisitorsByIpAsync(ipAddress) {
        if (!ipAddress || ipAddress.length < 7) throw new Error("Invalid IP Address: " + ipAddress);
        
        const path = "/api/web-visitor/ip/" + ipAddress;
        return await HttpService.instance.deleteAsync(path).then((rsp) => {
            return rsp.data;
        });
    }
}

export default ActivityService;
