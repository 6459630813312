import React, {useRef, useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faSearch} from "@fortawesome/free-solid-svg-icons";
import './SearchFilter.css';

const SearchFilter = (props) => {
    const { onFilter, minLength, label } = props;

    const len = typeof minLength === 'number' && minLength > 0 ? minLength : 3;
    let [filterClass, setFilterClass] = useState('');
    let filterInput = useRef(null);

    useEffect(() => { 
        if (!!filterClass) { 
            setTimeout(() => {
                filterInput.current?.focus();
            }, 100);
        }
    }, [filterClass]);
    
    if (typeof onFilter !== 'function') {
        return (<span className={"filter"} style={{backgroundColor: "#FF000033", color: "maroon"}}>SearchFilter: No onFilter(string) method was provided.</span>);
    }
    
    const onTextFilter = (e) => {
        const text = filterInput.current.value;
        
        if (text.length >= len) onFilter(text);
        else onFilter("");
    };

    return (<span className={("search-filter " + filterClass).trim()}>
            <a onClick={() => setFilterClass(' show')}><FontAwesomeIcon icon={faSearch} /> <label>{ label || "Search"}</label></a>
            <span className={"search-filter-input"}>
                <input className={"filter"} type={"text"} ref={filterInput} onChange={onTextFilter.bind(this)} placeholder={"Filter"} />
                <a onClick={() => setFilterClass('')}><FontAwesomeIcon icon={faCircleXmark} /></a>
            </span>
        </span>);
};

export default SearchFilter;
