import React, { useEffect, useState } from 'react';
import HeaderButton from "../../components/common/ui/HeaderButton";
import {faPeopleCarryBox, faSync} from "@fortawesome/free-solid-svg-icons";
import CommentForm from "../../components/messaging/ui/CommentForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterScreen from "../../MasterScreen";
import MetricsSubMenu from "../../components/metrics/ui/MetricsSubMenu";

const MetricsMasterScreen = (props) => {
    const { selector, bodyClassName, selection, entityId, refreshAsync, onRefresh, title, children, onClick, onSessionChange, subTitle, altMenu, entityType, icon, commentEntityType } = props;

    useEffect(() => {
        //
    }, []);

    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this ToolsMasterPage. If you meant to have this blank, try passing a null value to the "company" property.</p>)
    const refreshButton = (typeof onRefresh === 'function') ? (<HeaderButton icon={faSync} onClick={onRefresh}>Refresh</HeaderButton>) : null;
    const comments = typeof commentEntityType === 'number' && typeof entityId === 'string' && entityId.length > 10 ?
        (<CommentForm entityType={commentEntityType} entityId={entityId} />) :
        null;

    let h1 = (<><FontAwesomeIcon icon={icon || faPeopleCarryBox} />
        {title || "Growth Metrics"}
    </>);

    const subMenu = (<MetricsSubMenu selection={selection || "main"} />);

    return (<MasterScreen selector={selector || "tools"} title={h1} onClick={onClick} onRefresh={onRefresh} onSessionChange={onSessionChange}>
        <div id="tools-productivity-main" className="pad">
            <div className="details-body">
                { subMenu }
                <div className={"pad " + (bodyClassName || "x-large")}>
                    <h2>
                        {subTitle || "Tools and Productivity Page"}
                        {refreshButton}
                    </h2>
                    { altMenu }
                    { body }
                    { comments }
                </div>
            </div>
        </div>
    </MasterScreen>);
}

export default MetricsMasterScreen;

