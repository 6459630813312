import React, { useState, useEffect } from 'react';
import FormButton from "../../common/ui/FormButton";
import ProjectItemModel from "../models/ProjectItemModel";
import ProjectItemDates from "./ProjectItemDates";
import {Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import {bocgMarkdown} from "../../common/ui/svgs/LogoSvgs";
import MarkdownTextarea from "../../common/ui/form-elements/MarkdownTextarea";

const ProjectItemForm = (props) => {
    const getJson = () => {
        return typeof projectItem?.toJson === 'function' ? projectItem.toJson() : {};
    };
    
    let {project, company, projectItem, onChange, onSubmit, controller, autoFocus} = props;
    let initialJson = getJson();
    let [itemJson, setItemJson] = useState(initialJson);
    let [typeName, setTypeName] = useState(projectItem?.typeName || "Task");
    
    useEffect(() => { 
        if (typeof onChange === 'function')
            onChange(itemJson);
        
    }, [itemJson])
    
    useEffect(() => {
        if (autoFocus === true) {
            document.getElementById('project-item-name')?.focus();
        }

        setItemJson(getJson());
    }, [])
    
    const onProjectItemChange = (field, e) => {
        if (field === "duration") return;
        
        let json = {...itemJson};
        
        json[field] = e.target.value;
        
        setItemJson(json);
    };

    const onDateEstimateChange = (durationJson, e) => {
        let json = {...itemJson};
        json.duration = durationJson;
        setItemJson(json);
    };

    let durationFormElement = (<ProjectItemDates value={typeof projectItem?.toDurationJson === 'function' ? projectItem?.toDurationJson() : null} onChange={onDateEstimateChange} />);
    
    let typeOptions = ProjectItemModel.typeValues.map((t) => {
        return (<option key={t.id} value={t.id.toString()}>{t.name}</option>);
    });
    
    let nameElement = !!itemJson ? (<input type={"text"} onChange={onProjectItemChange.bind(this, "name")} defaultValue={itemJson?.name} id={"project-item-name"} />) : null;
    
    return (<div className={"dialog form medium"}>
        <label>Type</label>
        <select value={itemJson?.type || "1"} onChange={(e) => onProjectItemChange("type", e)}>
            {typeOptions}
        </select>
        
        <label>Title or Name:</label>
        {nameElement}

        <MarkdownTextarea label={"Description"} onChange={onProjectItemChange.bind(this, "description")}>
            {itemJson?.description}
        </MarkdownTextarea>
        
        {durationFormElement}
    </div>);
};

export default ProjectItemForm;
