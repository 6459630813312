import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Companies.css';

import {
    faIdCardClip,
    faPeopleGroup,
    faCreditCard,
    faCalendarWeek,
    faShieldCat,
    faPenFancy,
    faDiagramProject,
    faBookSkull,
    faLink,
    faAlignLeft,
    faEnvelope, faMobileRetro
} from "@fortawesome/free-solid-svg-icons";

import ConfigModel from "../../common/models/ConfigModel";

const CompanySubMenu = (props) => {
    const { company, selection } = props;
    
    if (!company?.id) return (<></>);
    
    const companyId = company.id;

    let sel = selection || 'main';
    let selections = {};
    selections[sel] = 'selected';

    const statusElement = null;
    const basePath = '/companies/' + companyId;
    const moreElements = [];
    
    if (ConfigModel.qrBlocksCompanyId === (companyId || "").toLowerCase()) {
        moreElements.push(<li className={selections['landing-pages']}><Link to={ basePath + '/landing-pages'}><FontAwesomeIcon icon={faMobileRetro} /> Landing Pages</Link></li>);
    }
    
    return (
        <div className="sub-column">
            { statusElement }
            
            <ul>
                <li className="sub-menu-header">
                    {company?.name}
                </li>
                <li className={selections['main']}><Link to={ basePath }><FontAwesomeIcon icon={faIdCardClip} /> Overview</Link></li>
                <li className={selections['people']}><Link to={ basePath + '/people'}><FontAwesomeIcon icon={faPeopleGroup} /> People</Link></li>
                <li className={selections['urls']}><Link to={ basePath + '/domains'}><FontAwesomeIcon icon={faLink} /> Domains + URLs</Link></li>
                <li className={selections['docs']}><Link to={ basePath + '/documentation'}><FontAwesomeIcon icon={faBookSkull} /> Documentation</Link></li>
                <li className={selections['billing']}><Link to={ basePath + '/billing'}><FontAwesomeIcon icon={faCreditCard} /> Billing</Link></li>
                <li className={selections['activity']}><Link to={ basePath + '/activity'}><FontAwesomeIcon icon={faAlignLeft} /> Activity</Link></li>
                <li className={selections['edit']}><Link to={ basePath + '/edit'}><FontAwesomeIcon icon={faPenFancy} /> Edit Company</Link></li>
            </ul>

            <ul>
                <li className="sub-menu-header">More</li>
                { moreElements }
                <li className={selections['messages']}><Link to={ basePath + '/messaging'}><FontAwesomeIcon icon={faDiagramProject} /> Message Users</Link></li>
                <li className={selections['message-templates']}><Link to={ basePath + '/messaging/email-messages'}><FontAwesomeIcon icon={faEnvelope} /> Message Templates</Link></li>
                <li className={selections['integrations']}><Link to={ basePath + '/integrations'}><FontAwesomeIcon icon={faDiagramProject} /> Integrations</Link></li>
                <li className={selections['projects']}><Link to={ basePath + '/projects'}><FontAwesomeIcon icon={faShieldCat} /> Projects</Link></li>
                <li className={selections['calendar']}><Link to={ basePath + '/calendar'}><FontAwesomeIcon icon={faCalendarWeek} /> Calendar</Link></li>
            </ul>
        </div>
    );

};


export default CompanySubMenu;