import React, {useEffect, useState} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowTrendUp,
    faBriefcase,
    faBriefcaseClock,
    faCalendar, faCalendarDays, faCalendarXmark,
    faCreditCardAlt, faList,
    faSignsPost
} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import ProjectService from "../../../components/projects/services/ProjectService";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import ProjectSubMenu from "../../../components/projects/ui/ProjectSubMenu";
import DateTime from "../../../components/common/ui/DateTime";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import ProjectItemDates from "../../../components/projects/ui/ProjectItemDates";
import RoadmapList from "../../../components/projects/ui/roadmap/RoadmapList";
import RoadmapCalendar from "../../../components/projects/ui/roadmap/RoadmapCalendar";
import Controller from "../../../components/common/services/Controller";
import HeaderButton from "../../../components/common/ui/HeaderButton";
import StageDropdown from "../../../components/projects/ui/StageDropdown";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import RoadmapWeekly from "../../../components/projects/ui/roadmap/RoadmapWeekly";

const ProjectRoadmapScreen = (props) => {
    let _;
    let { projectId, companyId } = useParams();
    let [company, setCompany] = useState();

    let [project, setProject] = useState(ProjectService.instance.projectMap[projectId]);
    let [isList, setIsList] = useState(false);
    
    let [durationJson, setDurationJson] = useState(null);
    let [projectStage, setProjectStage] = useState(project?.stageType || 0);

    let calendarController = new Controller();
    let controller = new DialogBoxController("Create Task", (<span>Word</span>));
    let dateController = new DialogBoxController("Change Dates", (<span>Date Changer</span>));

    const onCompany = (c) => {
        console.error("onCompany Good: " + c?.name);
        if (!!c) setCompany(c);
    };

    const getProjectAsync = async () => {
        if (projectId.length < 30) {
            return;
        }

        await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            setProject(p);
        });
    };

    const promptForDates = (item, e) => {
        if (!item) {
            dateController.payload = null;
            dateController.close();
            return;
        }
        
        dateController.title = item.title || "Update Item";
        dateController.payload = durationJson;
        dateController.onOkay = () => { 
            setDurationJson(null);
        };
        
        dateController.open();
    };
    
    const saveEstimatedDatesAsync = async (e) => {
        return await ProjectService.instance.updateProjectItemDateEstimatesAsync(durationJson, projectId).then((p) => {
            getProjectAsync().then((x) => {
                setDurationJson(null);
                calendarController.refresh();
            });
            
            return 2000;
        });
    };
    
    const onDateEstimateChange = (durJson, e) => {
        durationJson = durJson; // Do not setDurationJson because that will cause a re-render, and close/flicker the dialog box
    };
    
    const updateProjectStageAsync = async (newStage) => {
        newStage = parseInt(newStage);
        console.warn("New Stage: " + newStage);

        if (!isNaN(newStage)) {
            console.log('Saving ' + newStage + '...');
            setProjectStage(newStage);

            await ProjectService.instance.updateProjectStageAsync(projectId, newStage).then((p) => {
                setProject(p);
            });
        } else {
            //
        }
    };

    useEffect(() => { 
        promptForDates(durationJson);
    }, [durationJson]);
    
    useEffect(() => {
        _ = getProjectAsync();
        
    }, []);

    useEffect(() => {
        if (project) setProjectStage(project?.stageType || 0);
    }, [project]);
    
    let description = (<>No dates are assigned to each segment of the project.<br/>
        Once the <strong>{project?.name}</strong> starts, dates will be assigned accordingly.</>);
    
    if (project?.startDate) {
        description = (<>
            Project start date: <strong><DateTime value={project.startDate} /></strong>
        </>);
    }

    const onProjectItemClick = (project, e) => {
        //
    };

    calendarController.refreshAsync = (e) => {
        console.log("Roadmap Refresh");
        _ = getProjectAsync(true);
    };

    // let roadmapBody = isList ? (<RoadmapList project={project} company={company} onClick={(item, e) => setDurationJson(item.toDurationJson(item.name))}/>) :
    //     (<RoadmapCalendar controller={calendarController} project={project} company={company} onClick={(item, e) => setDurationJson(item.toDurationJson(item.name))} />);
    let roadmapBody = isList ? (<RoadmapWeekly controller={calendarController} projects={[project]} company={company} onClick={(item, e) => onProjectItemClick(item)} startDate={project.startDate} />) :
        (<RoadmapCalendar controller={calendarController} project={project} company={company} onClick={(item, e) => setDurationJson(item.toDurationJson(item.name))} />);
    
    let viewSelector = { list: "", calendar: "" };
    
    if (isList) viewSelector["list"] = "selected";
    else viewSelector["calendar"] = "selected";
    
    const h2title = (<><BackButton to={"/companies/" + companyId + "/projects"} />
        { project?.name }
        <span className={"subtitle"}>Roadmap</span></>);
    
    return (
        <CompanyMasterPage onRefresh={(e) => getProjectAsync(true)} menuSelection="projects" onCompany={onCompany} title={h2title}>
            <ProjectSubMenu project={project} projectId={projectId} company={company} companyId={companyId} selection={"roadmap"} />
            
            <p className={"full"}>
                <span className={"right"}>
                    <span className={"view-selector"}>
                        <a className={viewSelector.calendar} onClick={(e) => setIsList(false)}>
                            <FontAwesomeIcon icon={faCalendarDays} />
                        </a>
                        <a className={viewSelector.list} onClick={(e) => setIsList(true)}>
                            <FontAwesomeIcon icon={faList} />
                        </a>
                    </span>
                </span>
            
                <span className={"right container dark"}>
                    <StageDropdown startDate={project?.startDate} value={projectStage} onChange={updateProjectStageAsync} />
                </span>
            
                {description}
            </p>
            
            <div className={"x-large"}>{ roadmapBody }</div>
            
            <DialogBox controller={controller}>
                <h1>Toast Controller</h1>
            </DialogBox>
            
            <DialogBox controller={dateController} onCancel={(e) => setDurationJson(null)} onOkay={saveEstimatedDatesAsync}>
                <ProjectItemDates value={durationJson} onChange={onDateEstimateChange} />
            </DialogBox>

    </CompanyMasterPage>
);

};

export default ProjectRoadmapScreen;