import React, { useEffect, useState } from 'react';
import FormButton from "../../components/common/ui/FormButton";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import CompanyService from "../../components/companies/services/CompanyService";

const LoginScreen = (props) => {
    const { onAuthenticate, company } = props;
    
    const [creds, setCreds] = useState({username: '', password: ''});
    const [errorMessage, setErrorMessage] = useState('');
    const [domainCompany, setDomainCompany] = useState(company);
    
    const onChange = (fieldId, e) => {
        let c = {
            username: creds.username,
            password: creds.password
        };
        
        c[fieldId] = e.target.value;
        
        setCreds(c);
    };

    const onSubmitAsync = async (e) => {
        return await AuthenticationService.instance.authenticateAsync(creds.username, creds.password).then((session) => {
            if (!!onAuthenticate && typeof onAuthenticate === 'function') onAuthenticate(session);
            return 'Success';
        }).catch((err) => {
            setErrorMessage(err?.response?.data?.messages[0] || (err?.message || err));
            return 'error';
        });
    };

    const createErrorElement = () => {
        if (!errorMessage) return (<></>);

        let message = 'The login attempt for user ' + creds.username + ' failed. ' +
            "Remember that username and password are case sensitive. ";

        return (<div className="error-box">{message}<br/><br/>Server Says: <strong>{errorMessage}</strong></div>);
    };
    
    useEffect(() => {
        if (!domainCompany) {
            let _ = CompanyService.instance.getCompanyByDomainAsync().then((c) => {
                if (c?.id) setDomainCompany(c);
                else console.warn("Failed to get domain company");
            });
        }
    }, []);

    let errorElement = createErrorElement();
    const imageElement = (!!domainCompany?.imageUrl && domainCompany.imageUrl.length > 1) ?
        (<div className={"company-login-image"}><img src={domainCompany.imageUrl} alt={domainCompany.name} /></div>) : null;
    
    return (
        <div id="main">
            <div id="main-body-login">
                { imageElement }
                <div className="box form">
                    <div>
                        <label>{ domainCompany?.name } Username</label>
                        <div><input onChange={onChange.bind(this, 'username')} type={"text"} /></div>

                        <label>Password:</label>
                        <div><input onChange={onChange.bind(this, 'password')} type={"password"} /></div>

                        {errorElement}

                        <div className={"buttons"}>
                            <FormButton onClick={onSubmitAsync}>Login</FormButton>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
