import UserModel from "../../people/models/UserModel";

class SessionModel {
    static entityType = 12;
    
    constructor(json) {
        if (!json) json = {};
        
        this.userId = json.user_id;
        this.user = new UserModel(json.user);
        this.ipAddress = json.ip_address;
        this.expires = json.expires;
        this.authType = json.auth_type;
        this.status = json.status;
        this.id = json.id;
        this.user = new UserModel(json.user);
        this.apiMap = {};
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
}

export default SessionModel;
