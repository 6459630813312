import React, {useEffect, useState} from 'react';
import MasterScreen from "../../MasterScreen";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import UserService from "../../components/people/services/UserService";
import PersonForm from "../../components/people/ui/PersonForm";
import UserModel from "../../components/people/models/UserModel";
import HeaderButton from "../../components/common/ui/HeaderButton";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import UserMasterPage from "../../components/people/ui/UserMasterPage";

const UserEditScreen = (props) => {
    const {} = props;

    let _;
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [userJson, setUserJson] = useState(user?.toJson() || null);
   
    const isNewUser = !userId || userId.length < 30;
    
    const updateUserAsync = async (userModel) => {
        console.log('Press');

        await UserService.instance.updateUserAsync(userModel).then((uu) => {
            if (uu.id === AuthenticationService.instance.session?.userId)
                AuthenticationService.instance.getSessionAsync(true);

            onUser(uu);
        });
    };

    const onUser = (user) => {
        setUser(user);
    };
    
    const onUpload = (image) => {
        console.warn('Profile Image Update.');
    };

    useEffect(() => {
        if (user) setUserJson(user.toJson());
    }, [user]);
    
    useEffect(() => {
        //
    }, []);
    
    let subtitle = (<>Add User</>); 
    let pp = "Add a new user. You'll be prompted for more information on the next step";
    
    if (!!user) {
        subtitle = (<>
            { user?.fullName }
            <span className={"subtitle"}>Edit Details</span>
        </>);
        
        pp = "Details below are for display and correspondence.  You can change these at any time.";
    }

    let userForm = !!userJson || isNewUser ? (<PersonForm user={user} onSubmit={updateUserAsync} />) : null;
    
    return  (
        <UserMasterPage user={user} title={"Personnel"} subTitle={subtitle} onUser={onUser} selection={"edit"}>
            <p>{ pp }</p>

            <div className={"form medium"}>
                { userForm }
            </div>

        </UserMasterPage>
    );

};

export default UserEditScreen;
