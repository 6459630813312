import React, {useEffect, useRef, useState} from 'react';
import DropTarget from "../../common/ui/drop-target/DropTarget";
import {Tooltip} from "@mui/material";
import {faArrowUpRightFromSquare, faEdit, faExpand, faFile, faGlobeAfrica} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AuthenticationService from "../../authentication/services/AuthenticationService";
import DocumentService from "../services/DocumentService";
import FormButton from "../../common/ui/FormButton";
import './Content.css';
import FileUploadModel from "../models/FileUploadModel";
import DialogBox from "../../common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../common/ui/dialog-box/DialogBoxController";
import {piFigma} from "../../common/ui/svgs/LogoSvgs";
import CommentForm from "../../messaging/ui/CommentForm";
import CollectionItemModel from "../../collections/models/CollectionItemModel";
import DocumentForm from "./DocumentForm";
import DateTime from "../../common/ui/DateTime";

const ContentDropper = (props) => {
    let _;
    const { onPasteFiles, onPasteText, onChange, items, onRefresh, onDrop, onUpload, controller } = props;
    const initialState = { files: [], docs: items || [], textItems: []};
    const [contentData, setContentData] = useState({ contentView: null, doc: null, content: initialState });
    const [contentViewerDialogController, setContentViewerDialogController] = useState(controller || new DialogBoxController("View Content"));
    const [viewState, setViewState] = useState({ visible: false });

    const setContent = (content) => {
        const c = { ...contentData };
        c.content = content;
        setContentData(c);
    };
    
    const content = contentData?.content;
    const contentView = contentData?.contentView;
    
    const onFilesDrop = (files, textItems) => {
        console.log("FILES DROPPED OK")
        _ = Promise.all([
            FileUploadModel.fromFilesAsync(files),
            FileUploadModel.fromTextItemsAsync(textItems)
        ]).then((values) => { 
            // TODO: Clean list
            // values.map((v) => { 
            //     console.log(v);
            // });
            
            const newFiles = values.filter((v) => v.length > 0 && !!v[0].filePath).flat();
            const newText = values.filter((v) => v.length > 0 && !!v[0].text).flat();
            const content = contentData.content;
            
            if (typeof onDrop === 'function') onDrop(newFiles, newText);
            
            const co = {
                files: content.files.concat(newFiles),
                docs: content.docs,
                textItems: content.textItems.concat(newText)
            };
            
            setContent(co);
        });

    };

    const toggleForm = (e, visible = null) => { 
        if (typeof e?.preventDefault === "function") e.preventDefault();
        if (typeof e?.stopPropagation === "function") e.stopPropagation();

        const form = document.getElementById("document-form");
        if (!form) return;
        
        form.classList.contains("block") ? 
            form.classList.remove("block") :
            form.classList.add("block");
        
        return true;
    };
    
    const onTextContentDrop = (textData) => {
        console.log("Text Content Drop: " + textData);
    };

    const onFilesPasted = (files) => {
        FileUploadModel.fromFilesAsync(files).then((newFiles) => {
            const c = {
                files: content.files.concat(newFiles),
                docs: content.docs,
                textItems: content.textItems
            };

            setContent(c);
        });
    };

    const onTextPasted = (text) => {
        console.log("On text pasted: " + text);
    };

    const uploadAsync = async (e) => {
        if (typeof onUpload !== 'function') { 
            console.log("onUpload has not been set");
            return;
        }
        
        console.log("OnUpload")
        await onUpload(content?.files, content?.textItems, e);
    };

    useEffect(() => {
        console.log("ViewState: " + viewState.visible + "");
    }, [viewState]);
    
    useEffect(() => {
        setTimeout(() => {
            if (!!contentData.contentView) contentViewerDialogController.open(null, "View Content");
            else contentViewerDialogController.close();
        }, 100);

    }, [contentData]);
    
    const sid = AuthenticationService.instance.sessionId;

    const viewContentInTab = (dof) => {
        // console.log(JSON.stringify(dof));
        // if (dof.contentType?.startsWith("image")) {
        //     window.open("/secure/document/" + dof.fileName + "?session-id=" + sid );
        //     return;
        // }
        window.open(dof.uri);
    };
    
    const viewExpanded = (doc) => {
        const c = {...contentData, doc: doc };
        
        if (!!doc.text || doc.contentType?.indexOf("text/") >= 0) { 
            c.contentView =  (<pre className={"text"}>{doc.text || doc.content}</pre>);
            setContentData(c);
            return;
        }
        
        const imagePath = doc.filePath || doc.uri; //"/secure/document/" + doc.fileName + (doc.fileName && doc.fileName.includes("?") ? "&" : "?") + "session-id=" + sid;
        const imageElement = (<img src={imagePath} alt={""} />);

        c.contentView = (<div>{ imageElement }</div>);
        
        setContentData(c);
    }
    
    const deleteDocumentAsync = async (dof, index) => {
        const documentId = dof.id;
        const removed = {
            files: content.files.filter((f) => f.id !== documentId),
            docs: content.docs.filter((d) => d.id !== documentId),
            textItems: content.textItems.filter((t) => t.id !== documentId)
        }

        console.log("Deleting: " + (typeof dof.contentTypeKey).toString());
        setContent(removed);

        if (typeof dof.contentTypeKey === 'number') {
            await DocumentService.instance.deleteDocumentAsync(documentId);
            if (typeof onRefresh === 'function') onRefresh();
        }
    };
    
    const onDocumentSaved = (doc) => {
        console.log(doc);
        
        if (typeof onChange === "function")
            onChange(doc, "document");
        
        setContentData({ ...contentData, doc: doc });
    };
    
    const createActionPanel = (dof) => {
        const isDoc = (typeof dof.contentTypeKey === 'number');
        const isUrl = !!dof.text && dof.contentType === "text/uri-list";
        
        const tabView = isDoc || isUrl ? (<a onClick={() => viewContentInTab(dof)} title={"Open in new tab"}><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>) : null;
        const expandedView = (<a onClick={() => viewExpanded(dof)} title={"Large View"}><FontAwesomeIcon icon={faExpand} /></a>);
        
        return (<div className={"document-action-panel"}>
            <label>{ dof.name }</label>
            <div className={"content-document-date"}>
                <span>Modified:</span>
                <DateTime value={dof.modified || dof.created} time={true} />
            </div>
            <div>
                { expandedView }
                { tabView }
                <a onClick={() => deleteDocumentAsync(dof)} className="btn btn-danger btn-sm">{ isDoc ? "Delete" : "Remove" }</a>
            </div>
        </div>);
    };

    const getDocumentThumbnailElement = (f) => {
        if (!!f.contentType && f.contentType.startsWith("image") && !f.filePath) {
            const imagePath = f.uri;
            return (<img src={imagePath} className={"content-thumb"} alt={""} />);
        }

        // Pre-uploaded items
        if (!!f.filePath) {
            const imagePath = f.filePath;
            return (<img src={imagePath} className={"content-thumb"} alt={""} />);
        }

        let icon = faFile;
        const typeUri = "text/uri-list";
        
        if (!!f.uri && f.uri.indexOf("figma.com") >= 0) icon = piFigma;
        else if (f.contentType === typeUri || f.contentType === typeUri) icon = faGlobeAfrica;
        else console.warn("Unknown content type: " + f.contentType);
        
        return (<span className={"svg"}><FontAwesomeIcon icon={icon} /></span>);
    };

    const previewItems = (contentData.content?.files || [])
        .concat(contentData.content?.textItems || [])
        .concat(contentData.content?.docs || []);

    const filePreview = !!previewItems ? previewItems.map((f, i) => {
        const imageElement = getDocumentThumbnailElement(f);
        const panel = createActionPanel(f);

        return (<Tooltip title={panel} key={"file-preview-" + i} arrow={true} placement={"top"}>
                <span className={"document-preview-item"} style={{lineHeight: "0", padding: "8px"}} onClick={(e) => deleteDocumentAsync(f.id, f.index)}>
                    {imageElement}
                </span>
            </Tooltip>);
    }) : null;

    const uploadButton = (content?.files?.length || 0) + (content?.textItems?.length || 0) > 0 ? (<FormButton onClick={uploadAsync} label={"Upload"} />) : null;
    const editClassName = viewState.visible ? "block" : "";

    return (<>
        <DropTarget className={"content-dropper x-large"} onDrop={(files, items) => onFilesDrop(files, items)} onPaste={(files) => onFilesPasted(files)} onPasteText={(text) => onTextPasted(text)}>
            <label>
                <span>Drop Files Here</span>
                <span>{uploadButton}</span>
            </label>
            <div className={"file-previews"}>
                {filePreview}
            </div>
        </DropTarget>
        <DialogBox controller={contentViewerDialogController} fullScreen={true} hasCancelButton={false} onOkay={() => 100}>
            <div className={"view-content"}>{ contentView }</div>
            <div className={"content-comments"}>
                <div>Id: {contentData.doc?.id}</div>
                <h2 className={"menu-header"} onClick={(e) => toggleForm(e)}>
                    <span>{contentData.doc?.name || "(Untitled)"}</span>
                    <FontAwesomeIcon icon={faEdit} />
                </h2>
                <p>{content.doc?.description}</p>
                <div className={"dialog-document-editor"}>
                    <DocumentForm onComplete={onDocumentSaved} options={{}} className={"editable-content"} document={contentData.doc} id={"document-form"} />
                </div>
                <CommentForm entityId={contentData.doc?.id} entityType={CollectionItemModel.entityType} />
            </div>
        </DialogBox>
    </>);
};

export default ContentDropper;
