class SubscriptionModel { 
    static create = (json) => new SubscriptionModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.companyId = json.company_id;
        this.userId = json.user_id;
        this.name = json.name;
        this.description = json.description;
        this.subscriptionType = json.subscription_type;
        this.mrc = json.mrc;
        this.setupFee = json.setup_fee;
        this.annualDiscount = json.annual_discount;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            company_id: this.companyId,
            user_id: this.userId,
            name: this.name,
            description: this.description,
            subscription_type: this.subscriptionType,
            mrc: this.mrc,
            setup_fee: this.setupFee,
            annual_discount: this.annualDiscount,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => SubscriptionModel.create(json));
    }
}

export default SubscriptionModel;
