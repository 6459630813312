import React from 'react';
import {Link} from "react-router-dom";

const CompanyUserSubMenu = (props) => {
    const { company, user, selection } = props;

    let companyId = company?.id || "00-00";
    let userId = user?.id || "00-00";
    
    let sel = selection || 'info';
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";

    selections[sel] = 'selected';

    const getPath = (sel) => {
        if (companyId.length < 10) return "";

        if (!sel) sel = "";
        return '/companies/' + companyId + '/people/' + userId + sel;
    };

    return (
        <div className={"submenu company" + cn}>
            <Link className={selections['details']} to={getPath('')}>Profile</Link>
            <Link className={selections['access']} to={getPath('/access')}>Access</Link>
            <Link className={selections['edit']} to={getPath('/edit')}>Edit</Link>
            <Link className={selections['messages']} to={getPath('/messages')}>Messages</Link>
            <Link className={selections['sessions']} to={getPath('/sessions')}>Sessions</Link>
            <Link className={selections['history']} to={getPath('/history')}>Events</Link>
            <Link className={selections['errors']} to={getPath('/errors')}>Errors</Link>
            <Link className={selections['integrations']} to={getPath('/integrations')}>Integrations</Link>
        </div>
    );
};

export default CompanyUserSubMenu;
