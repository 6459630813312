import React from 'react';
import {useParams} from "react-router-dom";
import LandingPagesTable from "../../../../components/qr-blocks/ui/LandingPagesTable";
import CompanyModel from "../../../../components/companies/models/CompanyModel";
import CompanyMasterPage from "../../../../components/companies/ui/CompanyMasterPage";

const UserLandingPagesScreen = (props) => {
    const {} = props;
    const { companyId } = useParams();

    const onCompany = (c) => { 
        
    };
    
    const subtitle = "Landing Pages";
    return (
        <CompanyMasterPage bodyClassName={"large"} subTitleClassName={"options"} menuSelection="landing-pages" onCompany={onCompany} title={subtitle}>
            <p>
                This area will show a cool description.
            </p>

            <LandingPagesTable companyId={companyId} />
        </CompanyMasterPage>
    );

};

export default UserLandingPagesScreen;
