import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarWeek,
    faCreditCard, faDiagramProject,
    faIdCardClip, faPenFancy,
    faPeopleGroup,
    faShieldCat
} from "@fortawesome/free-solid-svg-icons";

const ProjectSubMenu = (props) => {
    let {company, companyId, projectId, project, selection} = props;

    if (!companyId) companyId = company?.id || "00-00";
    if (!projectId) projectId = project?.id || "00-00";
    
    let sel = selection || 'main';
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";

    selections[sel] = 'selected';

    const roadmapElement = (project?.startDay > 0) ?
        (<Link title={"Start Day: " + project?.startDay} className={selections['roadmap']} to={'/companies/' + companyId + '/projects/' + projectId + "/roadmap"}>Roadmap</Link>) : 
        (<span className="disabled">Roadmap</span>);
    
    return (
        <div className={"submenu project" + cn}>
            <Link className={selections['main']} to={'/companies/' + companyId + '/projects/' + projectId + ""}>Details</Link>
            <Link className={selections['content']} to={'/companies/' + companyId + '/projects/' + projectId + "/content"}>Content</Link>
            { roadmapElement }
            <Link className={selections['edit']} to={'/companies/' + companyId + '/projects/' + projectId + "/edit"}>Edit Details</Link>
        </div>
    );

};

export default ProjectSubMenu;
