import HttpService from "../../common/services/HttpService";
import ShortcutProjectModel from "../models/ShortcutProjectModel";

class ShortcutService { 
    static instance = new ShortcutService();

    constructor(props) {
        this.projectsMap = {};
    }
    
    async getShortcutProjectsAsync(companyId = null) {
        let path = "/api/shortcut/project";
        if (companyId) path = "/api/company/" + companyId + "/project/shortcut";

        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            const key = companyId || "house";
            const projects = ShortcutProjectModel.fromJsonArray(response.data);
            me.projectsMap[key] = projects;
            
            console.warn("Shortcut Projects: " + projects.length);
            
            return projects;
        });
    }
}

export default ShortcutService;
