import UserLiteModel from "../../people/models/UserLiteModel";

class ActivityItemModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.companyId = json.company_id || "";
        this.userId = json.user_id || "";
        this.entityId = json.entity_id | "";
        this.activitySessionId = json.activity_session_id || "";
        this.entityType = json.entity_type || 0;
        this.entityTypeName = json.entity_type_name || "";
        this.severityType = json.severity_type;
        this.severityName = json.severity_type_name || "N/A";
        this.actionType = json.action_type || 0;
        this.actionTypeName = json.action_type_name || "";
        this.user = new UserLiteModel(json.user);
        
        this.text = json.text;
        
        this.created = json.created;
        this.modified = json.modified;
    }
    
    toJson() {
        return {
            company_id: this.companyId,
            user_id: this.userId,
            entity_id: this.entityId,
            activity_session_id: this.activitySessionId,
            entity_type: this.entityType,
            severity_type: this.severityType,
            action_type: this.actionType,
            text: this.text,
        };
    }
    
    static createKey(entityId, entityType) {
        return entityType + "-" + entityId;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ActivityItemModel(json));
    }
}

export default ActivityItemModel;
