import React, {useEffect, useRef, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import UserService from "../../../components/people/services/UserService";
import CompanyService from "../../../components/companies/services/CompanyService";
import Controller from "../../../components/common/services/Controller";
import PersonForm from "../../../components/people/ui/PersonForm";
import CompanyUserSubMenu from "../../../components/companies/ui/CompanyUserSubMenu";
import CompanyEditSubMenu from "../../../components/companies/ui/CompanyEditSubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp} from "@fortawesome/free-solid-svg-icons";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import CompleteDialog from "../../../components/common/ui/dialog-box/CompleteDialog";
import IntegrationsList from "../../../components/authentication/ui/IntegrationsList";
import CompanyModel from "../../../components/companies/models/CompanyModel";
import UserModel from "../../../components/people/models/UserModel";
import ConfigModel from "../../../components/common/models/ConfigModel";
import ApiKeysTable from "../../../components/authentication/ui/ApiKeysTable";
import AuthenticationService from "../../../components/authentication/services/AuthenticationService";

const CompanyUserIntegrationsScreen = (props) => {
    let _;
    const { companyId, userId } = useParams();
    const [company, setCompany] = useState();

    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const [user, setUser] = useState(UserService.instance.userMap[userId || ""] || null);
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[user?.companyId || ""] || null);

    const [pageState, setPageState] = useState({ id: 0, landingPages: []} );
    const controller = useRef(new Controller()).current;

    const getLandingPagesAsync = async (e) => {
        if (companyId.toLowerCase() !== ConfigModel.qrBlocksCompanyId) return null;
        
        const items = await UserService.instance.getLandingPagesAsync(userId, companyId);
        console.warn("Got landing pages: " + items?.length + "");
        
        if (Array.isArray(items)) {
            setPageState({...pageState, landingPages: items});
            
            return items;
        }
    }
    
    const getUserAsync = async (e) => {
        //
        return await UserService.instance.getUserAsync(userId).then((user) => {
            if (!user) return;
            setUser(user);

            if (user.companyId !== companyId && !userCompany) {
                console.log("Getting User's Company: " + user.companyId);
                _ = CompanyService.instance.getCompanyAsync(user.companyId).then((c) => {
                    if (!!c) setUserCompany(c);
                });
            }

        });
    };

    useEffect(() => {
        _ = getUserAsync();
        _ = getLandingPagesAsync();
    }, []);

    const editMenu = (<CompanyUserSubMenu user={user} company={company} selection={"integrations"} />);
    const h2titleText = (user?.firstName || "User") + "'s Integrations";
    const menuSelector = "people";

    const userName = !!user ? (user.firstName + " " + user.lastName).trim() : "User";
    const subtitle = (<><BackButton to={"/companies/" + companyId + "/people"} />
        <span className={"pre-subtitle"}>Integrations:</span>
        { userName }</>);
    
    const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
    const companyElement = (isDifferentCompany) ?
        (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
        null;

    let returnUrl = "/companies/" + companyId + "/people/" + userId + "/integrations";
    let integrationsElement = !!company ? (<IntegrationsList controller={controller} userId={userId} company={company} returnUrl={returnUrl} entityTypes={[UserModel.entityType]} />) : null;
    
    const mapLandingPages = () => { 
        return pageState.landingPages.map((lp, i) => {
            const url = "https://" + lp.path + ".qrbk.io"
            return (<li key={"landing-page-" + i}><a onClick={() => window.open(url, "_blank")} href={url}>{lp.name} - { lp.path}.qrbk.io</a></li>);
        });
    };
    
    const landingPageTable = pageState.landingPages?.length > 0 ?
        (<ul>{mapLandingPages()}</ul>) : 
        null;
    
    const apiKeysTable = !!AuthenticationService.instance.session?.user?.id ? (<ApiKeysTable userId={userId} />) : null;
    
    return (
        <CompanyMasterPage selection={menuSelector} menuSelection="edit" onCompany={onCompany} title={subtitle}>

            { editMenu }

            <p>
                All integrations. Good.
                { companyElement }
            </p>

            <div className={"form medium"}>
                { integrationsElement }
            </div>

            {landingPageTable}

            {apiKeysTable}
            
            <CompleteDialog controller={controller} />

        </CompanyMasterPage>
    );
    
};

export default CompanyUserIntegrationsScreen;
