import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";

const PaymentDetailsScreen = (props) => {
    const { companyId, paymentId } = useParams();
    const [company, setCompany] = useState(null);

    const onCompany = (c) => {
        if (!!c) setCompany(c);
    }

    const subtitle = (<>Payment Details</>);

    return (
        <BillingMasterPage onCompany={onCompany} selection={"transactions"} title={subtitle}>
            <p>This is the billing screen.</p>
            <div></div>
        </BillingMasterPage>
    );

};

export default PaymentDetailsScreen;
