import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";
import AddNewHeaderButton from "../../../components/common/ui/AddNewHeaderButton";
import {
    faCreditCard,
    faEnvelope,
    faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import SubscriptionService from "../../../components/finance/services/SubscriptionService";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import EmptyBox from "../../../components/common/ui/EmptyBox";
import NumberDisplay from "../../../components/common/ui/NumberDisplay";
import DateTime from "../../../components/common/ui/DateTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BillingScreen = (props) => {
    const {} = props;
    const { companyId } = useParams();
    const [viewId, setViewId] = useState(null);
    const [userSubscriptions, setUserSubscriptions] = useState(null);
    const [company, setCompany] = React.useState(null);

    const [errors, setErrors] = useState({ general: "" });
    
    const setErrorMessage = (key, message) => {
        if (!message) {
            if (!key) return false;
            
            if (typeof key === "string") {
                message = key;
                key = "general";
            } else if (typeof key === "object") {
                message = key.response?.data?.message || key.message || key.general || key.error || "Unknown error: " + (key.toString());
            }

            key = "general";
        }
        
        if (!key) key = "general";
        
        const err = {...errors};
        err[key] = message;
        
        setErrors(err);
    };
        
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    }
    
    const getCompanySubscriptionsAsync = async (force = false) => { 
        if (!companyId) return [];
        if (userSubscriptions?.length > 0 && force !== true) return userSubscriptions;
        
        console.log("Getting Subscription for Company: " + companyId);
        const userSubs = await SubscriptionService.instance.getUsersSubscriptionsByCompanyAsync(companyId);
        if (!!userSubs) setUserSubscriptions(userSubs);
        
        return userSubs;
    };
    
    const toggleView = (userSubscription, viewMode = null) => { 
        if (viewMode === null) viewMode = !viewId;
        
        if (viewMode === true) {
            setViewId(userSubscription);
        } else if (viewMode === false) {
            setViewId(null);
        }
    };

    const updatePrice = async (userSubscription, e) => {
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        if (e?.stopPropagation === "function")
            e.stopPropagation();
        
        await updateSubscriptionAmountAsync(null, userSubscription);
    };
    
    const updateSubscriptionAmountAsync = async (e, userSubscription) => {
        console.log("Id: " + viewId?.id);
        console.log("SubId: " + userSubscription?.id);
        console.log("Amount: " + userSubscription?.mrc);
        console.log("New Amount: " + e?.target?.value);
        
        const amount = parseFloat(e?.target?.value + "") || -1;
        
        if (!isNaN(amount) && typeof amount === "number" && amount !== userSubscription?.mrc) {
            userSubscription.mrc = amount;
            const rsp = SubscriptionService.instance.updateUserSubscriptionAmountAsync(userSubscription.id, amount).then((us) => { 
                let _ = getCompanySubscriptionsAsync(true);
            }).catch((ex) => { 
                setErrorMessage(ex);
                return null;
            });
            
            if (rsp === null) return;
        }
        
        toggleView(userSubscription, false);
    };
    
    useEffect(() => {
        getCompanySubscriptionsAsync();
    }, []);
    
    const subtitle = (<>Billing and Invoices
        <AddNewHeaderButton to={"/companies/" + companyId + "/invoices/000-000/edit"} icon={faFileInvoiceDollar}>Create Invoice</AddNewHeaderButton></>)

    const subscriptionElements = userSubscriptions?.map((sub, i) => {
        const nextBillDate = sub.nextBillDate || null;
        const nextBillTime = typeof nextBillDate?.getTime === "function" ? nextBillDate.getTime() : 0;
        
        const lastBillDate = sub.lastBillDate || null;
        const lastBillTime = typeof lastBillDate?.getTime === "function" ? lastBillDate.getTime() : 0;
        
        const lastInvoiceDate = sub.lastInvoiceDate || null;
        const lastInvoiceTime = typeof lastInvoiceDate?.getTime === "function" ? lastInvoiceDate.getTime() : 0;
        const lastInvoiceId = sub.lastInvoiceId || null;
        
        let statusElement = (<DateTime value={lastBillDate} time={true} useRelative={true} />);
        let statusElements = [];
        
        if (lastInvoiceTime > 0) {
            statusElements.push(<span key={"key-sent"}>
                <FontAwesomeIcon icon={faEnvelope} /> Sent <DateTime value={lastInvoiceDate} time={true} useRelative={true} />
            </span>);
        } 
        
        if (nextBillTime > 0) {
            statusElements.push(<span key={"key-next-bill"}>
                <FontAwesomeIcon icon={faCreditCard} /> Next Bill <DateTime value={nextBillDate} time={true} useRelative={true} />
            </span>);
        }
        
        if (statusElements.length === 0) statusElements.push("Inactive");
        
        const viewElement = sub.id === viewId?.id ?
            (<input className={"price-update"} type={"text"} onBlur={(e) => updateSubscriptionAmountAsync(e, sub)} defaultValue={sub?.mrc.toString()} />) :
            (<a onClick={(e) => toggleView(sub)}><NumberDisplay type={"currency"} value={sub.mrc} /></a>);
        
        return (<tr key={"user-sub-" + i}>
            <td valign={"top"}>
                <span>{sub.name}</span>
                {statusElements}
            </td>
            <td valign={"top"} className={"price"}>
                {viewElement}
                <div className={"normal"}>
                    <a className={"button"} onClick={(e) => updatePrice(sub, e)}>Auto-Update</a>
                </div>
            </td>
            <td valign={"top"}><DateTime value={lastBillDate} time={true} useRelative={true} /></td>
        </tr>);
    });
    
    const body = subscriptionElements === null ? (<LoadingBox>Loading Subscriptions...</LoadingBox>) :
        subscriptionElements?.length > 0 ? (<table id={"billing-table"} width={"100%"}>
            <thead>
                <tr>
                    <th>Subscription</th>
                    <th>Amount</th>
                    <th>Last Status</th>
                </tr>
            </thead>
            <tbody>{ subscriptionElements }</tbody>
        </table>) : (<EmptyBox>No Subscriptions</EmptyBox>);

    return (
        <BillingMasterPage onCompany={onCompany} selection={"main"} title={subtitle} onRefresh={async () => getCompanySubscriptionsAsync(true)}>
            <p>
                Billing activity and information.
            </p>

            <div className={"error-box"} onClick={(e) => setErrors({ general: "" })}>{errors?.general}</div>
            
            <div>{body}</div>
            
            <p className={"notes"}>
                <strong>Note About Subscriptions:</strong> These are effectively Scheduled Invoices, which are called from the Azure Logic App "Http-Post-App." Each month (or other frequency) the invoice is created with a total based on the MRC of the [UserSubscription] record.
                It will back-fill a discount to ensure the invoice total matches the MRC. If the MRC is zero, no discount will be calculated; it will simply be the total of
                all the price values in the [SubscriptionFeature] table that DO NOT have a SubscriptionType of 5 (SubscriptionTypeEnum.PaddedFeature).<br/><br/>
            </p>
            
        </BillingMasterPage>
    );
};

export default BillingScreen;