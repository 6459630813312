import React, { useState, useEffect } from 'react';
import CalendarService from "../services/CalendarService";
import "./Calendar.css";
import DateTime from "../../common/ui/DateTime";
import {Link} from "react-router-dom";
import Controller from "../../common/services/Controller";
import LoadingBox from "../../common/ui/LoadingBox";

const CalendarView = (props) => {
    const { companyId, initialDate, viewRange, title, controller } = props;
    const [calendarItems, setCalendarItems] = useState(CalendarService.instance.calendarMap[companyId || ""] || null);
    
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const endDate = CalendarService.instance.getEndDate(selectedDate, viewRange);
    
    const getCalendarItemsAsync = async (force = false) => {
        if (!force && calendarItems?.length > 0) {
            return calendarItems;
        }
        
        const d = CalendarService.instance.getStartDate(selectedDate, viewRange);
        return await CalendarService.instance.getCalendarAsync(companyId, d, endDate, viewRange).then((items) => {
            //
            if (Array.isArray(items) && items.length > 0) {
                setCalendarItems(items);
                return items;
            }
            
            return null;
        });
    };
    
    useEffect(() => {
        if (controller instanceof Controller) { 
            controller.refreshAsync = async () => {
                setCalendarItems(null);
                await getCalendarItemsAsync(true);
            };
        }
        
        let _ = getCalendarItemsAsync();
    }, []);
    
    const titleElement = typeof title === "string" && !!title ? (<h2>{ title }</h2>) : null;
    
    const createDateKey = (d) => { 
        return d.getFullYear() + "-" + (d.getMonth() + 1).toString() + "-" + d.getDate();
    };
    
    const timeOptions = { hour: "numeric", minute: "numeric" };
    const today = new Date().addHours(-8);

    const sd = CalendarService.instance.getStartDate(new Date(), viewRange);
    let dateKey = !!calendarItems?.length > 0 ? createDateKey(sd) : null;

    const itemElements = calendarItems == null ?
        (<LoadingBox className={"calendar"} />) :
        (<ul className={"calendar-list"}>
            { calendarItems.map((item, index) => {
                const thisDateKey = createDateKey(item.startDate);
                const isToday = dateKey === thisDateKey;
                const cn = isToday ? "today" : "";

                const dayHeader = isToday ?
                    null :
                    (<li key={"cal-day-header-" + index} className={"date-title " + cn}><DateTime useUtc={true} weekDay={true} value={item.startDate} /> | {dateKey} : { thisDateKey }</li>);
                
                dateKey = thisDateKey;
                
                return (<>
                    { dayHeader }
                    <li key={"cal-item-" + index} >
                    <span>
                        <span>{ item.title }</span>
                        <span className={"time"}>
                            <DateTime useUtc={true} value={item.startDate} options={timeOptions} defaultValue={""} /> 
                            <DateTime useUtc={true} prefix={" to "} value={item.endDate} options={timeOptions} defaultValue={""} />
                        </span>
                    </span>
                    <span>
                        Organizer: <Link to={"/companies/"}>{ item.organizerName }</Link>
                    </span>
                </li></>);
            }) }
        </ul>);
    
    return (<div>
        { titleElement }
        { itemElements }
    </div>);
};

export default CalendarView;
