import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import IntegrationsList from "../../../components/authentication/ui/IntegrationsList";

import '../../../components/tools/ui/Productivity.css';
import CompanyProjectsSubMenu from "../../../components/companies/ui/CompanyProjectsSubMenu";
import CompanyApiModel from "../../../components/companies/models/CompanyApiModel";

import Controller from "../../../components/common/services/Controller";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import CompanyModel from "../../../components/companies/models/CompanyModel";
import CalendarView from "../../../components/calendar/ui/CalendarView";

const CalendarScreen = (props) => {
    let _;
    const [company, setCompany] = useState();
    const controller = useState(new Controller())[0];
    const { slug } = props;
    const [calendarItems, setCalendarItems] = useState([]);
    const { companyId, projectId } = useParams();

    const onCompany = (c) => {
        if (!!c) {
            setCompany(c);
        }
    };
    
    const onRefreshAsync = async () => {
        if (typeof controller.refreshAsync === "function") {
            return await controller.refreshAsync();
        }
        
        return null;
    };
    
    useEffect(() => {
        //
    }, []);

    const getCalendarElements = () => {
        return (<CalendarView controller={controller} companyId={companyId} />);
    };

    const submenu = slug ? (<CompanyProjectsSubMenu company={company} selection={"integrations"} />) : null;
    const apiTypes = [CompanyModel.entityType];
    const integrationsElement = company ?
        (<IntegrationsList apiTypes={[CompanyApiModel.typeGoogleOAuth]} showConnection={true} body={getCalendarElements} controller={controller} company={company} entityTypes={apiTypes} />) :
        null;

    return (<CompanyMasterPage onRefresh={onRefreshAsync} onCompany={onCompany} selection={"calendar"} title={"Calendar"} bodyClassName={"large"}>
        {submenu}
        <div>
            { integrationsElement }
        </div>
    </CompanyMasterPage>);
};

export default CalendarScreen;
