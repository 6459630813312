import React, { useState, useEffect } from 'react';
import ActivityService from "../services/ActivityService";
import DateTime from "../../common/ui/DateTime";

const WebVisitorTable = (props) => {
    const {domain, path, domainId, domains, paths, domainIds, startDate, endDate, excludeColumns} = props;
    const [pageState, setPageState] = useState({webVisitors: null, startDate: startDate, endDate: endDate});
    
    const getWebVisitorsAsync = async (force = false) => {
        if (pageState?.webVisitors?.length > 0 && !force) return pageState?.webVisitors;
        
        const options = {
            domain: domain || null,
            domains: domains,
            domainIds: domainIds,
            domainId: domainId || null,
            startDate: pageState.startDate,
            endDate: pageState.endDate,
            path: path || null,
            paths: paths || null,
        };
        
        return await ActivityService.instance.getWebVisitorsAsync(options).then((visitors) => { 
            if (Array.isArray(visitors)) {
                const newState = {...pageState, webVisitors: visitors};
                setPageState(newState);
            }
        });
    };
    
    useEffect(() => {
        getWebVisitorsAsync();
    }, []);
    
    const columns = {
        ipAddress: "IP Address",
        path: "Url",
        deviceName: "Device",
        browser: "Browser",
        location: "Location",
        created: "Date",
    };

    if (Array.isArray(excludeColumns)) { 
        excludeColumns.forEach((column) => {
            delete columns[column];
        });
    }
    
    const visitorElements = pageState.webVisitors?.map((visitor, index) => {
        // <td>{visitor.ipAddress}</td>
        // <td>{visitor.path}</td>
        // <td>{visitor.deviceName}</td>
        // <td>{visitor.browser?.name}</td>
        // <td>{visitor.city}</td>
        // <td><DateTime value={visitor.created} time={true} /></td>

        const tds = Object.keys(columns).map((key, index) => {
            if (key === "created") {
                return (<td key={"visitor-" + index}><DateTime className={"small-date"} value={visitor[key]} time={true} /></td>);
            }
            
            if (key === "browser") {
                return (<td key={"visitor-" + index}>{visitor.browser?.name || (<>&nbsp;</>)}</td>);
            }
            
            return (<td key={"visitor-" + index}>{visitor[key] || (<>&nbsp;</>)}</td>);
        });
        
        return (<tr key={"visitor-" + index}>
            {tds}
        </tr>)
    });
    
    return (<div className={"web-visitor-table"}>
        <table width={"100%"} className={"column-adjust"}>
            <thead>
            <tr onClick={(e) => getWebVisitorsAsync(true)}>
                <th>{columns.ipAddress}</th>
                <th>{columns.path}</th>
                <th>{columns.deviceName}</th>
                <th>{columns.browser}</th>
                <th>{columns.location}</th>
                <th>{columns.created}</th>
            </tr>
            </thead>
            
            <tbody>
            {visitorElements}
            </tbody>
        </table>
    </div>);
};

export default WebVisitorTable;