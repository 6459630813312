import React from 'react';

const LoadingBox = (props) => {
    const {children, className, size} = props;

    let sz = (size || 0).toString();
    
    if (["24", "32", "48", "64", "128"].includes(sz))
        sz = "";
    
    const cn = typeof className === "string" ? className + " " : "";
    return (
        <span className={cn + "loading-box spinner size" + sz}>
            <label className={"spinner-label"}>{ children || "Loading"}</label>
            <span><label className={"spinning"}></label></span>
        </span>);
};

export default LoadingBox;
