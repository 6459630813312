class MessageTemplateModel { 
    static create = (json) => new MessageTemplateModel(json);
    
    constructor(json) { 
        if (!json) json = {};

        this.id = json.id || null;
        this.name = json.name || null;
        this.messageTemplateType = json.message_template_type || 0;
        this.subject = json.subject || null;
        this.body = json.body || null;

        this.templateSourceType = json.template_source_type || 0;
        this.remoteId = json.remote_id || null;
        this.companyId = json.company_id || null;

        this.created = json.created ? new Date(json.created) : new Date();
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new MessageTemplateModel(json));
    }

    static getHtmlPreview(html) { 
        if (typeof html !== "string") throw new Error("Html must be a string");
        if (html?.trim().length === 0) return html;

        if (html.indexOf("<") < 0 || html.indexOf("<") < 0) return html;

        const doc = new DOMParser().parseFromString(html, "text/html");
        let cursor = doc.firstChild;

        while (!!cursor) {
            let sibling = cursor;

            while (!!sibling) { 
                const htmlText = sibling.textContent?.trim() || "";
                if (htmlText.length > 0) return htmlText;

                sibling = sibling.nextSibling;
            }

            cursor = cursor.firstChild;
        }

        return html?.toString();
    }
}

export default MessageTemplateModel;