class WorkflowStateModel { 
    static json = {
        "company_id": null,
        "user_id": "00000000-0000-0000-0000-000000000000",
        "workflow_state_number": -1,
        "name": "Deleted",
        "sort_order": -1,
        "status": 1,
        "id": "00000000-0000-0000-0000-000000000000",
        "created": "0001-01-01T00:00:00",
        "modified": null
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.companyId = json.company_id;
        this.userId = json.user_id;
        this.workflowStateNumber = json.workflow_state_number;
        this.name = json.name;
        this.sortOrder = json.sort_order;
        this.status = json.status;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new WorkflowStateModel(json));
    }
}

export default WorkflowStateModel;
