class SignalRMessageModel { 
    constructor(json) {
        if (typeof json === 'string') json = JSON.parse(json);
        if (!json) json = {};
        
        this.id = json.id || "";
        this.message = json.message || null;
        this.type = json.type || 0;
        this.typeName = json.type_name || "";
        this.path = json.path || null;
        this.data = json.data || null;
        this.icon = json.icon || null;
        this.date = json.date ? new Date(json.date) : new Date();
    }
}

export default SignalRMessageModel;
