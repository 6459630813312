import React, {useEffect, useState} from 'react';
import ApiAuthModel from "../models/ApiAuthModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCheckCircle, faHardDrive} from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "../services/AuthenticationService";
import GoogleService from "../services/GoogleService";
import googleLogo from "../../../images/google-logo.png";
import instagramLogo from "../../../images/instagram-logo.png";
import UserModel from "../../people/models/UserModel";
import CompanyApiModel from "../../companies/models/CompanyApiModel";
import {piTrello} from "../../common/ui/svgs/LogoSvgs";
import "./IntegrationsList.css";
import ApiAuthForm, {ApiAuthFormOptions} from "./ApiAuthForm";
import InstagramService from "../services/InstagramService";
import EmptyBox from "../../common/ui/EmptyBox";

/**
 * 
 * @param props {{controller: Controller, company: CompanyModel, returnUrl: string, userId: string, entityTypes: [number]}}
 * @returns {JSX.Element|null}
 * @constructor
 */
const IntegrationsList = (props) => {
    const { showConnection, controller, company, returnUrl, userId, entityTypes, empty, apiTypes, body } = props;

    let _;
    let [credsMap, setCredsMap] = useState(AuthenticationService.instance.session?.apiMap || null);
    let [initUrls, setInitUrls] = useState({});

    const [emptyCount, setEmptyCount] = useState(-1);
    
    const companyId = company?.id;
    const returnPath = !!returnUrl && typeof returnUrl === "string" ?
        returnUrl :
        window.location.pathname;
    
    const initAllUrlsAsync = async () => {
        const apis = company?.apis?.filter((a) => a?.isGoogle() === true || a?.isInstagram() === true);
        const newInit = {...initUrls};
        
        let initCalls = [];
        
        for(let i = 0; i < apis.length; i++) {
            const initCall = {
                id: apis[i].id,
                api: apis[i],
                callAsync: null
            };
            
            if (initCall.api.isGoogle()) {
                initCall.callAsync = async () => {
                    const cid = initCall.id.toString();
                    const entityType = initCall.api.entityType;
                    
                    return await GoogleService.instance.getOAuthInitAsync(returnPath, initCall.id).then((url) => {
                        return {
                            id: cid,
                            url: url,
                            entityType: entityType 
                        };                    
                    });
                };
                
            } else if (initCall.api.isInstagram()) {
                initCall.callAsync = async () => {
                    const cid = initCall.id.toString();
                    return await InstagramService.instance.getOAuthInitAsync(returnPath, initCall.id, userId).then((url) => {
                        return {id: cid, url: url};
                    }).catch((ex) => { 
                        console.error(ex);
                        return { id: cid, url: "" };
                    });
                };
                
            } else {
                console.error("Skipping Init: " + initCall.api.apiType);
                continue;
            }
            
            initCalls.push(initCall);
        }
        
        if (initCalls.length > 0) { 
            await Promise.all(initCalls.map((ic) => ic.callAsync())).then((results) => {
                results.forEach((r) => {
                    if (!!r?.id) { 
                        const ek = "url-" + r?.id;
                        newInit[ek] = r.url;
                    }
                });
                
                setInitUrls(newInit);
            });
        }
    };
    
    const getCurrentUserApiCredsAsync = async (companyId) => {
        const cid = !!userId && userId.length > 30 ? null : [companyId];
        
        const rsp = (cid?.length > 0) ? 
            await AuthenticationService.instance.getApiAuthsByCompaniesAsync(cid).catch((ex) => {
                console.error(ex);
                return null;
            }) :
            await AuthenticationService.instance.getCurrentUserApiAuthCredentialsAsync().catch((ex) => {
                console.error('Error Getting Creds:: ' + ex);
                return null;
            });

        const mm = ApiAuthModel.toMap(rsp, false);
        //console.warn(JSON.stringify(mm, null, 4));
        
        setCredsMap(mm);
        
        return rsp;
    };
    
    const deleteIntegrationAsync = async (api, e) => {
        console.log("Delete: " + api?.id);
        
        const element = e.target || null;
        let caption = null;
        
        if (element) {
            caption = element.innerText;
            element.innerHTML = "Deleting..."
        }
        
        AuthenticationService.instance.deleteIntegrationAsync(api?.id).then((x) => {
            _ = getCurrentUserApiCredsAsync();
        }).catch((ex) => {
            if (element && caption) {
                element.innerHTML = "Failed";
                setTimeout(() => {
                    element.innerHTML = caption;
                }, 2000);
            }
        });
    };
    
    const initCallbacks = () => { 
        if (!!controller) {
            controller.refreshAsync = async (e) => {
                await getCurrentUserApiCredsAsync();
            }

            if (typeof controller?.callbacks["empty_count"] !== "function") {
                console.error("Setting Callback [" + emptyCount + "]: empty_count");
                controller.setGetDataCallback("empty_count", () => {
                    return "Value: " + emptyCount?.toString() + ", Type: " + (typeof emptyCount);
                });

            } else {
                console.error("Callback Already Set: empty_count: " + (typeof controller.callbacks["empty_count"]));
            }
        } else {
            console.error("No Controller");
        }

        console.log("initted Callbacks");
        setCredsMap();

        
        //google_oauth
    };

    useEffect(() => {
        //console.warn("URLs Initted [" + randomKey + "]: " + Object.keys(initUrls).length);
        //console.log(JSON.stringify(initUrls));
    }, [initUrls]);
    
    useEffect(() => {
        //console.warn(JSON.stringify(credsMap, null, 4));
    }, [credsMap]);

    useEffect(() => {
        getCurrentUserApiCredsAsync(companyId);
        initAllUrlsAsync();
        initCallbacks();
    }, []);

    if (!company) return null;

    const getIconImage = (companyApi) => {
        let imageMap = {};
        const apiType = companyApi?.apiType?.toString() | "";
        imageMap[ApiAuthModel.googleOAuthKey] = googleLogo;

        if (apiType.toString() === ApiAuthModel.trelloKey) return (<FontAwesomeIcon icon={piTrello} />);
        if (apiType.toString() === ApiAuthModel.googleOAuthKey) return  (<img className={"icon"} src={googleLogo} style={{marginRight: "8px"}} alt={"Google Logo"} />);
        if (apiType.toString() === ApiAuthModel.instagramOAuthKey) return  (<img className={"icon"} src={instagramLogo} style={{marginRight: "8px"}} alt={"Instagram Logo"} />);
        
        const icon = imageMap[apiType] || null;
        
        return icon ?
            (<img className={"icon"} src={icon} style={{marginRight: "8px"}} alt={"Google Logo"} />) :
            (<FontAwesomeIcon icon={faHardDrive} />)
    };

    const onCredentialsSaveComplete = async (auth, e) => {
        setTimeout(() => {
            _ = getCurrentUserApiCredsAsync();
        }, 100);
    };

    const createApiForm = (companyApi) => {
        const options = new ApiAuthFormOptions({ accountName: true});
        const name = companyApi.name?.toLowerCase() || "";
        
        // noinspection FallThroughInSwitchStatementJS
        switch (companyApi.apiType) {
            case CompanyApiModel.typeTwilioSms:
                options.clientId = true;
            // eslint-disable-next-line no-fallthrough
            case CompanyApiModel.typeSendGrid:
                options.labels.accountName = "Authorized Sender Email";
            // eslint-disable-next-line no-fallthrough
            case CompanyApiModel.typeShortcut:
            case CompanyApiModel.typePaymentGateway:
                options.labels.secret = "Secret Key";
                options.secret = true;
                
                if (name.indexOf("stripe") >= 0) {
                    options.placeholders.clientId = "'test' or 'live'";
                    options.defaultValues.accountName = "Stripe";
                }
                
                if (name.indexOf("sandbox") >= 0)
                    options.defaultValues.clientId = "test";
                
                break;
            case CompanyApiModel.typePlaid:
                break;
            default:
                break;
        }

        return (<ApiAuthForm options={options} companyApi={companyApi} companyId={companyId} onComplete={onCredentialsSaveComplete} />);
    };
    
    let companyApis = company.apis;
    
    if (Array.isArray(entityTypes) && entityTypes.length > 0)
        companyApis = companyApis.filter((api) => entityTypes.includes(api.entityType));

    if (Array.isArray(apiTypes) && apiTypes.length > 0)
        companyApis = companyApis.filter((api) => apiTypes.includes(api.apiType));
    
    //apiTypes

    const navigateTo = (url, target = "_blank") => {
        console.log("Navigate To: " + url);
        window.open(url, target);
    };

    let ec = 0;
    const apiElements = companyApis.map((api, index) => {
        let element = (<></>);
        let ownerId = api.entityType === UserModel.entityType ? userId : companyId;
        let key = ApiAuthModel.createKey(api, api.apiType, userId, companyId);
        
        let deleteElement = (<></>);

        const isGoogle = api.isGoogle();
        const isInstagram = api.isInstagram();
        const icon = getIconImage(api);
        const initKey = "url-" + api.id;

        if (!!credsMap && !!credsMap[key || "nope"]) {
            let accountName = credsMap[key]?.accountName;
            
            if (!!accountName) accountName = "Account: " + accountName;
            else accountName = "";
            
            element = (<span className={"connected"} title={key}><FontAwesomeIcon icon={faCheckCircle} /> Connected {accountName}</span>);
            deleteElement = (<a className={"delete"} onClick={deleteIntegrationAsync.bind(this, credsMap[key])}>Delete</a>);
        } else {
            ec++;
            if (isGoogle) {
                const initUrl = initUrls[initKey];
                element = !!initUrl ? (<a className={"connect"} onClick={(e) => navigateTo(initUrl)} href={initUrl} target={"_blank"} rel="noreferrer">Connect a Google {api.entityTypeName} Account <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>) :
                    (typeof initUrl === 'undefined' || initUrl === null ? (<span>Checking...</span>) : (<strong>No Google Connected {initKey}</strong>));
            
            } else if (isInstagram) {
                const initUrl = initUrls[initKey];
                element = !!initUrl ? (<a className={"connect"} onClick={(e) => navigateTo(initUrl)} href={initUrl} target={"_blank"} rel="noreferrer">Connect Instagram {api.entityTypeName} Account <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>) :
                    (typeof initUrl === 'undefined' || initUrl === null ? (<span>Checking...</span>) : (<strong>No {api.entityTypeName} Instagram Account Connected</strong>));
            } else {
                element = createApiForm(api);
            }
        }

        return (
            <li key={"api-" + index.toString()}>
                <h2>
                    <span>{icon}</span>
                    <span className={"name"}>{api.entityTypeName} {api.name}</span>
                    <span>{deleteElement}</span>
                </h2>
                <p>{api.description}</p>
                {element}
            </li>
        );

    }) || null;
    
    if (companyApis?.length > 0) { 
        if (ec === 0 && !!body) {
            const bodyElement = (typeof body === "function") ? body() : body;
            if (showConnection === true) return (<><ul className={"integration-items"}>{apiElements}</ul>{bodyElement}</>);
            
            return bodyElement;
        }
        
        return (<ul className={"integration-items"}>{apiElements}</ul>);
    }
    
    const entityName = !!userId ? "this user in " : "";

    if (typeof empty === "function") return empty();

    return empty || (<EmptyBox>No APIs Available for {entityName + company?.name}</EmptyBox>);
};

export default IntegrationsList;
