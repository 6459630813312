import ProjectItemDayModel from "./ProjectItemDayModel";
import UserLiteModel from "../../people/models/UserLiteModel";
import {faCompassDrafting, faLocationCrosshairs} from "@fortawesome/free-solid-svg-icons";
import ProjectListItemModel from "./ProjectListItemModel";

class ProjectItemModel {
    static entityType = 18;
    static icon = faLocationCrosshairs;
    static create = (json) => new ProjectItemModel(json);

    static userTypeWatcher = 0;
    static userTypeOwner = 1;
    static userTypeCollaborator = 2;

    static typeValues = [
        { id: 0, name: "Information" },
        { id: 1, name: "Task" },
        { id: 2, name: "Bug" },
        { id: 3, name: "Story" },
        { id: 4, name: "Epic" },
        { id: 5, name: "Feature" },
        { id: 6, name: "TestCase" }
    ];

    constructor(json, index = -1) {
        if (!json) json = {};
        
        this.id = json.id;
        this.index = index;
        this.projectId = json.project_id || "";
        
        this.name = json.name;
        this.description = json.description;
        this.projectId = json.project_id;
        this.userId = json.user_id;
        this.imageId = json.image_id;
        this.apiAuthId = json.api_auth_id;
        this.mediaCollectionId = json.media_collection_id;
        this.remoteId = json.remote_id;
        this.uri = json.uri;
        this.stageType = json.stage_type;
        this.workflowStateNumber = json.workflow_state_number || this.stageType;
        this.price = json.price || 0;

        this.stageName = json.stage_type_name;
        this.status = json.status;
        this.statusName = json.status_name;
        
        this.completeDate = json.complete_date ? new Date(json.complete_date) : null;
        this.estimatedStartDate = json.estimated_start_date ? new Date(json.estimated_start_date) : null;
        
        this.duration = json.duration || 1;
        this.durationUnits = json.duration_units || "D";
        
        this.startDay = json.start_day || 1;
        this.startDate = json.start_date ? new Date(json.start_date) : null;
        this.endDate = json.end_date ? new Date(json.end_date) : null;

        this.activeDate = json.active_date ? new Date(json.active_date) : null;
        this.activeStartDate = json.active_start_date ? new Date(json.active_start_date) : null;
        this.activeEndDate = json.active_end_date ? new Date(json.active_end_date) : null;

        this.users = UserLiteModel.fromJsonArray(json.users);
        this.userMap = {};
        this.users.forEach(user => {
            this.userMap[user.id] = user;
            
            let userTypeKey = user.userType?.toString();
            if (userTypeKey) this.userMap[userTypeKey] = user;
        });
        
        this.project = new ProjectListItemModel(json.project || { id: this.projectId, name: "Project" });
        this.owner = this.userMap[this.id] || (this.userMap[ProjectItemModel.userTypeOwner.toString()] || null);
        
        this.index = typeof index === 'number' ? index : -1;
        this.data = {};
        this.days = ProjectItemDayModel.fromJsonArray(json.days);
        this.dayIndex = -1;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    getDuration() { 
        if (!this.days || !Array.isArray(this.days)) return this.duration;
        return Math.max(...this.days.map((dz) => dz.day));
    }
    
    cloneWithDay(dayModel, index) {
        let clone = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
        let dayClone = Object.assign(Object.create(Object.getPrototypeOf(dayModel)), dayModel);
        
        dayClone.index = index;
        
        clone.days = [dayClone];
        
        return clone;
    }
    
    searchFor(term) {
        if (!term) return true;
        term = term.toLowerCase();
        
        if (this.name.toLowerCase().indexOf(term) > -1) return true;
        
        const description = this.description || "";
        
        if (term.length > 4) { 
            return description.indexOf(term) > -1;
        }
        
        return ((description.match(/term/g) || []).length >= 3);
    }
    
    toDurationJson(additionalJson) { 
        if (typeof additionalJson === 'string') {
            additionalJson = { title: additionalJson };
        } else if (additionalJson !== 'object') {
            additionalJson = {};
        }
        
        let json = {
            start_day: this.startDay || 0,
            start_date: this.startDate,
            duration: this.duration || 1,
            id: this.id
        };

        for(let key in additionalJson) {
            if (!json[key]) json[key] = additionalJson[key];
        }

        //console.log(JSON.stringify(json));
        
        return json;
    }
    
    hasEndDate() {
        return this.endDate != null;
    }
    
    toJson() {
        let durationJson = {
            duration: this.duration || 1,
            estimated_start_date: this.estimatedStartDate,
            start_day: this.startDay || 1
        };
        
        return {
            name: this.name,
            description: this.description,
            remote_id: this.remoteId,
            uri: this.uri,
            id: this.id,
            duration: durationJson,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new ProjectItemModel(json));
    }
}

export default ProjectItemModel;
