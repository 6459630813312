/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import NavigationHistoryService from "../services/NavigationHistoryService";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const NavigationHistory = (props) => {
    const { className, onChange, header, length } = props;

    let items = NavigationHistoryService.instance.clean();
    if (typeof length === 'number' && length > 0) items = items.filter((_, index) => index < length);
    
    const historyItems = items.map((item, index) => {
        return (
            <li key={"history-item-" + index} title={item.score?.toString() || "No Score"}>
                <Link to={item.path || "/"}>{item.title || item.path}</Link>
                <a className={"remove"} onClick={() => removeHistoryItem(item.key)}><FontAwesomeIcon icon={faXmark} /></a>
            </li>
        );
    });

    const removeHistoryItem = (key) => {
        if (NavigationHistoryService.instance.remove(key))
            if (typeof onChange === 'function') onChange(key);
    };

    const cn = typeof className === 'string' ? " " + className : "";
    
    return (<ul className={"navigation-history" + cn}>
        {header || null}
        {historyItems}
    </ul>);
};

export default NavigationHistory;

