import React from 'react';
import SearchResult from "./SearchResult";

const SearchResultCategory = (props) => {
    const { category, wrapper, onClick } = props;

    const resultElements = category?.results?.map((result, i) => {
        result.entityType = category.entityType;
        return (<li key={"search-result-" + i}><SearchResult result={result} key={"result-" + i} /></li>);
    });
    
    const onResultClick = (e) => {
        if (typeof onClick === 'function') {
            e.preventDefault();
            onClick(e);
        }
    };

    const body = (
        <ul onClick={onResultClick} className={"search-result-container-" + category.entityType}>
            <li className={"search-result-title"}>{category?.name}</li>
            { resultElements }
        </ul>
    ); 

    if (typeof wrapper === "string") {
        switch (wrapper) { 
            case "li":
                return (<li>{ body }</li>);
            case "div":
                return (<div>{ body }</div>);
            case "span":
                return (<span>{ body }</span>);
        }
    }
    
    return body;
};

export default SearchResultCategory;