import React, {useEffect, useState, useRef} from 'react';
import ToolsMasterPage from "./ToolsMasterPage";
import {faCheckCircle, faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import CreditCardForm from "../../components/finance/ui/CreditCardForm";
import PaymentService from "../../components/finance/services/PaymentService";
import {useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import FormButton from "../../components/common/ui/FormButton";
import CompletedPanel from "../../components/common/ui/CompletedPanel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const PaymentScreen = () => {
    const { customerId } = useParams();
    const [errors, setErrors] = useState({});
    const [customer, setCustomer] = useState(UserService.instance.userMap[customerId || ""] || null);
    const [isComplete, setIsComplete] = useState(false);
    
    let _;
    
    const amountRef = useRef();
    
    const onChargeCardAsync = async (cardModel) => {
        const amount = parseFloat(amountRef.current?.value || "");
        
        if (isNaN(amount) || amount <= 0) { 
            console.error("Amount is invalid");
            setErrors({ amount: "Amount is invalid" });
            return false;
        }
        
        const data = { 
            credit_card: cardModel.toJson(),
            customer_id: null,
            total: amount,
        };
        
        console.log("Charging card...");
        
        return await PaymentService.instance.capturePaymentAsync(data).then((result) => {
            setIsComplete(true);
            return true;
        }).catch((ex) => {
            let errorMessage = ex?.response?.data?.message;
            setErrors({ general: errorMessage });
            
            return false;
        });
    };
    
    const getUserAsync = async (force) => {
        if (!customerId || (!force && !!customer)) return;
        
        return await UserService.instance.getUserAsync(customerId).then((c) => {
            if (!!c) setCustomer(c);
        });
    };
    
    const onFormChange = (e) => {
        if (!!errors) setErrors({});
    };
    
    const createErrorElement = (error) => {
        if (!error) return null;
        return (<div className={"error-box top"}>{ error }</div>);
    };
    
    const closeCompleteDialog = () => {
        setIsComplete(false);
    };
    
    useEffect(() => {
        _ = getUserAsync();
        if (!isComplete && !!amountRef.current) amountRef.current.value = "";
        
    }, [isComplete]);
    
    const customerProfileImage = !!customer?.image ? (<div>
        <span className={"profile-image"}><img src={customer?.getImageUrl("256x256")} alt={"Profile Image"} /></span>
    </div>) : null;
    
    const customerNameElement = !!customer?.fullName ? (<> for <strong>{ customer.fullName }</strong></>) : null;
    
    return (
        <ToolsMasterPage icon={faPeopleCarryBox} subTitle={"Make a Payments"} selection={"payments"}>
            <div className={"flex payment-customer-profile"}>
                { customerProfileImage }
                <p>
                    Make a random credit card transaction{ customerNameElement }
                </p>
            </div>

            <div>
                <div className={"form"}>
                    <div className={"form-group"}>
                        <label>Amount</label>
                        <span className={"currency"}>
                            <input type={"number"} className={"payment-amount"} placeholder={"$"} ref={amountRef}></input>
                            <span className={"symbol"}>$</span>
                        </span>
                        
                        { createErrorElement(errors.amount) }
                    </div>
                </div>
                { createErrorElement(errors.general) }
                <CreditCardForm useButton={true} buttonCaption={"Charge Credit Card"} onSubmit={onChargeCardAsync} onChange={onFormChange} clearOnComplete={true} />
            </div>

            <Dialog open={isComplete} fullScreen={false}>
                <DialogTitle className={"completed-title"}>Transaction Complete</DialogTitle>
                <DialogContent>
                    <DialogContentText className={"dialog-body"}>
                        <CompletedPanel>
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </CompletedPanel>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <span className={"dialog-buttons completed"}>
                        <FormButton onClick={closeCompleteDialog} caption={"Close"}>Okay</FormButton>
                    </span>
                </DialogActions>

            </Dialog>
            
        </ToolsMasterPage>
    );

};

export default PaymentScreen;

