import UserAgentModel from "@jadecharles/pi-react-packages/dist/authentication/models/UserAgentModel";
import AddressModel from "../../geo/models/AddressModel";
import CompanyModel from "../../companies/models/CompanyModel";
import WebSessionLabelModel from "./WebSessionLabelModel";
import UserLiteModel from "../../people/models/UserLiteModel";

class WebVisitorModel {
    static entityType = 51;
    static create = (json) => new WebVisitorModel(json);

    static stateMap = null;
    
    constructor(json) {
        if (!json) json = {};

        this.id = json.id || null;

        this.vendor = json.vendor || null;
        this.platform = json.platform || null;

        this.city = json.city || null;
        this.country = json.country || null;
        this.region = json.region || null;
        this.company = !!json?.company ? new CompanyModel(json.company) : null;
        
        if (!WebVisitorModel.stateMap) WebVisitorModel.stateMap = AddressModel.createStateMap();
        
        this.state = WebVisitorModel.stateMap[this.region] || null;
        this.location = [this.city || null, this.state?.abbr || (this.region || null)].filter(x => !!x).join(", ");
        
        this.path = json.path || null;
        this.user = !!json?.user?.id ? new UserLiteModel(json.user) : null;
        this.maxTouchPoints = json.max_touch_points || 0;
        this.label = WebSessionLabelModel.create(json.label);
        if (!this.label?.id) this.label = null;
        
        this.domainName = json.domain || (json.domainName || null);
        this.attribution = json.attribution || null;
        this.pdfViewerEnabled = json.pdf_viewer_enabled || null;
        this.hardwareConcurrency = json.hardware_concurrency || 0;
        this.cookieEnabled = json.cookie_enabled || null;
        this.appCodeName = json.app_code_name || null;
        this.appName = json.app_name || null;
        this.appVersion = json.app_version || null;
        this.ipAddress = json.ip_address || null;
        this.vendorSub = json.vendor_sub || null;
        this.productSub = json.product_sub || null;
        this.product = json.product || null;
        this.userAgent = json.user_agent || "";
        this.language = json.language || null;
        this.referrer = json.referer || (json.referrer || null);
        this.mediaDevices = json.media_devices || null;
        this.onLine = json.on_line || null;
        this.webdriver = json.webdriver || null;
        this.sessionId = json.session_id || "";
        this.webSession = json.web_session || null;
        this.deviceMemory = json.device_memory || 0;
        this.deviceName = WebVisitorModel.getVisitorDeviceName(this.userAgent);
        this.browser = new UserAgentModel(this.userAgent);
        this.isBot = WebVisitorModel.isBot(this.userAgent);
        this.lat = json.lat || null;
        this.lon = json.lon || null;

        this.rawData = json;
        this.isMobile = this.userAgent.toUpperCase().indexOf("MOBILE") >= 0;

        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }

    toJson() {
        return {
            max_touch_points: this.maxTouchPoints,
            pdf_viewer_enabled: this.pdfViewerEnabled,
            hardware_concurrency: this.hardwareConcurrency,
            cookie_enabled: this.cookieEnabled,
            app_code_name: this.appCodeName,
            app_name: this.appName,
            app_version: this.appVersion,
            ip_address: this.ipAddress,
            vendor_sub: this.vendorSub,
            product_sub: this.productSub,
            vendor: this.vendor,
            platform: this.platform,
            product: this.product,
            user_agent: this.userAgent,
            language: this.language,
            media_devices: this.mediaDevices,
            on_line: this.onLine,
            webdriver: this.webdriver,
            device_memory: this.deviceMemory,
            lat: this.lat,
            lon: this.lon,
        };
    }

    searchFor(term) {
        if (typeof term === "number") term = term.toString();

        if (!term) return true;
        if (term.length < 3) return true;

        term = term.toLowerCase();

        if (this.maxTouchPoints?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.hardwareConcurrency?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.appCodeName?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.appName?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.appVersion?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.ipAddress?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.vendorSub?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.productSub?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.vendor?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.platform?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.product?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.userAgent?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.language?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.mediaDevices?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.deviceMemory?.toString().toLowerCase().indexOf(term) >= 0) return true;

        if (this.city?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.country?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.region?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.path?.toString().toLowerCase().indexOf(term) >= 0) return true;

        if (this.lat?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.lon?.toString().toLowerCase().indexOf(term) >= 0) return true;

        return (term === this.id?.toLowerCase());
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => WebVisitorModel.create(json));
    }

    static getVisitorDeviceName(userAgent, defaultName = "Web Browser") {
        const path = "";
        if (!userAgent) return defaultName;

        if (userAgent.indexOf("Googlebot") >= 0) return "Google Bot";
        if (userAgent.indexOf("HeadlessChrome") >= 0) return "Chrome Pre-Fetch";
        if (userAgent.indexOf("iPhone") >= 0) return "iPhone";
        if (userAgent.indexOf("iPad") >= 0) return "iPad";
        if (userAgent.indexOf("iPod") >= 0) return "iPod";
        if (userAgent.indexOf("Windows Phone") >= 0) return "Windows Phone";
        if (userAgent.indexOf("BlackBerry") >= 0) return "BlackBerry";
        if (userAgent.indexOf("Macintosh") >= 0) return "MacBook";
        if (userAgent.indexOf("Windows") >= 0) return "Windows";
        if (userAgent.indexOf("Android") >= 0) return "Android";
        if (userAgent.indexOf("Linux") >= 0) return "Linux";

        return defaultName;
    }
    
    static isBot(userAgent) {
        if (!userAgent) return false;
        
        userAgent = userAgent.toLowerCase();
        
        if (userAgent.indexOf("googlebot") >= 0) return true;
        if (userAgent.indexOf("headlesschrome") >= 0) return true;
        
        return false;
    }
}

export default WebVisitorModel;
