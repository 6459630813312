import React, {useEffect, useRef} from 'react';

const LightSimpleMasterScreen = (props) => {
    const { onDateChange, onDateChangePress, defaultDate, title } = props;
    let body = document.body;
    let dt = useRef(defaultDate || null);
    
    body.className = "light";
    
    const onHeaderDateChange = (field, e) => {
        if (field === "date" && typeof onDateChange === 'function') onDateChange(e);
        if (field === "button" && typeof onDateChangePress === 'function') onDateChangePress(dt.current?.value, e);
    }
    
    useEffect(() => {
        if (dt.current) {
            dt.current.value = defaultDate;
        }
    }, []);
    
    return (
        <div id={"light-master-screen"}>
            <h1 className={"flex"}>
                <span>{ title || "Roadmap"}</span>
                <span style={{fontSize: "16px", display: "inline-block", paddingTop: "8px", paddingLeft: "32px"}}>
                    Start Date:
                </span>
                <span>
                    <input ref={dt} type={"date"} onChange={onHeaderDateChange.bind(this, "date")} />
                </span>
                <span>
                    <button onClick={onHeaderDateChange.bind(this, "button")}>
                        Update
                    </button>
                </span>
            </h1>
            
            {props.children}
        </div>
    );

};

export default LightSimpleMasterScreen;

