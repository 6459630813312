import React, {useEffect, useRef, useState} from 'react';
import FormButton from "../../common/ui/FormButton";
import CommentService from "../services/CommentService";
import CommentModel from "../models/CommentModel";
import CommentLineItem from "./CommentLineItem";
import DialogBox from "../../common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../common/ui/dialog-box/DialogBoxController";

const CommentForm = (props) => {
    const {entityId, entityType, id, onComplete, onError, onSubmit, onChange, header, hideHeader} = props;

    let [isDisabled, setIsDisabled] = useState(false);
    let [comments, setComments] = useState([]);

    let deleteController = new DialogBoxController("Delete Comment");

    const getCommentsAsync = async (force) => {
        await CommentService.instance.getCommentsAsync(entityId, entityType).then((c) => {
            setComments(c);
        });
    };

    const promptForDelete = (comment, e) => {
        console.log('Comment Text: ' + comment?.text);
        deleteController.payload = comment;
        deleteController.open(comment, "Delete Comment");
    };
    
    const deleteCommentAsync = async (e) => {
        let commentId = deleteController.payload?.id || null;
        
        if (!commentId) {
            console.warn("Failed to delete comment. No comment id included");
            return;
        }
        
        let cs = comments.filter((c) => c.id !== commentId);
        
        await CommentService.instance.deleteCommentAsync(commentId).then((x) => {
            deleteController.close();
        }).catch((ex) => {
            console.warn("Failed to delete comment. No comment id included");
        });
        
        setComments(cs);
    };
    
    let textRef = useRef();

    useEffect(() => {
        let _ = getCommentsAsync(false);
    }, []);

    if (typeof entityType !== 'number') return (<></>);
    
    let elementId = id || "comment-form-" + entityType + "-" + entityId;

    const onTextChange = (fieldId, event) => {
        let text = textRef.current?.value ?? "";
        if (typeof onChange === 'function') {
            onChange(fieldId, event);
        }
    };

    const onCommentSubmitAsync = async (e) => {
        let text = textRef.current?.value ?? "";
        if (text.trim().length === 0) return;

        if (typeof onSubmit === 'function') {
            let cont = onSubmit(text);
            if (cont === false) return;
        }

        setIsDisabled(true);

        let comment = new CommentModel({text: text, entity_id: entityId, entity_type: entityType});
        
        return await CommentService.instance.postCommentAsync(comment).then((c) => {
            textRef.current.value = "";
            setIsDisabled(false);
            
            setTimeout(() => {
                getCommentsAsync(true);
            }, 100);
            
            return c;
        }).catch((ex) => {
            setIsDisabled(false);
        });
    };

    let commentElements = comments.map((c, index) => {
        return (<CommentLineItem onDelete={promptForDelete.bind(this, c)} index={index} comment={c} key={c.id + "-" + index.toString()}/>);
    });

    const commentsTitle = hideHeader !== true ? (<h4 className={"comments comments-" + entityType?.toString()}>{ header || "Post a Comment"}</h4>) : null;
    
    return (
        <div className={"comments"} id={elementId}>
            { commentsTitle }
            
            <div className={"comment-form form"} id={elementId + "-form"}>
                <textarea ref={textRef} disabled={isDisabled} onChange={onTextChange.bind(this, "text")}
                          className={"comment-textarea"} id={elementId + '-textarea'}></textarea>
                <FormButton id={elementId + '-button'} onClick={onCommentSubmitAsync}>Post</FormButton>
            </div>

            {commentElements}

            <DialogBox controller={deleteController} onOkay={deleteCommentAsync}>
                Delete this comment?
            </DialogBox>
            
        </div>);

};

export default CommentForm;
