import React, { useRef, useState, useEffect } from 'react';
import {faArrowTrendUp, faCreditCardAlt, faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import ToolsSubMenu from "../../components/tools/ui/ToolsSubMenu";
import ToolsMasterPage from "./ToolsMasterPage";

const TextToolsScreen = (props) => {
    const { } = props;
    const [resultText, setResultText] = useState("");
    const textRef = useRef();
    const methodNameRef = useRef();
    const resultTextRef = useRef();

    const pageKey = "freelance";

    const createFileName = (text) => { 
        return text.replace(/[=\\/:*?"<>|]/g, " ").trim().replace(/\s+/g, ' ').replace(/ /g, '-').toLowerCase();
    };

    const countWordsInText = (text) => {
        const words = text.replace(/\s+/g, ' ').trim().split(" ");
        return "Word Count: " + words.length;
     };

    const convertPhotoShopScriptBadVariables = (text) => {

        //aply the regex and replace :: with _
        
        return text.replaceAll("idspl::", "idspl__").replaceAll(' """', '"\\"').replaceAll('""" ', '\\""'); 
    };
    
    const methods = {};
    methods.createFileName = createFileName;
    methods.countWordsInText = countWordsInText;
    methods.convertPhotoShopScriptBadVariables = convertPhotoShopScriptBadVariables;

    const convertText = (e) => {
        if (!textRef.current) { 
            console.log("No text ref");
            return;
        }

        const text = textRef.current.value;
        if (!text) return;

        const methodName = methodNameRef.current.value;
        
        if (typeof methods[methodName] !== "function") { 
            console.log("No method");
            return;
        }

        const result = methods[methodName](text);
        resultTextRef.current.value = result;
    };

    const subtitle = (<><span className={"pre-subtitle"}>Tools:</span> Freelance Tools</>);
    const subMenu = (<ToolsSubMenu selection={pageKey} />);

    return (
        <ToolsMasterPage selection={pageKey} icon={faPeopleCarryBox} subMenu={subMenu} subTitle={subtitle}>
            <p>
                Various Text and String manipulation tools.
            </p>

            <div className="form">
                <div className="form-group">
                    <label>Function(text)</label>                    
                    <select ref={methodNameRef}>
                        <option value="createFileName">Create File Name</option>
                        <option value="countWordsInText">Count the Amount of Words in Text</option>
                        <option value="convertPhotoShopScriptBadVariables">Convert PhotoShop Script Bad Variables</option>
                    </select>
                </div>
                
                <div className="form-group">
                    <label htmlFor="name">Enter Text</label>
                    <textarea id="tool-text" ref={textRef} className="text-tool" />
                </div>
                <div className="buttons">
                    <button className={"long-button"} onClick={ convertText }>Convert</button>
                </div>

                <div className="form-group">
                    <label htmlFor="name">Result</label>
                    <textarea id="tool-text-result" className="text-tool" ref={resultTextRef} />
                </div>
                
            </div>
        </ToolsMasterPage>
    );
};

export default TextToolsScreen;
