class ShortUrlModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.companyId = json.company_id;
        this.userId = json.user_id;
        this.companyDomainId = json.company_domain_id;
        this.path = json.path;
        this.redirectUrl = json.redirect_url;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            path: this.path,
            redirect_url: this.redirectUrl,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ShortUrlModel(json));
    }
}

export default ShortUrlModel;
