class SystemErrorModel {
    static domainMap = {
        qrblocksweb: "qrblocks.io",
        api: "dark.penumbralabs.io",
        shorturlweb: "*.qrbk.io",
    };
    
    constructor(json) {
        if (!json) json = {};

        this.id = json.id || "";
        this.companyId = json.company_id || null;
        this.userId = json.user_id || null;
        this.ipAddress = json.ip_address || null;
        this.message = json.message || "";
        this.status = json.status || 0;
        this.userAgent = json.user_agent || "";
        this.requestPath = json.request_path || "";
        this.endpoint = json.endpoint || "";
        this.webSession = json.web_session || "";
        this.domainName = json.domain_name || "";
        this.httpStatus = json.http_status || 0;
        this.errorCode = json.error_code || 0;
        this.errorCodeName = json.error_code_name || "Unknown";
        this.preview = this.message.length > 24 ? this.message.substring(0, 24) + "..." : this.message;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
        
        if (!this.domainName && !!this.endpoint && this.endpoint.indexOf(".") > 0) { 
            const tokens = this.endpoint.split(".");
            if (tokens.length > 1) {
                this.domainName = tokens[1] + " API: ";
            }
        }
        
        try { 
            const ex = JSON.parse(json.message);
            this.preview = ex.message;
        } catch (exception) { 
            //
        }
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new SystemErrorModel(json));
    }
}

export default SystemErrorModel;
