/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react';
import PagerController from "../../common/pager/PagerController";
import ActivityService from "../services/ActivityService";
import UserModel from "../../people/models/UserModel";
import {faBriefcase, faCopy, faExplosion, faUser} from "@fortawesome/free-solid-svg-icons";
import CompanyModel from "../../companies/models/CompanyModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "../../common/ui/DateTime";
import EmptyBox from "../../common/ui/EmptyBox";
import LoadingBox from "../../common/ui/LoadingBox";
import Pager from "../../common/pager/Pager";
import "./Activity.css";
import DialogBox from "../../common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../common/ui/dialog-box/DialogBoxController";
import CopyText from "../../common/ui/CopyText";

const ErrorsTable = (props) => {
    const { company, companyId, userId, onLoad, pageSize, controller } = props;
    const [errors, setErrors] = useState(ActivityService.instance.errorsMap[companyId || ""] || []);
    const [dateRange, setDateRange] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(currentPage, pageSize || 24, setCurrentPage)).current;
    const dialogBoxController = useRef(new DialogBoxController("", "")).current;
    
    let _;

    const refreshAsync = async (force) => {
        if (!!userId && userId.length > 30) _ = getCompanyUserErrorsAsync(force);
        else if (!!companyId) _ = getSystemErrorsAsync(force);        
    };
    
    if (!!controller) { 
        controller.refreshAsync = async () => {
            console.log("Refreshing errors...");
            return await refreshAsync(true);
        };
    }
    
    const getSystemErrorsAsync = async (force) => {
        if (!force && errors?.length > 0) {
            if (typeof onLoad === 'function') onLoad(errors);
            return;
        }

        return await ActivityService.instance.getSystemErrorsAsync(companyId, dateRange?.startDate, dateRange?.endDate).then((errs) => {
            setErrors(errs);
        });
    };

    const getCompanyUserErrorsAsync = async (force) => {
        if (!force && errors?.length > 0)
            return;

        return await ActivityService.instance.getCompanyUserErrorsAsync(userId, companyId).then((errs) => {
            setErrors(errs);
        });
    };


    const createDialogBody = (systemError) => {
        let rawError = systemError.message;
        try { 
            const json = JSON.parse(rawError);
            if (!!json) rawError = JSON.stringify(json, null, 4);
        } catch (e) {
            //
        }
        
        const requestPathElement = !!systemError.requestPath ? (<span className={"system-error-header request-path"}>Path: { systemError.requestPath || "."}</span>) : null;
        const ipElement = !!systemError.ipAddress ? (<span className={"system-error-header ip-address"}>IP: <CopyText text={systemError.ipAddress} copiedLabel={"Copied IP Address"} label={systemError.ipAddress} /></span>) : null;
        const userIdElement = !!systemError.userId ? (<span className={"system-error-header user-id"}>UserId: <CopyText text={systemError.userId} label={systemError.userId} /></span>) : null;
        
        return (<span className={"dialog-system-error-content"}>
            { ipElement }
            { userIdElement }
            { requestPathElement }
            <span className={"system-error-header user-agent"}>{ systemError.userAgent || "No User Agent Detected"}</span>
            <span className={"system-error-body"}>
                <pre>{ rawError }</pre>
                <div className={"copy-float"}>
                    <CopyText text={rawError} />
                </div>
            </span>
        </span>);
    };

    const openErrorDialog = (systemError) => {
        const body = createDialogBody(systemError);
        
        //controller.setTitle(systemError.created);
        dialogBoxController.open(systemError, (<DateTime value={systemError.created} time={true} />), body);
    };

    useEffect(() => {
        console.log("UseEffect: ErrorTable");
        if (!!errors && typeof onLoad === 'function')
            onLoad(errors);

    }, [errors]);

    useEffect(() => {
        refreshAsync();
    }, []);

    const getIcon = (action) => {
        switch(action) {
            case UserModel.entityType:
                return faUser;
            case CompanyModel.entityType:
                return faBriefcase;
            default:
                return faExplosion;
        }
    };

    const elements = pagerController.mapLineItems(errors, (item, i) => {
        const icon = getIcon(item.entityType) ?? faExplosion;

        return (<tr key={"error-item-" + i} className={"error-tr error-tr-" + item.entityType}>
            <td>
                <FontAwesomeIcon icon={icon} />
                <a onClick={() => openErrorDialog(item)}>{item.preview}</a>
            </td>
            <td>{ item.httpStatus }</td>
            <td>{ item.userAgent }</td>
            <td><DateTime value={item.created} time={true} /></td>
        </tr>);
    });

    const cn = "system-errors";

    if (errors?.length === 0) return (<EmptyBox>No Errors Logged. Good.</EmptyBox>);
    else if (errors === null) return (<LoadingBox />);

    return (<div className={cn}>
        <table className={"table-x-large activity"} width={"100%"}>
            <thead>
            <tr className={"error-tr"}>
                <th>Error</th>
                <th>HttpCode</th>
                <th>UserAgent</th>
                <th>Time</th>
            </tr>
            </thead>

            <tbody>
            { elements }
            </tbody>
        </table>

        <Pager controller={pagerController} items={errors} />
        <div id={"error-table-dialog-holder"}><DialogBox controller={dialogBoxController} hasCancelButton={false} /></div>
    </div>);
    
};


export default ErrorsTable;