import HttpService from "../../common/services/HttpService";
import SessionModel from "../models/SessionModel";
import ApiAuthModel from "../models/ApiAuthModel";

export default class AuthenticationService {
    static sessionKey = HttpService.sessionKey;
    static instance = new AuthenticationService();

    static hasValidSession() {
        return !!this.instance.session && this.instance.session.user !== null;
    }

    constructor() {
        let me = this;

        this.session = null;
        this.sessionId = localStorage.getItem(AuthenticationService.sessionKey);
        this.isGetting = false;

        this.onAuthenticate = (session) => {
            console.log("OnAuthenticate: " + session);
        };
        
        this.onSessionUpdate = (session, message) => {
            //console.log('Useless Session update: ' + message);
        };

        /**
         * Does something, then calls the onSessionUpdate callback.
         * @param err
         * @returns {boolean}
         */
        this.onSessionError = (err) => {
            console.error('Failed to get session: ' + err);
            console.warn(JSON.stringify(err, null, 4));
            return true;
        };

        if (!this.sessionId || this.sessionId.length < 10) this.sessionId = null;
        
        HttpService.instance.onUnauthorizedResponse = (errorResponse) => {
            if (!errorResponse) { 
                return errorResponse;
            }
            
            let result = me.onSessionError(errorResponse);
            
            if (result !== false) {
                let _ = me.signOutAsync();
            }
            
            return errorResponse;
        };

        HttpService.instance.setSessionId(this.sessionId);
        this.getSessionAsync(false).then((rsp) => this.setSessionJson(rsp?.data));
    }

    init(key) { 
        //
    }
    
    /**
     * Fires every time the session state updates (new session, general update, signout, etc)
     * @param session
     * @param message
     */
    onSessionEvent(session, message) {
        this.setHttpSession(session);
        let _ = this.getCurrentUserApiAuthCredentialsAsync();
        
        if (typeof this.onSessionUpdate === 'function') {
            this.onSessionUpdate(session, message);
        }
    }
    
    setHttpSession() {
        HttpService.instance.setSessionId(this.sessionId || null);
        return (!!this.sessionId);
    }

    /**
     * Does the following:
     *      1. Creates and sets the SessionModel from the server json response
     *      2. Stores the session.id in local storage
     *      3. Sets the HttpService.sessionId to the session.id
     *      4. Calls the onSessionUpdate callback (if it exists as a function)
     * @param json (object) - Server json response
     */
    setSessionJson(json) {
        this.session = !!json ? new SessionModel(json) : null;
        this.sessionId = !!this.session ? this.session.id : this.sessionId;

        HttpService.instance.setSessionId(this.sessionId);
        localStorage.setItem(AuthenticationService.sessionKey, this.sessionId);
        
        this.onSessionEvent(this.session, 'auth');
    }

    /**
     * Clears session data and invalidates the session on the server side.
     * @returns {Promise<void>}
     */
    async signOutAsync() {
        if (!this.sessionId) return;
        
        const path = '/api/auth';
        
        await HttpService.instance.deleteAsync(path).catch((ex) => {
            console.warn("Failed to delete session, but continuing anyway: " + ex?.response?.data?.message);
        });

        this.session = null;
        this.sessionId = null;
        this.isGetting = false;

        HttpService.instance.sessionId = null;
        localStorage.removeItem(AuthenticationService.sessionKey);

        this.onSessionEvent(null, 'signout');
    }

    async getUserSessionsAsync(userId, companyId) { 
        const path = "/api/company/" + companyId + "/user/" + userId + "/session";
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return rsp.data.map((sh) => new SessionModel(sh));
        });
    }
    
    /**
     * Logs in the user with the given credentials.
     * @param username {string} - Username to log in with
     * @param password {string} - Password to log in with
     * @returns {Promise<string|AxiosResponse<any>>}
     */
    async authenticateAsync(username, password) {
        let error = '';
        if (!username) error += 'Username is required. ';
        if (!password) error += 'Password is required. ';

        if (!!error) {
            return error;
        }

        // TODO: Move to HttpService
        const me = this;
        const url = '/api/auth';

        let data = {
            username: username,
            password: password,
            captcha: ''
        };

        return HttpService.instance.postAsync(url, data).then((rsp) => {
            if (!rsp?.data) {
                let message = null;
                
                if (rsp?.response?.data) {
                    throw new Error(rsp.response.data?.message || rsp.response.data.messages[0]);
                } else if (rsp?.message) {
                    message = "msg: " + rsp.message;
                }
                
                throw new Error(message || ('Invalid response from server: ' + JSON.stringify(rsp)));
            }
            
            me.setSessionJson(rsp.data);
            me.isGetting = false;
            me.onAuthenticate(me.session);

            return me.session;
        }).catch((err) => {
            me.isGetting = false;
            
            if (typeof me.onSessionError === 'function') 
                me.onSessionError(err);
            
            throw err;
        });
    }

    isLoggedIn() {
        return !!this.sessionId && this.sessionId.length > 10;
    }

    /**
     * Gets the current user's session object, including useful account data.
     * @param force {boolean} - Will make a server call, even if the session object is already set locally.
     * @returns {Promise<null|AxiosResponse<any>>}
     */
    async getSessionAsync(force) {
        if (!!this.session?.user?.auth?.id && !force) {
            console.log('Got from cache. Good.');
            HttpService.instance.setSessionId(this.session.id);
            
            if (typeof this.onSessionEvent === 'function') {
                this.onSessionEvent(this.session, 'auth');
            }
            
            return this.session;
        }

        if (this.isGetting === true) {
            //console.warn('Getting. So aborting.');
            return this.session;
        }

        if (!this.sessionId) {
            //console.error('Failed to get session. Session is no good: ' + this.sessionId);
            return this.session;
        }

        this.isGetting = true;

        const me = this;
        const url = '/api/auth/' + this.sessionId;

        HttpService.instance.setSessionId(this.sessionId);

        return HttpService.instance.getAsync(url).then((rsp) => {
            me.setSessionJson(rsp.data);
            me.isGetting = false;

            return me.session;
        }).catch((err) => {
            me.isGetting = false;
            me.onSessionError(err);
        });
    }

    /**
     * Gets valid password reset data object. Used to determine if the user is allowed to reset their password (Fail if it's expired or otherwise used up already)
     * @param resetId {string}- Guid of the password reset object
     * */
    async getAccountResetAsync(resetId) {
        const me = this;
        const url = '/api/user/account/' + resetId;

        return await HttpService.instance.getAsync(url).then((rsp) => {
            return rsp.data;
        });
    }

    /**
     * Sends a password reset email to the user's email address. If a companyId is included, it will use the company's email template and auth context
     * @param userId {string} - User's id {Guid}
     * @param companyId {string|null} - Company's id {Guid} optional
     * @param options {object[minutes:int]|null} - Optional parameters
     * @returns {AxiosResponse<any>}
     */
    async sendPasswordResetAsync(userId, companyId = null, options = {}) {
        const me = this;
        const qs = options?.minutes > 0 ? "?minutes=" + options.minutes.toString() : "";
        const url =  (typeof companyId === "string" && companyId.length > 30) ?
            "/api/company/" + companyId + "/auth/" + userId + "/password" + qs :
            "/api/auth/" + userId + "/password" + qs;

        console.warn("URL: " + url);
        if (!qs) console.error(JSON.stringify(options, null, 4));
        
        return await HttpService.instance.postAsync(url, {}).then((rsp) => {
            return rsp.data;
        });
    }
    
    async reSendWelcomeEmailAsync(userId, companyId = null) {
        let url = (!!companyId && companyId.toString().length > 30) ?
            "/api/company/" + companyId + "/user/" + userId + "/message/welcome" :
            "/api/user/" + userId + "/message/welcome";

        return await HttpService.instance.postAsync(url).then((rsp) => {
            return rsp.data;
        });
    }
    
    /**
     * Get list of api creds for all API Types (Google OAuth, Shortcut, Trello, etc)* for all companies
     * @returns {Promise<[ApiAuthModel]>}
     */
    async getCurrentUserApiAuthCredentialsAsync() {
        const url = "/api/api-authentication";
        const me = this;

        console.log("Getting Current User API Auth Credentials (getCurrentUserApiAuthCredentialsAsync)");
        return await HttpService.instance.getAsync(url).then((rsp) => {
            let models = ApiAuthModel.fromJsonArray(rsp.data);
            
            if (me.session !== null)
                me.session.apiMap = ApiAuthModel.toMap(models);
            
            return models;
        });
    }
    
    async getApiAuthsByCompaniesAsync(companyIds) {
        if (typeof companyIds === 'string' && companyIds.length === 36)
            companyIds = [companyIds];

        if (!companyIds || !Array.isArray(companyIds)) return [];
        
        const url = "/api/api-authentication?company-ids=" + companyIds.join(',');
        const me = this;

        //console.log(url);
        console.log("Getting Company API Auth Credentials (getApiAuthsByCompaniesAsync)");
        return await HttpService.instance.getAsync(url).then((rsp) => {
            const models = rsp?.data ? ApiAuthModel.fromJsonArray(rsp.data) : [];
            if (models.length > 0 && !!me.session?.id) {
                me.session.apiMap = ApiAuthModel.toMap(models);
            }
            
            return models;
        });
    }
    
    /**
     * Updates the user's password given the reset data object id
     * @param resetId - Guid of the password reset object
     * @param password - New password to set
     * @returns {Promise<AxiosResponse<Any>>}
     * */
    async updateAccountPasswordAsync(resetId, password) {
        const me = this;
        const url = '/api/user/account/' + resetId;

        return HttpService.instance.postAsync(url, { value: password}).then((rsp) => {
            return rsp.data;
        });
    }

    /**
     * Saves the AuthToken to the server 
     * @param userId
     * @param code
     * @param hd
     * @param scope
     * @returns {Promise<AxiosResponse<any>|void>}
     */
    async updateGoogleOAuthTokenAsync(userId, code, hd, scope) { 
        const url = "/api/google/token";
        const me = this;
        
        if (!scope) scope = null;
        if (!hd) hd = null;
        
        let data = {
            code: code,
            hd: hd,
            scope: scope
        };
        
        return await HttpService.instance.postAsync(url, data).then((rsp) => {
            //
            return null;
        }).catch((ex) => { 
            console.warn("Failed to save token: " + ex);
            throw ex;
        });
    }

    async saveUserApiAsync(api) {
        if (!this.session?.user) { 
            throw new Error("No user session");
        }

        const data = {
            api_type: api.apiType || api.api_type,
            api_auth_type: api.apiAuthType || api.api_auth_type,
            entity_type: api.entityType || api.entity_type,
            api_key: api.data,
            account_name: api.accountName || api.account_name
        };

        if (!data.api_key) throw new Error("No API Key");

        const path = "/api/user/" + this.session.user.id + "/api";
        const me = this;

        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new ApiAuthModel(response.data);
        });
    };    
    
    async deleteIntegrationAsync(integrationId) {
        const url = "/api/api-authentication/" + integrationId;
        const me = this;

        return await HttpService.instance.deleteAsync(url).then((rsp) => {
            return rsp.data;
        }).catch((ex) => {
            console.warn("Failed to delete api auth connection: " + ex);
            throw ex;
        });
    };
    
}
