import React, {useEffect, useRef, useState} from 'react';
import FormButton from "@jadecharles/pi-react-packages/dist/common/forms/FormButton";
import "./Payments.css";
import CreditCardModel from "@jadecharles/pi-react-packages/dist/finance/models/CreditCardModel";

const CreditCardForm = (props) => {
    const { onChange, useZip, useBillingZip, creditCard, onSubmit, useButton, buttonCaption, clearOnComplete, maskOnComplete, controller } = props;
    const [ errors, setErrors ] = useState({});
    const [ cardType, setCardType ] = useState(0);
    
    const numberRef = useRef();
    const nameRef = useRef();
    const expirationMonthRef = useRef();
    const expirationYearRef = useRef();
    const cvvRef = useRef();
    const zipRef = useRef();
    
    const validateForm = (e) => {
        let er = {};
        
        if (!nameRef.current?.value || nameRef.current.value.trim().length < 1) er.name = "Name is missing or invalid";
        
        if (!numberRef.current?.value || numberRef.current.value.trim().length < 15)
            er.number = "Card number is missing or invalid";
        else if (numberRef.current.value.indexOf("*") > -1)
            er.number = "You must include the full card number";
        
        if (!cvvRef.current?.value || cvvRef.current.value.trim().length < 3 || isNaN(parseInt(cvvRef.current.value.trim())) )
            er.cvv = "CVV is required";
        
        if (useBillingZip) {
            if (!zipRef.current?.value || zipRef.current.value.trim().length < 5)
                er.billing_zip = "Billing zipcode is invalid or missing";
        }
        
        const m = parseInt(expirationMonthRef.current?.value || "0");
        const y = parseInt(expirationYearRef.current?.value || "0");
        const d = Date.parse(m.toString() + "/1/" + y.toString());
        
        if (isNaN(d)) er.expiration_month = "Expiration date is invalid";
        else if (new Date(d).addMonths(1) < new Date()) er.expiration_month = "Card is expired";
        
        if (Object.keys(er).length === 0) {
            setErrors({});
            return true;
        }
        
        console.log("Errors: " + JSON.stringify(er));
        setErrors(er);
        
        return false;
    };

    const getMask = (s, len) => { 
        if (!s) return "";
        
        if (typeof s === 'number') s = s.toString();
        if (typeof s !== 'string') return "";
        if (typeof len !== 'number' || len < 3) len = 3;
        
        if (s.length <= len) return Array(s.length + 1).join("*");

        const maskLen = s.length - len;
        const mask = (s.length === 16) ? "**** **** ****" : (s.length === 15) ? "**** **** **** *" : Array(maskLen).join("*");
        return mask + " " + s.substr(maskLen);
    }
    
    const setMasks = () => {
        if (!!numberRef.current?.value)
            numberRef.current.value = getMask(numberRef.current?.value, 4);

        if (!!cvvRef.current)
            cvvRef.current.value = getMask(cvvRef.current?.value, 4);
        
        clearErrors();
    };
    
    const clearErrors = () => { 
        setErrors({});
    }
    
    const clearForm = () => {
        if (!!nameRef.current) nameRef.current.value = "";
        if (!!numberRef.current) numberRef.current.value = "";
        if (!!expirationMonthRef.current) expirationMonthRef.current.value = "";
        if (!!expirationYearRef.current) expirationYearRef.current.value = "";
        if (!!cvvRef.current) cvvRef.current.value = "";
        if (!!zipRef.current) zipRef.current.value = "";
        
        clearErrors();
    };
    
    const createJson = () => {
        return {
            number: numberRef.current?.value || "",
            name: nameRef.current?.value || "",
            expiration_month: expirationMonthRef.current?.value || "",
            expiration_year: expirationYearRef.current?.value || "",
            cvv: cvvRef.current?.value || "",
            billingZip: zipRef.current?.value || ""
        };
    };

    const handleChange = (fieldId, e) => {
        if (!!numberRef.current?.value && numberRef.current.value.length > 1) { 
            const c = parseInt(numberRef.current.value.substring(0, 1));
            if (!isNaN(c)) setCardType(c);
        }
        
        if (typeof onChange === 'function') {
            const card = new CreditCardModel(createJson());
            onChange(card);
        }
        
        if (Object.keys(errors).length > 0) clearErrors();
    };

    const handleError = (ex) => {
        const data = ex?.response?.data;
        const er = { };

        if (!data?.message) {
            er.general = "An error occurred while processing your request (" + ex + ")";
            setErrors(er);
            return;
        }

        if (!!data.objects && Array.isArray(data.objects)) {
            for(let i = 0; i < data.objects.length; i++) {
                const obj = data.objects[i];
                if (!!obj.field_name && !!obj.message) er[obj.field_name] = obj.message;
            }
        } else {
            er.general = data.message;
        }

        setErrors(er);
    }
    
    const handleSubmit = async (e) => {
        if (!validateForm(e)) return null;
        
        const json = createJson()
        
        if (typeof onSubmit === 'function') {
            const card = new CreditCardModel(json);
            const rsp = onSubmit(card);
            
            if (typeof rsp.then !== 'function') return rsp;
            
            return await rsp.then((x) => {
                if (clearOnComplete === true && x === true) clearForm();
                else if (maskOnComplete === true) setMasks();
                
                return x;
            }).catch((ex) => { 
                handleError(ex);
                return false;
            });
        }
        
        return null;
    };

    useEffect(() => {
        console.log("CardType: " + cardType);
        if (!!controller) controller.update = () => setMasks();
        
    }, [cardType]);
    
    const submitElement = useButton === true ? (
        <div className="buttons">
            <FormButton id="credit-card-update-button" onClick={handleSubmit}>
                { buttonCaption || "Update Card" }
            </FormButton>
        </div>
    ) : (<></>);

    const thisYear = new Date().getFullYear();
    const expirationMonths = [...Array(12).keys()].map(m => (<option key={'month-' + m} value={(m + 1).toString().padStart(2, '0')}>{(m + 1)}</option>));
    const expirationYears = [...Array(10).keys()].map(y => (<option key={ 'year-' + y} value={y + thisYear}>{y + thisYear}</option>));

    const billingZipElement = (useBillingZip === true || useZip === true) ? 
        (<div className="form-group">
            <label htmlFor="billing-zip">Billing Zipcode:</label>
            <input ref={zipRef} type="text" id="billing-zip" defaultValue={creditCard?.billingZip || creditCard?.billing_zip} onChange={handleChange.bind(this, "billing_zip")} />
            <div className={"form-error"}>{errors?.billing_zip}</div>
        </div>) :
        null;
    
    const getCardNumberMask = (number, mask = "*") => {
        if (!number || number.length < 4) return "";
        return "**** **** **** " + number.substr(number.length - 4);
    };
    
    const cardMask = getCardNumberMask(creditCard?.number);
    const expClass = !!errors?.cvv || !!errors?.expiration_year || !!errors?.expiration_month ? " show" : "";
    
    const numberLength = cardType === 3 ? 15 : 16;
    const cvvLength = cardType === 3 ? 4 : 3;
    
    return (
        <div className="form payment-form credit-card-form">
            <div className="form-group">
                <label htmlFor="cardholder-name">Cardholder's Name</label>
                <input type="text" id="cardholder-name" ref={nameRef} defaultValue={creditCard?.name} onChange={handleChange.bind(this, "name")} maxLength={64} />
                <div className={"form-error"}>{errors?.name}</div>
            </div>

            <div className="form-group">
                <label htmlFor="card-number">Credit Card Number</label>
                <input type="text" id="card-number" ref={numberRef} defaultValue={cardMask} onChange={handleChange.bind(this, "number")} maxLength={numberLength} />
                <div className={"form-error"}>{errors?.number}</div>
            </div>

            <div className="form-group triple">
                <div className={"minority expiration-month"}>
                    <label htmlFor="expiration-month">Expires</label>
                    <select id="expiration-month" ref={expirationMonthRef} defaultValue={creditCard?.expirationMonth || creditCard?.expiration_month} onChange={handleChange.bind(this, "expiration_month")}>
                        <option value="">MM</option>
                        {expirationMonths}
                    </select>
                    <div className={"form-error" + expClass}>{errors?.expiration_month || (errors?.expiration_date || errors?.expiration)}</div>
                </div>
                <div className={"minority expiration-year"}>
                    <label>&nbsp;</label>
                    <select id="expiration-year" ref={expirationYearRef} defaultValue={creditCard?.expirationYear || creditCard?.expiration_year} onChange={handleChange.bind(this, "expiration_year")}>
                        <option value="">YYYY</option>
                        {expirationYears}
                    </select>
                    <div className={"form-error" + expClass}>{errors?.expiration_year}</div>
                </div>
                
                <div className={"majority cvv"}>
                    <label htmlFor="cvv">CVV</label>
                    <input type="text" id="cvv" ref={cvvRef} onChange={handleChange.bind(this, "cvv")} maxLength={cvvLength} />
                    <div className={"form-error" + expClass}>{errors?.cvv}</div>
                </div>
            </div>

            {billingZipElement}

            <div className={"form-error" + expClass}>{errors?.general}</div>
            
            {submitElement}
        </div>
    );
};

export default CreditCardForm;