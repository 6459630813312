/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import ActivityService from "../services/ActivityService";
import ActivityItemModel from "../models/ActivityItemModel";
import DateTime from "../../common/ui/DateTime";
import PagerController from "../../common/pager/PagerController";
import Pager from "../../common/pager/Pager";
import EmptyBox from "../../common/ui/EmptyBox";
import LoadingBox from "../../common/ui/LoadingBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faEnvelope, faExplosion, faUser} from "@fortawesome/free-solid-svg-icons";
import UserModel from "../../people/models/UserModel";
import CompanyModel from "../../companies/models/CompanyModel";
import {Link} from "react-router-dom";
import "./Activity.css";
import ConfigModel from "../../common/models/ConfigModel";

const ActivityTable = (props) => {
    const { entityType, companyId, entityId, onLoad, emptyElement, showUser, options } = props;
    
    const key = ActivityItemModel.createKey(entityId, entityType);
    const [activities, setActivities] = React.useState([]);
    const [dateRange, setDateRange] = React.useState({ startDate: options?.startDate, endDate: options?.endDate });
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;

    let _;

    const setActivityItems = (items, count = -1) => {
        if (!Array.isArray(items)) return;
        
        if (count < 0) count = options?.count || 0;
        if (count > 0) items = items.filter((item, i) => i < count);
        setActivities(items);
    }
    
    const getActivityAsync = async (force) => {
        if (!force && activities.length > 0) {
            if (typeof onLoad === 'function') onLoad(activities);
            return;
        }
        
        if (typeof companyId === "string" && companyId.length > 30) { 
            return await ActivityService.instance.getCompanyActivityAsync(companyId, entityId, dateRange?.startDate, dateRange?.endDate).then((acts) => {
                setActivityItems(acts);
            });
        }
        
        return await ActivityService.instance.getActivityItemsAsync(entityId, entityType, dateRange?.startDate, dateRange?.endDate).then((acts) => {
            setActivityItems(acts);
        });
    };
    
    useEffect(() => {
        if (!!activities && typeof onLoad === 'function')
            onLoad(activities);
        
    }, [activities]);
    
    useEffect(() => {
        getActivityAsync(false);
    }, []);
    
    const getIcon = (item) => {
        switch (item.actionType) { 
            case 4:
                return faEnvelope;
            default:
                break;
        }

        const et = item.entityType;
        //const x = ActivityItemModel.create();

        switch(et) {
            case UserModel.entityType:
                return faUser;
            case CompanyModel.entityType:
                return faBriefcase;
            default:
                break;
        }

        return faExplosion;
    };
    
    const su = true; //(showUser === true);
    
    const convert = options?.reverse === true ?
        (text, userElement) =>  (<>{userElement} {text}</>) :
        (text, userElement) =>  (<>{text} {userElement}</>);
    
    const elements = pagerController.mapLineItems(activities, (item, i) => {
        const icon = getIcon(item) ?? faExplosion;
        const userElement = su ? (<span className={"updated-by-user"}><Link to={"/companies/" + (item.user.companyId || ConfigModel.emptyId) + "/people/" + item.userId}>{ item.user?.fullName }</Link></span>) : null;
        const cn = item.severityType > 2 ? " error-tr" : (item.severityType === 2 ? "warning-tr" : "");

        const messageElements = convert(item.text, userElement);
        
        return (<tr key={"activity-" + i} className={"activity-td-" + item.entityType + cn}>
            <td>
                <FontAwesomeIcon icon={icon} />
                { messageElements }
            </td>
            <td><DateTime value={item.created} time={true} options={{useTime: true}} useRelative={true} /></td>
        </tr>);
    });
    
    const cn = "activity-items-" + (entityType || 0).toString();
    
    if (activities?.length === 0) return emptyElement || (<EmptyBox>No Activity Recorded Yet</EmptyBox>);
    else if (activities === null) return (<LoadingBox />);
    
    const pagerElement = options?.pager !== false ? (<Pager controller={pagerController} items={activities} />) : null;
    
    return (<div className={cn} onDoubleClick={(e) => getActivityAsync(true)}>
            <table className={"table-x-large activity"} width={"100%"}>
                <thead>
                <tr>
                    <th>Event</th>
                    <th>Time</th>
                </tr>
                </thead>
        
                <tbody>
                { elements }
                </tbody>
            </table>

        { pagerElement }
        </div>);
};

export default ActivityTable;
