import HttpService from "../../common/services/HttpService";
import InvoiceModel from "../models/InvoiceModel";

class InvoiceService { 
    static instance = new InvoiceService();

    constructor() {
        this.invoicesMap = {};
        this.invoices = [];
        this.invoiceMap = {};
        this.getting = {};
    }

    async getInvoiceAsync(invoiceId, force = false) {
        const path = '/api/invoice/' + invoiceId;
        const me = this;

        if (force !== true && !!me.invoiceMap[invoiceId] && !me.getting[invoiceId]) {
            return me.invoiceMap[invoiceId];
        }
        
        me.getting[invoiceId] = true;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.invoiceMap[invoiceId] = InvoiceModel.create(response.data);
            delete me.getting[invoiceId];
            return me.invoiceMap[invoiceId];
        }).catch((ex) => {
            delete me.getting[invoiceId];
            throw ex;
         });
    }

    async getInvoicesAsync() {
        const path = '/api/invoice';
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.invoices = response.data.map((invoice) => {
                return new InvoiceModel(invoice);
            });

            return me.invoices;
        });
    }

    async getInvoicesByCompanyAsync(companyId) {
        const path = '/api/company/' + companyId + '/invoice';
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            let invoices = response.data.map((invoice) => {
                return new InvoiceModel(invoice);
            });
            
            me.invoicesMap[companyId] = invoices;
            
            return invoices;
        });
    }

    async uploadInvoiceDocumentsAsync(invoiceId, files) { 
        const path = "/api/invoice/" + invoiceId + "/document-upload";
        return await HttpService.instance.uploadAsync(path, files);
    }

    async deleteAttachmentDocumentAsync(documentId, invoiceId) { 
        if (typeof documentId !== "string" || documentId.length < 30) throw new Error("Invalid Document Id");
        if (typeof invoiceId !== "string" || invoiceId.length < 30) throw new Error("Invalid Invoice Id");

        const path = "/api/invoice/" + invoiceId + "/document/" + documentId;
        return await HttpService.instance.deleteAsync(path);
    }

    async clearPdfFilesAsync(invoiceId) { 
        const path = "/api/invoice/" + invoiceId + "/pdf";
        return await HttpService.instance.deleteAsync(path);
    }
    
    async createPaymentAsync(invoiceId, paymentJson) {
        if (typeof invoiceId !== "string" || invoiceId.length < 30) throw new Error("Invalid Invoice Id");
        if (typeof paymentJson?.total !== "number" || paymentJson?.total < 0)
            throw new Error("Invalid Payment Amount: " + paymentJson?.total + "");
        
        const path = '/api/invoice/' + invoiceId + '/payment';
        const me = this;
        
        return await HttpService.instance.postAsync(path, paymentJson).then((response) => {
            return new InvoiceModel(response.data);
        });
    }
    
    async deleteInvoiceAsync(invoiceId, companyId) {
        const path = "/api/invoice/" + invoiceId;
        const me = this;
        
        return await HttpService.instance.deleteAsync(path).then((response) => {
            if (!!companyId) {
                me.invoiceMap[companyId] = null;
                delete me.invoiceMap[companyId];
                
                setTimeout(() => {
                    me.getInvoicesByCompanyAsync(companyId);
                }, 100);
            }
        });
    }

    /**
     * @param {string} documentId
     * @param {string} invoiceId
     * 
     */
    getPdfDownloadUrl = (documentId, invoiceId) => {
        if (typeof documentId !== "string" || (documentId.length < 30 && !documentId.startsWith("000-"))) throw new Error("Invalid documentId: '" + documentId + "'");
        if (typeof invoiceId !== "string" || invoiceId.length < 30) throw new Error("Invalid invoiceId: '" + invoiceId + "'");

        //collectionId
        if (documentId === "000-001") return "/api/invoice/" + invoiceId + "/invoice-pdf";
        if (documentId === "000-002") return "/api/invoice/" + invoiceId + "/receipt-pdf";

        return "/api/invoice/" + invoiceId + "/document/" + documentId + "/download";
    };

    async downloadPdfAsync(documentId, invoiceId, doDownload = true) {
        const path = this.getPdfDownloadUrl(documentId, invoiceId);
        console.log("Downloaded Blob: " + path);

        const blobModel = await HttpService.instance.getBlobAsync(path);

        if (doDownload) { 
            HttpService.instance.downloadBlob(blobModel);
        }

        return blobModel;
    };
    
    async createInvoiceAsync(invoiceJson, companyId, viaEmail = false, viaPhone = false) {
        let path = '/api/company/' + companyId + '/invoice?';
        const me = this;
        const senders = [];

        if (viaEmail === true) senders.push("email=true");
        if (viaPhone === true) senders.push("phone=true");
        
        if (senders.length > 0) path += senders.join("&");
        
        console.log(path);
        console.warn(JSON.stringify(invoiceJson, null, 4));
        
        return await HttpService.instance.postAsync(path, invoiceJson).then((response) => {
            return new InvoiceModel(response.data);
        });
    }
    
    async resendInvoiceAsync(invoiceId, recipients = [], isReminder = false) {
        const ext = Array.isArray(recipients) && recipients.length > 0 ? "-to" : "";
        const qs = isReminder === true ? "?reminder=1" : "";
        const path = '/api/invoice/' + invoiceId + '/send' + ext + qs;
        const payload = !!ext ? recipients : {};

        console.log("Sending...");
        console.log(JSON.stringify(payload, null, 4));

        return await HttpService.instance.postAsync(path, payload).then((response) => {
            return new InvoiceModel(response.data);
        });
    }
}

export default InvoiceService;
