import React, {useEffect} from 'react';
import ProjectService from "../../services/ProjectService";
import './Roadmap.css';
import {Tooltip} from "@mui/material";
import ProjectModel from "../../models/ProjectModel";
import RoadmapDocumentItemCell from "./RoadmapDocumentItemCell";
import DialogBox from "../../../common/ui/dialog-box/DialogBox";
import ProjectItemForm from "../ProjectItemForm";
import DialogBoxController from "../../../common/ui/dialog-box/DialogBoxController";
import {Link} from "react-router-dom";

const RoadmapWeekly = (props) => {
    const {projects, startDate, controller, company } = props;
    let _;
    
    let [workDaysInAWeek, setWorkDaysInAWeek] = React.useState(5);
    let [selectedProjectItem, setSelectedProjectItem] = React.useState(null);
    let [movableProjectItemDay, setMovableProjectItemDay] = React.useState(null);
    let [selectedProjectItemEditor, setSelectedProjectItemEditor] = React.useState(null);
    let [projectItemDialogController, setProjectItemDialogController] = React.useState(new DialogBoxController("Edit Project Item"));
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const roadmapMeta = ProjectService.instance.createProjectRoadmapMetaData(projects, (projects.length === 1), workDaysInAWeek);
    const hasStartDate = roadmapMeta.roadmapStartDate instanceof Date;
    const today = (new Date()).toFormDate();

    let isSingle = (projects.length === 1);
    let roadmapStartDate = roadmapMeta.roadmapStartDate;
    
    const onProjectItemSubmitAsync = async (e) => {
        const data = projectItemDialogController.payload;
        let json = (typeof data?.toJson === 'function') ? data.toJson() : data;
        
        if (json.users) { 
            console.log("Users is there. Leaving.");
            return;
        }

        _ = await ProjectService.instance.saveProjectItemAsync(json).then((p) => {
            if (typeof onDoubleClick === "function") onDoubleClick(e);
        });
    }
    
    const deleteProjectItemAsync = async (projectItem, e) => {
        let id = projectItem?.id;
        if (!id) {
            console.error("No item id: " + id);
            return;
        }
        
        _ = await ProjectService.instance.deleteProjectItemAsync(id).then((p) => {
            if (typeof onDoubleClick === "function") onDoubleClick(e);
        });
    };
    
    const onProjectItemChangedInForm = (item, e) => {
        projectItemDialogController.payload = item;
    }
    
    projects.sort((a, b) => {
        let aDate = a.startDate?.getTime() || (a.modified?.getTime() || a.created?.getTime());
        let bDate = b.startDate?.getTime() || (b.modified?.getTime() || b.created?.getTime());
        
        return bDate - aDate;
    });

    /**
     * Sets the selected item, which contains only 1 .day list element, which was the one that was clicked.
     * @param projectItem
     * @param e
     * @returns {boolean}
     */
    const promptForItemEdit = (projectItem, e) => {
        setSelectedProjectItem(projectItem);
        return true;
    };

    const onSelectProjectItemDayForMove = (projectItemDay, e) => {
        setMovableProjectItemDay(projectItemDay);
    };
    
    const onWeekDayCellClick = (dayIndex, projectId, e) => {
        e.preventDefault();
        
        console.log("Cell Clicked: Day Index: " + dayIndex);
        //console.log("Project Item Id: " + projectItemId);
        console.log("ProjectId: " + projectId);
        
        if (!movableProjectItemDay) {
            console.log('No movable item day.');
        } else {
            let ps = projects.filter((p) => p.id === projectId);
            if (ps.length === 0) {
                console.warn("No Project Found For ProjectId: " + projectId);
                return;
            }
            
            let pItems = ps[0].items.filter((pi) => pi.id === movableProjectItemDay.projectItemId);
            if (pItems.length === 0) {
                console.warn("No Project Item Found For ProjectItemId: " + movableProjectItemDay.projectItemId);
                return;
            }
            
            let relativeDay = (dayIndex + 1) - (pItems[0].startDay + ps[0].startDay) + 2;
            console.log("Relative Day: " + relativeDay);

            ProjectService.instance.updateProjectItemDayAsync(movableProjectItemDay.id, relativeDay).then((resultModel) => {
                setMovableProjectItemDay(null);
                _ = controller?.refreshAsync().then(() => {
                });
            });
        }
    };

    const onProjectItemDayClick = (projectItemDay, projectItem, e) => {
        e.preventDefault();
        console.log("Project Item Day Clicked: " + projectItemDay?.id);
        // if (!!projectItemDay) {
        //     console.warn("Selected [" + projectItemDay.day + "] projectItemDayId: " + projectItemDay.id);
        //     setSelectedDay({...projectItemDay});
        // }
    };
    
    const onItemDayTooltipClose = (e) => {
        setAnchorEl(null);
    };
    
    const onDoubleClick = (e) => { 
        console.log("Double click");
        controller?.refreshAsync(e);
    };
    
    useEffect(() => {
        if (!!selectedProjectItem) {
            projectItemDialogController.open(selectedProjectItem, "Edit " + selectedProjectItem.name);
        }
    }, [selectedProjectItem]);

    const cellHeight = 64; //48;
    const cellHeightStyle = { minHeight: cellHeight.toString() + "px", height: cellHeight.toString() + "px"};
    const weekCellHeightStyle = { minHeight: (cellHeight * workDaysInAWeek).toString() + "px", height: (cellHeight * workDaysInAWeek).toString() + "px" };

    const toggleDayDate = (e) => {
        if (!hasStartDate || !e.currentTarget) {
            return;
        }
        
        let cn = e.currentTarget?.className;
        if (cn.endsWith(" date")) { 
            cn = cn.replace(" date", "");
        } else {
            cn += " date";
        }
        
        e.currentTarget.className = cn;
    };
    
    const getWeeklyOffsetDate = (dayIndex, globalStartDate, weeklyWorkDayCount = 5) => {
        let weekNumber = (Math.floor(dayIndex / weeklyWorkDayCount));
        console.warn("Global Start Date: " + globalStartDate);
        
        return globalStartDate.addDays((weekNumber * 2) + (dayIndex + 1));
    };
    
    const wrapDayCellWithTooltip = (dayCell, text, index) => {
        if (!!text) {
            const arrow = typeof text !== 'string';
            return (<Tooltip key={"day-cell-wrap-" + index} placement={"left"} arrow={arrow} title={text}>{dayCell}</Tooltip>);
        }
        return dayCell;
    }
    
    /**
     * Setup week cells (1st column)
     * @type {JSX.Element[]} - ReactComponent[]
     */
    let weekCells = (new Array(roadmapMeta.weekCount)).fill(0).map((w, index) => {
        let weekDateRangeCell = null;
        
        if (hasStartDate) {
            let ws = getWeeklyOffsetDate(index * workDaysInAWeek, roadmapMeta.firstDate, workDaysInAWeek); //roadmapStartDate.addDays(wd).formatDate();
            let we = ws.addDays(workDaysInAWeek - 1).formatDate();
            
            weekDateRangeCell = (<label>{ws.formatDate()}<br/>to {we}</label>);
        }
        
        return (
            <div key={"week-item-" + index} onDoubleClick={onDoubleClick} className={"roadmap-document-column-week cell"} style={weekCellHeightStyle}>
                <span className={"big-week"}>
                    <label>Week</label>
                    {(index + 1)}
                    {weekDateRangeCell}
                </span>
            </div>
        );
    });
    
    const weeksHeaderCell = (<div className={"roadmap-document-column-header cell"}><span>Week</span></div>);
    weekCells.splice(0, 0, weeksHeaderCell);

    /**
     * Setup day cells (2nd column)
     * @type {JSX.Element[]} - ReactComponent[]
     */
    let dayCells = (new Array(roadmapMeta.dayCount)).fill(0).map((d, index) => {
        let dayCellDateElement = null;
        let classToday = "";
        let tooltipText = null;
        
        if (hasStartDate) {
            const dt = getWeeklyOffsetDate(index, roadmapMeta.firstDate, workDaysInAWeek);
            const wd = dt.getDay();
            console.warn("WD: " + wd);
            console.warn("DT: " + dt);
            
            tooltipText = dt.formatDate();
            if (today === dt.toFormDate()) {
                classToday = " today";
                tooltipText = (<h3 className={"today"}>Today - {tooltipText}</h3>);
            }
            
            dayCellDateElement = (
                <span className={"day-cell-date"}>
                    <label>{dt.getWeekDay(true)}</label>
                    {dt.formatDate("MMM DAY")}
                </span>
            );
        }
        
        const cellElement = (<div key={"cell-item-" + index} className={"roadmap-document-column-day cell" + classToday} style={cellHeightStyle}>
            <span className={"day-cell-day"}>{(index + 1).formatNumber(0)}</span>
            {dayCellDateElement}
        </div>);
        
        return wrapDayCellWithTooltip(cellElement, tooltipText, index);
    });
    const daysHeaderCell = (<div className={"roadmap-document-column-header cell"}><span>Day</span></div>);
    dayCells.splice(0, 0, daysHeaderCell);
    
    let weeksColumnElement = (<div className={"roadmap-document-column weeks"}>{ weekCells }</div>);
    let daysColumnElement = (<div onClick={toggleDayDate} className={"roadmap-document-column days"}>{ dayCells }</div>);

    /**
     * Setup the Project Item columns, column-by-column
     * @type {ReactComponent[]}
     */
    let projectItemColumns = projects.map((project, index) => { 
        let projectTitle = isSingle ? "Todos:" : project.name;
        
        let headerCell = (<div key={"header-cell-" + index} className={"roadmap-document-column-header cell"}>
            <span><Link to={"/companies/"+ project.companyId + "/projects/" + project.id}>{projectTitle}</Link></span>
        </div>);
        
        let itemDayCount = Math.max(...project.items.map((item) => item.startDay + item.duration));
        let projectStartDay = Math.max((project.startDay || 1) - 1, 0);

        /**
         * List of objects that hold (and keep track of) projectItems based on the day of the project, projectItem, and the days within the projectItem
         * @type {{id: string, day: ProjectItemDayModel, items: ProjectItemModel[], cellStyle: null}[]}
         * @returns {object[]}
         */
        let cellModels = (new Array(roadmapMeta.dayCount)).fill(0).map((_, idx) => { 
            return {
                day: idx,
                items: [],
                cellStyle: null,
                id: (project.id + "-" + idx)
            };
        });

        const projectRelativeStartDay = Math.max(project.getStartDay(roadmapStartDate) - 1, 0);
        
        /**
         * Fill the cellModels with the projectItems. There may be multiple items in a single day.
         */
        project.items.map((item, ii) => {
            const itemStartDay = projectRelativeStartDay + Math.max(item.startDay - 1, 0);
            if (!item.color) item.color = ProjectModel.defaultColors[ii % ProjectModel.defaultColors.length];
            
            item.days.sort((a, b) => a.day - b.day);
            
            item.days.map((day, dayIndex) => {
                const dday = Math.max(day.day - 1, 0);
                const activeDay = projectStartDay + itemStartDay + dday;

                cellModels[activeDay]?.items.push(item.cloneWithDay(day, dayIndex));
            });
        });
        
        /**
         * 
         * @type {JSX.Element[]}
         */
        const itemCells = cellModels.map((cellModel, cellIndex) => {
            const key = "cell-item-" + cellIndex;
            const isToday = today === (roadmapMeta.firstDate?.addDays(cellIndex + 1).toFormDate() || "x");
            
            return (
                <React.Fragment key={key}>
                    <RoadmapDocumentItemCell
                        id={key}
                        project={project}
                        index={cellIndex}
                        anchorEl={anchorEl}
                        isToday={isToday}
                        onClick={onWeekDayCellClick}
                        onEditItemClick={promptForItemEdit}
                        onMoveClick={onSelectProjectItemDayForMove}
                        onDeleteItemClick={deleteProjectItemAsync}
                        onProjectItemDayClick={(d, itm, e) => onProjectItemDayClick(d, itm, e)}
                        onTooltipClose={onItemDayTooltipClose}
                        selectedDayId={movableProjectItemDay?.id}
                        cellContainer={cellModel} 
                    />
                </React.Fragment>
            );
        });
        
        return (
            <div key={"project-column-" + project.id} className={"roadmap-document-column project"}>
                {headerCell}
                {itemCells}
            </div>
        );
    });

    const columns = [
        weeksColumnElement,
        daysColumnElement,
        projectItemColumns,
    ];
    
    return (
        <div>
            <div className={"roadmap-document-viewer"}>
                { columns }
            </div>
            <DialogBox controller={projectItemDialogController} onOkay={onProjectItemSubmitAsync}>
                <ProjectItemForm autoFocus={true} projectItem={selectedProjectItem} project={selectedProjectItemEditor?.project} company={company} onChange={onProjectItemChangedInForm} controller={projectItemDialogController} />
            </DialogBox>
        </div>);
};

export default RoadmapWeekly;

