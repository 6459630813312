/**
 * Lite version of the ProjectModel class
 */
class ProjectListItemModel { 
    /**
     * Constructor: Lite version of the ProjectModel class
     * @param json {object} - JSON object
     */
    constructor(json) {
        if (!json) json = {};
        
        this.name = json.name || "";
        this.description = json.description || "";
        this.id = json.id;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
}

export default ProjectListItemModel;
