import React, { useRef } from 'react';
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import {faFileExcel, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import ContactRequestsTable from "../../components/messaging/ui/ContactRequestsTable";
import ConfigModel from "../../components/common/models/ConfigModel";
import Controller from "../../components/common/services/Controller";
import ContactRequestService from "../../components/messaging/services/ContactRequestService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ContactRequestsScreen = (props) => {
    const {} = props;
    const controller = useRef(new Controller()).current;
    const subtitle = (<>Incoming Requests</>);
    
    const onRefresh = async (force) => {
        await ContactRequestService.instance.syncWebsiteRequestsAsync();
        await controller.refreshAsync(true);
    }
    
    const navigateToExcel = () => {
        const url = "https://docs.google.com/spreadsheets/d/1UVsO6MOCmBfoN9P40XXdQyKXhDXAi4tSte4xijOS5gA";
        window.open(url);
    };
    
    return (<CommonMasterPage selector={"contact-requests"} title={"Contact Requests"} icon={faPaperPlane} subTitle={subtitle} onRefresh={onRefresh}>
        <ContactRequestsTable controller={controller} />
        
        <div className={"footer links"}>
            <a className={"excel"} onClick={navigateToExcel}>
                <FontAwesomeIcon icon={faFileExcel} /> View Website Contact Request Excel Spreadsheet
            </a>
        </div>
    </CommonMasterPage>);
};

export default ContactRequestsScreen;

