class GraphItemModel {
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        this.identifier = json.identifier;
        this.value = json.value || 0;
        this.name = json.name;
        
        this.date = !!json.date ? new Date(json.date) : null;
        this.created = this.date;
    }

    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new GraphItemModel(json));
    }
}

export default GraphItemModel;
