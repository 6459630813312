import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowTrendUp,
    faComputerMouse, faDeleteLeft,
    faMobileRetro,
    faRobot
} from "@fortawesome/free-solid-svg-icons";
import {Link, Navigate, useParams} from "react-router-dom";
import PagerController from "../../components/common/pager/PagerController";
import ActivityService from "../../components/activity/services/ActivityService";
import WebVisitorDetails from "./WebVisitorDetails";
import DateTime from "../../components/common/ui/DateTime";
import MetricsMasterScreen from "./MetricsMasterScreen";
import Pager from "../../components/common/pager/Pager";
import Controller from "@jadecharles/pi-react-packages/dist/common/controllers/Controller";
import DateRangeSelector from "../../components/common/ui/date-range/DateRangeSelector";
import DialogBox from "../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";

const WebVisitorsScreen = (props) => {
    const [webVisitors, setWebVisitors] = useState([]);
    const {webVisitorId, ipAddress, webSession, domainName, sessionId} = useParams();
    const [redirectPath, setRedirectPath] = useState("");
    const [webSessionLabel, setWebSessionLabel] = useState(null);
    const [formState, setFormState] = useState(-1);
    const [currentPage, setCurrentPage] = useState(0);
    const dateController = useState(new Controller())[0];
    const deleteController = useState(new DialogBoxController("Delete IP Address"))[0];

    const deleteIpRef = useRef();
    
    const getDates = () => {
        const sd = dateController?.startDate || null;
        const ed = dateController?.endDate || null;
        return { startDate: sd, endDate: ed };
    };
    
    const pagerController = useState(new PagerController({}, 24, setCurrentPage))[0];
    const webSessionLabelRef = useRef();

    let firstColumnName = "Domain";
    let firstColumnFieldName = "domainName";

    const getWebVisitors = async (force = false) => {
        const options = getDates();
        const isSession = !!sessionId;
        
        console.warn("IsSession: " + isSession);
        
        const visitors = isSession ? 
            await ActivityService.instance.getWebUsersBySessionAsync(sessionId, options) :
            await ActivityService.instance.getWebVisitorsAsync(options);

        setWebVisitors(visitors);

        return visitors;
    };

    const getWebSessionLabelAsync = async () => {
        if (!webSession) return null;
        const label = await ActivityService.instance.getWebSessionLabelAsync(webSession).catch((ex) => null);

        if (!!label?.id) {
            setWebSessionLabel(label);
        }

        setFormState(0);

        return label;
    };

    const saveWebVisitorAsync = async (e) => {
        setFormState(0);
        const labelName = webSessionLabelRef.current?.value;

        if (!labelName) {
            return null;
        }

        const label = webSessionLabel?.id ?
            await ActivityService.instance.updateSessionLabelAsync(webSessionLabel.id, { name: labelName, web_session_id: webSession }) :
            await ActivityService.instance.createWebSessionLabelAsync({ name: labelName, web_session_id: webSession });

        if (!!label?.id) {
            setWebSessionLabel(label);
        }

        setFormState(0);

        return label;
    };

    const onLinkClick = (webVisitor, e) => {
        WebVisitorDetails.webVisitor = webVisitor;
        return true;
    };

    const createLabelForm = (e) => {
        if (typeof e?.preventDefault === "function") e.preventDefault();
        if (typeof e?.stopPropagation === "function") e.stopPropagation();

        if (formState === 0) return (<a onClick={(e) => setFormState(1)}>Label</a>);

        return (<div className={"details-line"}>
            <input type={"text"} ref={webSessionLabelRef} defaultValue={webSessionLabel?.name} style={{marginRight: "12px"}} />
            <a onClick={saveWebVisitorAsync}>Save</a> | <a onClick={(e) => setFormState(0)}>Cancel</a>
        </div>)
    };
    
    const deleteWebVisitorsByIp = async (e) => { 
        console.log("Deleted IP Address: " + ipAddress);
        const matches = ipAddress === deleteIpRef.current?.value;
        
        if (matches) console.log("DELETED GOOD!");
        else return false;
        
        await new Promise((resolve, reject) => setTimeout(resolve, 1000));
        await ActivityService.instance.deleteWebVisitorsByIpAsync(ipAddress);
        await getWebVisitors();
        
        setRedirectPath("/metrics/web-visitors");
        
        return 300;
    };
    
    const createIpDeleteForm = (e) => {
        if (!ipAddress || typeof ipAddress !== "string") return null;
        deleteController.open(null, "Delete IP Address");
    };

    useEffect(() => {
        getWebVisitors();
        getWebSessionLabelAsync();
    }, []);
    
    // if (!!redirectPath) {
    //     console.log("Redirecting to: " + redirectPath);
    //     return (<Navigate to={redirectPath} />);
    // }

    let extraTitle = "";
    let filteredItems = [...webVisitors];

    if (!!ipAddress) {
        extraTitle = " - IP Address: " + ipAddress;
        filteredItems = filteredItems.filter((visitor, index) => {
            return visitor.ipAddress === ipAddress;
        });
    }

    if (!!webSession) {
        firstColumnFieldName = "path";
        firstColumnName = "Path";
        extraTitle = " - Web Session: " + webSession;
        filteredItems = filteredItems.filter((visitor, index) => {
            return visitor.webSession === webSession;
        });
    }

    if (!!domainName) {
        firstColumnFieldName = "path";
        firstColumnName = "Path";

        extraTitle = " - Domain Name: " + domainName;
        filteredItems = filteredItems.filter((visitor, index) => {
            return visitor.domainName === domainName;
        });
    }
    
    if (!!sessionId) {
        firstColumnFieldName = "path";
        firstColumnName = "SessionId";
        
        extraTitle = " - Session Id: " + sessionId;
        filteredItems = filteredItems.filter((visitor, index) => {
            return visitor.sessionId === sessionId;
        });
    }

    const firstColumnIsPathy = firstColumnName !== "path" && firstColumnName !== "SessionId";
    
    const elements = pagerController.mapLineItems(filteredItems, (visitor , index) => {
        const areas = [];

        if (!!visitor.city) areas.push(visitor.city);
        if (!!visitor.region) areas.push(visitor.region);
        if (!!visitor.country && visitor.country !== "US") areas.push(visitor.country);

        const location = areas.join(", ");
        const deviceName = visitor.browser?.name || (visitor.deviceName + "!");
        const deviceIcon = visitor.isBot === true ? faRobot : (visitor.isMobile === true ? faMobileRetro : faComputerMouse);

        const visitorLabel =  (firstColumnIsPathy && visitor.path !== "/" ? (<span style={{color: "white", fontWeight: "bold"}}>{visitor.path}</span>) : "");
        const sessionElement = !!visitor.sessionId ? (<div className={"session-id"}>SessionId: {visitor.sessionId}</div>) : null;
        
        return (<tr key={"visitor-" + index} title={JSON.stringify(visitor.browser, null, 4)}>
            <td title={firstColumnName} className={"web-visitor-label"}><Link to={"/metrics/web-visitor/" + visitor.id} onClick={() => onLinkClick(visitor)}>
                <FontAwesomeIcon icon={deviceIcon} />
                <span>{visitor[firstColumnFieldName]}{visitorLabel}</span>
                {sessionElement}
            </Link></td>
            <td className={"multi-line"}>
                <Link to={"/metrics/web-visitors/ip/" + visitor.ipAddress}>{visitor.ipAddress}</Link>
                <span>{location}</span>
            </td>
            <td className={"multi-line"}>
                <span>{visitor.browser?.name}</span>
                <span>{visitor.deviceName}</span>
            </td>
            <td><DateTime value={visitor.created} time={true} useRelative={true} /></td>
        </tr>);
    });

    const labelElement = !!webSession && formState >= 0 ?
        (formState === 1 ? createLabelForm() : (<div onClick={() => setFormState(1)}><strong>Label: </strong>{webSessionLabel?.name || "(Add Label)"}</div>)) : null;

    const dn = domainName || (filteredItems?.[0]?.domainName);
    const urlElement = (!!domainName || !!webSession) ?
        (<div className={"details-line"}><strong>Domain: </strong> <Link to={"/metrics/web-visitors/domain-name/" + dn}>{dn}</Link></div>) :
        null;

    const deleteElement = !!ipAddress ? (<><br/><a onClick={createIpDeleteForm}>Delete IP Address</a></>) : null;
    
    return (<MetricsMasterScreen selector={"metrics"} icon={faArrowTrendUp} selection={"web-visitors"} title={"Growth Metrics"} subTitle={"Web Visitors" + extraTitle} onRefresh={() => getWebVisitors(true)}>
            <p className={"no-bottom large"} style={{marginBottom: "16px"}}>
                This area will show the analytics for all companies, demonstrating their performance.
                { deleteElement }
            </p>

            <div style={{marginBottom: "32px"}}>
                <DateRangeSelector range={"today"} controller={dateController} onClick={async (e) => await getWebVisitors(true)} />
            </div>
            
            <div className={"details-lines"}>
                {labelElement}
                {urlElement}
            </div>

            <div>
                <table width={"100%"} className={"table table-striped table-hover web-visitors"}>
                    <thead>
                    <tr>
                        <th>{firstColumnName}</th>
                        <th>IP Address</th>
                        <th>Client</th>
                        <th>Date</th>
                    </tr>
                    </thead>

                    <tbody>{elements}</tbody>
                </table>

                <div>
                    <Pager items={filteredItems} controller={pagerController} />
                </div>
            </div>
            
            <DialogBox className={"confirm-delete"} controller={deleteController} onOkay={deleteWebVisitorsByIp} okCaption={(<><FontAwesomeIcon icon={faDeleteLeft} /> Delete</>)}>
                Are you sure you want to <strong>permanently</strong> delete the IP {ipAddress}?<br/>
                This runs a DELETE operation on the server, and cannot be undone!
                <span style={{display: "block", paddingTop: "24px"}} className={"form-group"}>
                    <label>Enter the IP Address to Confirm:</label>
                    <input type={"text"} ref={deleteIpRef} id={"delete-ip-address"} />
                </span>
            </DialogBox>
            
        </MetricsMasterScreen>
    );

};

export default WebVisitorsScreen;