import React from 'react';
import {Link} from "react-router-dom";

const CompanyProjectsSubMenu = (props) => {
    const { company, selection } = props;

    let companyId = company?.id || "00-00";
    let sel = selection || 'main';
    let selections = {};

    selections[sel] = 'selected';

    return (
        <div className={"submenu company"}>
            <Link className={selections['main']} to={'/companies/' + companyId + '/projects'}>Projects</Link>
            <Link className={selections['roadmap']} to={'/companies/' + companyId + '/projects/roadmap'}>Full Roadmap</Link>
            <Link className={selections['content']} to={'/companies/' + companyId + '/projects/content'}>All Content</Link>
            <Link className={selections['integrations']} to={'/companies/' + companyId + '/projects/integrations'}>Project Integrations</Link>
        </div>
    );
};

export default CompanyProjectsSubMenu;
