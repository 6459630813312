import React, { useState, useEffect } from 'react';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import DropTarget from "../../components/common/ui/drop-target/DropTarget";
import FormButton from "../../components/common/ui/FormButton";
import ImageService from "../../components/media/services/ImageService";
import ToolsMasterPage from "./ToolsMasterPage";
import {Tooltip} from "@mui/material";
import DialogBox from "../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";

const ImageSizerScreen = (props) => {
    let { settingId } = useParams();
    let { sizes } = props;

    const [base64Controller, setBase64Controller] = useState(new DialogBoxController("Base64 Image Data"));
    const [base64Data, setBase64Data] = useState({ base64: "", sizeTag: ""});
    
    const [presetKey, setPresetKey] = useState(settingId || "flutter_icons");
    const [fileList, setFileList] = useState([]);
    const [zipData, setZipData] = useState(null);
    
    const presets = [
        {
            key: "flutter_icons",
            name: "Flutter Icons",
            sizes: ["32x32"],
            fileNameMask: "{fileName}_{size}.{ext}"
        },
        {
            key: "app_icon",
            name: "iOS App Icons",
            sizes: ["60x60", "60x60@2x", "60x60@3x", "76x76", "76x76@2x", "76x76@3x", "83.5x83.5", "83.5x83.5@2x", "83.5x83.5@3x", "1024x1024"] 
        },
        {
            key: "std",
            name: "Standard Square Sizes",
            sizes: ["32x32", "64x64", "128x128", "256x256", "512x512", "1024x1024"] 
        },
    ];
    
    useEffect(() => {
        if (!!base64Data?.base64) base64Controller.open(null, "Base64 Image Data " + base64Data.sizeTag);
        else base64Controller.close();
        
    }, [base64Data]);
    
    useEffect(() => { 
        setFileList([]);
    }, [zipData]);
    
    let presetMap = {};
    presets.reduce((map, preset) => {
        presetMap[preset.key] = preset;
        return map;
    } , presetMap);
    
    const onUploadAsync = async (e) => {
        console.log('Upload');
    };

    const onFileChange = (e) => {
        console.log('File Changed');
    };

    const onFileSelect = (e) => {
        console.log('File Selected');
    };
    
    const onDropFiles = (files, e) => {
        console.log('Files Dropped');
        
        let newFiles = [];
        let countDown = files.length;
        
        for(let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            
            // eslint-disable-next-line no-loop-func
            reader.onload = (ev) => {
                let fileItem = {
                    filePath: ev.target.result,
                    file: files[i],
                    name: files[i].name,
                    title: ""
                };

                newFiles.push(fileItem);
                
                countDown--;

                if (countDown <= 0) {
                    // When all files are read/loaded asynchronously, set the file list state view
                    setFileList(newFiles);
                }
                //me.setState({ selectedFile: selectedFile, imageUrl: ev.target.result });
            };

            reader.readAsDataURL(files[i]);
        }
    };

    const onSetPreset = (pKey, e) => {
        console.log('Set Preset: ' + pKey);
        setPresetKey(pKey);
    };

    const uploadAsync = async (e) => {
        console.log("Uploading...");
        
        await ImageService.instance.createZippedThumbsAsync(fileList, presetMap[presetKey]?.sizes).then((blob) => {
            console.log('Good.');
            if (blob) setZipData(blob);
        });
    };

    const cancelDownload = (e) => {
        setZipData(null);
    };

    let content = (<></>);
    let selections = {};
    
    selections[presetKey] = 'selected';

    let menuItems = presets.map((p, index) => {
        let sep = (index === 0) ? "" : " | ";
        return (<React.Fragment key={"menu-item-" + index}> {sep} <a className={selections[p.key] || ""} onClick={onSetPreset.bind(this, p.key)}>{ p.name }</a></React.Fragment>);
    });

    let fileInfo = "";
    let sizeInfo = "";
    let submitButton = null;

    const getSizeString = (size) => {
        if (typeof size === "string") {
            let wh = size.split("x", 2);
            let w = parseInt(wh[0]);
            let h = parseInt(wh[1]);

            if (isNaN(w) || isNaN(h)) return null;

            return { 
                width: w,
                height: h
            }
        }
        
        return null;
    };
    
    const uploadForBase64 = async (file, sizeTag) => {
        console.log("Loading Base64...");
        
        await ImageService.instance.uploadImageForBase64Async(file, sizeTag).then((b64) => {
            console.log("Good:");
            const data = { base64: b64, sizeTag: sizeTag};
            setBase64Data(data);
        });
    };
    
    const createImageThumbMenuElement = (item, sizeTag) => {
        //console.log(JSON.stringify(item, null, 4));
        return (<span><a onClick={() => uploadForBase64(item.file, sizeTag)}>{ sizeTag }</a></span>);
    }
    
    if (fileList && fileList.length > 0) {
        console.log(fileList);
        
        let proNoun = fileList.length === 1 ? "it" : "them";
        let preset = presetMap[presetKey];
        let sizes = preset?.sizes;
        let imageSizes = presets ? sizes.join(", ") || "Null[" + presetKey + "]" : "No-Presets";
        
        fileInfo = fileList.length.toString() + " files converted to " + preset.name;
        sizeInfo = (<><strong>Resizing to:</strong> {imageSizes}</>);
        
        let listItems = fileList.map((f, i) => { 
            const key = "file-" + "-" + (Math.random() * 100000).toString(36) + "-" + i
            
            let imageUrl = f.filePath;
            let thumbs = sizes.map((sz, ii) => { 
                let s = getSizeString(sz);
                if (!s) return null;
                
                let style = {width: s.width + "px", height: s.height + "px"};
                let cn = "image-preview-thumb";
                let labelElement = null;
                
                if (s.width > 180 || s.height > 180) {
                    //cn = "image-preview-thumb no-image";
                    style = {width: "180px", height: "180px"};
                    labelElement = (<span className={"label"}>{sz}</span>);
                }

                const menuElement = createImageThumbMenuElement(f, sz);
                
                return (
                    <span key={key + "--" + ii} className={cn}>
                        <Tooltip title={menuElement} placement={"top"}><span><img src={imageUrl} style={style} alt={sz} /></span></Tooltip>
                        {labelElement}
                    </span>
                );
            });
            
            return (<li key={key} className={"image-preview-thumbs"}>
                    <div>{ f.name }</div>
                    <div className={"thumbs"}>{ thumbs }</div>
                </li>);
        });
        
        fileInfo = (<div className="file-information">
            <h3 className={"blue"}>File info: { fileInfo }</h3>
            <p>{sizeInfo}</p>
            <div>
                <ul>
                    {listItems}
                </ul>
            </div>
        </div>);
        
        submitButton = (<div className={"buttons"}>
            <FormButton onClick={uploadAsync}>Upload Files</FormButton>
        </div>);
    } else {
        console.warn("No Files");
    }

    let zipDownloadElement = null;
    
    if (zipData) {
        console.log("Zip Data Type: " + (typeof zipData).toString());
        
        let zipLink = window.URL.createObjectURL(zipData);
        let zipFileName = "images.zip";
        
        console.warn(zipLink);
        
        let zSize = zipData.size;
        let zipSize = zSize.toString() + " bytes";
        
        if (zSize > 1024 * 1024) zipSize = (zSize / (1024 * 1024)).toFixed(2) + " MB";
        if (zSize > 1024) zipSize = (zSize / 1024).toFixed(2) + " KB";
        
        zipDownloadElement = (<div className={"zip-file-download"}>
            <span>
                <a href={zipLink} title={zipLink} download={zipFileName}>
                    <FontAwesomeIcon icon={faDownload} /> Download Zip File ({zipSize})
                </a>
            </span>
            
            <span>
                <a onClick={cancelDownload}>Close</a>
            </span>
        </div>);
    } else {
        //
    }
    
    const subtitle = (<><span className={"pre-subtitle"}>Tools:</span> Image and Icon Re-Shaper</>);
    
    return (
        <ToolsMasterPage icon={faPeopleCarryBox} selection={"re-shaper"} subTitle={subtitle}>

            <p>
                This area will show a cool description.
            </p>

            <div>
                {zipDownloadElement}

                <div className={"upload-menu"} style={{paddingBottom: "24px"}}>
                    <strong>Presets:</strong> &nbsp;
                    {menuItems}
                </div>

                <DropTarget className={"drop-target"} onDrop={onDropFiles}>
                    Drop Files Here
                </DropTarget>

                <div className={"file-upload-container"}>
                    {fileInfo}
                </div>

                {submitButton}

            </div>
            <DialogBox controller={base64Controller} onOkay={() => setBase64Data({ base64: "", sizeTag: ""})} hasCancelButton={false}>
                <textarea className={"base64-code"} value={base64Data?.base64}></textarea>
            </DialogBox>
        </ToolsMasterPage>
    );
};

export default ImageSizerScreen;
