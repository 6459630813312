import React, {useEffect} from 'react';
import DocumentationMasterPage from "../../components/documentation/ui/DocumentationMasterPage";
import GlossaryTermService from "../../components/documentation/services/GlossaryTermService";
import {Link} from "react-router-dom";
import '../../components/documentation/ui/Documentation.css';
import {marked} from "marked";

const GlossaryScreen = (props) => {
    const { letter } = props;
    const [terms, setTerms] = React.useState(GlossaryTermService.instance.glossaryTerms || null);
    let _;
    
    const getTermsAsync = async () => {
        return await GlossaryTermService.instance.getGlossaryTermsAsync().then((ts) => {
            if (!!ts && Array.isArray(ts)) setTerms(ts);
        });
    };
    
    useEffect(() => {
        _ = getTermsAsync();
    }, []);
    
    const letterElements = GlossaryTermService.letters.map((letter, idx) => {
        return (<span className={"glossary-letter"} key={"letter-" + idx }>
            <Link to={"/documentation/glossary/" + letter?.toString()}>{letter?.toString()}</Link>
        </span>);
    });

    const glossaryTermElements = !!terms ? terms.map((term, i) => {
        return (<li key={"gloss-term-" + i}>
                <div>
                    <h3>{term.term}</h3>
                    <p className={"glossary-definition"} dangerouslySetInnerHTML={{ __html: marked.parse(term.definition || "")}}></p>
                </div>
            </li>
        );
    }) : null;
    
    return (
        <DocumentationMasterPage selection={"glossary"} subTitle={"Glossary of Terms"} bodyClassName={"large"}>
            <ul className={"glossary"}>
                { glossaryTermElements }
            </ul>
            
            <div className={"letters"}>
                {letterElements}
            </div>
        </DocumentationMasterPage>
    );
};

export default GlossaryScreen;
