import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import BackButton from "../../../components/common/ui/BackButton";
import DocumentConfigs from "../../../config/DocumentConfigs";
import BulletArrow from "../../../components/common/ui/BulletArrow";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import DocumentService from "../../../components/content/services/DocumentService";
import {Tooltip} from "@mui/material";
import CopyText from "../../../components/common/ui/CopyText";

const DocumentationDetailsScreen = (props) => {
    const { docKey } = props;
    const docConfig = DocumentConfigs.data.docTypeValues[docKey];
    
    let _;
    let { companyId } = useParams();
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    let [documents, setDocuments] = useState([]);
    
    const backPath = "/companies/" + companyId + "/documentation";

    const getDocumentsOfTypeAsync = async () => {
        const docType = docConfig.key;
        DocumentService.instance.getDocumentsByTypeAsync(companyId, docType).then((docs) => {
            setDocuments(docs);
        });
    };
    
    const onCompany = (c) => {
        setCompany(c);
    };

    const onCopy = (text) => {
        console.log("Clipboard Text: " + text);
    };
    
    useEffect(() => {
        _ = getDocumentsOfTypeAsync();
    }, []);
    
    const subtitle = (<><BackButton to={backPath} />
        <span className={"subtitle"}><Link to={backPath}>Documents</Link></span>
        <BulletArrow />
        <span className={"subtitle"}>{ docConfig?.title }</span></>);
    
    const documentElements = documents?.map((doc, index) => {
        return (
            <li key={"doc-key-" + doc.id}>
                <span style={{marginRight: "24px"}}>{ doc.getFileSizeDisplay() }</span>

                <CopyText text={doc.id} onCopy={onCopy}>    
                    <Tooltip title={"Click to Copy: " + doc.id}>
                        <span><a href={doc.uri}>{ doc.name || doc.fileName }</a></span>
                    </Tooltip>
                </CopyText>
            </li>
        );
    });
    
    return (
        <CompanyMasterPage onCompany={onCompany} selection={"docs"} title={subtitle}>
            <p>
                These documents are <strong>stored securely</strong> in the cloud and are accessible only authenticated users of this platform.
            </p>

            <div>
                <ul>
                    {documentElements}
                </ul>
            </div>
       </CompanyMasterPage>        
    );
    

};

export default DocumentationDetailsScreen;
