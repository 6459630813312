import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import NavigationHistoryService from "../../../components/activity/services/NavigationHistoryService";
import DateTime from "../../../components/common/ui/DateTime";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import ShortUrlService from "../../../components/websites/services/ShortUrlService";
import '../../../components/websites/ui/ShortUrls.css';
import ShortUrlForm from "../../../components/websites/ui/ShortUrlForm";

const ShortUrlDomainScreen = (props) => {
    let _;
    const { companyId, domainId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId || ""] || null);
    const [shortUrls, setShortUrls] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const getShortUrlsAsync = async (force) => {
        if(!force && shortUrls?.length > 0) {
            return shortUrls;
        }
        
        console.log("Getting urls...");
        await ShortUrlService.instance.getShortUrlsAsync(domainId).then((shortUrls) => {
            console.warn("URLs returned: " + shortUrls.length);
            if (!!shortUrls) setShortUrls(shortUrls);
        });
    };
    
    const deleteShortUrlAsync = async (id) => {
        await ShortUrlService.instance.deleteShortUrlAsync(id).then((x) => {
            _ = getShortUrlsAsync(true);
            setDeleteId("");
        });
    };
    
    useEffect(() => {
        if (!!deleteId && deleteId.length > 30) { 
            _ = deleteShortUrlAsync(deleteId);
        } else if (domainId === "") { 
            _ = getShortUrlsAsync(true);
        }
    }, [deleteId]);
    
    useEffect(() => {
        if (company?.name) NavigationHistoryService.instance.addDelayedItem(company.name + " Short URLs",8000);
    }, [company]);

    useEffect(() => {
        _ = getShortUrlsAsync();
    }, []);

    const domain = company?.domains?.find((d) => d.id === domainId);
    const description = company?.description || "No description available";
    const subtitle = !!domain?.domain ? "Short URLs for " + domain.domain : ((company?.name || "") + " Short URLs");
    const urlForm = (<ShortUrlForm companyId={companyId} companyDomain={domain} onComplete={(e) => getShortUrlsAsync(true)} />);
    
    const urlElements = shortUrls?.length > 0 ? shortUrls.map((shortUrl, index) => {
        const deleteCell = deleteId === shortUrl.id ? (<span className={"spinner.spinning"}>&nbsp;</span>) : (<>
            <span className={"delete-cell"}><a onClick={(e) => setDeleteId(shortUrl.id)}>Delete</a></span>
            <span className={"non-delete-cell"}><DateTime value={shortUrl.created} /></span>
        </>);

        return (
            <tr key={"domain-" + index}>
                <td>{shortUrl.path}</td>
                <td className={"clicks"}>{shortUrl.clicks || 0}</td>
                <td className={"url"}>{shortUrl.redirectUrl}</td>
                <td className={"delete-cell"}>{ deleteCell }</td>
            </tr>
        );
    }) : null;

    const tableElement = !!urlElements ? (
        <table className="table x-large" width={"100%"}>
            <thead>
            <tr>
                <th>URL Path</th>
                <th>Clicks</th>
                <th>Redirect To</th>
                <th>Created</th>
            </tr>
            </thead>
            <tbody>
            {urlElements}
            </tbody>
        </table>
    ) : null;

    return (
        <CompanyMasterPage bodyClassName={"large"} subTitleClassName={"options"} menuSelection="urls" onCompany={onCompany} title={subtitle} onRefresh={(e) => getShortUrlsAsync(true)}>
            <p>
                The short URLs below are for the domain <strong>{domain?.domain}</strong>.<br />
            </p>

            <div>
                {tableElement}
            </div>

            <br/>
            <br/>
            <div>{ urlForm }</div>
        </CompanyMasterPage>
    );
};

export default ShortUrlDomainScreen;
