import HttpService from "../../common/services/HttpService";
import ContactRequestModel from "../models/ContactRequestModel";
import UserModel from "../../people/models/UserModel";

class ContactRequestService {
    static instance = new ContactRequestService();

    constructor() {
        this.contactRequestsMap = {};
        this.contactRequests = [];
        this.contactRequestMap = {};
    }

    async getContactRequestAsync(contactRequestId, noRead = false) {
        const path = '/api/contact-request/' + contactRequestId;
        const me = this;
        const nr = noRead === true ? "?no-read=1" : "";
        
        return await HttpService.instance.getAsync(path + nr).then((response) => {
            me.contactRequestMap[contactRequestId] = new ContactRequestModel(response.data);
            return me.contactRequestMap[contactRequestId];
        });
    }

    async getContactRequestsByCompanyAsync(companyId) {
        if (!companyId || companyId.length < 30) throw new Error("Missing companyId when getting contact requests by company.");
        return await this.getContactRequestsAsync(companyId);
    }

    async getContactRequestsAsync(companyId) {
        const path = !!companyId && companyId.length > 30 ? "/api/company/" + companyId + "/contact-request" : "/api/contact-request";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const cs = ContactRequestModel.fromJsonArray(response.data);
            
            if (!companyId) me.contactRequests = cs;
            else me.contactRequestsMap[companyId] = cs;
            
            return cs;
        });
    }

    async deleteContactRequestAsync(contactRequestId) { 
        const path = "/api/contact-request/" + contactRequestId;
        
        console.warn("Deleting: " + path);
        
        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response?.data;
        });
    }
    
    async createUserFromContactRequestAsync(contactRequestId) {
        const path = "/api/contact-request/" + contactRequestId + "/user";
        
        return await HttpService.instance.postAsync(path).then((response) => {
            return new UserModel(response.data);
        });
    }
    
    async syncWebsiteRequestsAsync() {
        const path = "/api/contact-request/website";
        await HttpService.instance.getAsync(path);
    }
}

export default ContactRequestService;
