import React, {createRef, useEffect, useState} from 'react';
import SearchResultCategory from "./SearchResultCategory";
import SearchService from "../services/SearchService";

const stateHolder = { hash: null, results: null };

const SearchBox = (props) => {
    const {controller, company, companyId, entities, placeholder} = props;
    
    let [searchResultsOpen, setSearchResultsOpen] = useState(false);
    let [searchResults, setSearchResults] = useState(controller?.id === stateHolder.hash ? stateHolder.results : null);
    
    const searchRef = createRef();
    const masterInputId = props.id || "master-search-input";
    
    let _;
    let searchTerm = "";
    let lastSearchTerm = "";

    const searchDelay = 1000;
    let searchReady = false;

    const closeSearchResults = (e, close) => {
        if (!e?.target) {
            //console.warn("e.target is null (" + e?.target + ") ... Not closing.");
            return;
        } 

        const id = e.target.id;
        const tagName = (e.target.tagName || "").toLowerCase();

        if (id === masterInputId) {
            //console.warn("Search MasterInput === " + id + " ... Not closing.");
            return;
        }
        //console.log("Search MasterInput != " + id + " ... Closing.");
        setSearchResultsOpen(false);
    };


    const onSearchChange = (e) => {
        const newTerm = e.target?.value || "";
        searchReady = false;

        if (newTerm.length < 3) {
            searchTerm = newTerm;

            if (newTerm.length === 0) {
                console.warn("Clearing results.");
                setSearchResults(null);
            }
            return;
        }

        if (newTerm.length < searchTerm.length) {
            searchTerm = newTerm;
            return;
        }

        searchReady = true;
        searchTerm = newTerm;

        setTimeout(() => {
            //console.log("Search Ready: " + searchReady.toString() + " => " + searchTerm);
            if (searchReady) {
                _ = getSearchedResultsAsync();
            }
        }, searchDelay);
    };

    const onSearchFocus = (e) => {
        console.log('Search focus: ' + e.target?.value);
        if (searchResults !== null && searchResults.length > 0 && !searchResultsOpen) {
            setSearchResultsOpen(true);
        } else { 
            console.warn("Leave closed: " + (searchResults?.length))
        }
    };

    const onSearchBlur = (e) => {
        console.log('Search blur: ' + e.target?.value);
    };

    const getSearchedResultsAsync = async () => {
        if (!!lastSearchTerm && lastSearchTerm.startsWith(searchTerm)) {
            return;
        }

        lastSearchTerm = searchTerm;

        const cid = (companyId || company?.id) || null;
        
        await SearchService.instance.searchAsync(searchTerm, entities, cid).then((searchResults) => {
            setSearchResults(searchResults);
        });
    };

    useEffect(() => {
        stateHolder.results = searchResults;
        setSearchResultsOpen(searchResults !== null);
    },[searchResults]);

    useEffect(() => {
        console.log("Re-rendering search box.");
        if (!!controller?.id && stateHolder?.hash !== controller?.id) { 
            stateHolder.hash = controller.id;
            stateHolder.results = null;
        }
        controller?.setOpenCallback(() => {
            console.log("Opening search results callback.");
        });

        controller?.setCloseCallback((e) => {
            closeSearchResults(e);
        });

    }, []);
    
    const getEmptyResults = () => {
        return (<ul><li><strong className={"empty"}>No Results Found</strong></li></ul>);
    };

    const searchResultElements = searchResultsOpen && searchResults !== null ? (searchResults.length > 0 ? searchResults.map((category, index) => {
        return (<SearchResultCategory key={index} category={category} />);
    }) : getEmptyResults()) : null;

    const resultsElement = !!searchResultElements ? (<div className={"search-results"}>
        {searchResultElements}
    </div>) : null;

    const placeholderText = placeholder || "Search Platform...";
    
    return (
        <div id={"master-search"} className={"form x-large"}>
            <div id={"master-search-container"}>
                <div><input className={"search-box"} autoComplete={"off"} id={masterInputId} type={"text"} placeholder={placeholderText} onFocus={onSearchFocus} onBlur={onSearchBlur} onChange={onSearchChange} /></div>
                { resultsElement }
            </div>
        </div>        
    );
};

export default SearchBox;
