import React from 'react';
import {Link} from "react-router-dom";

const ActivitySubMenu = (props) => {
    const { company, selection } = props;

    const companyId = company?.id || "00-00";

    let sel = selection || "main";
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";

    selections[sel] = "selected";

    const getPath = (sel) => {
        if (companyId.length < 10) return "";

        if (!sel) sel = "";
        return "/companies/" + companyId + "/activity" + sel;
    };
    
    return (
        <div className={"submenu company" + cn}>
            <Link className={selections["main"]} to={getPath("")}>Activity History</Link>
            <Link className={selections["messages"]} to={getPath("/messages")}>Message Log</Link>
            <Link className={selections["errors"]} to={getPath("/errors")}>Errors</Link>
        </div>
    );
};

export default ActivitySubMenu;