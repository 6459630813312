import React from 'react';
import {faArrowTrendUp, faCreditCardAlt, faPeopleCarryBox} from "@fortawesome/free-solid-svg-icons";
import ToolsSubMenu from "../../components/tools/ui/ToolsSubMenu";
import ToolsMasterPage from "./ToolsMasterPage";

const FlutterToolsScreen = (props) => {
    const {} = props;
    const subtitle = (<><span className={"pre-subtitle"}>Tools:</span> Flutter Tools</>);

    return (
        <ToolsMasterPage selection={"flutter"} icon={faPeopleCarryBox} subMenu={<ToolsSubMenu selection={"flutter"} />} subTitle={subtitle}>
            <p>
                Flutter tools go here.
            </p>
        </ToolsMasterPage>
    );
};

export default FlutterToolsScreen;
