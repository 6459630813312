import React, { useRef, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import HeaderButton from "../../components/common/ui/HeaderButton";
import UserMasterPage from "../../components/people/ui/UserMasterPage";
import MyAccountMasterPage from "../../components/my-account/ui/MyAccountMasterPage";
import ActivityService from "../../components/activity/services/ActivityService";
import PagerController from "../../components/common/pager/PagerController";
import Pager from "../../components/common/pager/Pager";
import '../../components/activity/ui/Activity.css';

const MyActivityScreen = (props) => {
    let _;
    let { userId } = useParams();
    let [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    let [activity, setActivity] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const pageController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;
    
    const getUserAsync = async (force) => {
        if (!force && user) {
            return user;
        }

        return await UserService.instance.getUserAsync(userId).then((user) => {
            onUser(user);
        });
    };

    const getActivityAsync = async (force) => {
        const uid = userId || user?.id;
        if (!uid) {
            return activity;
        }
        
        if (!force && !!activity && activity.length > 0) {
            return activity;
        }

        return await ActivityService.instance.getUserActivityAsync(uid).then((activity) => {
            if (!!activity) setActivity(activity);
        });
    };
    
    const onSessionChange = (session) => {
        if (!!session?.user) onUser(session.user);
    };
    
    const onUpload = (image) => {
        _ = getUserAsync(true);
    };

    const onUser = (user) => {
        setUser(user);
    };

    useEffect(() => {
        _ = getActivityAsync();
    }, [user]);

    useEffect(() => {
        _ = getActivityAsync();
    }, []);

    let activityElements = activity ? pageController.mapLineItems(activity,(item, index) => {
        return (
            <tr key={index}>
                <td className={"text"}><Link to={"/people/" + item.id}>{ item.text }</Link></td>
                <td className={"level"}>{ item.severityName }</td>
                <td className={"date"}>{ item.created.formatDate() }</td>
            </tr>
        );
    }) : null

    let subtitle = (<>{ user?.fullName }
        <span className={"subtitle"}>Activity</span>
        <HeaderButton onClick={async (e) => getActivityAsync(true)}>Refresh</HeaderButton></>);

    return (
        <MyAccountMasterPage title={"My Account"} onUser={onUser} subTitle={subtitle} selection={"activity"} onSessionChange={onSessionChange}>
            <p>
                Activity you've engaged in on the platform.
            </p>

            <div className={"form x-large"}>
                <table width={"100%"} className={"activity"}>
                    <thead>
                    <tr>
                        <th className={"text"}>Activity</th>
                        <th className={"level"}>Level</th>
                        <th className={"date"}>Created</th>
                    </tr>
                    </thead>

                    <tbody>
                    { activityElements}
                    </tbody>
                </table>
                
                <Pager controller={pageController} items={activity} />
            </div>

        </MyAccountMasterPage>
    );


};

export default MyActivityScreen;

