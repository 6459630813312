import NavigationHistoryItemModel from "../models/NavigationHistoryItemModel";

class NavigationHistoryService {
    static localKey = 'navigation-history';
    static instance = new NavigationHistoryService();
    
    constructor(maxItems = 24) {
        this.items = JSON.parse(localStorage.getItem(NavigationHistoryService.localKey) || "[]") || [];
        this.maxItems = maxItems || 24;
        this.delays = {};
        this.currentId = null;
        
        if (typeof this.maxItems !== 'number' || this.maxItems < 1)
            this.maxItems = 24;

        if (!Array.isArray(this.items)) this.items = [];
        
        if (this.items.length > 0) this.items = this.items.map((item) => { 
            if (typeof item.date === 'string') item.date = new Date(item.date);
            return item;
        });
    }

    /**
     * Removes an item from the history based on the key
     * @param key {string|null} - If null or otherwise not a string, nothing happens.
     * @returns {boolean}
     */
    remove(key) {
        if (typeof key !== 'string') {
            console.warn("Failed to remove: " + key);
            return false;
        }
        
        const count = this.items.length;
        this.items = this.items.filter(i => i.key !== key);
        this.saveLocally();

        const didRemove = count !== this.items.length;
        console.warn("Removed " + key + ": " + didRemove);

        return didRemove;
    }
    
    clear() { 
        this.items = [];
        this.saveLocally();
    }
    
    addItem(item, time = 0, timeThreshold = 2000) { 
        if (!item) item = new NavigationHistoryItemModel(null);
        else if (typeof item === 'string') item = new NavigationHistoryItemModel(item);
        
        if (!item.time) item.time = time || 0;
        if (!item.key) item.key = NavigationHistoryItemModel.createKey();
        
        const existing = this.items.find(i => i.key === item.key);
        
        if (!!existing) {
            const d = new Date();
            
            if (typeof existing.date === 'string') existing.date = new Date(existing.date);
            const diff = d.getTime() - existing.date.getTime();
            if (diff < timeThreshold) return false;

            const scoreDx = Math.floor(Math.max((30000 - diff) * 0.00025, 1));

            existing.score += scoreDx;

            if (existing.score > 300)
                existing.score = ((existing.score - 200) * 0.5) + 200;

            existing.title = item.title;
            existing.date = d;
            existing.time = item.time;
            
        } else {
            this.items.push(item);
        }
        
        console.warn("Navigation Item Added [overwrite:" + (!!existing).toString() + "]: " + item.title);
        this.saveLocally();

        return item;
    }
    
    async addDelayedItemAsync(addFunction, delay = 5000) {
        if (typeof addFunction !== 'function') return false;
        if (typeof delay !== 'number' || delay < 0) delay = 5000;

        const key = window.location.href;
        // const me = this;
        // const now = new Date().getTime();

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const result = (key === window.location.href) ? addFunction() : false;
                resolve(result || false);
            }, delay);
        });
    }

    /**
     * Waits for the given time period before logging the history
     * @param item
     * @param delay
     */
    addDelayedItem(item, delay = 8000) {
        const key = window.location.href;
        const me = this;
        const now = new Date().getTime();
        
        if (typeof item === 'number') { 
            const title = (typeof delay === 'string') ? delay : null;
            
            delay = item;
            item = title;
        }
        
        setTimeout(() => {
            if (key === window.location.href)
                me.addItem(item, new Date().getTime() - now);
        }, delay);
    }
    
    /**
     * Trims the history to the maxItems, and returns the trimmed items in proper order
     */
    clean() { 
        let items = this.getItems();
        
        if (items.length > this.maxItems) {
            items = items.filter((item, index) => index < this.maxItems);
        }
        
        return items;
    }

    getItems() {
        const fiveMins = 5 * 60 * 1000;
        return this.items.sort((a, b) => {
            const chronOrder = Math.floor(b.date.getTime() / fiveMins) - Math.floor(a.date.getTime() / fiveMins);
            const scoreOrder = b.score - a.score;
            
            return chronOrder === 0 ? scoreOrder : (chronOrder || b.date.getTime() - a.date.getTime());
        });
    }
    
    saveLocally() {
        localStorage.setItem(NavigationHistoryService.localKey, JSON.stringify(this.items));
    }
}

export default NavigationHistoryService;
