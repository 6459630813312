class BankAccountModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || "";
        this.companyId = json.company_id || "";
        this.routingNumber = json.routing_number || "";
        this.accountNumber = json.account_number || "";
        this.directDepositNumber = json.direct_deposit_routing_number || "";
        
        this.accountType = json.bank_account_type || "";
        this.accountHolderType = json.account_holder_type || "";
        
        this.name = json.name || "";
        this.secCode = json.sec_code || "web";
        this.identifier = json.identifier || "";
        this.remoteId = json.remote_id || "";
        this.status = json.status || 0;
        
        this.created = json.created ? new Date(json.created) : new Date();
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new BankAccountModel(json));
    }
}

export default BankAccountModel;
