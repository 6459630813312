import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyService from "../../components/companies/services/CompanyService";
//import CommentForm from "../../components/messaging/ui/CommentForm";
import CompanyModel from "../../components/companies/models/CompanyModel";
import CompanyMasterPage from "../../components/companies/ui/CompanyMasterPage";
import MarkdownToggleEditor from "../../components/common/ui/form-elements/MarkdownToggleEditor";
import NavigationHistoryService from "../../components/activity/services/NavigationHistoryService";
import FileUploader from "../../components/common/ui/file-uploader/FileUploader";

const CompanyDetailsScreen = (props) => {
    const { companyId } = useParams();
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId || ""] || null);
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };
    
    const onCompanyDescriptionChangeAsync = async (desc, e) => {
        console.warn("Submitting: " + desc);
        return await CompanyService.instance.updateCompanyDescriptionAsync(company?.id, desc, null).then((c) => {
            setCompany(c);
            return c;
        });
    };
    
    const onImageUploadComplete = (jsonData) => { 
        //
    };
    
    useEffect(() => {
        if (company?.name) NavigationHistoryService.instance.addDelayedItem(company.name +" Overview",8000);
    }, [company]);
    
    useEffect(() => {
        //
    }, []);

    // const stage = company?.stage || 0;
    // const stageOptions = CompanyModel.stages.map((stage, i) => (<option key={i.toString() + "-" + stage.id} value={stage.id}>{stage.name}</option>));
    //const companyDropdown = !!company ? (<span className={"right container stage-dropdown"}><select defaultValue={stage.toString()} onChange={onCompanyStageChangeAsync}>{ stageOptions }</select></span>) : null;
    const description = company?.description || "No description available";
    const subtitle = company?.name + " Company Overview";
    
    const descriptionEditor = !!company ? (<MarkdownToggleEditor defaultValue={description} onComplete={onCompanyDescriptionChangeAsync}></MarkdownToggleEditor>) : null;
    const imageUrl = company?.image?.url || "";
    const fileUploader = !!company ? (<FileUploader url={"/api/company/" + companyId + "/image/upload"} imageUrl={imageUrl} onComplete={onImageUploadComplete} />) : null;
    
    return (
        <CompanyMasterPage bodyClassName={"large"} subTitleClassName={"options"} menuSelection="main" onCompany={onCompany} title={subtitle} commentEntityType={CompanyModel.entityType} onComplete={onCompanyDescriptionChangeAsync}>
            
            <div className={"p-columns"}>
                <span className={"company-image-upload"}>{ fileUploader }</span>
                {descriptionEditor}
            </div>

            <div>
                
            </div>

        </CompanyMasterPage>
    );
    
};

export default CompanyDetailsScreen;