import React, {useEffect, useRef, useState} from 'react';
import FormButton from "../../common/ui/FormButton";
import "./Payments.css";
import BankAccountModel from "../models/BankAccountModel";

const AchForm = (props) => {
    const { bankAccount, onClick, onChange, ach, achAccount, onComplete } = props;
    const [ errors, setErrors ] = useState({});

    const nameRef = useRef();
    const accountNumberRef = useRef();
    const routingNumberRef = useRef();
    
    const secCodeRef = useRef();
    const accountTypeRef = useRef();
    const accountHolderTypeRef = useRef();

    const validateForm = (e) => {
        let er = {};

        if (!nameRef.current?.value || nameRef.current.value.trim().length < 1) er.name = "Name is missing or invalid";

        if (!accountNumberRef.current?.value || accountNumberRef.current.value.trim().length < 9)
            er.account_number = "Account number is missing or invalid";
        else if (accountNumberRef.current.value.indexOf("*") > -1)
            er.account_number = "You must include the full account number";

        if (!routingNumberRef.current?.value || routingNumberRef.current.value.trim().length < 9)
            er.routing_number = "Routing number is missing or invalid";
        else if (routingNumberRef.current.value.indexOf("*") > -1)
            er.routing_number = "You must include the full routing number";

        if (Object.keys(er).length === 0) return true;

        setErrors(er);

        return false;
    };

    const handleChange = (fieldId, e) => {
        if (typeof onChange === "function") onChange(fieldId, e);
    };
    
    const createJson = () => {
        return {
            name: nameRef.current?.value || null,
            account_number: accountNumberRef.current?.value || null,
            routing_number: routingNumberRef.current?.value || null,
            sec_code: secCodeRef.current?.value || null,
            account_type: accountTypeRef.current?.value || null,
            account_holder_type: accountHolderTypeRef.current?.value || null
        };
    };

    const onSubmitAsync = async (e) => {
        validateForm(e);
        
        if (typeof onClick !== 'function') return;

        const ac = new BankAccountModel(createJson());
        const rsp = onClick(ac);
        
        if (typeof rsp.then === 'function') {
            return await rsp.then((x) => {
                if (typeof onComplete === 'function') onComplete(x);
                return x;
            });
        }
        
        return rsp;
    };

    let submitElement = props.useButton ? (
        <div className="buttons">
            <FormButton id="address-update-button" onClick={onSubmitAsync} label="Update Card" />
        </div>
    ) : (<></>);

    const account = ach || achAccount;
    const accountNum = account?.accountNumber || account?.account_number;
    const routingNum = account?.routingNumber || account?.routing_number;
    const mask = accountNum ? "*** *** " + accountNum.substr(accountNum.length - 4) : "";
    const routingMask = routingNum ? "*** *** " + routingNum.substr(routingNum.length - 4) : "";
    
    return (
        <div className="form payment-form ach-form">
            <div className="form-group">
                <label htmlFor="account-name">Name on Account</label>
                <input type="text" ref={nameRef} id="account-name" defaultValue={account?.name} onChange={handleChange.bind(this, "account_name")} />
                <div className={"form-error"}>{errors?.name}</div>
            </div>

            <div className="form-group">
                <label htmlFor="account-number">Account Number</label>
                <input type="text" ref={accountNumberRef} id="account-number" defaultValue={mask} onChange={handleChange.bind(this, "account_number")} />
                <div className={"form-error"}>{errors?.account_number}</div>
            </div>

            <div className="form-group">
                <label htmlFor="routing-number">Routing Number</label>
                <input type="text" ref={routingNumberRef} id="routing-number" defaultValue={routingMask} onChange={handleChange.bind(this, "routing_number")} />
                <div className={"form-error"}>{errors?.routing_number}</div>
            </div>

            <div className="form-group">
                <label htmlFor="sec-code">Entry SEC Code</label>
                <select id="sec-code" ref={secCodeRef} defaultValue={account?.secCode || account?.sec_code} onChange={handleChange.bind(this, "sec_code")}>
                    <option value={"ARC"}>Accounts Receivable Entry (ARC)</option>
                    <option value={"WEB"}>Web Entry (Web)</option>
                    <option value={"PPD"}>Prearranged Payment &amp; Deposit (PPD)</option>
                    <option value={"CCD"}>Corporate Cash Disbursement (CCD)</option>
                    <option value={"TEL"}>Telephone Initiated Entry (TEL)</option>
                    <option value={"BOC"}>Back Office Conversion (BOC)</option>
                    <option value={"POP"}>Point-Of-Presence (POP)</option>
                    <option value={"RCK"}>Re-presented Check Entry (RCK)</option>
                </select>
                <div className={"form-error"}>{errors?.sec_code}</div>
            </div>

            <div className="form-group double">
                <div className={"half"}>
                    <label htmlFor="account-holder-type">Account Holder Type</label>
                    <select id="account-holder-type" ref={accountHolderTypeRef} defaultValue={account?.accountHolderType || account?.account_holder_type} onChange={handleChange.bind(this, "account_holder_type")}>
                        <option value={1}>Business</option>
                        <option value={0}>Personal</option>
                    </select>
                    <div className={"form-error"}>{errors?.account_holder_type}</div>
                </div>

                <div className={"half"}>
                    <label htmlFor="account-type">Account Type</label>
                    <select id="account-type" ref={accountTypeRef} defaultValue={account?.accountType || account?.account_type} onChange={handleChange.bind(this, "account_type")}>
                        <option value={0}>Checking</option>
                        <option value={1}>Savings</option>
                    </select>
                    <div className={"form-error"}>{errors?.account_type}</div>
                </div>
            </div>

            {submitElement}
        </div>
    );
};

export default AchForm;

