import logo from '../../../images/logo-white.png';

const Header = (props) => {
    //let id = props.id || 'main-header';
    let title = props.title || 'Welcome!';
    
    return (
        <div id="dark-header">
            <div className="inner">
                <img src={logo} id="logo" alt="PenumbraLabs.io"/>
                <div className="title">
                    <h3>{title}</h3>
                    <div>PenumbraLabs.io</div>
                </div>
            </div>
        </div>
    );
}

export default Header;
