class CompanyDomainModel {
    static entityType = 23;
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.companyId = json.company_id;
        this.userId = json.user_id;
        this.domain = json.domain;

        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            company_id: this.companyId,
            user_id: this.userId,
            domain: this.domain,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new CompanyDomainModel(json));
    }
}

export default CompanyDomainModel;
