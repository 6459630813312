import { memo, useCallback, useState } from 'react';
import './Roadmap.css';
import {Tooltip} from "@mui/material";
import ProjectItemModel from "../../models/ProjectItemModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import ProjectService from "../../services/ProjectService";

const RoadmapCalendar = (props) => {
    let { company, project, projects, onClick, controller } = props;
    let [workDaysInAWeek, setWorkDaysInAWeek] = useState(5);
    let [refreshCount, setRefreshCount] = useState(0);
    
    if (!project && !projects) return (<>Empty</>);

    const format = "yyyy-MM-dd";
    const dayTicks = 1000 * 60 * 60 * 24;

    const onDayClick = (dayIndex, e) => {
        console.log("dayIndex: " + dayIndex);
    };
    
    const onWeekClick = (weekIndex, e) => {
        console.log('weekIndex: ' + weekIndex);
    };
    
    const onProjectItemClick = (item, e) => {
        console.log("Item: " + item.name);
        if (typeof onClick === 'function') onClick(item, e);
    };
    
    let isSingle = false;
    
    if (!projects) {
        isSingle = true;
        projects = [project];
    }

    let projectStartDayMap = !isSingle ? projects.reduce((map, item) => {
        if (!!item.startDay) {
            map[item.id] = item.startDay;
        }
        
        return map;
    }, {}) : {};
    
    if (controller) controller.refresh = () => {
        console.warn("Refreshing...");
        if (refreshCount > 100) refreshCount = -1;
        setRefreshCount(refreshCount + 1);
    };

    /**
     * If there's a project start date (time: ticks), then we will map actual dates onto the calendar.
     * Let's leave this out for now.
     * @type {number}
     */
    let startTime = 0; // Math.min(...projects.map(p => p.startDate?.getTime() || 0));
    
    let projectStartDate = startTime > 10 ? new Date(startTime) : null;
    let weekDayOffset = (!!projectStartDate) ? projectStartDate.getDay() : 0;
    if (weekDayOffset > 0) projectStartDate = projectStartDate.addDays(-weekDayOffset);

    let projectMap = {};    // Used to quickly find a project by name, given a project id.
    let weekDaysOff = 7 - workDaysInAWeek;
    
    let dayCellWidth = 100; // Width of a day cell in pixels (2nd to the top header row)
    let weekCellWidth = dayCellWidth * workDaysInAWeek; // Width of a week cell in pixels (top header row)

    let roadmapMeta = ProjectService.instance.createProjectRoadmapMetaData(projects, isSingle, workDaysInAWeek);
    // let roadmapMeta = {
    //     startDate: startTime > 10 ? new Date(startTime) : null, // Actual date the project started. Null if it has not started yet
    //     firstDate: projectStartDate, // First date displayed in the calendar. Null if there is no start date
    //     weekCount: 0,
    //     dayCount: 0,
    //     weekHeaderCells: [],
    //     dayHeaderCells: [],
    // };

    projectMap = projects.reduce((map, project) => {
        map[project.id] = project;
        return map;
    }, projectMap);

    let minDate = null;
    let maxDate = null;

    let totalDays = 0;
    let totalWeeks = 0;

    /**
     * There are 7 classes that account for the colors of project cells: .cell.roadmap-cell.roadmap-cell-item-{n}, where n=0..6
     * This value is modded (%) by 7 to get the color class for a given project in an indexed list.
     * If you want to add more colors, you need to add more classes to the css file.
     */
    const projectColorsCount = 7;
    
    // Inserts a blank ProjectItem object at the start of the list so we can render it as a header row later
    // const createdHeadedItemsList = !isSingle ? (p, index) => { 
    //     if (!p?.items || p.items.length === 0) return p.items;
    //    
    //     let items = [...p.items];
    //     let item = items[0];
    //     let projectName = p.name;
    //     let i = p.index || (index || 0);
    //     let idx = (i % projectColorsCount);
    //    
    //     let json = {
    //         name: projectName,
    //         project_id: item.projectId,
    //         description: "project-index-header-" + (idx || 0).toString(),
    //         start_day: 0,
    //         duration: 0,
    //         id: null
    //     };
    //    
    //     let newItem = new ProjectItemModel(json);
    //    
    //     items.splice(0, 0, newItem);
    //    
    //     return items;
    // } : (p, index) => p.items;

    // Combine all projectItems from all projects into a single array
    // let items = projects.map((p, index) => createdHeadedItemsList(p, index)).flat();
    // let d = projectStartDate || new Date().addHours(100000);
    //
    // totalDays = Math.max(...items.map((item) => item.startDay + item.duration));
    // totalWeeks = Math.max(Math.ceil(totalDays / workDaysInAWeek), 1);
    //
    // // Adjust so that the last day falls on the Friday of the last week
    // totalDays = Math.max(totalDays, totalWeeks * workDaysInAWeek);
    //
    // roadmapMeta.weekCount = totalWeeks || 0;
    // roadmapMeta.dayCount = totalDays || 0;
    //
    let sd = projectStartDate;

    roadmapMeta.weekHeaderCells = roadmapMeta.weekCount > 0 ? (new Array(roadmapMeta.weekCount)).fill(0).map((x, index) => {
        let displayDate = "Week " + (index + 1).formatNumber(0);
        let date = sd?.addDays(1);
        
        if (date) {
            displayDate = date.formatDate() + " (" + displayDate + ")"; 
            sd = sd.addDays(7);
        }

        return {
            index: index,
            label: displayDate,
            date: date
        };
    }) : [];
    
    let weekCellElements = roadmapMeta.weekHeaderCells.map((x) => {
        let displayDate = x.label;
        let index = x.index;

        return (
            <span key={"week-cell-" + index.toString()} className={"cell"} id={"week-cell-" + index.toString()} style={{width: weekCellWidth.toString() + "px", minWidth: weekCellWidth.toString() + "px"}}>
                <a onClick={onWeekClick.bind(this, index)}>{displayDate}</a>
            </span>
        );
    });

    sd = projectStartDate;
    
    roadmapMeta.dayHeaderCells = roadmapMeta.dayCount > 0 ? (new Array(roadmapMeta.dayCount)).fill(0).map((x, index) => {
        let displayDay = (index + 1) - weekDayOffset;
        let label = "";
        let date = null;
        let dayData = null;

        if (displayDay >= 0) {
            label = "Day " + displayDay.formatNumber(0);

            if (sd) {
                let offset = (Math.floor(index / workDaysInAWeek) * weekDaysOff) + 1 - weekDayOffset; // Remove weekends (Sun=0, Sat=6)
                date = sd.addDays(offset + weekDayOffset);
                
                dayData = {
                    dayOfWeek: date.getDay(),
                    name: date.getWeekDay(false),
                    abbr: date.getWeekDay(true)
                };

                let md = date.getDate();
                label = dayData.abbr + ", " + date.getMonthName(true) + " " + md;
                sd = sd.addDays(1);
            }
        } else if (sd) {
            let offset = (Math.floor(index / workDaysInAWeek) * weekDaysOff) + 1; // Remove weekends (Sun=0, Sat=6)
            date = sd.addDays(offset);
            label = date.getWeekDay(true);
            sd = sd.addDays(1);
        }

        return {
            index: index,
            dayNumber: displayDay,
            label: label,
            day: dayData,
            date: date
        };
    }) : [];

    let dayCellElements = roadmapMeta.dayHeaderCells.map((cell) => {
        let index = cell.index;
        let label = cell.label;

        return (
            <span key={"day-cell-" + index.toString()} className={"cell"} id={"day-cell-" + index.toString()}>
                <a onClick={onDayClick.bind(this, index)}>{ cell.label }</a>
            </span>
        );
    });

    let body = null;
    let header = null;

    let dayMap = {};
    let nonWorkDayOffset = Math.max(weekDayOffset - weekDaysOff, 0);
    
    const tooltipClasses = {
        
    };
    
    const hw = (roadmapMeta.dayCount * dayCellWidth).toString() + "px";
    const projectHeaderStyle = { width: hw, minWidth: hw };
    const tooltipPlacement = "left";

    let itemCellRows = roadmapMeta.items.map((item, index) => {
        if (!item.id && !!item.projectId && !!item.name) {
            return (
                <Tooltip followCursor={true} key={"header-row-" + index.toString()} classes={tooltipClasses} arrow={true} title={"Show only " + item.name} placement={tooltipPlacement}>
                    <div className={"roadmap-project-header-row " + item.description} style={projectHeaderStyle} key={"header-row-" + index.toString()}>
                        <Link to={"/companies/" + company?.id + "/projects/" + item.projectId + "/roadmap"}>{item.name}</Link>
                    </div>
                </Tooltip>
            );

        }
        
        let projectStartDay = (projectStartDayMap[item.projectId] || 1);
        let itemStartDay = (item.startDay || 1);
        
        let projectStartDayOffset = projectStartDay - 1;
        let itemStartDayOffset = (itemStartDay - 1) + nonWorkDayOffset + projectStartDayOffset;
        let duration = item.duration || 1;
        
        let startPadding = itemStartDayOffset > 0 ? (<span className={"empty-roadmap-cell"} style={{width: (itemStartDayOffset * dayCellWidth).toString() + "px"}}><span>&nbsp;</span></span>) : null;
        let endPadding = (itemStartDayOffset + duration) < totalDays ? (<span className={"empty-roadmap-cell"} style={{width: ((totalDays - (itemStartDayOffset + duration)) * dayCellWidth).toString() + "px"}}><span>&nbsp;</span></span>) : null;

        let parentProject = projectMap[item.projectId];
        let tooltipText = parentProject?.name + ":";
        let cellClassName = "roadmap-cell-item-" + (parentProject?.data?.index || 0).toString();

        let cn = "";
        let completedIcon = (<></>);
        
        if (item.completeDate !== null) {
            cn = " completed"
            tooltipText = "Completed on " + item.completeDate.formatDate();
            completedIcon = (<FontAwesomeIcon icon={faCheckCircle} />);
        }
        
        let projectItemCell = (
            <span className={"cell roadmap-cell " + cellClassName + cn} style={{width: (duration * dayCellWidth).toString() + "px"}}>
            <a onClick={onProjectItemClick.bind(this, item)}>{completedIcon} {item.name}</a>
        </span>);

        return (
            <div className={"roadmap-calendar-row project-row"} key={"project-row-" + index.toString()}>
                {startPadding}
                <Tooltip followCursor={true} classes={tooltipClasses} arrow={true} title={tooltipText} placement={tooltipPlacement}>{projectItemCell}</Tooltip>
                {endPadding}
            </div>
        );
    });
    
    return (<div className={"roadmap-calendar-container"}>
        <div className={"roadmap-viewer"}>
            <div className={"roadmap-timeline-header weeks-header"}>{weekCellElements}</div>
            <div className={"roadmap-timeline-header days-header"}>{dayCellElements}</div>
            {itemCellRows}
        </div>
    </div>);
};

export default RoadmapCalendar;
