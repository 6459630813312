import React from 'react';
import MenuItem from "@mui/material/MenuItem";
import {ClickAwayListener, Tooltip} from "@mui/material";
import StyledMenu from './RoadmapDocumentItemMenu';
import UserBadge from "../../../people/ui/UserBadge";
import {Link} from "react-router-dom";
import {marked} from "marked";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDeleteLeft, faPenToSquare, faUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";

const RoadmapDocumentItemCell = (props) => {
    const {
        id,
        project,
        cellContainer, 
        height,
        cellHeight,
        onClick,
        onMoveClick,
        onEditItemClick,
        onDeleteItemClick,
        onTooltipClose,
        anchorEl, 
        selectedDayId, 
        onProjectItemDayClick,
        isToday,
    } = props;

    let key = "cell-item-" + id;

    const todayCn = isToday === true ? " today" : "";
    const x = cellContainer;
    const h = (cellHeight || height) || 64;
    const cellHeightStyle = { minHeight: h.toString() + "px", height: h.toString() + "px"};
    const dayIndex = cellContainer.day;
    const userBadgeTooltipClasses = {
        tooltipArrow: "user-badge-tooltip-arrow",
        tooltip: "user-badge-tooltip",
    };
    
    const onCellDayClick = (e) => {
        e.preventDefault();
        e.nativeEvent?.preventDefault();
        if (typeof onClick === 'function') onClick(dayIndex, project.id, e);
    };

    const onMoveItemClick = (projectItemDay, projectItem, e) => {
        if (typeof onMoveClick === 'function')
            onMoveClick(projectItemDay, e);
    };
    
    if (x.items.length === 0) {
        return (<div key={key + "-empty"} onClick={(e) => onCellDayClick(e)} className={"roadmap-document-column-item cell" + todayCn} style={cellHeightStyle}><span>&nbsp;</span></div>);
    }

    const onMenuClose = (e) => {
        if (typeof onTooltipClose === 'function') onTooltipClose(e);
    };

    const onDeleteClick = (item, e) => {
        e.preventDefault();
        console.log("Deleting item: " + item?.id);
        
        if (typeof onDeleteItemClick === 'function')
            onDeleteItemClick(item, e);
        else console.error("No delete function");
    }
    
    const onEditClick = (item, e) => {
        if (!item) {
            console.error("No item to edit");
            return;
        }
        e.preventDefault();
        
        if (typeof onEditItemClick === 'function')
            onEditItemClick(item, e);
    }
    
    const onProjectItemDayClickHandler = (projectItemDay, projectItem, e) => {
        e.preventDefault();
        e.nativeEvent?.preventDefault();

        if (typeof onProjectItemDayClick === 'function') {
            onProjectItemDayClick(projectItemDay, projectItem, e);
        }
    };

    const item = x.items[0];
    const title = item.name;
    const wrapClass = (x.items.length > 1) ? "wrap" : "";

    let menuId = "multi-menu-fade-" + key;
    let listProps = {'aria-labelledby': 'fade-button'};

    let labels = x.items.map((itm, idx) => {
        const st = { backgroundColor: itm.color };
        const user = (itm.users.length > 0) ? itm.users[0] : { name: "N/A"};
        const projectItemPath = "/companies/" + project.companyId + "/projects/" + itm.projectId + "/item/" + itm.id;
        
        let userCard = (<strong>No badge user</strong>);
        
        if (user?.id) {
            let cardBody = (<div className={"project-item-card"}>
                <div className={"project-item-project-title"}>{project?.name || "Sub-Project"}</div>
                <h2>
                    <Link to={projectItemPath}>{itm.name}</Link>
                </h2>
                <div>Day { (itm.days[0].index + 1) } of { itm.duration.formatNumber(0) }</div>
            </div>);
            
            userCard = (<span className={"project-item-card container"}>
                <UserBadge userLite={user} bodyPlacement={"top"} className={"roadmap"}>
                    {cardBody}
                </UserBadge>
                <span className={"project-item-card-options"}>
                    <Tooltip title={"Move"} placement={"right"}>
                        <a onClick={(e) => onMoveItemClick(selectedDay, itm, e)}><FontAwesomeIcon icon={faUpDownLeftRight} /></a>
                    </Tooltip>
                    <Tooltip title={"Edit Task"} placement={"right"}>
                        <a onClick={onEditClick.bind(this, itm)}><FontAwesomeIcon icon={faPenToSquare} /></a>
                    </Tooltip>
                    <Tooltip title={"Delete Task"} placement={"right"}>
                        <a onClick={onDeleteClick.bind(this, itm)}><FontAwesomeIcon icon={faDeleteLeft} /></a>
                    </Tooltip>
                </span>
            </span>);
        }

        const selectedDay = itm.days?.length > 0 ? itm.days[0] : null;
        const cn = !!selectedDay?.id && selectedDay?.id === selectedDayId ? "selected" : "";
        
        return (
            <Tooltip classes={userBadgeTooltipClasses} key={key + "-tool-" + idx} placement={"left"} arrow={true} title={userCard}>
                <label className={cn} key={key + "-project-item-day-item-" + idx} style={st}>
                    <span>
                        <Link to={projectItemPath}>{itm.name}</Link>
                    </span>
                </label>
            </Tooltip>
        );
    });
    
    return (
        <div key={key} id={menuId} onClick={(e) => onCellDayClick(e)} className={"roadmap-document-column-item cell project-item" + todayCn}>
            <span className={wrapClass} style={cellHeightStyle}>{ labels }</span>
        </div>);

    
};

export default RoadmapDocumentItemCell;
