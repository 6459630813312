import React, { useEffect, useState, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";
import CreditCardForm from "../../../components/finance/ui/CreditCardForm";
import AchForm from "../../../components/finance/ui/AchForm";
import UserService from "../../../components/people/services/UserService";
import PaymentMethodModel from "../../../components/finance/models/PaymentMethodModel";
import ConfigModel from "../../../components/common/models/ConfigModel";
import Controller from "../../../components/common/services/Controller";
import CopyText from "../../../components/common/ui/CopyText";
import CompleteDialog from "../../../components/common/ui/dialog-box/CompleteDialog";

/**
 * Gets and sets the credit card on file for a company's primary user.
 * So this is the house account's context, for the primary user's card on file.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentMethodsScreen = (props) => {
    const {} = props;
    const { companyId } = useParams();
    const [company, setCompany] = useState(null);
    const [payMeth, setPayMeth] = useState(0);

    const user = company?.primaryUser;
    const userId = user?.id || "";
    let _;
    
    const [paymentMethod, setPaymentMethod] = useState(UserService.instance.paymentMethodMap[userId] || null);
    const controller = useRef(new Controller()).current;
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    }
    
    const updateBillingAsync = async (cardModel, e) => {
        const json = cardModel.toJson();

        return await UserService.instance.updateCardOnFileAsync(userId, json, ConfigModel.companyId).then((result) => {
            if (!!result) {
                result.status = "updated";
                setPaymentMethod(result);
            }
            return false;
        });
    };

    const getPaymentMethodsAsync = async () => {
        await UserService.instance.getUserPaymentMethodAsync(userId, ConfigModel.companyId).then((pm) => {
            setPaymentMethod(pm || new PaymentMethodModel());
        }).catch((ex) => {
            console.warn("Nothing on file");
            setPaymentMethod(new PaymentMethodModel());
        });
    };

    useEffect(() => {
        if (paymentMethod?.status === "updated") {
            paymentMethod.status = null;
            setPaymentMethod(paymentMethod);
        }
    }, [paymentMethod]);
    
    useEffect(() => {
        if (!!company?.primaryUser?.id) _ = getPaymentMethodsAsync();
        
    }, [company]);
    
    const selection = ["", ""];
    const titles = ["Credit Card Details", "ACH Account Details"];
    const identifierElement = !!paymentMethod?.identifier ? (<span className={"identifier"}><CopyText text={paymentMethod.identifier}>Identifier: { paymentMethod.identifier }</CopyText></span>) : null;

    selection[payMeth] = "selected";
    
    const form = !!userId && userId.length > 30 ? (payMeth === 1 ? (<AchForm useButton={true} />) :
        (<CreditCardForm controller={controller} maskOnComplete={true} onSubmit={updateBillingAsync} creditCard={paymentMethod?.creditCard} useButton={true} useZip={true} />)) : null;
    
    return (
        <BillingMasterPage onCompany={onCompany} selection={"payment"} title={"Payment Methods"} bodyClassName={"x-large"}>
            <p>
                Below is the payment method on file for { company?.name}. The payment record is associated with the primary user of the company, <Link to={"/companies/" + companyId + "/people/" + userId}>{ user?.fullName }</Link>.
                {identifierElement}
            </p>
            
            <div className={"top-border medium dashed"}>
                <h3 className={"payment-method-header"}>
                    <span>{ titles[payMeth] }</span>
                        <span className={"view-selector"}>
                        <span>
                            <a onClick={() => setPayMeth(0)} className={selection[0]}>Credit Card</a>
                            <a onClick={() => setPayMeth(1)} className={selection[1]}>ACH/e-Check</a>
                        </span>
                    </span>
                </h3>
                
                { form }
            </div>
            <CompleteDialog controller={controller} title={"Success"} text={"The payment method has been updated."} />
        </BillingMasterPage>
    );
};

export default PaymentMethodsScreen;
