import React, {useEffect, useRef, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import ProjectService from "../../../components/projects/services/ProjectService";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import BackButton from "../../../components/common/ui/BackButton";
import StageDropdown from "../../../components/projects/ui/StageDropdown";
import {marked} from "marked";
import ProjectItemSubMenu from "../../../components/projects/ui/ProjectItemSubMenu";
import BulletArrow from "../../../components/common/ui/BulletArrow";
import CommentForm from "../../../components/messaging/ui/CommentForm";
import ProjectItemModel from "../../../components/projects/models/ProjectItemModel";
import DateTime from "../../../components/common/ui/DateTime";
import ConfirmDialogBox from "../../../components/common/ui/dialog-box/ConfirmDialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import NavigationHistoryService from "../../../components/activity/services/NavigationHistoryService";

const ProjectItemDetailsScreen = (props) => {
    let { companyId, projectId, projectItemId } = useParams();
    let { slug } = props;
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    let [project, setProject] = useState(ProjectService.instance.projectMap[projectId] || null);
    let [projectItem, setProjectItem] = useState(ProjectService.instance.projectItemMap[projectItemId] || null);
    let [projectItemStage, setProjectItemStage] = useState(projectItem?.stageType || null);
    let [redirectUrl, setRedirectUrl] = useState(null);
    
    let [confirmOpen, setConfirmOpen] = useState(null);
    let [controller, setController] = useState(new DialogBoxController("Are you sure?", (<p>This will be deleted.</p>)));
    
    /**
     * Component: MarkdownEditor
     */
    let [editMode, setEditMode] = useState(false);
    let editDescriptionRef = useRef();
    
    let _;

    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };
    
    const getProjectAsync = async () => {
        if (projectId.length < 30) {
            return;
        }

        await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            setProject(p);
        });
    };

    const getProjectItemAsync = async () => {
        if (projectId.length < 30) return;

        await ProjectService.instance.getProjectItemAsync(projectItemId).then((pi) => {
            setProjectItem(pi);
        });
    };

    /**
     * Component: MarkdownEditor
     */
    const toggleEditMode = (e) => {
        const tagName = e.target?.tagName?.toLowerCase() || "";
        if (tagName === "select" || tagName === "a") return;

        e.preventDefault();

        if (editMode) { 
            let hasFocus = document.activeElement === editDescriptionRef.current;
            if (hasFocus) return;
        }
        setEditMode(!editMode);
    };

    /**
     * Component: MarkdownEditor
     * @param e
     */
    const updateProjectItemText = async (e) => {
        let newText = e.target?.value;
        if (newText === projectItem?.description) {
            toggleEditMode(e);
            return;
        }
        
        await ProjectService.instance.updateProjectItemDescriptionAsync(projectItem.id, newText).then((item) => { 
            if (item) setProjectItem(item);
        });
    };
    
    const updateProjectItemStageAsync = async (newStage) => {
        await ProjectService.instance.updateProjectItemStageAsync(projectItem?.id, projectItem?.projectId, parseInt(newStage)).then((item) => {
            setProjectItemStage(newStage);
        });
    };
    
    const deleteProjectItemAsync = async (prompt, e) => {
        if (prompt === false) { 
            return await ProjectService.instance.deleteProjectItemAsync(projectItem.id).then((item) => {
                setRedirectUrl("/companies/" + companyId + "/projects/" + projectId);
            });
        }
        setConfirmOpen(true);
    };

    useEffect(() => {
        console.warn("confirmOpen Effect: " + confirmOpen);
        if (confirmOpen === null) return;
        
        if (!!controller?.open) controller.open();
    }, [confirmOpen]);

    useEffect(() => {
        if (confirmOpen !== null && !!redirectUrl) setConfirmOpen(false);
    }, [redirectUrl]);

    useEffect(() => {
        if (editMode) editDescriptionRef?.current?.focus();
        else if (!!projectItem) setProjectItemStage(project.stageType);
    }, [editMode]);

    useEffect(() => {
        if (editMode) setEditMode(false);
        else if (!!projectItem) {
            NavigationHistoryService.instance.addItem(company?.name + " Task: " + projectItem?.name);
            setProjectItemStage(projectItem.stageType);
        }

    }, [projectItem, company]);
    
    useEffect(() => {
        CompanyEffect.create(setCompany, companyId);
        _ = getProjectAsync();
        _ = getProjectItemAsync();
    }, []);
    
    if (typeof redirectUrl === 'string') {
        return (<Navigate to={redirectUrl} />);
    }

    if (!slug) slug = "";
    const backUrl = "/companies/" + companyId + "/projects/" + projectId + "/" + slug;
    
    const body = editMode ? (<p className={"form medium project-item x-large"} onClick={toggleEditMode}><textarea ref={editDescriptionRef} onBlur={updateProjectItemText} defaultValue={projectItem?.description} /></p>) :
        (<p className={"markdown has-details x-large"} onClick={toggleEditMode}>
            <span dangerouslySetInnerHTML={{ __html: marked.parse(projectItem?.description || "")}}></span>
        </p>);
    
    const subtitle = (<><BackButton to={backUrl} />
        <span className={"pre-subtitle"}>
            <Link to={"/companies/" + companyId + "/projects"}>Project:</Link>
        </span>
        <Link to={"/companies/" + companyId + "/projects/" + projectId}>{project?.name}</Link>
        <BulletArrow />
        <span className={"subtitle"}>{projectItem?.name}</span></>);
    
    const stageDropdownElement = projectItemStage !== null ? (<StageDropdown startDate={projectItem?.startDate} value={projectItemStage} onChange={updateProjectItemStageAsync} />) : null;
    
    return (
        <CompanyMasterPage subTitleClassName={"tree"} title={subtitle} company={company} selection={"projects"} onRefresh={getProjectItemAsync} onCompany={onCompany}>
            <div className={"submenu-flexer"}>
                <ProjectItemSubMenu project={project} projectId={projectId} projectItem={projectItem} company={company} companyId={companyId} selection={slug} />
                <span>
                    
                    { stageDropdownElement }
                </span>
            </div>
            
            {body}
            
            <label className={"detail-footer"}>
                <span>
                    Modified <DateTime value={projectItem?.modified || projectItem?.created} time={true} defaultValue={"Never"} />
                </span>
                <span className={"delete"}>
                    <a className={"action-button delete"} onClick={(e) => deleteProjectItemAsync(true, e)}>Delete</a>
                </span>
            </label>

            <div className={"comments"}>
                <CommentForm entityType={ProjectItemModel.entityType} entityId={projectItemId} />
            </div>
            <ConfirmDialogBox controller={controller} isOpen={confirmOpen} onOkay={async (e) => deleteProjectItemAsync(false, e)} />
            
        </CompanyMasterPage>        
    );

};

export default ProjectItemDetailsScreen;