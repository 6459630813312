import ProjectItemModel from "./ProjectItemModel";
import {faCompassDrafting} from "@fortawesome/free-solid-svg-icons";

class ProjectModel { 
    static entityType = 17;
    static icon = faCompassDrafting;
    static create = (json) => new ProjectModel(json);

    static defaultColors = [
        "#FF00000A",
        "#00FF000A",
        "#0000FF0A",
        "#00FFFF0A",
        "#FFFF000A",
        "#FF00FF0A",
    ];
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.companyId = json.company_id;
        this.userId = json.user_id;
        this.primaryContactUserId = json.primary_contact_user_id;
        this.imageId = json.image_id;
        this.name = json.name;
        this.description = json.description;
        this.stageType = json.stage_type;
        this.stageName = json.stage_name;
        this.completeDate = json.complete_date;
        this.remoteUri = json.uri || null;
        this.remoteId = json.remote_id || null;
        this.startDate = json.start_date ? new Date(json.start_date) : null;
        this.startDay = json.start_day || 1;
        this.estimatedStartDate = json.estimated_start_date ? new Date(json.estimated_start_date).toUtcDate() : null;
        this.color = this.color || "";
        this.price = json.price || 0;
        
        this.items = ProjectItemModel.fromJsonArray(json.items);
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
        this.data = {};
    }

    /**
     * Gets the project's start day, relative to the roadmap initial start DATE, if it exists
     * @param roadmapStartDate {Date}
     */
    getStartDay(roadmapStartDate) { 
        let startDay = this.startDay || 1;
        if (!roadmapStartDate || !this.startDate) return startDay;
        
        let diff = this.startDate.getTime() - roadmapStartDate.getTime();
        let day = Math.floor(diff / (1000 * 60 * 60 * 24));
        
        return day + startDay;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            primary_contact_user_id: this.primaryContactUserId,
            image_id: this.imageId,
            name: this.name,
            description: this.description,
            stage_type: this.stageType,
            complete_date: this.completeDate,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        
        let colorIndex = 0;
        
        return jsonArray.map((json, index) => {
            let model = new ProjectModel(json);
            model.data.index = index;
            
            if (!model.color) {
                model.color = ProjectModel.defaultColors[colorIndex];
                colorIndex++;
                
                if (colorIndex >= ProjectModel.defaultColors.length)
                    colorIndex = 0;
            }
            
            model.data.color = model.color;
            
            return model;
        });
    }
}

export default ProjectModel;
