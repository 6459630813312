import React, { useEffect, useState } from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import ContactRequestService from "../services/ContactRequestService";
import ErrorModel from "@jadecharles/pi-react-packages/dist/common/models/ErrorModel";
import DateTime from "@jadecharles/pi-react-packages/dist/common/ui/formatting/DateTime";
import DeleteButton from "@jadecharles/pi-react-packages/dist/common/ui/buttons/DeleteButton";
import ActivityLink from "../../common/ui/buttons/ActivityLink";
import ReactModal from "@jadecharles/pi-react-packages/dist/common/ui/modals/react/ReactModal";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

const ContactRequestDetails = (props) => {
    const { contactRequest, contactRequestId, onLoad } = props;
    const id = useParams().contactRequestId;
    const requestId = (contactRequestId || (contactRequest?.id || id));
    const [redirectUrl, setRedirectUrl] = useState("");
    const [request, setRequest] = useState(contactRequest || ContactRequestService.instance.contactRequestMap[requestId] || null);
    const [errors, setErrors] = useState(null);

    let _;
    
    const getContactRequest = async (force) => {
        if (!!request && !force) return;
        
        await ContactRequestService.instance.getContactRequestAsync(requestId).then((request) => {
            if (!force) ContactRequestService.instance.contactRequests = null;
            
            setRequest(request);
        });
    };

    const createUserAsync = async () => {
        const user = await ContactRequestService.instance.createUserFromContactRequestAsync(requestId);
        await ContactRequestService.instance.getContactRequestAsync(requestId);
        
        request.contactUserId = user.id;
        request.contactUser = user;
        
        setRequest({...request, contactUser: user, contactUserId: user.id});
    };

    const deleteContactRequestAsync = async (commit = false) => {
        const dialog = new ReactModal();

        if (!commit) {
            await dialog.openConfirmDialogAsync("This will remove this contact request from the system.", async () => {
                let errorMessage = "";

                const rsp = await ContactRequestService.instance.deleteContactRequestAsync(requestId).catch((ex) => {
                    const err = ErrorModel.createFormError(ex);
                    errorMessage = (err.general || ex?.response?.data?.message) || (ex?.message || "Unknown Error");
                    setErrors(err);

                    dialog.toAlertAsync("Fail: " + errorMessage);
                });
                
                if (!!rsp) { 
                    const options = {
                        title: "Good Job.",
                        onClick: () => {
                            setRedirectUrl("/contact-requests");
                        }
                    };

                    dialog.toAlertAsync("Contact request has been deleted.", options);
                    
                    return false;
                } else { 
                    dialog.toErrorAsync("Fail: " + errorMessage);
                    return false;
                }
            }, { title: "Are you sure?" });

        }

        return false;
    };

    useEffect(() => {
        if (!!request && typeof onLoad === 'function') onLoad(request);
    }, [request]);
    
    useEffect(() => { 
        getContactRequest();
    }, []);

    if (!!redirectUrl) { 
        return (<Navigate to={redirectUrl} />);
    }
    
    const readDateElement = !!request?.readDate ? (<div className={"read-date"}><DateTime value={request.readDate} time={true} prefix={"Read on: "} /></div>) : null;
    const replyDateElement = !!request?.replyDate ? (<div className={"reply-date"}><DateTime value={request.replyDate} time={true} prefix={"Replied on: "} /></div>) : null;
    const contacts = [];
    
    if (!!request?.email) contacts.push({ name: "Email", value: request.email, link: "mailto:" + request.email });
    if (!!request?.phone) contacts.push({ name: "Phone", value: request.phone.formatPhoneNumber(), link: "tel:" + request.phone });
    if (!!request?.website) contacts.push({ name: "Website", value: request.website, link: request.website });
    
    const contactElements = contacts.length > 0 ? contacts.map((c, i) => { 
        return (<div key={"contact-" + i} className={"contact"}>
            {c.name}: <a href={c.link} className={"name"}>{c.value}</a>
        </div>);
        
    }) : null;
    
    const userId = request?.contactUserId;
    const contactElement = !!contactElements ? (<div className={"contacts"}>{contactElements}</div>) : null;
    
    const userElement = !!userId ?
        (<span className={"contact-request-profile"}><Link to={"/companies/" + request.companyId + "/people/" + request.contactUserId}><FontAwesomeIcon icon={faUser} /> View Profile</Link></span>) :
        (<span><ActivityLink activity={"Creating..."} onClick={() => createUserAsync()}>+ Create User</ActivityLink></span>);
    
    return (<div className={"contact-request-details"}>
        <div className="error-box top wide">{errors?.general || ""}</div>

        <h4 className={"contact-info-header"}>
            { userElement }
            <span>Type: {request?.contactRequestTypeName}</span>
            <span>Date: <DateTime value={request?.created} time={true} /></span>

            { readDateElement }
            { replyDateElement }
        </h4>
        
        <div className={"contact-body"}>
            <div className={"contact-body-message"}>{ request?.message }</div>
            {contactElement}            
        </div>

        <div className="footer-action-panel">
            <DeleteButton onClick={() => deleteContactRequestAsync()}>Delete</DeleteButton>
        </div>
    </div>);
};

export default ContactRequestDetails;
