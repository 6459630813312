import CreditCardModel from "@jadecharles/pi-react-packages/dist/finance/models/CreditCardModel";

class PaymentMethodModel {
    constructor(json) {
        if (!json) json = {};

        this.id = json.id || null;
        this.name = json.name || null;
        this.mask = json.mask || null;
        this.display = json.display || "";
        this.identifier = json.identifier || null;
        this.remoteId = json.remote_id || null;
        this.paymentType = json.payment_type || 0;
        this.paymentTypeName = json.payment_type_name || null;
        this.status = null;

        this.creditCard = new CreditCardModel(json.data);
    }
}

export default PaymentMethodModel;
