import AuthModel from "../../authentication/models/AuthModel";
import AddressModel from "../../geo/models/AddressModel";
import ImageModel from "../../media/models/ImageModel";
import {faPerson} from "@fortawesome/free-solid-svg-icons";

class UserModel { 
    static entityType = 13;
    static icon = faPerson;
    
    static create = (json) => new UserModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.firstName = json.first_name || "";
        this.lastName = json.last_name || "";
        this.email = json.email || "";
        this.phone = json.phone || "";
        this.companyId = json.company_id || "";
        this.userType = json.user_type || 0;
        this.userTypeName = json.user_type_name || "";
        this.imageId = json.image_id || "";
        this.image = new ImageModel(json.image);
        this.addressId = json.address_id || "";
        this.address = new AddressModel(json.address);
        this.auth = new AuthModel(json.auth);
        this.roleId = typeof json.role_id === 'number' ? json.role_id : 0;
        this.role = json.role || null;
        this.status = json.status || 0;
        this.statusName = json.status_name || "Pending";
        this.id = json.id || "";
        this.companyName = json.company_name || "";
        this.imageUrl = !!json.image?.thumbs ? json.image?.thumbs[0]?.url : "";
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
        
        if (!this.firstName && !this.lastName) { 
            this.firstName = "";
            this.lastName = "";
        }
        
        this.fullName = (this.firstName + ' ' + this.lastName).trim();
    }

    /**
     * 
     * @param sizeTag
     * @returns {*|string}
     */
    getImageUrl(sizeTag) { 
        let thumbUrl = this.image?.thumbMap[sizeTag]?.url;
        return thumbUrl || (this.image?.url || '');
    }
    
    isValid() { 
        return !!this.id && this.id.length > 10; 
    }

    /**
     * 
     * @returns {{company_id: (*|string), phone: (*|string), last_name: (*|string), first_name: (*|string), email: (*|string)}}
     */
    toJson() { 
        return {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone: this.phone,
            company_id: this.companyId
        };
    }
    
    searchFor(term) {
        const name = (this.firstName + " " + this.lastName).trim().toLowerCase();
        if (name.indexOf(term) >= 0) return true;

        if (this.companyName && this.companyName.toLowerCase().indexOf(term) >= 0) return true;
        return (this.phone && this.phone.toLowerCase().indexOf(term) >= 0);
    }
    
    static fromJsonArray(jsonArray) {
        return jsonArray.map((x) => new UserModel(x));
    }
}

export default UserModel;
