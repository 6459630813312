import React, { useState, useEffect } from 'react';
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import CompanyService from "../../../components/companies/services/CompanyService";
import {useParams} from "react-router-dom";
import ActivitySubMenu from "../../../components/activity/ui/ActivitySubMenu";

const CompanyActivityMasterScreen = (props) => {
    const { title, subTitle, onCompany, onSessionChange, selection, children } = props;
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    
    const onCompanyHandler = (c) => {
        if (!!c) {
            if (typeof onCompany === 'function') onCompany(c);
            setCompany(c);
        }
    };
    
    const body = children || (<p>No Body Content set for this activity page.</p>)
    
    return (<CompanyMasterPage onCompany={onCompanyHandler} title={title} menuSelection={"activity"} onSessionChange={onSessionChange}>
            <ActivitySubMenu company={company} selection={selection} />
            
            { body }
        </CompanyMasterPage>
    );
};

export default CompanyActivityMasterScreen;

