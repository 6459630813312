class InvoiceItemModel {
    static entityType = 9;
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.invoiceId = json.invoiceId;
        this.description = json.description;
        this.name = json.name;
        this.tax = json.tax || 0;
        this.isTaxPercent = json.is_tax_percent === true;
        this.qty = typeof json.qty === 'number' ? json.qty : parseInt(json.qty || '0');
        this.price = typeof json.price === 'number' ? json.price : parseFloat(json.price || '0');
        
        if (isNaN(this.qty)) this.qty = 0;
        if (isNaN(this.price)) this.price = 0.0;
        
        this.amount = this.price * this.qty;
    }
    
    toJson() { 
        return { 
            name: this.name,
            price: this.price,
            tax: this.tax,
            is_tax_percent: this.isTaxPercent || false,
            qty: this.qty,
        };
    }
    
    getTaxAmount() {
        const subTotal = this.price * this.qty;
        if (typeof this.tax === 'number' && this.tax > 0) {
            let tx = this.tax;
            
            if (this.isTaxPercent === true) return (subTotal * tx);

            return tx;
        }        
    }
    
    getAmount() { 
        const subTotal = this.price * this.qty;
        
        if (typeof this.tax === 'number' && this.tax > 0) { 
            let tx = this.tax;
            if (this.isTaxPercent === true) return subTotal + (subTotal * tx);
            
            return subTotal + tx;
        }
        
        return subTotal;
    }
    
    static fromJsonArray(jsonArray) {
        let items = [];
        
        if (!jsonArray || !Array.isArray(jsonArray)) return items;
        
        for (let i = 0; i < jsonArray.length; i++) {
            let item = new InvoiceItemModel(jsonArray[i]);
            if (item.amount !== 0 || !!item.name) items.push(item);
        }
        
        return items;
    }
    
}

export default InvoiceItemModel;
