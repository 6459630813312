import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import HeaderButton from "../../components/common/ui/HeaderButton";
import {faPeopleCarryBox, faSync} from "@fortawesome/free-solid-svg-icons";
import CommentForm from "../../components/messaging/ui/CommentForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterScreen from "../../MasterScreen";
import ToolsSubMenu from "../../components/tools/ui/ToolsSubMenu";

const ToolsMasterPage = (props) => {
    const {
        onRefresh,
        onClick,
        onSessionChange,
        menuSelection,
        selection,
        selector,
        children,
        controller,
        data,
        title,
        subTitle,
        entityId,
        entityType,
        icon,
        commentEntityType,
        altMenu
    } = props;

    const params = useParams();

    const refreshAsync = async (e) => {
        let isAsync = (onRefresh?.constructor?.name === 'AsyncFunction');

        if (isAsync) {
            console.log("GETTING ALL :)");
            await Promise.all([
                onRefresh(),
            ]);
            return;
        }
        if (typeof onRefresh === "function")
            onRefresh(e);
    };

    useEffect(() => {
        //
    }, []);

    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this ToolsMasterPage. If you meant to have this blank, try passing a null value to the "company" property.</p>)
    const refreshButton = (typeof onRefresh === 'function') ? (<HeaderButton icon={faSync} onClick={refreshAsync}>Refresh</HeaderButton>) : null;
    const comments = typeof commentEntityType === 'number' && typeof entityId === 'string' && entityId.length > 10 ?
        (<CommentForm entityType={commentEntityType} entityId={entityId} />) :
        null;

    let h1 = (<><FontAwesomeIcon icon={icon || faPeopleCarryBox} />
        {title || "Tools and Productivity"}
    </>);

    const subMenu = (<ToolsSubMenu selection={selection || "main"} />);

    return (
        <MasterScreen selector={selector || "tools"} title={h1} onClick={onClick} onSessionChange={onSessionChange}>
            <div id="tools-productivity-main" className="pad">

                <div className="details-body">
                    { subMenu }

                    <div className="pad x-large">
                        <h2>
                            {subTitle || "Tools and Productivity Page"}
                            {refreshButton}
                        </h2>
                        { altMenu }
                        { body }
                        { comments }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );

};

export default ToolsMasterPage;


