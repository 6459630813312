import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import CompanyService from '../../../components/companies/services/CompanyService';
import MessageTemplateService from '../../../components/messaging/services/MessageTemplateService';
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import FormButton from "@jadecharles/pi-react-packages/dist/common/forms/FormButton";
import ReactModal from '@jadecharles/pi-react-packages/dist/common/ui/modals/react/ReactModal';
import MessageTemplateModel from '../../../components/messaging/models/MessageTemplateModel';
import ConfigModel from '@jadecharles/pi-react-packages/dist/common/models/ConfigModel';
import DeleteButton from '@jadecharles/pi-react-packages/dist/common/ui/buttons/DeleteButton';

const EmailMessageDetailsScreen = (props) => {
    const { companyId, templateId } = useParams();
    const templateList = MessageTemplateService.instance[companyId] || null;

    const initialState = {
        templateTypes: MessageTemplateService.instance.templateTypes,
        loading: 0,
    };

    const [errors, setErrors] = useState({ general: "" });
    const [screenState, setScreenState] = useState(initialState);
    const templateTypes = screenState.templateTypes;

    const getOrCreateTemplate = () => {
        if (typeof templateId === "string" && templateId.length > 30 && typeof templateList?.find === "function") {
            return templateList.find(tt => tt.id === templateId);
        }

        if (templateId?.toString().length > 0 && templateId.toString().length < 30 && !isNaN(parseInt(templateId))) {
            const tt = templateTypes?.find(tt => tt.value === parseInt(templateId?.toString()) || tt.value.toString() === templateId.toString());

            if (!!tt) return new MessageTemplateModel({ message_template_type: parseInt(templateId), name: tt.name, id: "000-000" });
        }

        return null;
    };

    const [template, setTemplate] = useState(getOrCreateTemplate());
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);

    const isNew = !templateId || templateId?.toString().length < 30;

    const nameRef = useRef();
    const subjectRef = useRef();
    const bodyRef = useRef();

    const setTemplateTypes = (st) => { 
        if (typeof st !== "object") return false;
        
        const newState = { ...screenState };
        newState.templateTypes = st;

        setScreenState(newState);

        return true;
    }

    const subtitle = (Array.isArray(templateTypes) && !!template?.id ?
        templateTypes?.find(tt => tt.value === template?.messageTemplateType || tt.value.toString() === templateId?.toString() )?.name : null) || "Template...";
    
    const getTemplatesAsync = async (companyId) => {
        console.warn("Getting Templates for CompanyId: " + companyId);
        return await MessageTemplateService.instance.getMessageTemplatesAsync(companyId);
    };
    
    const getHouseTemplateAsync = async (templateType = -1) => {
        if (templateType <= 0)
            templateType = template?.messageTemplateType || (!isNaN(parseInt(templateId || "---")) ? parseInt(templateId) : -1);

        const houseTemplates = await getTemplatesAsync(ConfigModel.houseCompanyId);
        const t = houseTemplates?.find((tt) => tt.messageTemplateType === templateType);

        if (!!t) return t;

        for (let i = 0; i < houseTemplates.length; i++) { 
            console.log(" > " + i + ".\t" + houseTemplates[i].messageTemplateType + "\t" + houseTemplates[i].name);
        }

        return null;
    };

    const onRefresh = async (force = false) => {
        if (template?.id > 30 && !force) return template;

        const messageTemplates = await getTemplatesAsync(companyId);
        const messageTemplate = typeof messageTemplates?.find === "function" ?
            messageTemplates.find(tt => tt.id === templateId) :
            null;

        if (!!messageTemplate?.id) {
            setTemplate(messageTemplate);
            return messageTemplate;
        } else if (isNew) {
            const mt = getOrCreateTemplate();
            if (!!mt) setTemplate(mt);
        } else {
            console.error("No template found: " + templateId);
        }

        return null;
    }

    const onCompany = (c) => { 
        setCompany(c);
    }

    const getTypesAsync = async (force = false) => {
        if (templateTypes?.length > 9 && !force) return templateTypes;

        return await MessageTemplateService.instance.getTemplateTypesAsync().then((types) => {
            if (types?.length > 0) {
                setTemplateTypes(types);
                return types;
            }
        });
    };

    const insertHouseTemplateAsync = async (e) => {
        const houseTemplate = await getHouseTemplateAsync();

        if (!houseTemplate?.id) { 
            console.error("No house template found for type: " + template?.messageTemplateType);
            return null;
        }

        const tm = { ...template || {} };
        tm.body = houseTemplate.body;
        tm.subject = houseTemplate.subject;

        setTemplate(tm);
    };

    const upsertTemplateAsync = async () => { 
        console.log("Click");

        const data = {
            name: nameRef.current?.value,
            body: bodyRef.current?.value,
            subject: subjectRef.current?.value,
            message_template_type: template?.messageTemplateType,
            template_source: null,  // TODO: Add this to the UI
            id: template?.id || null,
        };

        const err = {};
        if (!data?.name?.trim()) err.name = "Name is required";
        if (!data?.subject?.trim()) err.subject = "Subject is required";
        if (!data?.body?.trim()) err.body = "Body is required";

        if (Object.keys(err).length > 0) { 
            setErrors(err);
            return null;
        }

        const handleServerError = (ex) => {
            const dialog = new ReactModal();
            dialog.openAlertAsync(ex?.response?.data?.message || ex?.message || "Unknown error");
        };

        const model = isNew ?
            await MessageTemplateService.instance.createMessageTemplateAsync(data, companyId).catch(handleServerError) :
            await MessageTemplateService.instance.updateMessageTemplateAsync(data, companyId).catch(handleServerError);
        
        if (model?.id) {
            if (templateId !== model?.id) { 
                setRedirectUrl("/companies/" + companyId + "/messaging/email-messages/" + model.id);
                return true;
            }
            
            setTemplate(model);
            return true;
        }

        return false;
    };

    const deleteTemplateAsync = async () => { 
        await MessageTemplateService.instance.deleteMessageTemplateAsync(template?.id, companyId).catch((ex) => null);
        setRedirectUrl("/companies/" + companyId + "/messaging/email-messages");

        return true;
    }

    const promptDeleteAsync = async () => { 
        const dialog = new ReactModal();
        
        await dialog.openConfirmAsync("This email message will no longer be sent out.", (e) => {
            console.log("Delete :)");
        }, { title: "Are you sure?" });
    }

    useEffect(() => { 
        if (isNew && !template) {
            const t = getOrCreateTemplate();
            if (!!t) setTemplate(t);
        }
        
    }, [templateTypes]);

    useEffect(() => { 
        if (typeof redirectUrl === "string" && redirectUrl.length > 36) { 
            setRedirectUrl(null);
        } else { 
            onRefresh();
            getTypesAsync();
        }

    }, [redirectUrl]);

    if (typeof redirectUrl === "string" && redirectUrl.length > 36) {
        return (<Navigate to={redirectUrl} />);
    }

    const templateForm = template?.id ? (<div className="form wide" id="template-form">
        <div className="form-group" id="message-template-name-group">
            <label htmlFor="message-template-subject">Name:</label>
            <input type="text" id="message-template-name" ref={nameRef} defaultValue={ template?.name } />
            <div className="form-error">{errors?.name}</div>
        </div>

        <div className="form-group">
            <label htmlFor="message-template-subject">Subject:</label>
            <input type="text" id="message-template-subject" ref={subjectRef} defaultValue={ template?.subject } />
            <div className="form-error">{errors?.subject}</div>
        </div>

        <div className="form-group wide">
            <label htmlFor='message-template-textarea' onClick={insertHouseTemplateAsync}>Email Body:</label>
            <textarea id="message-template-body" ref={bodyRef} defaultValue={template?.body} />
            <div className="form-error">{errors?.body}</div>
        </div>

        <div className="buttons">
            <FormButton onClick={upsertTemplateAsync}>Update Message Template</FormButton>

            <div className="wide right">
                <DeleteButton onClick={promptDeleteAsync}>Delete</DeleteButton>
            </div>
        </div>
    </div>) : null;

    return (<CompanyMasterPage onCompany={onCompany} selection={"message-templates"} title={subtitle}>
        <p>{ template?.description }</p>
        { templateForm }
    </CompanyMasterPage>);
}

export default EmailMessageDetailsScreen;
