import React from 'react';
import {Link} from "react-router-dom";

const CompanyInvoiceSubMenu = (props) => {
    const { company, invoice, selection } = props;

    let companyId = company?.id || "00-00";
    let invoiceId = invoice?.id || "00-00";
    let sel = selection || 'invoices';
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";

    selections[sel] = 'selected';

    return (
        <div className={"submenu company" + cn}>
            <Link className={selections['invoices']} to={'/companies/' + companyId + '/invoices/' + invoiceId + ""}>Invoice Details</Link>
            <Link className={selections['logs']} to={'/companies/' + companyId + '/invoices/' + invoiceId + "/send-logs"}>Send Logs</Link>
        </div>
    );

};

export default CompanyInvoiceSubMenu;
