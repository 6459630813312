import React from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faBookSkull, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import DocumentationSubMenu from "../../components/documentation/ui/DocumentationSubMenu";
import DocumentationMasterPage from "../../components/documentation/ui/DocumentationMasterPage";
import CommentForm from "../../components/messaging/ui/CommentForm";
import RouteUriModel from "../../components/content/models/RouteUriModel";
import CopyText from "../../components/common/ui/CopyText";

const PlatformDocsScreen = (props) => {
    const {} = props;
    const routeId = "4AF7153B-D257-4B54-994E-D04D358CA891";

    return (<DocumentationMasterPage selector={"documentation"} icon={faBookSkull} selection={"docs"} subTitle={"Platform Documentation"}>
        <p>
            Just put some comments here for now. This way we can capture some of the things we need to do.<br/>
            Route Uri Id: <CopyText>{ routeId }</CopyText>
        </p>

        <CommentForm entityId={routeId} entityType={RouteUriModel.entityType} />
        
    </DocumentationMasterPage>);
    

};

export default PlatformDocsScreen;
