import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCardAlt, faDashboard, faIdCardClip, faImage, faPersonDigging, faProjectDiagram, faQrcode} from "@fortawesome/free-solid-svg-icons";
import {piFlutter} from "../../common/ui/svgs/LogoSvgs";

const ToolsSubMenu = (props) => {
    const { selection } = props;

    let sel = selection || 'main';
    let selections = {};
    selections[sel] = 'selected';

    return (
        <div className="sub-column">
            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['main']}><Link to={'/tools'}><FontAwesomeIcon icon={faIdCardClip} /> Overview</Link></li>
                <li className={selections['qr-codes']}><Link to={'/tools/qr-codes'}><FontAwesomeIcon icon={faQrcode} /> QR Codes</Link></li>
                <li className={selections['payments']}><Link to={'/tools/payments'}><FontAwesomeIcon icon={faCreditCardAlt} /> Payments</Link></li>
                <li className={selections['re-shaper']}><Link to={'/tools/image-re-shaper'}><FontAwesomeIcon icon={faImage} /> Image Re-Shaper</Link></li>
                <li className={selections['flutter']}><Link to={'/tools/flutter'}><FontAwesomeIcon icon={piFlutter} /> Flutter Tools</Link></li>
                <li className={selections['freelance']}><Link to={'/tools/freelance'}><FontAwesomeIcon icon={faPersonDigging} /> Freelancing</Link></li>
                <li className={selections['project-dashboard']}><Link to={'/tools/project-dashboard'}><FontAwesomeIcon icon={faDashboard} /> Projects Dashboard</Link></li>
            </ul>

        </div>
    );

};

export default ToolsSubMenu;
