import React, {useEffect, useState} from 'react';
import {
    faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import InvoiceService from "../../../components/billing/services/InvoiceService";
import DateTime from "../../../components/common/ui/DateTime";
import NumberDisplay from "../../../components/common/ui/NumberDisplay";
import AddNewHeaderButton from "../../../components/common/ui/AddNewHeaderButton";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";
import LoadingBox from '../../../components/common/ui/LoadingBox';
import EmptyBox from '../../../components/common/ui/EmptyBox';
import NavigationHistoryService from '../../../components/activity/services/NavigationHistoryService';

const InvoicesScreen = (props) => {
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    const [invoices, setInvoices] = useState(InvoiceService.instance.invoicesMap[companyId] || null);
    let _;

    const getInvoicesAsync = async (force) => {
        if (!force && invoices?.length > 0) { 
            return invoices;
        }
        
        await InvoiceService.instance.getInvoicesByCompanyAsync(companyId).then((invs) => {
            console.log('Count: ' + invs?.length);

            if (Array.isArray(invs)) { 
                setInvoices(invs);
                return invs;
            }

            return invoices;
        });
    };

    const refreshAsync = async () => {
        setInvoices(null);
        await getInvoicesAsync(true);
    };
    
    const onCompany = (c) => {
        setCompany(c);
    };
    
    useEffect(() => {
        if (invoices?.length > 0 && !!company?.id) { 
            NavigationHistoryService.instance.addDelayedItem(company.name + " Invoices", 2000);
        }

    }, [invoices, company]);

    useEffect(() => {
        getInvoicesAsync();
    }, []);

    let invoiceElements = invoices?.length > 0 ? invoices.map((invoice) => {
        let sentStatus = "Not Sent";
        let sentClassName = "";
        
        const isOverdue = (!invoice.paidDate && !!invoice?.dueDate && invoice.dueDate < new Date());
        let cn = isOverdue ? "overdue" : "";

        const dateOptions = {maxDays: 365, ago: "ago", month: "short", day: "numeric"};
        
        if (isOverdue) {
            sentStatus = (<><DateTime options={dateOptions} value={invoice.dueDate} showRelative={true}
                                      time={true}/> Past Due</>);
        } else if (!!invoice.paidDate) {
            sentClassName = "paid";
            cn = "paid";
            sentStatus = (<>Paid <DateTime options={dateOptions} value={invoice.paidDate} showRelative={true} format={"MMM DAY, hh:mm [ap]"} time={true} /></>);
        } else if (invoice.sentDate) {
            sentStatus = (<>Sent <DateTime value={invoice.sentDate} options={dateOptions} showRelative={true} format={"MMM DAY, hh:mm [ap]"} time={true} /></>);
            sentClassName = "sent";
            cn = "sent";
        }
        
        return (
            <tr key={invoice.id} className={("smaller " + cn).trim()} title={invoice.paymentId + " is the payment id"}>
                <td className={"normal"}><Link to={"/companies/" + companyId + "/invoices/" + invoice.id}><NumberDisplay value={invoice.total} type={"currency"} /></Link></td>
                <td>{invoice.label || "#" + invoice.invoiceNumberId.toString()}</td>
                <td className={"small-date invoice-status " + sentClassName}>{sentStatus}</td>
                <td className={"small-date"}><DateTime value={invoice.invoiceDate} time={true} /></td>
            </tr>
        );
    }) : (invoices === null ? (<LoadingBox />) : (<EmptyBox>No Invoice Items Yet.</EmptyBox>));

    /// companies/376fae42-b586-423c-9194-cbdf411060be/invoices/000-000/edit
    const subtitle = (<>Invoices <AddNewHeaderButton to={"/companies/" + companyId + "/invoices/000-000/edit"} icon={faFileInvoiceDollar}>Create Invoice</AddNewHeaderButton></>)
    
    const elementBody = invoices?.length > 0 ? (<table width={"100%"}>
        <thead>
        <tr>
            <th>Amount</th>
            <th>Number or Label</th>
            <th>Status</th>
            <th>Created</th>
        </tr>
        </thead>

        <tbody>
        {invoiceElements}
        </tbody>
    </table>) : invoiceElements;
    
    return (
        <BillingMasterPage onCompany={onCompany} onRefresh={refreshAsync} selection={"invoices"} title={subtitle}>
            <div>{elementBody}</div>            
        </BillingMasterPage>
    );
    
    
};

export default InvoicesScreen;