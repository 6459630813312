import React, {useEffect, useRef, useState} from 'react';
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import Pager from "../../../components/common/pager/Pager";
import PagerController from "../../../components/common/pager/PagerController";
import CompanyUserSubMenu from "../../../components/companies/ui/CompanyUserSubMenu";
import UserService from "../../../components/people/services/UserService";
import {Link, useParams} from "react-router-dom";
import BackButton from "../../../components/common/ui/BackButton";
import DateTime from "../../../components/common/ui/DateTime";
import CompanyService from "../../../components/companies/services/CompanyService";
import AuthenticationService from "../../../components/authentication/services/AuthenticationService";

const CompanyUserSessionsScreen = (props) => {
    let _;
    const { companyId, userId } = useParams();
    const {} = props;
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[companyId || ""] || null);

    const initUserData = {
        user: UserService.instance.userMap[userId || ""] || null,
        sessions: null,
    };
    
    const [userData, setUserData] = useState(initUserData);
    
    const onCompany = (c) => {
        setCompany(c);
    };

    const user = userData?.user;

    const getSessionsAsync = async (force) => {
        if (!userId) {
            console.warn("No UserId: " + userId);
            return;
        }

        console.warn("Getting User Sessions: " + userId);
        return AuthenticationService.instance.getUserSessionsAsync(userId, companyId);
    };

    const getUserAsync = async (force, e) => {
        if (!force && !!userData?.user) return userData?.user;

        return await UserService.instance.getUserAsync(userId).then((u) => {
            return u;
        });
    };

    const getAsync = async (force) => {
        await Promise.all([
            getUserAsync(force),
            getSessionsAsync(force),
        ]).then((values) => {
            const data = {
                user: values[0],
                sessions: values[1],
            };

            console.log("Session Count: " + data.sessions?.length);
            setUserData(data);

            if (data.user.companyId !== companyId && !userCompany) {
                console.log("Getting User's Company: " + data.user?.companyId);
                _ = CompanyService.instance.getCompanyAsync(data.user.companyId).then((c) => {
                    if (!!c) setUserCompany(c);
                });
            }
        });
    };
    
    useEffect(() => {
        getAsync(true);
    }, []);
    
    const sessions = userData?.sessions;

    let userName = !!user ? (user.firstName + " " + user.lastName).trim() : "User";
    let userSubMenu = user ? (<CompanyUserSubMenu user={user} company={company} selection={"sessions"} />) : null;

    const subtitle = (<><BackButton to={"/companies/" + companyId + "/people"} />
        <span className={"pre-subtitle"}>Sessions:</span>
        { userName }</>);

    const sessionElements = pagerController.mapLineItems(sessions, (h, i) => {
        return (<tr key={"activity-" + i}>
            <td><Link to={"/metrics/web-visitors/ip/" + h.ipAddress}>{h.ipAddress}</Link></td>
            <td><DateTime value={h.created} time={true} useTitle={true} useRelative={true} /></td>
        </tr>);
    });
    
    return (
        <CompanyMasterPage onCompany={onCompany} selection={"people"} title={subtitle} subMenu={userSubMenu} onRefresh={getSessionsAsync}>
            <p>Sessions for {userName}</p>

            <div>
                <table className={"table-x-large"} width={"100%"}>
                    <thead>
                        <tr>
                            <th>IP Address</th>
                            <th>Time</th>
                        </tr>
                    </thead>

                    <tbody>{sessionElements}</tbody>
                </table>

                <Pager controller={pagerController} items={userData?.sessions} />
            </div>
        </CompanyMasterPage>
    );
};

export default CompanyUserSessionsScreen;