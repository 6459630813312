import React, {useState, useEffect} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';

import {Link} from "react-router-dom";
import '../../components/tools/ui/Productivity.css';
import CompanyProjectsSubMenu from "../../components/companies/ui/CompanyProjectsSubMenu";
import IntegrationsList from "../../components/authentication/ui/IntegrationsList";

import Controller from "../../components/common/services/Controller";
import CompanyMasterPage from "../../components/companies/ui/CompanyMasterPage";
import CompanyModel from "../../components/companies/models/CompanyModel";

const CompanyIntegrationsScreen = (props) => {
    let _;
    const [company, setCompany] = useState();
    const controller = useState(new Controller())[0];
    const { slug } = props;
    const { companyId, projectId } = useParams();

    const onCompany = (c) => {
        if (!!c) {
            setCompany(c);
        }
    };

    
    useEffect(() => {
        //
    }, []);

    // const notConnected = (<strong>Not Connected</strong>);
    // const menuSelection = slug ? "projects" : "integrations";
    // const subtitle = slug ? "Project Integrations" : "Integrations";
    const submenu = slug ? (<CompanyProjectsSubMenu company={company} selection={"integrations"} />) : null;
    const returnUrl = "/companies/" + companyId + "/integrations";
    const integrationsElement = company ? (<IntegrationsList controller={controller} company={company} returnUrl={returnUrl} entityTypes={[CompanyModel.entityType]} />) : null;
    
    let paragraph = slug ? 
        (<p>
            Project Integrations are listed below. Company-wide integrations can be managed <Link to={"/companies/" + companyId + "/integrations"}>here</Link>.<br/>
            Alternatively, your individual integrations are listed in the <Link to={"/me/integrations"}>My Account Integrations</Link> section.<br/>
        </p>) :
        (<p>
        Below are all the integrations available to {company?.name}, including any that are specific to your individual account.<br/><br/>
        Integrations that are used in <Link to={"/companies/" + companyId + "/projects/content"}>projects</Link> are listed in <Link to={"/companies/" + companyId + "/projects/integrations"}>Project Integrations</Link>, specifically.<br/>
        Alternatively, your individual integrations are listed in the <Link to={"/me/integrations"}>My Account Integrations</Link>.
        </p>);
    
    return (
        <CompanyMasterPage onCompany={onCompany} selection={"integrations"} title={"Company Level Integrations"} bodyClassName={"large"}>
            {submenu}
            { paragraph }
            <div>
                { integrationsElement }
            </div>
        </CompanyMasterPage>
        
    );


};

export default CompanyIntegrationsScreen;