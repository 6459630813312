import React from 'react';
import NumberDisplay from "./NumberDisplay";
import {Link} from "react-router-dom";
import ReactModal from "@jadecharles/pi-react-packages/dist/common/ui/modals/react/ReactModal";
import Dark from "../../Dark";

const JumboList = (props) => {
    const { value, items, decimalPlaces, children, reverse, count } = props;
    
    const numberElement = typeof value === "object" ? (<>{value || null}</>) :
        (<h1><NumberDisplay value={typeof value === "number" ? value : items?.length || 0} decimalPlaces={decimalPlaces || 0} /></h1>);
    
    const getFilteredItems = () => {
        if (!(count > 0)) return items;
        
        return (count > 0 ? items.filter((_, i) => i < count) : items);
    };
    
    const getItemDisplay = (item, index) => {
        if (Array.isArray(item.value)) { 
            return item.value.map((v) => getItemDisplay(v, index));
        }
            
        if (!item.display && item.display !== 0)
            return (<>{item?.text || item?.caption || (item?.toString() || "")}</>);

        const allowedTypes = ["string", "object"];
        const itemType = (typeof item.display).toLowerCase();

        if (itemType === "string") return (<>{item.display}</>);
        if (Dark.isReact(item.display)) return item.display;
        if (typeof item.display === "function") return item.display(item, index);
        if (typeof item.display === "object") return (<>{item.display.toString()}</>);
        
        return (<>Type Error: {itemType}</>);
    };
    
    const createListBody = () => {
        const elements = getFilteredItems().map((item, index) => {
            const href = item?.href || item?.to || item?.path || null;
            const onClick = typeof item?.onClick === "function" ? item.onClick : null;
            const key = "list-item-" + index;
            const caption = getItemDisplay(item, index);
            
            const element = !!href ?
                (<Link to={href}>{caption}</Link>) :
                (!!onClick ? (<a onClick={(e) => onClick(e)}></a>) : (typeof item === "string" ? (<a>{item}</a>) : (<a>{caption}</a>)));
            
            return (<li key={key}>{element}</li>);
        });
        
        return (<ul>{elements}</ul>);
    };
    
    const body = !!children ? children : 
        Array.isArray(items) && items.length > 0 ? createListBody() : null;
    
    if (!body) return null;
    
    return (<div className={"jumbo-list black"}>
        {reverse !== true ? numberElement : null }
        <div>
            {body}
        </div>
        {reverse === true ? numberElement : null }
    </div>);
};

export default JumboList;
