import React, {useState, useEffect} from 'react';
import MetricsMasterScreen from "./MetricsMasterScreen";
import {faArrowTrendUp, faDesktop, faMobileRetro, faRobot} from "@fortawesome/free-solid-svg-icons";
import DateTime from "../../components/common/ui/DateTime";
import {Link, useParams} from "react-router-dom";
import ActivityService from "../../components/activity/services/ActivityService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const WebVisitorDetails = (props) => {
    const {} = props;
    const { webVisitorId } = useParams();
    const [webVisitor, setWebVisitor] = useState(WebVisitorDetails.webVisitor || null);

    const getWebVisitor = async (force = false) => {
        if (!force && !!webVisitor?.id) return webVisitor;
        
        const visitor = await ActivityService.instance.getWebVisitorAsync(webVisitorId);
        
        setWebVisitor(visitor);
    };
    
    useEffect(() => {
        let _ = getWebVisitor(true);
    }, []);
    
    const visitorUrl = !!webVisitor ? webVisitor.domainName + (webVisitor.path === "/" ? "" : webVisitor.path) : null;
    const webSessionElement = webVisitor?.webSession ? (<li><strong>Web Session :</strong> <Link to={"/metrics/web-visitors/web-session/" + webVisitor.webSession}>{webVisitor?.webSession}</Link></li>) : null;
    const sessionElement = webVisitor?.sessionId ? (<li className={"authed-item"}><strong>SessionId:</strong> <Link to={"/metrics/web-visitors/session/" + webVisitor.sessionId}>{ webVisitor.sessionId }</Link></li>) : null;
    const webSessionLabelElement = !!webVisitor?.label?.id ? (<li><strong>Label:</strong> <Link to={"/metrics/web-visitors/web-session/" + webVisitor.webSession}>{webVisitor?.label?.name}</Link></li>) : null; 
    const deviceIcon = webVisitor?.isBot === true ? faRobot : (webVisitor?.isMobile === true ? faMobileRetro : faDesktop);
    const referrerElement = !!webVisitor?.referrer ? (<li><strong>Referrer:</strong> <Link to={"/metrics/web-visitors/referrer/" + webVisitor?.referrer}>{webVisitor?.referrer}</Link></li>) : null;
    
    const userElement = webVisitor?.user?.id ? 
        (<li><strong>User:</strong> <Link to={"/companies/" + webVisitor.user.companyId + "/people/" + webVisitor.user.id}>{webVisitor.user.fullName}</Link></li>) :
        null;
    
    return (<MetricsMasterScreen selector={"metrics"} selection={"web-visitors"} icon={faArrowTrendUp} title={"Growth Metrics"} subTitle={"Web Visitor: " + webVisitor?.ipAddress} onRefresh={() => getWebVisitor(true)}>
            <p>
                Web Visitor Details: {visitorUrl}
            </p>

            <div>
                <ul className={"analytics-details"}>
                    {webSessionLabelElement}
                    {sessionElement}
                    {userElement}
                    <li><FontAwesomeIcon icon={deviceIcon} /><strong style={{marginLeft: "8px", display: "inline-block"}}>Device:</strong> {webVisitor?.deviceName}</li>
                    <li><strong>Date:</strong> <DateTime value={webVisitor?.created} time={true} /></li>
                    {webSessionElement}
                    <li><strong>IP Address:</strong> <Link to={"/metrics/web-visitors/ip/" + webVisitor?.ipAddress || ""}>{ webVisitor?.ipAddress }</Link></li>
                    <li><strong>Domain Name:</strong> <Link to={"/metrics/web-visitors/domain-name/" + webVisitor?.domainName || ""}>{ webVisitor?.domainName }</Link></li>
                    <li><strong>Path:</strong> { webVisitor?.path }</li>
                    <li><strong>Id :</strong> { webVisitor?.id }</li>
                    {referrerElement}
                </ul>
                
                <div className={"user-agent-section"}>
                    <h4>User-Agent</h4>
                    { webVisitor?.userAgent}
                </div>
                
                <div className={"user-agent-section smaller"}>
                    <pre>{JSON.stringify(webVisitor, null, 4)}</pre>
                </div>
            </div>
        </MetricsMasterScreen>
    );};

WebVisitorDetails.webVisitor = null;

export default WebVisitorDetails;
