import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import HeaderButton from "../../common/ui/HeaderButton";
import {faBookSkull, faBriefcase, faSync} from "@fortawesome/free-solid-svg-icons";
import CommentForm from "../../messaging/ui/CommentForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MasterScreen from "../../../MasterScreen";
import DocumentationSubMenu from "./DocumentationSubMenu";

const DocumentationMasterPage = (props) => {
    const {
        onRefresh,
        selection,
        selector,
        children,
        controller,
        data,
        title,
        subTitle,
        entityId,
        entityType,
        icon,
        commentEntityType,
        bodyClassName,
        altMenu
    } = props;

    const params = useParams();

    const refreshAsync = async (e) => {
        let isAsync = (onRefresh?.constructor?.name === 'AsyncFunction');
        if (isAsync) return await onRefresh(e);

        if (typeof onRefresh === "function")
            onRefresh(e);
    };

    useEffect(() => {
        //
    }, []);

    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this DocumentationPasterPage.</p>)
    const refreshButton = (typeof onRefresh === 'function') ? (<HeaderButton icon={faSync} onClick={refreshAsync}>Refresh</HeaderButton>) : null;
    const comments = typeof commentEntityType === 'number' && typeof entityId === 'string' && entityId.length > 10 ?
        (<CommentForm entityType={commentEntityType} entityId={entityId} />) :
        null;

    let h1 = (<><FontAwesomeIcon icon={icon || faBookSkull}/>
        {title || "Documentation"}
    </>);

    const docsMenu = (<DocumentationSubMenu selection={selection} />);
    const cn = typeof bodyClassName === 'string' ? " " + bodyClassName : "";
    
    return (
        <MasterScreen selector={selector} title={h1}>
            <div id="company-main" className="pad">

                <div className="details-body">
                    { docsMenu }

                    <div className={"pad " + (bodyClassName || "x-large")}>
                        <h2>
                            {typeof subTitle === 'undefined' ? "Documentation" : subTitle}
                            {refreshButton}
                        </h2>
                        { altMenu }
                        { body }
                        { comments }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );

};

export default DocumentationMasterPage;

