/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';

import "@jadecharles/pi-react-packages/dist/common/ui/modals/Modal.css";
import './components/search/ui/Search.css';
import './App.css';
import './mobile.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandsClapping, faHome} from "@fortawesome/free-solid-svg-icons";

import AuthenticationService from "./components/authentication/services/AuthenticationService";
import CommonMasterPage from "./components/common/ui/CommonMasterPage";
import SearchBox from "./components/search/ui/SearchBox";
import Controller from "./components/common/services/Controller";
import NavigationHistory from "./components/activity/ui/NavigationHistory";
import ProjectService from "./components/projects/services/ProjectService";
import ProjectItemsDue from "./components/projects/ui/ProjectItemsDue";
import LineGraph from "./components/analytics/ui/line-graph/LineGraph";
import UserService from "./components/people/services/UserService";
import HttpService from './components/common/services/HttpService';
import JumboList from "./components/common/ui/JumboList";
import ActivityTable from "./components/activity/ui/ActivityTable";

function App() {
    let [user, setUser] = useState(AuthenticationService.instance.session?.user || null);
    let [myState, setMyState] = useState(0);
    let [searchController, setSearchController] = useState(new Controller());
    let [userProjectItems, setUserProjectItems] = useState(ProjectService.instance.userProjectsDueMap[user?.id] || null);
    
    const [userSeries, setUserSeries] = useState(null);
    let _;
    
    const onSessionChange = (session) => {
        if (!!session?.user) setUser(session.user)
    };
    
    const getProjectItemsAsync = async (force) => {
        if (!user?.id) return;
        if (!force && !!userProjectItems && userProjectItems.length > 0) return userProjectItems;
        
        await ProjectService.instance.getProjectItemsDueByUserAsync().then((items) => {
            if (!!items) setUserProjectItems(items);
        });
    };

    let urg = false;
    
    const getUserReportAsync = async (force) => {
        if (urg === true) { 
            console.warn("Getting already. Aborting.");
            return;
        }
        
        urg = true;
        
        await UserService.instance.getUserSeriesByDayAsync().then((series) => {
            urg = false;
            
            if (!!series) {
                series.color = "yellow";
                setUserSeries(series);
            } else { 
                throw new Error("No series returned.");
            }
        }).catch((ex) => {
            urg = false;
        });
    };
    
    useEffect(() => {
        getProjectItemsAsync();
        getUserReportAsync();
    }, [user]);

    useEffect(() => {
    }, []);
    
    const name = user?.firstName || 'Fellow Traveller';
    const welcomeMessage = "Hello, " + name;
    
    const subtitle = (<><FontAwesomeIcon icon={faHandsClapping} /> {welcomeMessage}</>);
    const myProjectsElement = !!userProjectItems ? (<ProjectItemsDue userId={user?.id} items={userProjectItems} />) : null;
    
    const usersTitle = (<h3 onClick={() => getUserReportAsync(true)}>No new users recently</h3>);
    const lineGraph = userSeries?.items?.length > 0 ? (<LineGraph empty={usersTitle} title={"New Users"} series={userSeries} />) : null;
    
    const overdueItems = [
        { 
            caption: "Jocko Publishing Invoice #3403", 
            value: 12345,
            display: null,
            path: "/companies/d6829b18-b64b-4012-b41d-5c8ac26f5937/invoices/2e53f177-43c4-4aa2-97b4-255c8459a78a", 
        },
        {
            caption: "Paylani Invoice #4349389",
            value: 32,
            display: null,
            path: "",
        },
    ];
   
    const myActiveItems = Array.isArray(userProjectItems) ? userProjectItems.map((item, index) => {
        return {
            caption: item.name,
            value: item.created,
            path: "/",
        };
    }) : null;
    
    const activityOptions = {
        startDate: new Date().addDays(-14),
        reverse: true,
        pager: false,
        count: 7,
    };
    
    return (<CommonMasterPage title={"Dashboard"} onSessionChange={onSessionChange} subTitle={subtitle} icon={faHome} onClick={(e) => {
          console.log("Close Click.");
          searchController.close(e);
      }}>
            <SearchBox controller={searchController} />

            <div className={"dashboard-row"}>
                {lineGraph}
            </div>
        
            <div className={"dashboard-columns even"}>
                <div className={"pod small"}><ActivityTable options={activityOptions} /></div>
                <div className={"pod"}>
                    <h4>Open Items</h4>
                    <JumboList items={overdueItems} count={4} />
                    <h4>Projects Pending</h4>
                    <JumboList items={myActiveItems} count={4} />
                </div>
            </div>
      </CommonMasterPage>);
}

export default App;
