import React from 'react';
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookAtlas,
    faBookSkull, faSpellCheck
} from "@fortawesome/free-solid-svg-icons";
import {piGoogleIcon, piWindowsIcon} from "../../common/ui/svgs/LogoSvgs";

const DocumentationSubMenu = (props) => {
    const { selection } = props;
    const letter = useParams();
    
    let sel = selection || 'main';
    let selections = {};
    selections[sel] = 'selected';
    
    return (
        <div className="sub-column">
            <ul>
                <li className="sub-menu-header">
                    Documentation
                </li>
                <li className={selections['main']}><Link to={'/documentation'}><FontAwesomeIcon icon={faBookSkull} /> Main</Link></li>
                <li className={selections['docs']}><Link to={'/documentation/docs'}><FontAwesomeIcon icon={faBookAtlas} /> Platform Docs</Link></li>
                <li className={selections['google']}><Link to={'/documentation/google'}><FontAwesomeIcon icon={piGoogleIcon} /> Google</Link></li>
                <li className={selections['azure']}><Link to={'/documentation/azure'}><FontAwesomeIcon icon={piWindowsIcon} /> Azure</Link></li>
                <li className={selections['glossary']}><Link to={'/documentation/glossary'}><FontAwesomeIcon icon={faSpellCheck} /> Glossary</Link></li>
            </ul>

        </div>
    );
    
};

export default DocumentationSubMenu;
