import React from 'react';
import {Link} from "react-router-dom";

const BillingSubMenu = (props) => {
    let {company, companyId, selection} = props;

    if (!companyId) companyId = company?.id || "00-00";

    let sel = selection || 'main';
    let selections = {};
    let cn = companyId.length < 10 ? " disabled" : "";

    selections[sel] = 'selected';

    const path = "/companies/" + companyId;
    
    return (
        <div className={"submenu project" + cn}>
            <Link className={selections['main']} to={path + "/billing/"}>Main</Link>
            <Link className={selections['invoices']} to={path + "/invoices"}>Invoices</Link>
            <Link className={selections['transactions']} to={path + "/billing/transactions"}>Tranactions</Link>
            <Link className={selections['payment']} to={path + "/billing/payment-methods"}>Payment Methods</Link>
        </div>
    );
};

export default BillingSubMenu;
