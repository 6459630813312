import UserModel from "../../people/models/UserModel";

class LandingPageModel {
    static entityType = 45;
    static create = (json) => new LandingPageModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.companyId = json.company_id || null;
        this.domainId = json.domain_id || null;
        this.name = json.name || null;
        this.path = json.path || null;
        
        this.user = UserModel.create(json.user);
        if (!this.user?.id) this.user = null;
        
        this.sorting = json.sorting || 0;
        this.content = json.content || null;
        this.status = json.status || 0;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            company_id: this.companyId,
            domain_id: this.domainId,
            name: this.name,
            path: this.path,
            sorting: this.sorting,
            content: this.content,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.name?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.path?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.sorting?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.content?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => LandingPageModel.create(json));
    }
}

export default LandingPageModel;
