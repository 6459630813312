import React, { useRef, useEffect, useState } from 'react';
import FormButton from "../../common/ui/FormButton";
import UserModel from "../models/UserModel";
import ConfigModel from "../../common/models/ConfigModel";
import ErrorResponse from "../../common/models/ErrorResponse";

const PersonForm = (props) => {
    const { user, title, onSubmit, companyId, buttonCaption } = props;
    const [errors, setErrors] = useState({});
    
    let userJson = {
        first_name: useRef(null),
        last_name: useRef(null),
        email: useRef(null),
        phone: useRef(null),
    };
    
    const onPersonUpdateAsync = async () => {
        if (typeof onSubmit !== 'function') {
            setErrors({ general: 'Invalid onSubmit function'});
            return;
        }
        
        let status = 0;
        if (companyId === ConfigModel.companyId) status = 1;
        
        let json = {
            first_name: userJson.first_name.current.value,
            last_name: userJson.last_name.current.value,
            email: userJson.email.current.value,
            phone: userJson.phone.current.value,
            company_id: companyId || ConfigModel.companyId,
            status: status,
            id: user?.id
        };

        await onSubmit(new UserModel(json)).catch((error) => {
            const err = new ErrorResponse(error, "An unexpected error occurred");
            const jsonError = err.toFormError();
            console.error(JSON.stringify(jsonError));
            setErrors(jsonError);
        });
    };
    
    useEffect(() => {
        userJson.first_name.current.value = user?.firstName || "";
        userJson.last_name.current.value = user?.lastName || "";
        userJson.email.current.value = user?.email || "";
        userJson.phone.current.value = user?.phone || "";
    }, []);
    
    const onPersonFormChange = (fieldId, event) => {
        setErrors({});
    };
    
    const titleElement = !!title ? (<h3 className={"person-form-title"}>{title}</h3>) : null;
    const errorElement = (<div className={"form-error"}>{errors?.general}</div>);
    
    return (<div className={"person-form"}>
        {titleElement}
        <div className={"double"}>
            <div className={"half"}>
                <label>First Name</label>
                <input type={"text"} ref={userJson.first_name} onChange={onPersonFormChange.bind(this, "first_name")} />
                <div className={"form-error"}>{errors?.first_name}</div>
            </div>
            <div className={"half"}>
                <label>Last Name</label>
                <input type={"text"} ref={userJson.last_name} onChange={onPersonFormChange.bind(this, "last_name")} />
                <div className={"form-error"}>{errors?.last_name}</div>
            </div>
            
        </div>

        <label>Email:</label>
        <input type={"text"} ref={userJson.email} onChange={onPersonFormChange.bind(this, "email")} />
        <div className={"form-error"}>{errors?.email}</div>
        
        <label>Phone Number:</label>
        <input type={"text"} ref={userJson.phone} onChange={onPersonFormChange.bind(this, "phone")} />
        <div className={"form-error"}>{errors?.phone}</div>
        
        <div className={"buttons"}>
            {errorElement}
            <FormButton onClick={onPersonUpdateAsync}>{ buttonCaption || "Update Details"}</FormButton>
        </div>

    </div>);
};

export default PersonForm;