import React, {useEffect, useState} from 'react';
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import MyAccountMasterPage from "../../components/my-account/ui/MyAccountMasterPage";
import PersonForm from "../../components/people/ui/PersonForm";
import UserService from "../../components/people/services/UserService";

const MyAccountEditScreen = (props) => {
    const [user, setUser] = useState(AuthenticationService.instance.session?.user || null);
    const [userJson, setUserJson] = useState(user?.toJson() || null);

    const onUser = (u) => {
        setUser(u);
    };

    const updateUserAsync = async (userModel) => {
        console.log('Press');

        await UserService.instance.updateUserAsync(userModel).then((uu) => {
            AuthenticationService.instance.getSessionAsync(true).then((s) => {
                onUser(uu);
            });

        });
    };

    useEffect(() => {
        console.warn("Edit Screen Got User");
    }, [user]);

    const name = user?.firstName || 'Fellow Traveller';
    const welcomeMessage = "Edit Your Details";

    const userForm = !!userJson ? (<PersonForm user={user} onSubmit={updateUserAsync} />) : null;

    return (
        <MyAccountMasterPage title={"My Account"} onUser={onUser} subTitle={welcomeMessage} selection={"edit"}>
            <div className={"form medium"}>
                { userForm }
            </div>
        </MyAccountMasterPage>
    )

};

export default MyAccountEditScreen;

