import React, {useState, useEffect, useRef} from 'react';
import {
    faArrowTrendUp,
    faComputerMouse
} from "@fortawesome/free-solid-svg-icons";
import MetricsMasterScreen from "./MetricsMasterScreen";
import ActivityService from "../../components/activity/services/ActivityService";
import {Link, useParams} from "react-router-dom";
import NumberDisplay from "../../components/common/ui/NumberDisplay";
import DateRangeSelector from "../../components/common/ui/date-range/DateRangeSelector";
import Controller from "@jadecharles/pi-react-packages/dist/common/controllers/Controller";
import HttpService from "../../components/common/services/HttpService";

const GrowthMetricsScreen = (props) => {
    const [leaderBoards, setLeaderBoards] = useState({ domainName: [], ipAddress: [], webSession: [] });
    const paths = {domainName: "domain-name", ipAddress: "ip", webSession: "web-session"};
    const dateController = useState(new Controller())[0];
    const extraTitle = "";

    const getDates = () => {
        const sd = dateController?.startDate || null;
        const ed = dateController?.endDate || null;
        return { startDate: sd, endDate: ed };
    };

    const getLeaderBoardsAsync = async (force = false) => {
        const options = getDates();
        options.count = 8;
        
        await Promise.all([
            ActivityService.instance.getLeaderBoardAsync("DomainName", options),
            ActivityService.instance.getLeaderBoardAsync("IpAddress", options),
            ActivityService.instance.getLeaderBoardAsync("WebSession", options),
        ]).then((itemLists) => { 
            const boards = {...leaderBoards};
            boards.domainName = itemLists[0];
            boards.ipAddress = itemLists[1];
            boards.webSession = itemLists[2];
            
            setLeaderBoards(boards);
        });
    };
    
    useEffect(() => {
        let _ = getLeaderBoardsAsync();
    }, []);
    
    const createElements = (fieldName) => { 
        const items = Array.isArray(leaderBoards[fieldName]) ? leaderBoards[fieldName] : [{ label: "Toast", value: 10 }];
        const path = paths[fieldName] || null;
        
        return items.map((item, index) => {
            const key = "item-" + index + "";
            const count = item.value;
            const url = "/metrics/web-visitors/" + path + "/" + item.name;
            
            return (<tr key={key}>
                <td><Link to={url}>{item.name}</Link></td>
                <td><NumberDisplay value={count} /></td>
            </tr>);
        });
    };
    
    const st12 = {marginBottom: "16px"};
    
    return (<MetricsMasterScreen bodyClassName={"medium"} selector={"metrics"} icon={faArrowTrendUp} title={"Growth Metrics"} subTitle={"Leaderboard" + extraTitle} onRefresh={() => getLeaderBoardsAsync(true)}>
            <p className={"large"} style={st12}>
               This area will show the analytics for all companies, demonstrating their performance.
                <br/>My IP: <strong>{HttpService.instance.ipAddress}</strong>
            </p>
            
            <div style={{marginBottom: "32px"}}>
                <DateRangeSelector range={"today"} controller={dateController} onClick={async (e) => await getLeaderBoardsAsync(true)} />
            </div>
            
            <div className={"grid thirds"}>
                <div>
                    <h3>Domain Names</h3>
                    <table className={"table table-small"}>
                        <thead>
                            <tr>
                                <th>Domain Name</th>
                                <th>IPs</th>
                            </tr>
                        </thead>
                        <tbody>{createElements("domainName")}</tbody>
                    </table>
                </div>
                <div>
                    <h3>Web Sessions</h3>
                    <table className={"table table-small"}>
                        <thead>
                        <tr>
                            <th>Session Name</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>{createElements("webSession")}</tbody>
                    </table>
                </div>
                <div>
                    <h3>IP Addresses</h3>
                    <table className={"table table-small"}>
                        <thead>
                        <tr>
                            <th>Ip Address</th>
                            <th>Count</th>
                        </tr>
                        </thead>
                        <tbody>{createElements("ipAddress")}</tbody>
                    </table>
                </div>
            </div>
        </MetricsMasterScreen>
    );

};

export default GrowthMetricsScreen;
