import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import NavigationHistoryService from "../../../components/activity/services/NavigationHistoryService";
import CompanyModel from "../../../components/companies/models/CompanyModel";
import MarkdownToggleEditor from "../../../components/common/ui/form-elements/MarkdownToggleEditor";
import FileUploader from "../../../components/common/ui/file-uploader/FileUploader";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import DateTime from "../../../components/common/ui/DateTime";

const ShortUrlDomainsScreen = (props) => {
    let _;
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId || ""] || null);
    const [shortUrls, setShortUrls] = useState([]);
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const getShortUrlsAsync = async (force) => {
        //
    };
    
    useEffect(() => {
        if (company?.name) NavigationHistoryService.instance.addDelayedItem(company.name + " Short URLs",8000);
    }, [company]);

    useEffect(() => {
        //
    }, []);

    const description = company?.description || "No description available";
    const subtitle = company?.name + " Short URL Domains";
    
    const domainElements = company?.domains?.length > 0 ? company.domains.map((domain, index) => {
        return (
            <tr key={"domain-" + index}>
                <td>
                    <Link to={"/companies/" + companyId + "/domains/" + domain.id}>{domain.domain}</Link>
                </td>
                <td><DateTime value={domain.created} /></td>
            </tr>
        );
    }) : null;
    
    const tableElement = !!domainElements ? (
        <table className="table large" width={"100%"}>
            <thead>
                <tr>
                    <th>Domains</th>
                    <th>Created</th>
                </tr>
            </thead>
            <tbody>
                {domainElements}
            </tbody>
        </table>
    ) : null;
    
    return (
        <CompanyMasterPage bodyClassName={"large"} subTitleClassName={"options"} menuSelection="urls" onCompany={onCompany} title={subtitle}>
            <p>
                Below are the domains available to this company. Click on a domain to view the short URLs for that domain.
            </p>
            
            <div>
                {tableElement}
            </div>

        </CompanyMasterPage>
    );
};

export default ShortUrlDomainsScreen;
