import React from 'react';

const Spinner = (props) => {
    let isWorking = props.isWorking || false;
    
    return isWorking ? (
        <span className="spinner-container">
            <span className="spinner">&nbsp;</span>
        </span>
    ) : (<></>);
};

export default Spinner;
