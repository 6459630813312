import React, {useEffect, useState, useRef, createRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import {Link, Navigate, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import ProjectService from "../../../components/projects/services/ProjectService";
import BackButton from "../../../components/common/ui/BackButton";
import ProjectSubMenu from "../../../components/projects/ui/ProjectSubMenu";
import DateTime from "../../../components/common/ui/DateTime";
import AddNewHeaderButton from "../../../components/common/ui/AddNewHeaderButton";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import ProjectItemForm from "../../../components/projects/ui/ProjectItemForm";
import StageDropdown from "../../../components/projects/ui/StageDropdown";
import {marked} from "marked";
import ProjectEffect from "../../../components/projects/services/ProjectEffect";
import PagerController from "../../../components/common/pager/PagerController";
import Pager from "../../../components/common/pager/Pager";
import ProjectItemModel from "../../../components/projects/models/ProjectItemModel";
import ConfirmDialogBox from "../../../components/common/ui/dialog-box/ConfirmDialogBox";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import SearchFilter from "../../../components/search/ui/search-filter/SearchFilter";
import CommentForm from "../../../components/messaging/ui/CommentForm";
import NavigationHistoryService from "../../../components/activity/services/NavigationHistoryService";

const ProjectDetailsScreen = (props) => {
    let { projectId, companyId } = useParams();

    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [project, setProject] = useState(ProjectService.instance.projectMap[projectId]);
    let [projectStage, setProjectStage] = useState(project?.stageType || 0);
    let [selectedProjectItem, setSelectedProjectItem] = useState(null);
    let [projectItemDialogController, setProjectItemDialogController] = useState(new DialogBoxController("Create Task, Bug, or Feature"));
    let [redirectUrl, setRedirectUrl] = useState(null);

    const [confirmController, setConfirmController] = useState(new DialogBoxController("Are you sure?", (<p>This will be deleted.</p>)));
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(0);
    const editDescriptionRef = useRef();

    const [viewState, setViewState] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const slider = createRef();
    
    let _;
    
    let pagerController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;
    let newItemJson = null;

    const promptForAddTask = (projectItem, e) => {
        setSelectedProjectItem(projectItem || new ProjectItemModel());
        return true;
    };

    const onEditDialogOpen = (e) => {
        
    };
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };
    
    useEffect(() => {
        if (selectedProjectItem) {
            console.warn("Effect Open DialogBox");
            projectItemDialogController.setTitle("Create Task for " + selectedProjectItem.name);
            projectItemDialogController.open(selectedProjectItem);
        }
    }, [selectedProjectItem]);

    useEffect(() => {
        ProjectEffect.create(setProject, projectId);
    }, []);

    useEffect(() => {
        if (editMode) {
            editDescriptionRef?.current?.focus();
            
        } else if (!!project) setProjectStage(project?.stageType);
        
    }, [editMode]);
    
    useEffect(() => {
        if (!project) return;
        if (!!company) NavigationHistoryService.instance.addDelayedItem(company.name + ": " + project.name, 4500);
        
        if (editMode) setEditMode(false);
        else setProjectStage(project.stageType);
    }, [project, company]);

    const getProjectAsync = async (e) => {
        if (projectId.length < 30) {
            return;
        }

        await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            setProject(p);
        });
    };

    const deleteProjectAsync = async (prompt, e) => {
        if (prompt === false) {
            return await ProjectService.instance.deleteProjectAsync(project.id).then((success) => {
                if (success) setRedirectUrl("/companies/" + companyId + "/projects");
            });
        }
        
        setConfirmOpen(true);
    };
    
    const getTrimmedText = (text, maxLength, defaultText) => {
        if (typeof text !== 'string' || !text) return defaultText;
        if(!maxLength) return text;
        
        if (text.length > maxLength) return text.substring(0, maxLength) + " [..]";
        
        return text;
    };
    
    const onItemChange = (item, e) => {
        newItemJson = item;
    };
    
    const onProjectItemSubmitAsync = async (e) => {
        let json = newItemJson || projectItemDialogController.payload;
        json.project_id = projectId;

        console.warn(JSON.stringify(json));
        if (!json.project_id) {
            console.error("No ProjectId Found");
            return;
        }
        
        if (!json.duration && !!json.id) { 
            console.error("No Duration Found");
            console.warn(JSON.stringify(json));
            return;
        }
        
        console.warn("Saving...");
        await ProjectService.instance.saveProjectItemAsync(json).then((itemModel) => {
            getProjectAsync();
            setRedirectUrl("/companies/" + companyId + "/projects/" + projectId + "/item/" + itemModel?.id);
        });
    };
    
    const updateProjectStageAsync = async (newStage) => {
        newStage = parseInt(newStage);
        console.warn("New Stage: " + newStage);
        
        if (!isNaN(newStage)) {
            console.log('Saving ' + newStage + '...');
            setProjectStage(newStage);
            
            await ProjectService.instance.updateProjectStageAsync(projectId, newStage).then((p) => {
                setProjectStage(p.stageType);
            });
        } else { 
            //
        }
    };

    /**
     * Component: MarkdownEditor
     */
    const toggleEditMode = (e) => {
        const tagName = e.target?.tagName?.toLowerCase() || "";
        if (tagName === "select" || tagName === "a") return;

        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
            e.stopPropagation();
        }
        
        if (editMode && document.activeElement) {
            let hasFocus = (document.activeElement === editDescriptionRef.current);
            if (hasFocus) return;
        }

        setEditMode(!editMode);
    };

    /**
     * Component: MarkdownEditor
     * @param e
     */
    const updateProjectText = async (e) => {
        let newText = e.target?.value;
        if (newText === project?.description) {
            toggleEditMode(e);
            return;
        }

        await ProjectService.instance.updateProjectDescriptionAsync(project.id, newText).then((p) => {
            if (p) setProject(p);
        });
    };

    projectItemDialogController.setSubmitCallback((itemJson) => {
        console.log('Controller OnSubmit: ' + (itemJson?.name || "No Good"));
    });
   
    if (typeof redirectUrl === 'string') {
        return (<Navigate to={redirectUrl} />);
    }

    let statusClass = {};
    statusClass["0"] = "red";

    const toggleViewState = (e) => {
        setViewState(viewState === 0 ? 1 : 0);
    };
    
    const renderSortableElement = (ref, item, index, isDragging) => {
        let handlerId = "";
        let cn = isDragging ? ' dragging' : '';
        //let statusElement = (<><span>{item.stageName}</span></>);
        let statusElement = (<></>);
        let s = (item.duration === 1) ? "" : "s";
        let durationElement = (<>{ item.duration.formatNumber()} Day{s}</>); //(<DateTime useUtc={true} value={item.activeDate} />);

        if (item.estimatedStartDate !== null) {
            if (item.stageType > 0) statusElement = (<><span>{item.stageName}</span></>);
            else statusElement = (<DateTime prefix={"Starts on: "} value={item.activeStartDate}/>);
        } else if (item.stageType === 0) {
            //statusElement = (<span></span>);
        }

        let path = "/companies/" + companyId + "/projects/" + projectId + "/item/" + item.id;

        return (<tr ref={ref} data-handler-id={"handlerId"} key={index} className={"drag-sort" + cn}>
                <td>
                    <Link to={path}><FontAwesomeIcon icon={faBars} />
                        <span className={"day"}>Day { item.startDay }</span>
                        {item.name}
                    </Link>
                </td>
                <td className={"small"}>{getTrimmedText(item?.description, 128, "No description available")}</td>
                <td className={"date"}>
                    <a onClick={promptForAddTask.bind(this, item)} className={"project-item-status"}>
                        {durationElement}
                    </a>
                </td>
            </tr>);
    };

    let items = project?.items || [];
    
    const createItemElements = () => { 
        if (!!filterText && filterText.length > 2) 
            items = items.filter((item) => item.searchFor(filterText));
        
        return pagerController.mapLineItems(items, (item, index) => {
            let handlerId = "";
            let cn = "";

            let statusElement = (<></>);
            let s = (item.duration === 1) ? "" : "s";
            let durationElement = (<>{ item.stageName }</>);

            if (item.estimatedStartDate !== null) {
                if (item.stageType > 0) statusElement = (<><span>{item.stageName}</span></>);
                else statusElement = (<DateTime prefix={"Starts on: "} value={item.activeStartDate}/>);
            } else if (item.stageType === 0) {
                durationElement = (<>{ (item.modified || item.created).formatDate("DATE-TIME") }</>);
            }

            let path = "/companies/" + companyId + "/projects/" + projectId + "/item/" + item.id;
            
            return (
                <tr key={index} className={cn}>
                    <td className={"one-line medium-name"}>
                        <Link to={path}>
                            <span className={"day"}>Day { item.startDay }</span>
                            {item.name}
                        </Link>

                    </td>
                    <td className={"small"}>{getTrimmedText(item?.description, 128, "No description available")}</td>
                    <td className={"date-time"}>
                        <a onClick={promptForAddTask.bind(this, item)} className={"project-item-status"}>
                            {durationElement}
                        </a>
                    </td>
                </tr>
            );
        }) || (<></>);
    };
    
    let itemElements = createItemElements();
    const viewClassName = "trimmed ";

    let body = editMode ? (<p ref={slider} className={"form medium project-item"} onClick={toggleEditMode}><textarea ref={editDescriptionRef} onBlur={updateProjectText} defaultValue={project?.description} /></p>) :
        (<p className={viewClassName + "markdown has-details project-item " + (slider.current || "")} onClick={(e) => toggleEditMode(e)}>
            <span dangerouslySetInnerHTML={{ __html: marked.parse(project?.description || "")}}></span>
        </p>);
    
    const subtitle = (<>
            <BackButton to={"/companies/" + companyId + "/projects"} tooltip={"Back to all " + (company?.name) + " Projects"} />
            <span className={"pre-subtitle"}>
                <Link to={"/companies/" + companyId + "/projects"}>Project:</Link>
            </span>
            {project?.name}
            <AddNewHeaderButton onClick={promptForAddTask} id={faFileInvoiceDollar}>+ Add Task</AddNewHeaderButton>
        </>);

    const startDayElement = project?.startDay > 0 ? (<span>Start on Roadmap Day { project.startDay }</span>) : null;
    const startDateElement = project?.startDate ? (<span>Start Date <DateTime value={project?.startDate} time={true} defaultValue={"Not Specified"} /></span>) : null;
    
    return (
        <CompanyMasterPage title={subtitle} company={company} selection={"projects"} onRefresh={getProjectAsync} onCompany={onCompany}>
            <div className={"submenu-flexer"}>
                <ProjectSubMenu project={project} projectId={projectId} company={company} companyId={companyId} />
                <span>
                    <StageDropdown startDate={project?.startDate} value={projectStage} onChange={updateProjectStageAsync} />
                </span>
            </div>
            
            {body}
            
            <label className={"detail-footer"}>
                <span>
                    Modified <DateTime value={project?.modified || project?.created} time={true} defaultValue={"Never"} />
                </span>
                
                {startDayElement}
                
                {startDateElement}
                <span className={"delete"}>
                    <a className={"action-button delete"} onClick={(e) => deleteProjectAsync(true, e)}>Delete</a>
                </span>
            </label>

            <div>
                <h3 className={"has-search view-state-" + viewState.toString()}>
                    <span>User Stories and Subtasks</span>
                    <span className={"icon"}><a onClick={toggleViewState}><FontAwesomeIcon icon={faBars} /></a></span>
                    <span><SearchFilter onFilter={setFilterText}>Search Project</SearchFilter></span>
                </h3>
                <table width={"100%"} className={"unfixed table x-large"}>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Preview</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                        { itemElements }
                    </tbody>
                </table>

                <CommentForm entityId={projectId} entityType={ProjectItemModel.entityType} />
                
                <Pager controller={pagerController} items={project?.items} />
                <ConfirmDialogBox controller={confirmController} isOpen={confirmOpen} onCancel={() => setConfirmOpen(false)} onOkay={async (e) => deleteProjectAsync(false, e)} />
                <DialogBox controller={projectItemDialogController} onOkay={onProjectItemSubmitAsync} onCancel={(e) => {
                    console.error("On cancel");
                    setSelectedProjectItem(null);
                    return true;
                }}>
                    <ProjectItemForm project={project} projectItem={selectedProjectItem} company={company} onChange={onItemChange} controller={projectItemDialogController} />
                </DialogBox>
            </div>
        </CompanyMasterPage>
    );

};

export default ProjectDetailsScreen;
