import React, {useEffect} from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt, faPersonRunning} from "@fortawesome/free-solid-svg-icons";
import CommonMasterPage from "../../components/common/ui/CommonMasterPage";
import ActivityService from "../../components/activity/services/ActivityService";
import {Link} from "react-router-dom";

const ActivityScreen = (props) => {
    const {} = props;

    let _;
    const [activeConnections, setActiveConnections] = React.useState(ActivityService.instance.activeConnections || null);
    
    const getActiveConnectionsAsync = async () => {
        return await ActivityService.instance.getActiveConnectionsAsync().then((cs) => {
            setActiveConnections(cs);
        }).catch((error) => {
            console.log('Ex: ' + error);
        });
    };
    
    useEffect(() => {
        _ = getActiveConnectionsAsync();
    }, []);
    
    const connectionElements = activeConnections?.map((user, i) => {
        return (
            <div key={"key-" + i}>
                <Link to={"/people/" + user.id}>{ user.fullName }</Link>
            </div>
        );
    }) || null;
    
    if (connectionElements.length > 0) connectionElements.splice(0, 0, (<h3 key={"key-active-users-head"}>Active Connections</h3>));
    
    return (
        <CommonMasterPage selector={"activity"} title={"Platform Activity"} icon={faPersonRunning} subTitle={"Today's Activity"}>
            <div>
                {connectionElements}
            </div>
        </CommonMasterPage>
    );

};

export default ActivityScreen;
