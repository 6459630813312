import React from 'react';
import DateTime from "../../common/ui/DateTime";
import "./Comments.css";
import {Link} from "react-router-dom";
import ConfigModel from "../../common/models/ConfigModel";
import {marked} from "marked";

const CommentLineItem = (props) => {
    const { comment, showTime, index, viewer, onDelete } = props;

    const deleteCommentAsync = async (e) => {
        if (typeof onDelete === 'function') { 
            onDelete(comment, e);
            return;
        }
        
        console.warn("No onDelete function defined for comment line item.");
    };
    
    return (<div className={"comment"}>
        <span><Link to={"/people/" + comment.userId}><img src={ comment.user?.getImageUrl("64x64") || ConfigModel.defaultProfileImageUrl } alt={"Image"} /></Link></span>
        <span>
            <strong><Link to={"/people/" + comment.userId}>{comment.user.fullName || "No Name"}</Link></strong>
            <p className={"comment-text"} dangerouslySetInnerHTML={{ __html: marked.parse(comment.text || "")}}></p>
            <div className={"comment-date"}>
                <DateTime value={comment.created} time={showTime !== false} />
                <span className={"comment-action-panel"}>
                    <a onClick={deleteCommentAsync}>Delete</a>
                </span>
                
            </div>
        </span>
    </div>);
};

export default CommentLineItem;
