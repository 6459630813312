import React, {useEffect, useState} from 'react';
import CompanyService from "../../../components/companies/services/CompanyService";
import Controller from "../../../components/common/services/Controller";
import UsersTable from "../../../components/people/ui/UsersTable";
import {Link, useParams} from "react-router-dom";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";

const CompanyUsersScreen = (props) => {
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    const controller = useState(new Controller())[0];
    const [screenState, setScreenState] = useState({ id: 0 });
    
    let _;

    const refreshUsersAsync = async (e) => {
        if (typeof e?.stopPropagation === "function") e.stopPropagation();
        if (typeof e?.preventDefault === "function") e.preventDefault();
        
        const items = await controller?.refreshAsync(true);
        
        const newId = new Date().getTime();
        setScreenState({ id: newId });
        
        return items;
    }
    
    const onCompany = (c) => {
        setCompany(c);
    };
    
    useEffect(() => {
        //
    }, [company]);

    useEffect(() => {
        //
    }, []);

    const usersTable = company ? (<UsersTable company={company} companyId={companyId} ownerId={company.primaryUserId} controller={controller} />) : null;
    const authUsersTable = company ? (<UsersTable title={"External Users with Access"} empty={"No External Users"} ownerId={company.primaryUserId} company={company} companyId={companyId} controller={controller} authUsers={true} />) : null;
    const h2title = (<>Users <Link to={"/companies/" + companyId + "/people/000-000/edit"} className={"add"}><FontAwesomeIcon icon={faUserPlus} /> Add User</Link></>);
    
    return (<CompanyMasterPage onCompany={onCompany} selection={"people"} title={h2title} onRefresh={refreshUsersAsync}>
            <p>All users for { company?.name }.</p>
            <div>{usersTable}</div>
            <div className={"auth-users"}>
                {authUsersTable}
            </div>
        </CompanyMasterPage>);
};

export default CompanyUsersScreen;

