/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from 'react';
import NumberDisplay from "../../common/ui/NumberDisplay";
import Checkbox from "@jadecharles/pi-react-packages/dist/common/forms/Checkbox";
//import FormController from "@jadecharles/pi-react-packages/dist/common/controllers/FormController";
import SetterFunction from "@jadecharles/pi-react-packages/dist/common/controllers/SetterFunction";

const InvoicePreview = (props) => {
    const { invoice, company, onChange } = props;
    const initialState = {
        phone: company?.phone || "",
        email: company?.email || "",
        viaPhone: !!company?.phone,
        viaEmail: !!company?.email,
        phoneView: 0,
        emailView: 0,
        reminder: false,
    };

    const [recipient, setRecipient] = useState(initialState);
    const emailSetter = useState(new SetterFunction())[0];
    const phoneSetter = useState(new SetterFunction())[0];
    const reminderSetter = useState(new SetterFunction())[0];
    
    const emailRef = useRef();
    const phoneRef = useRef();

    const onCheckBox = (isChecked, prop, e = null) => {

        const isFromController = e?.fromController === true;
 
        if (!isFromController) { 
            if (prop === "viaPhone" && !recipient?.phone) return false;
            if (prop === "viaEmail" && !recipient?.email) return false;
        }

        setViewState(prop, isChecked);
    };

    const setInputView = (e, prop, value = null) => { 
        if (e?.target?.tagName === "INPUT") return;

        if (prop !== "emailView") prop = "phoneView";
        if (e?.target?.tagName === "TD" && recipient[prop] === 1) return;

        if (typeof value !== "number") { 
            value = recipient[prop] === 0 ? 1 : 0;
        }

        setViewState(prop, value);
    };

    const setViewState = (prop, value) => { 
        let newViewState = { ...recipient };
        newViewState[prop] = value;
        setRecipient(newViewState);
    };

    const onBlur = (prop, e) => { 
        let newState = { ...recipient, emailView: 0, phoneView: 0 };
        const value = e.target?.value || "";

        let b = "viaEmail";

        if (prop === "email") {
            if (!!emailRef.current) newState.email = emailRef.current?.value || "";
            else if (!!value) newState.email = value;

            newState.viaEmail = typeof newState.email === "string" && newState.email.indexOf("@") > 0;
            emailSetter.set(newState.viaEmail, e);
        } else {
            b = "viaPhone";
            prop = "phone";
            if (!!phoneRef.current) newState.phone = phoneRef.current?.value || "";
            else if (!!value) newState.phone = value;

            newState.viaPhone = typeof newState.phone === "string" && newState.phone.length >= 10;
            phoneSetter.set(newState.viaPhone, e);
        }

        setRecipient(newState);
    };

    useEffect(() => { 
        if (typeof onChange === "function") onChange(recipient);
    }, [recipient]);

    useEffect(() => {
        if (typeof onChange === "function") onChange(recipient);
    }, []);
    
    if (!invoice) return (<></>);

    const email = recipient?.email || "";
    const phone = recipient?.phone || "";

    const uns = "un-selected";
    const ss = "soft-selected";

    const phoneClassName = !!phone && phone.length >= 10 ? (recipient.viaPhone === true ? ss : uns) : "disabled";
    const emailClassName = !!email && email.indexOf("@") > 0 ? (recipient.viaEmail === true ? ss : uns) : "disabled";

    const emailElement = !!recipient.emailView ?
        (<span><input type="text" defaultValue={recipient.email} ref={emailRef} autoFocus={true} onBlur={(e) => onBlur("email", e)} /></span>) :
        (<strong>{email || "No Email on File"}</strong>);
    
    const phoneElement = !!recipient.phoneView ?
        (<span><input type="text" defaultValue={recipient.phone} ref={phoneRef} autoFocus={true} onBlur={(e) => onBlur("phone", e)} /></span>) :
        (<strong>{phone || "No Mobile Phone on File"}</strong>);
    
    const reminderElement = !invoice.paidDate && !!recipient.email ? (<tr>
            <td className="td-check">
                <Checkbox
                    setter={reminderSetter}
                    isChecked={recipient.reminder === true}
                    onCheck={(c, e) => onCheckBox(c, "reminder", e)}></Checkbox>
            </td>
            <td>Send as Reminder to {recipient.email}</td>
            <td></td>
        </tr>) : null;
    
    return (
        <table width={"100%"}>
            <tbody>
                <tr className={emailClassName}>
                    <td className="td-check">
                        <Checkbox
                            setter={emailSetter}
                            isChecked={recipient.viaEmail === true}
                            onCheck={(c, e) => onCheckBox(c, "viaEmail", e)}></Checkbox>
                    </td>
                    <td onClick={(c) => emailSetter.set(c) }>Send via Email to:</td>
                    <td onClick={(e) => setInputView(e, "emailView")}>{emailElement}</td>
                </tr>
                <tr className={phoneClassName}>
                    <td className="td-check">
                        <Checkbox
                            setter={phoneSetter}
                            isChecked={recipient.viaPhone === true}
                            onCheck={(c, e) => onCheckBox(c, "viaPhone", e)}></Checkbox>
                    </td>
                    <td>Send via Text Message to({recipient.viaPhone?.toString()}):</td>
                    <td onClick={(e) => setInputView(e, null)}>{phoneElement}</td>
                </tr>
                { reminderElement }
                <tr>
                    <td colSpan={2}>Total:</td>
                    <td><NumberDisplay value={invoice.total} type={"currency"} /></td>
                </tr>
            </tbody>
        </table>        
    );
};

export default InvoicePreview;
