import HttpService from "../../common/services/HttpService";
import ApiKeyAuthModel from "../models/ApiKeyAuthModel";

class ApiKeyAuthService {
    static instance = new ApiKeyAuthService();

    constructor() {
        this.apiKeyAuthsMap = {};
        this.apiKeyAuths = [];
        this.apiKeyAuthMap = {};
    }

    async getApiKeyAuthAsync(apiKeyAuthId) {
        const path = '/api/api-key/' + apiKeyAuthId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.apiKeyAuthMap[apiKeyAuthId] = new ApiKeyAuthModel(response.data);
            return me.apiKeyAuthMap[apiKeyAuthId];
        });
    }

    async getApiKeyAuthsByUserAsync(userId) {
        const path = "/api/user/" + userId + "/api-key";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.apiKeyAuths = response.data.map((apiKeyAuth) => {
                return new ApiKeyAuthModel(apiKeyAuth);
            });

            return me.apiKeyAuths;
        });
    }
    
    async updateApiKeyAuthAsync(apiKeyAuthId, name) {
        const path = "/api/api-key/" + apiKeyAuthId;
        const json = { name: name };

        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            //
            return ApiKeyAuthModel.create(rsp.data);
        });
    }
    
    async createApiKeyAuthAsync(name, userId) {
        const path = "/api/user/" + userId + "/api-key";
        const json = { name: name };
        
        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            //
            return ApiKeyAuthModel.create(rsp.data);
        });
    }
    
    async deleteApiKeyAuthAsync(apiKeyAuthId) {
        const path = "/api/api-key/" + apiKeyAuthId;
        console.log("Deleting: " + path);
        console.log("  > " + apiKeyAuthId);
        
        return await HttpService.instance.deleteAsync(path);
    }
}

export default ApiKeyAuthService;
