class ConfigModel { 
    static companyId = "A32B21B2-1A90-4ECA-AFAA-6E8A08C60EDD";
    static companyName = "Penumbra Labs";
    static appName = "Pi Dark";
    static companyNumber = 7;
    static defaultProfileImageUrl = "";
    static paymentsDomain = "payments.penumbralabs.io";
    
    static qrBlocksCompanyId = "376fae42-b586-423c-9194-cbdf411060be";
    static emptyId = "00000000-0000-0000-0000-000000000000";

    static init(companyName, companyId, companyNumber, appName = null, defaultProfileImageUrl = "") {
        if (typeof companyName !== "string" || companyName.trim().length === 0) throw new Error("Invalid company name: " + companyName);
        if (typeof companyId !== "string" || companyId.length !== 36 || companyId === ConfigModel.emptyId) throw new Error("Invalid company id. Must be a valid Guid: " + companyId);
        if (typeof companyNumber !== "number" || companyNumber <= 0) throw new Error("Invalid company number. Must be a positive number: " + companyNumber);

        ConfigModel.companyId = companyId;
        ConfigModel.companyName = companyName;
        ConfigModel.appName = appName || (companyName + " App");
        ConfigModel.companyNumber = companyNumber;
        ConfigModel.defaultProfileImageUrl = defaultProfileImageUrl;
    }
}

export default ConfigModel;
