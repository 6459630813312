class ContactRequestModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.userId = json.user_id || "";
        this.companyId = json.company_id || "";
        this.messageSource = json.message_source || "";

        this.name = json.name || "";
        this.email = json.email || "";
        this.phone = json.phone || "";
        this.website = json.website || "";
        this.message = json.message || "";
        this.remoteId = json.remote_id || null;
        
        if (!!this.website) { 
            if (!this.website.startsWith("http://") && !this.website.startsWith("https://"))
                this.website = "https://" + this.website;
        }

        this.contactRequestType = json.contact_request_type || 0;
        this.contactRequestTypeName = json.contact_request_type_name;
        
        this.apiTypeId = json.api_type_id ? json.api_type_id : 0;
        this.readDate = json.read_date ? new Date(json.read_date) : null;
        this.replyDate = json.reply_date ? new Date(json.reply_date) : null;
        this.pinDate = json.pin_date ? new Date(json.pin_date) : null;
        this.contactUserId = json.contact_user_id || null;
        this.contactUser = json.contact_user || null;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            company_id: this.companyId,
            name: this.name,
            email: this.email,
            phone: this.phone,
            website: this.website,
            message_source: this.messageSource,
            remote_id: this.remoteId,
            contact_request_type: this.contactRequestType,
            api_type_id: this.apiTypeId,
            read_date: this.readDate,
            reply_date: this.replyDate,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ContactRequestModel(json));
    }
}

export default ContactRequestModel;
