import CompanyModel from "./CompanyModel";
import {
    faBriefcase, faBuildingColumns,
    faHandshake, faLightbulb, faPeopleArrows,
    faPeopleCarryBox,
    faPersonDigging,
    faQuestion, faUserSecret
} from "@fortawesome/free-solid-svg-icons";

class CompanyTypeViewModel {
    static icons = {
        "-1": faBuildingColumns,
        "0": faPeopleArrows,
        "1": faHandshake,
        "2": faPersonDigging,
        "3": faPeopleCarryBox,
        "4": faLightbulb,
        "5": faUserSecret
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.type = json.company_type || 0;
        this.name = json.company_type_name || "Unknown (" + this.type + ")";
        this.companies = CompanyModel.fromJsonArray(json.companies);
    }
    
    static fromJsonArray(jsonArray) { 
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(x => new CompanyTypeViewModel(x));
    }
}

export default CompanyTypeViewModel;
