import HttpService from "../../common/services/HttpService";

class ImageService { 
    static instance = new ImageService();
    
    createSizeTagParams(sizeTags, prefix = "size-tags=") {
        if (sizeTags) {
            let pms;

            if (Array.isArray(sizeTags)) pms = sizeTags.join(',');
            else pms = sizeTags.toString();

            return prefix + pms;
        }
        
        return "";
    }
    
    async createZippedThumbsAsync(fileModels, sizeTags) {
        let path = "/api/image/zip?" + this.createSizeTagParams(sizeTags, "size-tags=");
        const me = this;

        let formFiles = fileModels.map((file) => {
            let isFile = file?.toString().toLowerCase() === "[object file]";
            if (isFile) return file;

            return file.file || null;

        }).filter((ff) => ff !== null);

        return await HttpService.instance.uploadAsync(path, formFiles, null,  "files", "blob").then( (rsp) => {
            return rsp.data;
        });
    }
    
    async uploadImageForBase64Async(fileModel, sizeTag) {
        const path = "/api/image/base64?size-tag=" + (sizeTag || "");
        const f = !!fileModel.file ? fileModel.file : fileModel;
        
        return HttpService.instance.uploadAsync(path, f).then((rsp) => {
            return rsp.data;
        });
    }
    
    async uploadImagesAsync(fileModels, sizeTags) {
        let path = "/api/image?" + this.createSizeTagParams(sizeTags, "size-tags=");
        const me = this;
        
        if (sizeTags) {
            let pms;

            if (Array.isArray(sizeTags)) pms = sizeTags.join(',');
            else pms = sizeTags;

            path += "?" + pms;
        }
        
        let formFiles = fileModels.map((file) => {
            let isFile = file?.toString().toLowerCase() === "[object file]";
            if (isFile) return file;
            
            return file.file || null;
            
        }).filter((ff) => ff !== null);
        
        return await HttpService.instance.uploadAsync(path, formFiles).then(function (rsp) {
           return rsp.data;
        });
    }
    
    
}

export default ImageService;
