import HttpService from "../../common/services/HttpService";
import ConfigModel from "@jadecharles/pi-react-packages/dist/common/models/ConfigModel";
import MessageTemplateModel from "../models/MessageTemplateModel";

class MessageTemplateService {
    static instance = new MessageTemplateService();

    constructor() { 
        this.emailMessagesMap = {};
        this.templateTypes = null;

        HttpService.isDebug = true;
    }

    async getMessageTemplatesAsync(companyId) {
        const path = "/api/company/" + companyId + "/message-template";
        const me = this;
        
        HttpService.isDebug = true;
        console.warn("Clean Path: " + HttpService.instance.cleanPath(path));

        return await HttpService.instance.getAsync(path).then((rsp) => {
            if (Array.isArray(rsp.data)) {
                me.emailMessagesMap[companyId] = MessageTemplateModel.fromJsonArray(rsp.data);
                return me.emailMessagesMap[companyId];
            }

            return null;
        });
    }

    async getTemplateTypesAsync() { 
        const path = "/api/message-template/types";
        const templateTypes = await HttpService.instance.getAsync(path).then((rsp) => rsp.data);

        if (Array.isArray(templateTypes) && templateTypes.length > 0) { 
            this.templateTypes = templateTypes.filter((x) => x.value > 0);
            return this.templateTypes;
        }

        return null;
    }

    async getMessageTemplateAsync(id, companyId) {
        if (!companyId) companyId = ConfigModel.companyId;

        const path = "/api/company/" + companyId + "/message-template/" + id;
        const me = this;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            return MessageTemplateModel.create(rsp.data);
        });
    }

    async createMessageTemplateAsync(data, companyId = null) {
        if (!data) throw new Error("Data is required");
        
        const path = typeof companyId === "string" && companyId.length > 30 ?
            "/api/company/" + companyId + "/message-template" :
            "/api/message-template";

        delete data.id;

        return HttpService.instance.postAsync(path, data).then((rsp) => {
            if (!!rsp?.data) { 
                return MessageTemplateModel.create(rsp.data);
            }

            return null;
        });
    }

    async updateMessageTemplateAsync(data, companyId = null) {
        if (!data) throw new Error("Data is required");
        if (!data?.id) throw new Error("Data.id is missing. Call createMessageTemplateAsync instead, or include an id");

        const path = typeof companyId === "string" && companyId.length > 30 ?
            "/api/company/" + companyId + "/message-template/" + data.id :
            "/api/message-template/" + data.id;

        delete data.id;
        
        return HttpService.instance.postAsync(path, data).then((rsp) => {
            if (!!rsp?.data) { 
                return MessageTemplateModel.create(rsp.data);
            }
            return null;
        });
    }
}

export default MessageTemplateService;
