import React from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faBookSkull, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import DocumentationSubMenu from "../../components/documentation/ui/DocumentationSubMenu";
import {piGoogleIcon} from "../../components/common/ui/svgs/LogoSvgs";
import DocumentationMasterPage from "../../components/documentation/ui/DocumentationMasterPage";

const GoogleScreen = (props) => {
    const {} = props;

    const subtitle = (<><FontAwesomeIcon icon={piGoogleIcon} /> Google Details</>);
    
    return (<DocumentationMasterPage selector={"documentation"} icon={faBookSkull} selection={"google"} subTitle={subtitle}>
        <p>
            This area will show a cool description.
        </p>

    </DocumentationMasterPage>);
};

export default GoogleScreen;
