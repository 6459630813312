import React from 'react';

const BulletArrow = (props) => {
    const { children } = props;

    let content = children || (<>»</>);
    
    return (<span className={"submenu-delimiter"}>{content}</span>);
};

export default BulletArrow;

