import React from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import googleLogo from "../../images/google-logo.png";
import azureLogo from "../../images/azure-logo.png";
import companyLogo from "../../images/logo-white.png";

import {faArrowTrendUp, faBookSkull, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import DocumentationMasterPage from "../../components/documentation/ui/DocumentationMasterPage";

const DocumentationScreen = (props) => {
    const {} = props;

    const go = (url, target) => { 
        window.open(url, target || '_blank');
    }
    
    const createLink = (url, text, target = "_blank") => {
        return (<a href={url} onClick={() => go(url, target || "_blank")}>{ text }</a>);
    };

    return (<DocumentationMasterPage selector={"documentation"} icon={faBookSkull} subTitle={"Platform Documents and Knowledge Base"}>
        <p>
            Documentation and reference material. This will be added to, ongoing.
        </p>

        <div className={"dark-boxes"}>
            <div className={"box"}>
                <h3>Technical Docs</h3>
                <div className={"icon"}><Link to={"/documentation/docs"}><img src={companyLogo} alt={"BoCG Book"} /></Link></div>
                <ul>
                    <li><Link to={"/documentation/docs"}>Platform Documentation</Link></li>
                    <li><a onClick={() => go("https://github.com", "github")}>GitHub Repo</a></li>
                    <li><Link to={"/documentation/docs"}>More...</Link></li>
                </ul>
            </div>

            <div className={"box"}>
                <h3>Google</h3>
                <div className={"icon"}><Link to={"/documentation/google"}><img src={googleLogo} alt={"Google Logo"} /></Link></div>
                <ul>
                    <li>{ createLink("https://console.cloud.google.com/getting-started?authuser=2&project=penumbra-main-project", "Cloud Console", "google_drive")}</li>
                    <li>{ createLink("https://admin.google.com", "Admin Console", "google_drive")}</li>
                    <li><Link to={"/documentation/google"}>More...</Link></li>
                </ul>
            </div>

            <div className={"box"}>
                <h3>Azure</h3>
                <div className={"icon"}><Link to={"/documentation/azure"}><img src={azureLogo} alt={"Azure Logo"} /></Link></div>
                <ul>
                    <li>{ createLink("https://portal.azure.com/#home", "Azure Dashboard") }</li>
                    <li>{ createLink("https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.Storage%2FStorageAccounts", "Storage Accounts", "azure") }</li>
                    <li><Link to={"/documentation/azure"}>More...</Link></li>
                </ul>
            </div>

        </div>

    </DocumentationMasterPage>);

};

export default DocumentationScreen;