import React, {useRef} from 'react';
import UserModel from "../../people/models/UserModel";
import CompanyService from "../../companies/services/CompanyService";
import FormButton from "../../common/ui/FormButton";

const ApiAuthForm = (props) => {
    /**
     * @param options { ApiAuthFormOptions }
     */
    const { apiAuth, auth, companyApi, onComplete, companyId, options } = props;

    const accountNameRef = useRef();
    const apiKeyRef = useRef();
    const secretRef = useRef();
    const containerRef = useRef();
    const passwordRef = useRef();
    const clientIdRef = useRef();
    
    const apiOptions = options || new ApiAuthFormOptions();
    
    const onApiFormChange = (field, e) => {
        //
    };

    const onApiFormSubmitAsync = async (e) => {
        if (companyApi.entityType !== UserModel.entityType) {
            // Save company api
            let data = companyApi.toApiAuthData(accountNameRef.current?.value || "");
            
            data.account_name = accountNameRef.current?.value || null;
            data.api_key = apiKeyRef.current?.value || "";
            data.container = containerRef.current?.value || null;
            data.secret = secretRef.current?.value || null;
            data.password = passwordRef.current?.value || null;
            data.client_id = clientIdRef.current?.value || null;
            data.company_api_id = companyApi?.id || null;

            await CompanyService.instance.saveCompanyApiAsync(companyId, data).then((x) => {
                if (typeof onComplete === 'function') onComplete(x, e);
            }).catch((ex) => {
                return false;
            });
        } else {
            // Save user api
        }
    };

    const accountNameField = apiOptions.accountName ? (<div className={"form-group"}>
        <label>{ apiOptions.labels?.accountName || "Account Name"}:</label>
        <span><input placeholder={apiOptions.placeholders?.accountName} defaultValue={apiOptions.defaultValues?.accountName} ref={accountNameRef} type={"text"} onChange={(e) => onApiFormChange("account_name", e)} /></span>
    </div>) : null;

    const containerField = apiOptions.container ? (<div className={"form-group"}>
        <label>{ apiOptions.labels?.container || "Container"}:</label>
        <span><input placeholder={apiOptions.placeholders?.container} defaultValue={apiOptions.defaultValues?.container} ref={containerRef} type={"text"} onChange={(e) => onApiFormChange("container", e)} /></span>
    </div>) : null;

    const clientIdField = apiOptions.clientId ? (<div className={"form-group"}>
        <label>{ apiOptions.labels?.clientId || "ClientId"}:</label>
        <span><input placeholder={apiOptions.placeholders?.clientId} defaultValue={apiOptions.defaultValues?.clientId} ref={clientIdRef} type={"text"} onChange={(e) => onApiFormChange("client_id", e)} /></span>
    </div>) : null;

    const apiKeyField = apiOptions.apiKey ? (<div className={"form-group"}>
        <label>{ apiOptions.labels?.apiKey || "API Key"}:</label>
        <span><input placeholder={apiOptions.placeholders?.apiKey} defaultValue={apiOptions.defaultValues?.apiKey} ref={apiKeyRef} type={"text"} onChange={(e) => onApiFormChange("api_key", e)} /></span>
    </div>) : null;

    const secretField = apiOptions.secret ? (<div className={"form-group"}>
        <label>{ apiOptions.labels?.secret || "Secret"}:</label>
        <span><input placeholder={apiOptions.placeholders?.secret} defaultValue={apiOptions.defaultValues?.secret} ref={secretRef} type={"text"} onChange={(e) => onApiFormChange("secret", e)} /></span>
    </div>) : null;

    const passwordField = apiOptions.password ? (<div className={"form-group"}>
        <label>{ apiOptions.labels?.password || "Password"}:</label>
        <span><input placeholder={apiOptions.placeholders?.password} defaultValue={apiOptions.defaultValues?.password} ref={passwordRef} type={"text"} onChange={(e) => onApiFormChange("password", e)} /></span>
    </div>) : null;

    return (<div className={"api-form form"}>
        { accountNameField }
        { clientIdField }
        { containerField }
        { apiKeyField }
        { secretField }
        { passwordField }
        
        <div className={"buttons"}><FormButton onClick={onApiFormSubmitAsync}>Save</FormButton></div>
    </div>);
};

export default ApiAuthForm;

export class ApiAuthFormOptions {
    constructor(props) {
        if (!props) props = { apiKey: true, accountName: true};
        
        this.container = props.container === true;
        this.accountName = props.accountName === true;
        this.clientId = props.clientId === true;
        this.clientSecret = props.clientSecret === true;
        this.password = props.password === true;
        this.apiKey = props.apiKey === true;
        this.secret = props.secret === true;
        
        this.placeholders = props.placeholders || {};
        this.defaultValues = props.defaultValues || {};
        this.labels = props.labels || {};
    }

}
