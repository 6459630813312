import React, {useEffect, useState} from 'react';
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt, faFaceSmile, faHardDrive} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import ApiAuthModel from "../../components/authentication/models/ApiAuthModel";
import companyEffect from "../../components/companies/services/CompanyEffect";
import {Link, useParams, useSearchParams} from "react-router-dom";
import CompanyService from "../../components/companies/services/CompanyService";
import CompanyApiListElement from "../../components/companies/ui/CompanyApiListElement";
import CompanyEffect from "../../components/companies/services/CompanyEffect";
import GoogleService from "../../components/authentication/services/GoogleService";
import IntegrationsList from "../../components/authentication/ui/IntegrationsList";
import HeaderButton from "../../components/common/ui/HeaderButton";
import Controller from "../../components/common/services/Controller";
import UserModel from "../../components/people/models/UserModel";
import MyAccountMasterPage from "../../components/my-account/ui/MyAccountMasterPage";
import ConfigModel from "../../components/common/models/ConfigModel";

const MyIntegrationsScreen = (props) => {
    let [user, setUser] = useState(AuthenticationService.instance.session?.user || null);
    let [myState, setMyState] = useState(0);
    let [company, setCompany] = useState(CompanyService.instance.companyMap[user?.companyId || ""] || null);
    let _;

    const [oauthParams, setOauthParams] = useSearchParams();
    const houseCompanyId = process.env.REACT_APP_HOUSE_ID;

    const controller = new Controller();
    
    let companyId = company?.id || (user?.companyId || "");
    
    const onSessionChange = (session) => {
        if (session?.user) setUser(session.user)
        else setMyState(myState + 1);
    };
    
    const onUser = (user) => {
        setUser(user);
    };
    
    const refreshIntegrationsAsync = async (e) => {
        await controller.refreshAsync(e);
    };
    
    useEffect(() => {
        //
    }, [user]);
    
    useEffect(() => {
    }, []);
    
    let name = user?.firstName || 'Fellow Traveller';
    let welcomeMessage = "Hello, " + name;

    let userSubMenu = user ? (<UserSubMenu isMe={true} selection={"integrations"} user={user} />) : null;
    const entityTypeFilter = [UserModel.entityType];
    const returnUrl = "/me/integrations";
    const integrationsElement = !!company?.id && !!user?.id ? (<IntegrationsList userId={user?.id} returnUrl={returnUrl} controller={controller} company={company} entityTypes={entityTypeFilter} />) : null;

    return (
        <MyAccountMasterPage title={"My Account"} onUser={onUser} subTitle={"My Integrations"} selection={"integrations"}>
            <p>
                Below are integrations that connect your individual accounts to { ConfigModel.appName }. These are not <Link to={"/companies/" + houseCompanyId + "/integrations"}>company level integrations</Link>.
            </p>

            <div>
                { integrationsElement }
            </div>
        </MyAccountMasterPage>        
    );
    

};

export default MyIntegrationsScreen;

