import InvoiceItemModel from "./InvoiceItemModel";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import ImageModel from "../../media/models/ImageModel";
import DocumentModel from "../../content/models/DocumentModel";

class InvoiceModel { 
    static entityType = 8;
    static icon = faFileInvoiceDollar;
    static create = (json) => new InvoiceModel(json);

    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        this.label = json.label || '';
        this.companyId = json.company_id || null;
        this.userId = json.user_id || null;
        this.description = json.description || '';
        this.invoiceNumber = json.invoice_number || 0;
        this.total = json.total || 0;
        this.subtotal = json.subtotal || 0;
        this.paymentId = json.payment_id || null;
        this.totalQty = json.total_qty || 0;
        this.tax = json.tax || 0;
        this.documents = DocumentModel.fromJsonArray(json.documents) || [];
        this.invoiceNumberId = json.invoice_number_id || 0;
        this.invoiceNumberDisplay = json.invoice_number_display || ("#" + this.invoiceNumberId);
        this.lineItemCount = json.line_item_count || 0;
        this.imageId = json.image_id || "";
        this.receiptUrl = json.receipt_url || null;
        this.fileUrl = json.file_url || null;
        this.image = new ImageModel(json.image);
        this.json = json;
        
        if (!!this.fileUrl) { 
            this.documents.unshift(new DocumentModel({ id: "000-001", name: "Invoice PDF Document", uri: this.fileUrl }));
        }

        if (!!this.receiptUrl) { 
            this.documents.unshift(new DocumentModel({ id: "000-002", name: "Receipt PDF Document", uri: this.fileUrl }));
        }

        /**
         * Deprecated - Use userId instead
         * @type {string}
         */
        this.createdBy = json.user_id || "";
        
        this.items = InvoiceItemModel.fromJsonArray(json.items);
        this.summary = InvoiceItemModel.fromJsonArray(json.summary) || [];

        this.sentDate = json.sent_date ? new Date(json.sent_date) : null;
        this.paidDate = json.paid_date ? new Date(json.paid_date) : null;
        this.dueDate = json.due_date ? new Date(json.due_date) : null;
        
        this.created = json.created ? new Date(json.created) : new Date();
        this.modified = json.modified ? new Date(json.modified) : null;
        this.invoiceDate = json.invoice_date ? new Date(json.invoice_date) : this.created;
    }

    /**
     * Adds an item to the invoice and returns the new total
     * @returns {float} - The new total
     * @param item {InvoiceItemModel} The item to add to the invoice
     */
    addItem(item) { 
        this.items.push(item);
        return this.update();
    }

    /**
     * Removes an item from the invoice and returns whether the item was removed
     * @param index {int} - The index of the item to remove
     * @returns {boolean} - Whether something was removed or not
     */
    removeItemAtIndex(index) {
        if (index < 0 || index >= this.items.length)
            return false;
        
        this.items.splice(index, 1);
        
        this.update();
        
        return true;
    }
    
    update() {
        let t = this.items.reduce((total, item) => total + (item.qty * item.price), 0);
        let q = this.items.reduce((total, item) => total + item.qty, 0);

        this.lineItemCount = this.items.length;
        this.subtotal = t;
        this.totalQty = q;
        this.total = this.tax + this.subtotal;

        return this.total;
    }

    getItemsJsonArray() {
        return this.items?.map(item => item.toJson()) || [];
    }
}

export default InvoiceModel;
