import UserService from "./UserService";

class UserEffect {
    static create(setFunction, userId, sessionId = null) {
        let _ = new UserEffect(setFunction, userId, sessionId);
    }

    constructor(setFunction, userId, sessionId) {
        this.setUser = setFunction;
        this.userId = userId;
        this.sessionId = sessionId || null;
        this.updateScreen();
    }

    updateScreen() {
        if (!this.userId) return;
        const me = this;

        let h = null;
        if (!!this.sessionId) h = { 'session-id': this.sessionId };

        UserService.instance.getUserAsync(this.userId, h).then((user) => {
            me.setUser(user);
        });
    }
}

export default UserEffect;
