import React, { useEffect, useState } from 'react';
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import MetricsMasterScreen from './MetricsMasterScreen';
import PaymentService from "../../components/finance/services/PaymentService";
import NumberDisplay from "@jadecharles/pi-react-packages/dist/common/ui/formatting/NumberDisplay";
import DateTime from "@jadecharles/pi-react-packages/dist/common/ui/formatting/DateTime";

const CardTransactionsScreen = (props) => {
    const [payments, setPayments] = useState(PaymentService.instance.payments);

    const getTransactionsAsync = async (force = false) => { 
        if (Array.isArray(payments) && payments.length > 0 && !force) return payments;

        const ps = await PaymentService.instance.getPaymentsAsync('1/1/2021');
        if (!!ps) {
            console.warn("Payments: " + ps.length);
            setPayments(ps);
        }
        else console.error("Failed to get payments");
    };

    useEffect(() => { 
        getTransactionsAsync();
    }, []);

    const paymentElements = !!payments ? payments.map((p, i) => {
        const paymentNumber = p.paymentNumber.toString().padStart(6, '0');
        const statusElement = !!p.transactionId ? p.transactionId : p.paymentStatusName;
        const className = "payment-status-" + p.paymentStatus.toString();

        return (<tr key={"payment-" + i} className={className}>
            <td>#{paymentNumber}</td>
            <td>{p.payerCompany?.name || "Unknown Company"}</td>
            <td>{statusElement}</td>
            <td><DateTime value={p.created} time={true} /></td>
            <td><NumberDisplay type="currency" value={p.total} /></td>
        </tr>);
     }) : null;

    
    return (<MetricsMasterScreen selection="transactions" onRefreshAsync={getTransactionsAsync} selector={"metrics"} icon={faArrowTrendUp} title={"Growth Metrics"} subTitle={"Credit Card Transactions"}>
            <p>
               All transactions going through the house Stripe Account
            </p>

            <div>
            <table className="table wide x-large lined full">
                <thead>
                    <tr>
                        <th>Payment #</th>
                        <th>Company</th>
                        <th>TransactionId</th>
                        <th>Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>

                <tbody>
                    {paymentElements}
                </tbody>

                </table>
            </div>
        </MetricsMasterScreen>);
}

export default CardTransactionsScreen;
