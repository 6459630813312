import React, {useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import CompanyActivityMasterScreen from "./CompanyActivityMasterScreen";
import ErrorsTable from "../../../components/activity/ui/ErrorsTable";
import Controller from "../../../components/common/services/Controller";
import MessageSendLogs from "../../../components/activity/ui/MessageSendLogs";

const CompanyMessageSendLogsScreen = (props) => {
    const {} = props;
    const { companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    const refreshController = useRef(new Controller()).current;

    const onCompany = (c) => {
        if (!!c) {
            setCompany(c);
        }
    };

    const refreshAsync = async () => {
        return await refreshController.refreshAsync();
    };
    
    const messageLogTable = !!companyId && companyId.length > 0 ? (<MessageSendLogs controller={refreshController} companyId={companyId} />) : null;

    return (
        <CompanyActivityMasterScreen onCompany={onCompany} title={"Message Send Logs"} selection={"messages"} onRefresh={refreshAsync}>
            <p>{ company?.name } Message Send Logs</p>
            { messageLogTable }
        </CompanyActivityMasterScreen>
    );

};

export default CompanyMessageSendLogsScreen;





