import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import HeaderButton from "../../components/common/ui/HeaderButton";
import UserMasterPage from "../../components/people/ui/UserMasterPage";
import CreditCardForm from "../../components/finance/ui/CreditCardForm";
import PaymentMethodModel from "../../components/finance/models/PaymentMethodModel";
import CopyText from "../../components/common/ui/CopyText";

const UserCardOnFileScreen = (props) => {
    let _;
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [paymentMethod, setPaymentMethod] = useState(UserService.instance.paymentMethodMap[userId] || null);
    const [activity, setActivity] = useState([]);

    const onUser = (user) => {
        if (!!user) setUser(user);
    };

    const onRefresh = () => {
        console.log("onRefresh");
    };

    const updateBillingAsync = async (cardModel, e) => {
        const json = cardModel.toJson();
        console.log(JSON.stringify(json));
        
        return await UserService.instance.updateCardOnFileAsync(userId, json).then((result) => {
            if (!!result) setPaymentMethod(result);
            return false;
        });
    };

    const getPaymentMethodsAsync = async () => {
        await UserService.instance.getUserPaymentMethodAsync(userId).then((pm) => {
            console.warn(JSON.stringify(pm));
            
            setPaymentMethod(pm || new PaymentMethodModel());
        }).catch((ex) => {
            console.log("Nothing on file");
            setPaymentMethod(new PaymentMethodModel());
        });
    };
    
    useEffect(() => {
        _ = getPaymentMethodsAsync();
    }, []);

    const subtitle = (<>{ user?.fullName } <span className={"subtitle"}>Card on File</span></>);
    const cardForm = !!user?.id ? (<CreditCardForm onSubmit={updateBillingAsync} creditCard={paymentMethod?.creditCard} useBillingZip={true} useButton={true} />) : null;
    const identifierElement = !!paymentMethod?.identifier ? (<span className={"identifier"}><CopyText text={paymentMethod.identifier}>Identifier: { paymentMethod.identifier }</CopyText></span>) : null;
    
    return (
        <UserMasterPage user={user} title={"Personnel"} subTitle={subtitle} onUser={onUser} selection={"card"}>
            <p>
                { identifierElement } For payments, mainly.
            </p>

            <div className={"form x-large"}>
                {cardForm}
            </div>

        </UserMasterPage>
    );

};

export default UserCardOnFileScreen;

