import HttpService from "../../common/services/HttpService";
import NotificationModel from "../models/NotificationModel";
import {HubConnectionBuilder} from "@microsoft/signalr";
import UserNotificationModel from "../models/UserNotificationModel";

class NotificationService { 
    static instance = new NotificationService();

    constructor() {
        this.notifications = [];
        this.connection = null;
        this.onMessage = (message) => {
            console.log("Incoming Message: " + message);
            console.warn(" > No onMessage handler set for NotificationService");
        };
    }

    /**
     * Sets the connection.
     * @param connection {any}
     * @returns {HubConnectionBuilder}
     */
    setConnection(connection = null) {
        if (!connection) {
            connection = new HubConnectionBuilder()
                .withUrl('/signalr')
                .withAutomaticReconnect()
                .build();
        }
        
        this.connection = connection;
        return this.connection;
    };
    
    async markNotificationAsReadAsync(notificationId) {
        //if (!!notificationId) throw new Error("Toast");
        
        const path = '/api/user-notification/' + notificationId;
        const me = this;

        return await HttpService.instance.postAsync(path, {}).then((response) => {
            if (!response?.data) return null;
            
            let notification = new NotificationModel(response?.data);

            let index = me.notifications.findIndex((item) => item.id === notification.id);
            if (index >= 0) me.notifications[index] = notification;

            return notification;
        });
    }

    async getNotificationsAsync() {
        const path = "/api/notification";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const ns = UserNotificationModel.fromJsonArray(response.data);
            
            // if (ns?.length > 0) { 
            //     console.log(JSON.stringify(response.data[0], null, 4));
            //     console.warn(JSON.stringify(ns[0], null, 4));
            // }
            
            me.notifications = ns;
            
            return ns;
        });
    }
    
    async registerConnectionAsync(connectionId) { 
        if (!connectionId || connectionId.length < 10) return false;
        if (!HttpService.instance.sessionId) return false;
        
        const path = "/api/auth/signalr";
        const me = this;
        const data = { text: connectionId };
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            //console.log("SignalR Connection Registered OK: " + connectionId);
            return true;
        });
    }
}

export default NotificationService;
