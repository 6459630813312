import React, {useEffect, useState} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faSync} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import HeaderButton from "../../common/ui/HeaderButton";
import CommentForm from "../../messaging/ui/CommentForm";

const CommonMasterPage = (props) => {
    const {
        onRefresh,
        onClick,
        onSessionChange,
        menuSelection,
        selection,
        selector,
        children,
        controller,
        data,
        subMenu,
        title,
        subTitle,
        entityId,
        entityType,
        icon,
        bodyClassName,
        commentEntityType,
        altMenu
    } = props;

    const params = useParams();

    const refreshAsync = async (e) => {
        if (typeof onRefresh !== "function") return;
    
        return await Promise.all([
            onRefresh(e),
        ]);
    };

    useEffect(() => {
        //
    }, []);

    const body = typeof children !== 'undefined' ? children : (<p>No body was given to this CompanyMasterPage. If you meant to have this blank, try passing a null value to the "company" property.</p>)
    const refreshButton = (typeof onRefresh === 'function') ? (<HeaderButton icon={faSync} onClick={refreshAsync}>Refresh</HeaderButton>) : null;
    
    const comments = typeof commentEntityType === 'number' && typeof entityId === 'string' && entityId.length > 10 ?
        (<CommentForm entityType={commentEntityType} entityId={entityId} />) :
        null;

    const h1 = (<><FontAwesomeIcon icon={icon || faBriefcase}/>{title}</>);
    const cn = !!bodyClassName ? " " + bodyClassName : "";
    
    return (<MasterScreen selector={selector} title={h1} onClick={onClick} onSessionChange={onSessionChange}>
            <div id="company-main" className="pad">

                <div className={"details-body" + cn}>
                    { subMenu }
                    <div className="pad x-large">
                        <h2>
                            {typeof title === 'undefined' ? "Company Information Page" : subTitle}
                            {refreshButton}
                        </h2>
                        
                        {altMenu}
                        {body}
                        {comments}
                    </div>
                </div>
            </div>
        </MasterScreen>);

};

export default CommonMasterPage;
