import React, {useEffect, useState} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import BackButton from "../../../components/common/ui/BackButton";
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import ProjectService from "../../../components/projects/services/ProjectService";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import ProjectItemSubMenu from "../../../components/projects/ui/ProjectItemSubMenu";

const ProjectItemEditScreen = (props) => {
    let { companyId, projectId, projectItemId } = useParams();
    let { slug } = props;
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    let [project, setProject] = useState(ProjectService.instance.projectMap[projectId] || null);
    let [projectItem, setProjectItem] = useState(ProjectService.instance.projectItemMap[projectItemId] || null);

    let _;

    const getProjectAsync = async () => {
        if (projectId.length < 30) {
            return;
        }

        await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            setProject(p);
        });
    };

    const getProjectItemAsync = async () => {
        if (projectId.length < 30) return;

        await ProjectService.instance.getProjectItemAsync(projectItemId).then((pi) => {
            setProjectItem(pi);
        });
    };

    useEffect(() => {
        CompanyEffect.create(setCompany, companyId);
        _ = getProjectAsync();
        _ = getProjectItemAsync();
    }, []);

    if (!slug) slug = "";
    let backUrl = "/companies/" + companyId + "/projects/" + projectId + "/" + slug;

    return (
        <MasterScreen selector={"projects"}>
            <div id="dashboard-main" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faArrowTrendUp}/>
                    Companies:
                    <span className={"subtitle"}>{company?.name}</span>
                </h1>

                <div className="details-body">
                    <CompanySubMenu company={company} selection={"projects"} />
                    
                    <div className="pad x-large">
                        <h2 className={"tree"}>
                            <BackButton to={backUrl} />
                            <span className={"subtitle"}>
                                <Link to={"/companies/" + companyId + "/projects/" + projectId}>{project?.name}</Link>
                            </span>
                            <span className={"submenu-delimiter"}>»</span>
                            <span className={"subtitle"}>{projectItem?.name}</span>
                        </h2>

                        <ProjectItemSubMenu project={project} projectId={projectId} projectItem={projectItem} company={company} companyId={companyId} selection={"edit"} />

                        <p>
                            This area will show a cool description.
                        </p>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </MasterScreen>
    );

};

export default ProjectItemEditScreen;