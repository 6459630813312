import React, {useEffect, useRef} from 'react';
import AddressModel from "../models/AddressModel";
import FormButton from "../../common/ui/FormButton";

const AddressForm = (props) => {
    let { title, onChange, address, onSubmit, value } = props;

    if (!address) address = value;
    if (!address) address = new AddressModel();
    
    let addressJson = {
        street: useRef(address?.street || ""),
        unit: useRef(address?.unit || ""),
        city: useRef(address?.city || ""),
        state: useRef(address?.state || ""),
        zip: useRef(address?.zip || ""),
        country: useRef(address?.country || ""),
    };
    
    useEffect(() => {
        addressJson.street.current.value = address?.street || "";
        addressJson.unit.current.value = address?.unit || "";
        addressJson.city.current.value = address?.city || "";
        addressJson.state.current.value = address?.state || "";
        addressJson.zip.current.value = address?.zip || "";
        addressJson.country.current.value = address?.country || "";
    }, []);
    
    const onAddressChange = (fieldId, event) => {
        //console.log(fieldId + ": " + event.target.value);
    };
    
    const onAddressSubmitAsync = async (e) => {
        if (typeof onSubmit === 'function') {
            let json = {
                street: addressJson.street.current.value,
                unit: addressJson.unit.current.value,
                city: addressJson.city.current.value,
                state: addressJson.state.current.value,
                zip: addressJson.zip.current.value,
                country: addressJson.country.current.value,
            };
            
            await onSubmit(new AddressModel(json));
        }
    };
    
    let titleElement = !!title ? (<h3 className={"address-form-title"}>{title}</h3>) : null;
    
    let stateOptions = AddressModel.states.map((state) => {
        return (<option key={state.abbr} value={state.abbr}>{state.name}</option>);
    });

    let countryOptions = AddressModel.countries.map((country) => {
        return (<option key={country.abbr} value={country.abbr}>{country.name}</option>);
    });

    return (<div className={"address-form"}>
        {titleElement}

        <label>Street</label>
        <input type={"text"} onChange={onAddressChange.bind(this, "street")} ref={addressJson.street} />

        <label>Unit</label>
        <input type={"text"} onChange={onAddressChange.bind(this, "unit")} ref={addressJson.unit} />

        <label>City</label>
        <input type={"text"} onChange={onAddressChange.bind(this, "city")} ref={addressJson.city} />

        <div className={"double"}>
            <div className={"half"}>
                <label>State</label>
                <select onChange={onAddressChange.bind(this, "state")} ref={addressJson.state}>
                    <option value={""}>Select State</option>
                    { stateOptions }
                    
                </select>
            </div>

            <div className={"half"}>
                <label>Zip</label>
                <input type={"text"} onChange={onAddressChange.bind(this, "zip")} ref={addressJson.zip} />
            </div>
        </div>

        <label>Country</label>
        <select onChange={onAddressChange.bind(this, "country")} ref={addressJson.country}>
            <option value={""}>Select Country</option>
            { countryOptions }
        </select>

        <div className={"buttons"}>
            <FormButton onClick={onAddressSubmitAsync}>Update Address</FormButton>
        </div>

    </div>);
};

export default AddressForm;
