import WorkflowStateModel from "./WorkflowStateModel";
import ProjectItemModel from "./ProjectItemModel";

class WorkflowViewModel { 
    constructor(data, index = -1) {
        if (!data) data = {};
        else if (typeof data === 'object') { 
            //
        }
        
        this.index = index;
        this.workflowState = new WorkflowStateModel(data.workflow_state);
        this.projectItems = ProjectItemModel.fromJsonArray(data.project_items);
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        
        return jsonArray.map((json, index) => new WorkflowViewModel(json, index));
//            .filter((w) => typeof w.workflowStateNumber === 'number' && !!w.name);
    }
}

export default WorkflowViewModel;

