import React, {useEffect, useRef, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import UserService from "../../../components/people/services/UserService";
import CompanyService from "../../../components/companies/services/CompanyService";
import Controller from "../../../components/common/services/Controller";
import CompanyUserSubMenu from "../../../components/companies/ui/CompanyUserSubMenu";
import CompanyEditSubMenu from "../../../components/companies/ui/CompanyEditSubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp} from "@fortawesome/free-solid-svg-icons";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import CompleteDialog from "../../../components/common/ui/dialog-box/CompleteDialog";
import PersonForm from "../../../components/people/ui/PersonForm";
import FormButton from "../../../components/common/ui/FormButton";
import PhoneNumber from "../../../components/common/ui/PhoneNumber";
import MessagingService from "../../../components/messaging/services/MessagingService";
import ActivityService from "../../../components/activity/services/ActivityService";
import DateTime from "../../../components/common/ui/DateTime";
import Checkbox from "@jadecharles/pi-react-packages/dist/common/forms/Checkbox";
import ConfigModel from "../../../components/common/models/ConfigModel";

const CompanyUserMessages = (props) => {

    let _;
    const { companyId, userId } = useParams();
    
    const initialData = {
        company: null,
        message: null,
        user: UserService.instance.userMap[userId || ""] || null,
        userCompany: CompanyService.instance.companyMap[companyId || ""] || null,
        newUserId: null,
        state: 0,
        tagView: false,
        cursor: 0,
        houseCheck: false,
    };
    
    const [pageState, setPageState] = useState(initialData);
    const tags = MessagingService.tags;
    const sections = MessagingService.sections;

    const user = pageState.user || null;
    const company = pageState?.company;
    const userCompany = pageState?.userCompany;
    
    const setCompany = (c) => {
        const st = {...pageState};
        st.company = c;
        setPageState(st);
    };
    
    const setMessages = (messageItems) => { 
        const st = {...pageState};
        st.messages = messageItems;
        
        setPageState(st);
    };
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    };

    const [newUserId, setNewUserId] = useState(null);
    const controller = useRef(new Controller()).current;

    const isCompanyUser = true; // !!userId;
    const isNewUser = !!userId && userId.length < 30;
    const isCompanyPrimaryUser = !userId && !!companyId;
    
    const recipientRef = useRef();
    const subjectRef = useRef();
    const messageRef = useRef();
    const linkRef = useRef();

    const getUserAsync = async (e, reRender = false) => {
        if (isNewUser) return;

        const user = await UserService.instance.getUserAsync(userId);
        
        if (!user) return { user: null, company: c };

        let c = user.company || null;

        c = await CompanyService.instance.getCompanyAsync(user.companyId);
        
        if (reRender === true) { 
            const newState = {...pageState};
            newState.user = user;
            if (!!c?.id) newState.company = c;
            
            setPageState(newState);
        }
        
        return { user: user, company: c };
    };
    
    const getMessagesAsync = async (force = false, reRender = false) => {
        if (isNewUser && force !== true) return;

        return await ActivityService.instance.getUserMessageSendLogsAsync(userId).then((messages) => {
            if (!messages) return [];
            
            if (reRender === true && Array.isArray(messages)) 
                setMessages(messages);
            
            return messages;
        });
    };
    
    const createJson = () => {
        return {
            user_id: user?.id,
            recipient: recipientRef.current?.value || pageState.recipient || null,
            subject: subjectRef.current?.value || pageState.subject || null,
            link: linkRef.current?.value || pageState.link || null,
            company_id: pageState.houseCheck === true ? null : companyId,
            message: createHtmlPreviewFromText(messageRef.current?.value || pageState.message || null),
            message_template_type: null,
        }
    };
    
    const createHtmlPreviewFromText = (text = null) => {
        if (!text) text = messageRef.current?.value || "";
        return text.trim().replaceAll("\n", "<br/>");
    };
    
    const suppressEvent = (e) => {
        if (typeof e?.stopPropagation === 'function') e.stopPropagation();
        if (typeof e?.preventDefault === 'function') e.preventDefault();
    };
    
    const getMessagePreviewAsync = async (e) => {
        suppressEvent(e);

        const json = createJson();

        if (!json?.recipient) {
            console.error("No recipient");
            return;
        }

        if (!json?.message) {
            console.error("No Message");
            return;
        }

        const cid = pageState.houseCheck === true ? ConfigModel.companyId : companyId;

        const rsp = await MessagingService.instance.getMessagePreviewAsync(json, cid).catch((ex) => {
            return false;
        });

        const previewHtml = rsp.body || null;
        if (!previewHtml) return false;

        const newState = {
            ...rsp,
            ...pageState,
            state: 2,
            previewHtml: previewHtml,
            recipient: recipientRef.current?.value || null,
            link: linkRef.current?.value || null,
            subject: subjectRef.current?.value || null,
            message: messageRef.current?.value || null,
        };

        setPageState(newState);

        return true;
    };

    const onTagClick = (e, tagName, isSection = false) => {
        suppressEvent(e);
        
        let tokenName = (tagName || (e?.target?.innerHTML || ""));
        if (!tokenName) return;

        tokenName = isSection === true ? "<pi:" + tokenName + "></pi:" + tokenName + ">" : "<pi:" + tokenName + " />";

        messageRef.current.value = messageRef.current.value.substring(0, pageState.cursor) + tokenName + messageRef.current.value.substring(pageState.cursor);
        messageRef.current.focus();

        setTimeout(() => {
            messageRef.current.selectionStart = pageState.cursor + tokenName.length;
            messageRef.current.selectionEnd = pageState.cursor + tokenName.length;
        }, 100);
    };

    const onBlur = (e) => {
        suppressEvent(e);
        const cursorPosition = e?.target?.selectionStart;
        let text = e?.target?.value;

        setPageState({...pageState, cursor: cursorPosition});
    };
    
    const sendMessageAsync = async (e) => {
        suppressEvent(e);

        const json = createJson();

        if (!json?.recipient) {
            console.error("No recipient");
            console.log(JSON.stringify(json, null, 4));
            return;
        }

        if (!json?.message) {
            const t = setTimeout(() => {
                clearTimeout(t);
                const element = document.getElementById("message");
                if (typeof element?.focus === "function") element.focus(e);
            }, 250);

            return null;
        }
        
        const msg = await MessagingService.instance.sendMessageAsync(json).catch((ex) => {
            console.error(ex?.response?.data?.message || ex?.message || ex?.toString() || "Unknown error");
            return null;
        });

        const items = await getMessagesAsync(true, false);
        
        return resetForm(e, items);
    };


    const resetForm = (e, historyItems = null) => {
        suppressEvent(e);

        let newState = {...pageState};
        
        if (historyItems === true || Array.isArray(historyItems)) {
            newState = initialData;
            
            if (!!messageRef.current)
                messageRef.current.value = "";
            
            if (!!subjectRef.current)
                subjectRef.current.value = "";
            
            if (!!linkRef.current)
                linkRef.current.value = "";

            if (Array.isArray(historyItems)) 
                newState.messages = historyItems;
        }

        newState.state = 0;
        newState.previewHtml = null;

        setPageState(newState);
    };

    const getAsync = async (force = false) => {
        await Promise.all([
            getUserAsync(force, false),
            getMessagesAsync(force, false),
        ]).then((values) => { 
            const newState = {...pageState};
            
            newState.user = values[0]?.user || newState.user;
            newState.company = values[0]?.company || newState.company;
            newState.messages = values[1];
            
            setPageState(newState);
        });
    }
    
    const getPreviewAsync = async () => {
        // 
    };
    
    const onCheckboxClick = (a, b, c) => {
        console.log("Checkbox Clicked: ", a, b, c);
        const newState = {...pageState};
        newState.houseCheck = a;
        setPageState(newState);
    };

    useEffect(() => {
        getAsync();
    }, []);

    if (!!newUserId && newUserId.length > 30) {
        return (<Navigate to={"/companies/" + companyId + "/people/" + newUserId} />);
    }

    const u = isCompanyUser ? user : company?.primaryUser;
    
    const messageForm = !!pageState.previewHtml ? (<div style={{marginBottom: "64px"}}>
            <h3 className={"title-submenu"}>
                <span>Message Preview</span>
                <span><a onClick={(e) => resetForm(e)}>Reset</a></span>
            </h3>
            <div className={"message-preview"} dangerouslySetInnerHTML={{__html: pageState.previewHtml}}></div>
            <div><FormButton onClick={sendMessageAsync}>Send Message to User</FormButton></div>
        </div>) : (<div className={"form"}>
        <div className={"form-group"}>
            <label htmlFor={"recipient"}>To Email or Phone Number:</label>
            <div><input defaultValue={pageState?.recipient || user?.email || user?.phone} type={"text"} id={"recipient"} ref={recipientRef} /></div>
        </div>

        <div className={"form-group"}>
            <label htmlFor={"subject"}>Subject:</label>
            <div><input type={"text"} id={"subject"} defaultValue={pageState?.subject || ""} ref={subjectRef} /></div>
        </div>

        <div className={"form-group"}>
            <label htmlFor={"message"}>Message:</label>
            <div><textarea id={"message"} onBlur={onBlur} defaultValue={pageState?.message || ""} ref={messageRef}></textarea></div>
        </div>

        <div className={"form-group"}>
            <label htmlFor={"link"}>Action Link (Optional):</label>
            <div><input type={"text"} defaultValue={pageState?.link || ""} id={"link"} ref={linkRef} /></div>
        </div>

        <div className={"form-group flex"} style={{justifyContent: "flex-start", alignItems: "center", gap: "12px" }}>
            <Checkbox onCheck={onCheckboxClick} />
            <label htmlFor={"use-house"}>Use House Account Email Template (Not recommended)</label>
        </div>
        
        <div className={"buttons"}>
            <FormButton onClick={getMessagePreviewAsync}>Preview</FormButton>
        </div>
    </div>);

    const editMenu = isCompanyUser ?
        (user ? (<CompanyUserSubMenu user={user} company={company} selection={"messages"} />) : null) :
        (company ? (<CompanyEditSubMenu company={company} selection={"owner"} />) : null);

    const h2titleText = isNewUser ? "Create New User" : "" + (user?.firstName || "User") + "'s Messages";
    const h2title = (<>{ h2titleText }</>);

    const menuSelector = isCompanyUser ? "people" : "edit";
    const backButton = isCompanyUser ? (<BackButton to={"/companies/" + companyId + "/people"} />) : null;

    const subtitle = (<>
        {backButton}
        {h2title}
    </>);

    const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
    const companyElement = (isDifferentCompany) ?
        (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
        null;

    const messageElements = pageState.messages?.map((message, index) => {
        return (<tr key={"message-" + message?.id}>
            <td>{message?.recipient}</td>
            <td>{message?.message}</td>
            <td><DateTime value={message.created} time={true} /></td>
        </tr>);
    });
    
    const messageTable = pageState.messages?.length > 0 ? (<div>
        <h2>Message History ({pageState.messages.length})</h2>
        <table className={"table x-large table-x-large"} style={{width: "100%", marginBottom: "48px"}}>
        <thead>
            <tr>
                <th>To</th>
                <th>Message</th>
                <th>Sent On</th>
            </tr>
        </thead>
        <tbody>{messageElements}</tbody>
        </table>
    </div>) : null;

    const phoneElement = !!user?.phone ? (<strong> or <PhoneNumber value={user.phone} /></strong>) : null;
    const tagElements = pageState.tagView === true ? tags.map((tag, index) => {
        return (<span key={index} onClick={(e) => onTagClick(e, tag.name, false)} title={tag.description}>&lt;pi:{tag.name} /&gt;</span>);
    }) : null;

    const sectionElements = pageState.tagView === true ? sections.map((section, index) => {
        return (<span key={index} onClick={(e) => onTagClick(e, section.name, true)} title={section.description}>&lt;pi:{section.name}&gt;...&lt;/pi:{section.name}&gt;</span>);
    }) : null;
    
    const pp = !!tagElements ? (<p>
        Send messages to users with the following tokens:
        <span className={"tokens"}>
            {tagElements}
            {sectionElements}
        </span>
        <span style={{display: "block", marginTop: "12px"}}><a className={"link"} onClick={(e) => setPageState({...pageState, tagView: false })}>- Hide Tags</a></span>
    </p>) : (<div><a onClick={(e) => setPageState({...pageState, tagView: true })} className={"link"}>+ Show Tags</a></div>);
    
    return (<CompanyMasterPage selection={"messages"} menuSelection="edit" onCompany={onCompany} title={subtitle} onRefresh={() => getAsync(true)}>
            {editMenu}
            {pp}
            <div className={"form medium"}>{messageForm}</div>
            {messageTable}
            <CompleteDialog controller={controller} />
        </CompanyMasterPage>
    );
};

export default CompanyUserMessages;
