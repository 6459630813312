class JobModel {
    static entityType = 57;
    static create = (json) => new JobModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.companyId = json.company_id || null;
        this.userId = json.user_id || null;
        this.startDate = !!json.start_date ? new Date(json.start_date) : null;
        this.endDate = !!json.end_date ? new Date(json.end_date) : null;
        this.message = json.message || null;
        this.name = json.name || null;  
        this.jobStatus = json.job_status || 0;
        this.entityType = json.entity_type || 0;
        this.entityId = json.entity_id || null;
        this.status = json.status || 0;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            company_id: this.companyId,
            user_id: this.userId,
            start_date: this.startDate,
            end_date: this.endDate,
            message: this.message,
            name: this.name,
            job_status: this.jobStatus,
            entity_type: this.entityType,
            entity_id: this.entityId,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.message?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.name?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.jobStatus?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => JobModel.create(json));
    }
}

export default JobModel;
