import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowTrendUp,
    faArrowUpRightFromSquare,
    faBriefcase,
    faCreditCardAlt, faExpand, faFile,
    faSignsPost
} from "@fortawesome/free-solid-svg-icons";
import CompanyService from "../../../components/companies/services/CompanyService";
import {Link, useParams} from "react-router-dom";
import BackButton from "../../../components/common/ui/BackButton";
import ProjectService from "../../../components/projects/services/ProjectService";
import ProjectSubMenu from "../../../components/projects/ui/ProjectSubMenu";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import CompanyProjectsSubMenu from "../../../components/companies/ui/CompanyProjectsSubMenu";
import ProjectItemSubMenu from "../../../components/projects/ui/ProjectItemSubMenu";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import DropTarget from "../../../components/common/ui/drop-target/DropTarget";
import FileUploadModel from "../../../components/content/models/FileUploadModel";
import FormButton from "../../../components/common/ui/FormButton";
import AuthenticationService from "../../../components/authentication/services/AuthenticationService";
import {Tooltip} from "@mui/material";
import DocumentService from "../../../components/content/services/DocumentService";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import BulletArrow from "../../../components/common/ui/BulletArrow";
import {bocgFigma, bocgMarkdown} from "../../../components/common/ui/svgs/LogoSvgs";
import ContentDropper from "../../../components/content/ui/ContentDropper";

/**
 * This screen is for displaying the content of: Company, Project, or ProjectItem. The display depends on the input params
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProjectContentScreen = (props) => {
    let _;
    const { projectId, companyId, projectItemId } = useParams();

    const initialContent = {
        files: [],
        documents: ProjectService.instance.contentMap[projectId] || null,
        project: ProjectService.instance.projectMap[projectId] || null,
        textItems: []
    };

    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    const [projectData, setProjectData] = useState(initialContent);

    const isProjectItem = !!projectItemId;
    
    // const getCompanyContentAsync = async () => {
    //     if (!companyId) return;
    //     await CompanyService.instance.getContentAsync(companyId).then((items) => {
    //         if (items === null) return;
    //
    //         const c = {
    //             files: content.files,
    //             docs: items,
    //             textItems: content.textItems
    //         };
    //
    //         setContent(c);
    //     });
    // };
    
    const setProject = (p) => {
        const d = {...projectData};
        d.project = p;
        
        setProjectData(d);
    };
    
    const setDocuments = (docs) => {
        const d = {...projectData};
        d.documents = docs;
        
        setProjectData(d);
    };

    const onCompany = (c) => {
        setCompany(c);
    };

    const getProjectAsync = async (rerender = false) => {
        if (!projectId) return projectData.project;
        
        return await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            if (rerender) setProject(p);
            
            return p;
        });
    };
    
    const getProjectDocumentsAsync = async (rerender = false) => {
        if (!projectId) return projectData.documents;
        
        return await ProjectService.instance.getProjectContentAsync(projectId).then((items) => {
            if (rerender === true) setDocuments(items);
            return items;
        });
    };

    const onRefreshAsync = async () => {
        return await getProjectDocumentsAsync();
    };

    const uploadAsync = async (files, textItems) => {
        console.log("Uploading ...");

        const formFiles = files.map((f) => f.file);

        await Promise.all([
            ProjectService.instance.uploadProjectContentAsync(projectId, formFiles),
            ProjectService.instance.createProjectContentAsync(projectId, textItems),
        ]).then((results) => {
            results = results.flat();
            console.log("Upload Results: " + JSON.stringify(results));
            setDocuments(results);
        });
    };

    const onFilesDrop = (files, items) => {
        console.log('Files: ' + JSON.stringify(files));
        console.log("Items: " + JSON.stringify(items));
    };

    const getAsync = async () => {
        await Promise.all([
            getProjectAsync(),
            getProjectDocumentsAsync(),
        ]).then((responses) => {
            const project = responses[0];
            const content = responses[1];
            const dt = {...projectData};
            
            dt.project = project;
            dt.documents = content;

            console.warn("PROJECT: ", project);
            console.warn("Documents: ", content);
            setProjectData(dt);
        });

    };
    
    useEffect(() => {
        _ = getAsync();
    }, []);

    let backUrl = "/companies/" + companyId + "/projects";
    if (!!projectItemId) backUrl += "/" + projectId;
    //if (!!projectItemId) backUrl += "/items/" + projectItemId;
    
    const project = projectData.project || null;
    const documents = projectData.documents || [];
    
    const subtitle = projectId ? project?.name : "All " + (company?.name || "");
    let contentTitle = projectItemId ? "Item Content" : (projectId ? "Project Content" : "Content");

    let submenu = projectId ?
        (<ProjectSubMenu project={project} projectId={projectId} company={company} companyId={companyId} selection={"content"} />) :
        (<CompanyProjectsSubMenu company={company} selection={"content"} />);

    let h2 = project ?  (<><BackButton to={backUrl} />
        <span className={"pre-subtitle"}>
            <Link to={"/companies/" + companyId + "/projects"}>Project:</Link>
        </span>
        <Link to={"/companies/" + companyId + "/projects/" + projectId}>{project?.name}</Link>
        <BulletArrow />
        <span className={"subtitle"}>{project?.name} Content</span></>) : null;


    const sid = AuthenticationService.instance.sessionId;
    const name = (project?.name || "Project") + " Content";
    const pp = "All content for " + name + " (" + documents?.length + ")";

    const contentDropper = documents !== null ? 
        (<ContentDropper
            onDrop={onFilesDrop} 
            items={documents} 
            onUpload={uploadAsync} 
        />) : null;

    return (
        <CompanyMasterPage subTitleClassName={"tree"} menuSelection="projects" onCompany={onCompany} title={subtitle} subMenuSelection={"content"} subMenu={submenu} onRefresh={onRefreshAsync}>
            <p className={"condensed x-large"} title={JSON.stringify(documents, null, 4)}>
                {pp}
            </p>

            <div className={"x-large"}>
                { contentDropper }
            </div>

        </CompanyMasterPage>
    );

};

export default ProjectContentScreen;