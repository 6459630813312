import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import MasterScreen from "../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import PersonForm from "../../components/people/ui/PersonForm";
import PhoneNumber from "../../components/common/ui/PhoneNumber";
import HeaderButton from "../../components/common/ui/HeaderButton";
import UserModel from "../../components/people/models/UserModel";
import UserMasterPage from "../../components/people/ui/UserMasterPage";

const UserActivityScreen = (props) => {
    let _;
    let { userId } = useParams();
    let [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    let [activity, setActivity] = useState([]);
    
    const getUserAsync = async (force) => {
        if (!force && user) {
            return user;
        }
        
        return await UserService.instance.getUserAsync(userId).then((user) => {
            onUser(user);
        });
    };

    const onUpload = (image) => {
        console.warn('Profile Image Update.');
        _ = getUserAsync(true);
    };

    const onUser = (user) => {
        setUser(user);
    };
    
    useEffect(() => {
        //
    }, [user]);
    
    useEffect(() => {
        //
    }, []);

    let activityElements = activity.map((item, index) => {
        return (
            <tr key={index}>
                <td><Link to={"/people/" + item.id}>{ item.text }</Link></td>
                <td>{ item.severityName }</td>
                <td>{ item.created.formatDate() }</td>
            </tr>
        );
    });

    let subtitle = (<>{ user?.fullName }
        <span className={"subtitle"}>Activity</span>
        <HeaderButton onClick={getUserAsync.bind(this, true)}>Refresh</HeaderButton></>);
    
    return (
        <UserMasterPage user={user} title={"Personnel"} subTitle={subtitle} onUser={onUser} selection={"activity"}>
            <p>
                You have a meeting tonight at 9:00 pm Pacific Time with
            </p>

            <div className={"form x-large"}>
                <table width={"100%"}>
                    <thead>
                    <tr>
                        <th>Activity</th>
                        <th>Level</th>
                        <th>Created</th>
                    </tr>
                    </thead>

                    <tbody>
                    { activityElements}
                    </tbody>
                </table>
            </div>
            
        </UserMasterPage>        
    );
    
    
};

export default UserActivityScreen;