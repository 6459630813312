import React, {createRef, useEffect, useRef, useState} from 'react';
import MasterScreen from "../../../MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faBriefcase, faCreditCardAlt, faHandPointUp} from "@fortawesome/free-solid-svg-icons";
import {Link, useParams} from "react-router-dom";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import companyEffect from "../../../components/companies/services/CompanyEffect";
import CompanyService from "../../../components/companies/services/CompanyService";
import CompanyEffect from "../../../components/companies/services/CompanyEffect";
import UserService from "../../../components/people/services/UserService";
import CompanyUserSubMenu from "../../../components/companies/ui/CompanyUserSubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import DateTime from "../../../components/common/ui/DateTime";
import PagerController from "../../../components/common/pager/PagerController";
import Pager from "../../../components/common/pager/Pager";

const CompanyUserHistoryScreen = (props) => {
    const { userId, companyId } = useParams();
    let _;
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    
    const initialUser = UserService.instance.userMap[userId] || null;
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[initialUser?.companyId || ""] || null);
    
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;
    
    const initUserData = {
        user: UserService.instance.userMap[userId || ""] || null,
        history: UserService.instance.userHistoryMap[userId || ""] || null,
    };
    const [userData, setUserData] = useState(initUserData);
    
    const getUserAsync = async (force, e) => {
        if (!force && !!userData?.user) return userData?.user;

        return await UserService.instance.getUserAsync(userId).then((u) => {
            return u;
        });
    };

    const getUserHistoryAsync = async (force) => {
        if (!force && !!userData?.history && userData.history.length > 0)
            return userData?.history;

        return await UserService.instance.getUserHistoryAsync(userId).then((h) => {
            return h;
        });
    };
    
    const onCompany = (c) => {
        setCompany(c);
    };

    const getAsync = async (force) => {
        await Promise.all([
            getUserAsync(force),
            getUserHistoryAsync(force),
        ]).then((values) => { 
            const data = {
                user: values[0],
                history: values[1],
            };
            
            setUserData(data);

            if (data.user.companyId !== companyId && !userCompany) {
                console.log("Getting User's Company: " + data.user?.companyId);
                _ = CompanyService.instance.getCompanyAsync(data.user.companyId).then((c) => {
                    if (!!c) setUserCompany(c);
                });
            }
        });
    };
    
    useEffect(() => {
        console.log("Got data: " + userData?.history?.length);
    }, [userData]);
    
    useEffect(() => {
        _ = getAsync();
    }, []);
    
    const user = userData?.user;
    const history = userData?.history;
    
    let userName = !!user ? (user.firstName + " " + user.lastName).trim() : "User";
    let userSubMenu = user ? (<CompanyUserSubMenu user={user} company={company} selection={"history"} />) : null;
    
    const subtitle = (<><BackButton to={"/companies/" + companyId + "/people"} />
        <span className={"pre-subtitle"}>User History:</span>
        { userName }</>);
    
    const historyElements = pagerController.mapLineItems(history, (h, i) => {
        return (<tr key={"activity-" + i}>
            <td>{h.text}</td>
            <td><DateTime value={h.created} time={true} /></td>
        </tr>);
    });

    const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
    const companyElement = (isDifferentCompany) ?
        (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
        null;

    return (
        <CompanyMasterPage onCompany={onCompany} selection={"people"} title={subtitle} subMenu={userSubMenu}>
            <p>
                User history.
                {companyElement }
            </p>
            
            <div>
                <table className={"table-x-large"} width={"100%"}>
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        { historyElements }
                    </tbody>
                </table>
                
                <Pager controller={pagerController} items={userData?.history} />
            </div>
        </CompanyMasterPage>
    );

};

export default CompanyUserHistoryScreen;