import React, {useEffect, useState} from 'react';
import CompanyService from "../../companies/services/CompanyService";
import SortController from "../../common/services/SortController";
import {Link} from "react-router-dom";
import PhoneNumber from "../../common/ui/PhoneNumber";
import "./Users.css";
import EmptyBox from "../../common/ui/EmptyBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "@mui/material";

const UsersTable = (props) => {
    const { companyId, company, controller, isPlatformUsers, authUsers, title, empty, ownerId } = props;
    const initUsers = authUsers !== true ? CompanyService.instance.usersMap[companyId] || [] : [];
    
    let [users, setUsers] = useState(initUsers);
    let [sortController, setSortController] = useState(new SortController());
    let _;

    const getUsersAsync = async (force = false) => {
        if (authUsers === true) {
            console.log("Getting Auth Users...");
            return await getAuthUsersAsync(force);
        }

        console.log("Getting Company " + companyId + " Users...");
        
        if (force !== true && users !== null && users.length > 0) {
            console.warn("No Force. Returning Users: " + users.length);
            return users;
        }
        
        console.warn("Getting Users for " + companyId);
        const items = await CompanyService.instance.getUsersAsync(companyId, "0,1").then((us) => {
            if (!!us) setUsers(us);
            
            return us;
        });
        
        console.warn("Got Users. Good: " + items?.length);
        
        return items;
    };

    const getAuthUsersAsync = async (force = false) => {
        if (force !== true && users !== null && users.length > 0) return users;
        
        return await CompanyService.instance.getAuthUsersAsync(companyId, "0,1").then((us) => {
            if (!!us) setUsers(us);
            
            console.warn("Got Users from Service: " + us?.length);
            return us;
        });
    };

    const onSort = (field, e) => {
        setSortController(sortController.sortBy(field));
        console.log('Sorting by ' + field);
    };
    
    useEffect(() => {
        controller.refreshAsync = async (force = true) => {
            _ = getUsersAsync(force);
        }
        
        _ = getUsersAsync();
    }, []);

    const path = (isPlatformUsers === true) ? "" : "/companies/" + companyId;
    
    const userElements = sortController.sort(users).map((user, index) => {
        const statusClassName = user.status <= 0 ? "inactive" : "";
        const statusElement = user.userType > 1 ? (<span className={"user-type user-type-" + user.userType}>{ user.userTypeName }</span>) : null;
        const icon = user.id === ownerId ? (<Tooltip title={"Owner"}><FontAwesomeIcon icon={faStar} /></Tooltip>) : null;
        //const path = authUsers === true ? "/companies/" + user.companyId : prePath;
        
        return (
            <tr key={index} className={statusClassName} title={"Id: " + user.id}>
                <td><Link to={path + "/people/" + user.id}>{ user.fullName } { icon }</Link> {statusElement}</td>
                <td><a href={"mailto:" + user.email}>{ user.email }</a></td>
                <td><PhoneNumber value={user.phone} /></td>
                <td>{ user.created.formatDate() }</td>
            </tr>
        );
    });

    const titleElement = !!title ? (typeof title === "string" ? (<h3 className={"users-table-title"}>{ title }</h3>) : title) : null;
    
    if (users !== null && users.length === 0 && !!empty) {
        if (typeof empty === "string") return (<EmptyBox>{ empty }</EmptyBox>);
        if (typeof empty === 'boolean') return (<EmptyBox>No Users</EmptyBox>);
        if (typeof empty === 'object') return empty;
    }
    
    return (
        <>
        { titleElement }
        <table width={"100%"} className={"users-table"}>
            <thead>
            <tr>
                <th><a onClick={onSort.bind(this, "firstName")}>Name</a></th>
                <th>Email</th>
                <th>Phone</th>
                <th><a onClick={onSort.bind(this, "created")}>Created</a></th>
            </tr>
            </thead>

            <tbody>
            { userElements}
            </tbody>
        </table>
        </>
    );
};

export default UsersTable;
