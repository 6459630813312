import React, {useEffect, createRef, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import CompanyService from "../../components/companies/services/CompanyService";
import HeaderButton from "../../components/common/ui/HeaderButton";
import CompanyUserSubMenu from "../../components/companies/ui/CompanyUserSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import CompanyMasterPage from "../../components/companies/ui/CompanyMasterPage";
import UserModel from "../../components/people/models/UserModel";
import UserMasterPage from "../../components/people/ui/UserMasterPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp, faPaperPlane, faShieldAlt, faSkull} from "@fortawesome/free-solid-svg-icons";
import FormButton from "../../components/common/ui/FormButton";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import Checkbox from "@jadecharles/pi-react-packages/dist/common/forms/Checkbox";

/**
 * Dual use - House User and Company User
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UserAccessScreen = (props) => {
    let _;
    const { userId, companyId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId || ""] || null);
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[user?.companyId || ""] || null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [viewState, setViewState] = useState({ state: 0, message: "", neverExpires: false });
    const [session, setSession] = useState(AuthenticationService.instance.session || null);
    const [redirect, setRedirect] = useState("");
    
    const usernameRef = createRef();
    const roleRef = createRef();
    
    const getUserAsync = async () => {
        return await UserService.instance.getUserAsync(userId, companyId).then((user) => {
            onUser(user);
            return user;
        });
    };

    const onSessionChange = (sh) => {
        //console.warn("onSessionChange Access: " + sh?.user?.roleId);
        if (!!sh?.user?.id) {
            console.warn("Set Session Change: " + sh?.user?.id);
            setSession(sh);
        }
    };
    
    const onUser = (user) => {
        if (!!user) {
            if (user.companyId !== companyId && !userCompany) { 
                console.log("Getting User's Company: " + user.companyId);
                _ = CompanyService.instance.getCompanyAsync(user.companyId).then((c) => {
                    if (!!c) setUserCompany(c);
                });
            }
            
            setUser(user);
        }
    };

    const deleteAsync = async () => {
        if (!userId || userId.length < 30) return null;

        const result = await UserService.instance.deleteUserAsync(userId).then(() => {
            const path = !!companyId ? "/companies/" + companyId + "/people" : "/people";
            //setRedirectUrl(path);
            return true;
        }).catch((ex) => {
            const m = ex?.response?.data?.message || (ex?.message || JSON.stringify(ex));
            setErrorMessage(m);
            return null;
        });
        
        if (result === true && !!companyId)
            await CompanyService.instance.getUsersAsync(companyId);
        
        const path = !!companyId ? "/companies/" + companyId + "/people" : "/people";
        console.log("Good :)");
        setRedirect(path);
    };

    const onCompany = (company) => {
        //
    };
    
    const sendPasswordReset = async () => {
        const options = { toast: false };
        if (viewState.neverExpires) options.minutes = 9999999;
        
        console.log("Send Password Reset: " + options.minutes);
        
        return await AuthenticationService.instance.sendPasswordResetAsync(userId, companyId, options).then((rsp) => {
            return "Sent";
        }).catch((ex) => {
            const m = ex?.response?.data?.message || (ex?.message || JSON.stringify(ex));
            setErrorMessage(m);
            return null;
        });
    };

    const reSendWelcomeEmailAsync = async () => { 
        return await AuthenticationService.instance.reSendWelcomeEmailAsync(userId, companyId).then((rsp) => {
            return "Sent";
        }).catch((ex) => {
            const m = ex?.response?.data?.message || (ex?.message || JSON.stringify(ex));
            setErrorMessage(m);
            return null;
        });
    }
    
    const handleUserNameFocus = (e) => { 
        if (!user?.id) return;
        
        if (!!usernameRef.current)
            usernameRef.current.value = user?.email;
    };

    const activateUserAuthAsync = async (e) => {
        const username = usernameRef.current?.value;
        const roleId = roleRef.current?.value || "0";

        const authModel = await UserService.instance.activateUserAuthAsync(userId, username, roleId, companyId).catch((ex) => {
            setErrorMessage(ex?.response?.data?.message || ex?.message || JSON.stringify(ex));
            return null;
        });
        
        if (!!authModel) {
            await getUserAsync(true);
            setViewState({ state: 2, message: "Activation Sent" });
        }
    };

    const updateUserAuthAsync = async (e) => {
        const username = usernameRef.current?.value || null;
        const roleId = parseInt(roleRef.current?.value || "x");
        
        if (isNaN(roleId)) {
            console.error("Invalid RoleId: " + roleRef.current?.value);
            return;
        }
        
        console.log("Update Role to " + roleId);
        
        await UserService.instance.updateUserRoleAsync(userId, roleId).then((user) => {
            //
        });
    };

    const onExpiresChecked = (isChecked, e) => {
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        if (typeof e?.stopPropagation === "function")
            e.stopPropagation();
        
        const newState = {...viewState};
        newState.neverExpires = isChecked;
        
        setViewState(newState);
        return true;
    };
    
    const delayReset = (delay) => {
        if (!!delay) delay = 3500;
        
        setTimeout(() => {
            setViewState({ state: 0, message: "" });
        }, delay);
    };
    
    useEffect(() => {
        if (viewState > 0) { 
            delayReset();
        }
    }, [viewState]);
    
    useEffect(() => {
        if (!!errorMessage) { 
            setTimeout(() => {
                setErrorMessage(null);
            }, 5000);
        }
    }, [errorMessage]);
    
    useEffect(() => { 
        if (typeof user?.roleId === 'number' && !!roleRef.current) {
            roleRef.current.value = user?.roleId?.toString();
        }
        
    }, [user])
    
    useEffect(() => {
        if (companyId) _ = getUserAsync();
    }, []);

    if (!!redirect && redirect.length > 0) {
        return (<Navigate to={redirect} />);
    }
    
    let masterSelection = "users";
    let subSubMenu = (<></>);
    let backButton = null;

    let subtitle = (<>{backButton}
        { user?.fullName }
        <span className={"subtitle"}>Access</span>
        <HeaderButton onClick={getUserAsync.bind(this, true)}>Refresh</HeaderButton></>);

    const errorElement = !!errorMessage ? (<div className={"error-box top"}>{errorMessage}</div>) : null;
    const deleteElement = (<div className={"section-bottom"}>
        <a onClick={deleteAsync} className={"delete"}>Delete User</a>
    </div>);

    const roles = [
        { id: "", name: "Select Role" },
        { id: "0", name: "Guest" },
        { id: "1", name: "User" },
    ];

    const me = (session || AuthenticationService.instance?.session)?.user;
    
    if (me?.roleId > 0) {
        if (me.roleId >= 50) roles.push({ id: "50", name: "Admin" });
        if (me.roleId >= 100) roles.push({ id: "100", name: "God Mode" });
    }
    
    const hasAuth = (!!user?.auth?.id);
    const activateCaption = hasAuth ? "Update" : "Activate";
    const activateTitle = hasAuth ? "Change Access Role (" + user.auth?.roleName + ")" : "Activate Account Login";
    const authMethod = hasAuth ? updateUserAuthAsync : activateUserAuthAsync;
    const activateDescription = hasAuth ?
        (<>Update access level (Role) for user name <strong>{user?.auth?.username}</strong></>) :
        (<>Send an activation link to allow {user?.firstName} to set it up a password</>);
    const usernameElement = hasAuth ? (<span>Update Role:</span>) : (<input type={"text"} ref={usernameRef} placeholder={"Username / Email"} onFocus={handleUserNameFocus} />);
    
    const roleOptionElements = roles.map((role, idx) => {
        return (<option key={"role-option-" + idx} value={role.id}>{role.name}</option>);
    });

    const roleSelector = !!user?.id ? (<select ref={roleRef}>{ roleOptionElements }</select>) : null;
    
    const sendElement = viewState.state === 0 ? 
        (<span className={"access-form form"}>
            { usernameElement }
            { roleSelector }
        </span>) : 
        (<span className={"access-form form sent"}><span onClick={() => delayReset(10)}>{ viewState.message || "Sent"}</span></span>);
    
    const userAuthElement = (<li>
        <span className={"icon"}><FontAwesomeIcon icon={faShieldAlt} /></span>
        <span className={"content"}>
            <h3>{ activateTitle }</h3>
            <p>
                { activateDescription }
                { sendElement }
            </p>
        </span>
        <span className={"value"}>
            <FormButton onClick={authMethod} confirmPrompt={!hasAuth ? "Confirm Activation" : null}>
                { activateCaption }
            </FormButton>
        </span>
    </li>);

    const noExpireElement = (<Checkbox preventDefault={true} onCheck={onExpiresChecked}>Non-Expiring</Checkbox>);
    const confirmElement = (<div>
        <div style={{paddingBottom: "6px"}}>Confirm Send</div>
        <div>{noExpireElement}</div>
    </div>);

    const passwordResetElement = hasAuth ? (<li>
        <span className={"icon"}><FontAwesomeIcon icon={faPaperPlane} /></span>
        <span className={"content"}>
            <h3>Send Password Reset</h3>
            <p>Send a password-reset link to { user?.email }</p>
        </span>
        <span className={"value"}>
            <FormButton onClick={sendPasswordReset} confirmPrompt={confirmElement}>
                Send
            </FormButton>
        </span>
    </li>) : null;

    const resendWelcomeEmailElement = company?.companyType === 4 ? (<li>
        <span className={"icon"}><FontAwesomeIcon icon={faPaperPlane} /></span>
        <span className={"content"}>
            <h3>Re-Send Wecome Email</h3>
            <p>Send to { user?.email }</p>
        </span>
        <span className={"value"}>
            <FormButton onClick={reSendWelcomeEmailAsync} confirmPrompt={"Confirm Send"} completedPrompt={"Sent!"}>
                Send
            </FormButton>
        </span>
    </li>) : null;
    
    const body = (
        <div>
            <ul className={"section-panels"}>
                { resendWelcomeEmailElement }
                { userAuthElement }
                { passwordResetElement }
                <li>
                    <span className={"icon"}><FontAwesomeIcon icon={faSkull} /></span>
                    <span className={"content"}>
                        <h3>Deactivate { user?.fullName }</h3>
                        <p>User can be restored later if so desired. Once a user is deactivated, it can be purged which permanently from the system (full delete)</p>
                    </span>
                    <span className={"value"}>
                        <FormButton onClick={deleteAsync} confirmPrompt={"Confirm Deactivation"} completedMessage={"Done!"}>
                            Deactivate
                        </FormButton>
                    </span>
                </li>
                
            </ul>
        </div>
    );
    
    if (companyId) {
        const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
        const companyElement = (isDifferentCompany) ?
            (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
            null;
        
        subSubMenu = (<CompanyUserSubMenu company={company} user={user} selection={"access"} />);
        backButton = (<BackButton to={"/companies/" + companyId + "/people"} />);

        subtitle = (<>{backButton}
            { user?.fullName }
            <span className={"subtitle"}>Access</span></>);

        const onCompany = (c) => {
            setCompany(c);
        };

        return (
            <CompanyMasterPage onSessionChange={onSessionChange} selection={"people"} title={subtitle} onCompany={onCompany} subMenu={subSubMenu} emptyCompanyId={user?.companyId} bodyClassName={"x-large"}>
                <p>
                    User Access for { user?.fullName }. { companyElement }
                </p>

                { errorElement }
                {body}
            </CompanyMasterPage>
        );
    }

    return (
        <UserMasterPage onSessionChange={onSessionChange} user={user} onUser={onUser} title={"Personnel"} onCompany={onCompany} subMenu={subSubMenu} subTitle={subtitle} backButton={backButton} masterSelection={masterSelection}
                        entityId={userId} entityType={UserModel.entityType} selection={"access"} bodyClassName={"x-large"}>
            <p>
                User Access for { user?.fullName }. Put some information about this user here.
            </p>

            { errorElement }
            {body}
        </UserMasterPage>
    );


};

export default UserAccessScreen;
