import {faGavel, faPersonChalkboard, faRobot, faSkull} from "@fortawesome/free-solid-svg-icons";

class DocumentConfigs { 
    static ANY = "Any";
    static PRESENTATION_DECK = "PresentationDeck";
    static TECHNICAL_DOCUMENT = "TechnicalDocument";
    static LEGAL_DOCUMENT = "LegalDocument";
    static SCREEN_SHOT = "ScreenShot";
    static DESIGN_PROJECT = "DesignProject";
    static MOCK_UP = "MockUpImage";
    static WEB_FOLDER = "WebFolder";
    static TAX_DOCUMENT = "TaxDocument";
    
    static data = {
        docTypeKeys: {
            0: DocumentConfigs.ANY,
            1: DocumentConfigs.PRESENTATION_DECK,
            2: DocumentConfigs.TECHNICAL_DOCUMENT,
            3: DocumentConfigs.LEGAL_DOCUMENT,
            4: DocumentConfigs.MOCK_UP,
            5: DocumentConfigs.DESIGN_PROJECT,
            6: DocumentConfigs.WEB_FOLDER,
            7: DocumentConfigs.SCREEN_SHOT,
            8: DocumentConfigs.TAX_DOCUMENT,
            Any: 0,
            PresentationDeck: 1,
            TechnicalDocument: 2,
            LegalDocument: 3,
            ScreenShot: 4,
            DesignProject: 5,
            WebFolder: 6,
            MockUpImage: 7,
            TaxDocument: 8
        },
        docTypeValues: { 
            PresentationDeck: {
                title: "Decks and Presentations",
                description: "Investor Decks, One-Pagers, Teasers, and other presentations",
                path: "decks",
                icon: faPersonChalkboard,
                key: 1,
            },
            TechnicalDocument: {
                title: "Technical Documentation",
                description: "System specifications, technical documentation, and other technical information",
                path: "technical-docs",
                icon: faRobot,
                key: 2
            },
            LegalDocument: {
                title: "Agreements and Legal Documents",
                description: "Contracts, NDAs, and other legal documents",
                path: "agreements",
                icon: faGavel,
                key: 3,
            },
            TaxDocument: {
                title: "Tax Forms and Documents",
                description: "Forms (W-2, 1099, etc), along with any mitigating documents",
                path: "taxes",
                icon: faSkull,
                key: 8,
            }
        }
    }
}

export default DocumentConfigs;
