import React, { useRef, useState } from 'react';
import ShortUrlService from "../services/ShortUrlService";
import FormButton from "../../common/ui/FormButton";
import './ShortUrls.css';

const ShortUrlForm = (props) => {
    const { onComplete, companyId, companyDomain } = props;
    const [errors, setErrors] = useState([]);
    
    const pathRef = useRef();
    const redirectUrlRef = useRef();

    const createShortUrlAsync = async (e) => {
        const shortUrlPath = pathRef.current?.value || "";
        const redirectUrl = redirectUrlRef.current?.value;

        let formErrors = [];
        
        if (!redirectUrl) formErrors.push("");

        if (redirectUrl.indexOf("https://") !== 0 && redirectUrl.indexOf("http://") !== 0) {
            formErrors.push("Redirect URL must start with 'http://' or 'https://'");
        }

        if (formErrors.length > 0) {
            setErrors(formErrors);
            return;
        }

        await ShortUrlService.instance.createShortUrlAsync(companyId, companyDomain.id, shortUrlPath, redirectUrl).then((shortUrl) => {
            if (typeof onComplete === "function") onComplete(shortUrl);
            redirectUrlRef.current.value = "";
            pathRef.current.value = "";
        }).catch((error) => {
            let msg = error?.response?.data?.message;
            console.error("ERR: " + msg);
            console.log(JSON.stringify(error.response));
            
            setErrors([msg]);
        })
    };
    
    const onInputChange = (field, e) => {
        if (errors.length > 0) {
            setErrors([]);
        }
    };
    
    const errorElement = errors.length > 0 ? errors.map((error, index) => (<div key={"error-" + index} className={"error-item"}>{ error }</div>)) : null;
    
    return (<div className={"short-url-form form"}>
        <label>Path:</label>
        <div className={"url"}>https://{ companyDomain?.domain }/ <input type={"text"} id={"short-url-path"} ref={pathRef} maxLength={128} onChange={onInputChange.bind(this, "short-url-path")} /></div>

        <label>Redirect to URL:</label>
        <div><input placeholder={"https://"} type={"text"} id={"redirect-url"} ref={redirectUrlRef} onChange={onInputChange.bind(this, "redirect-url")} /></div>

        { errorElement }
        
        <div className={"buttons"}>
            <FormButton onClick={createShortUrlAsync}>Save URL</FormButton>
        </div>
    </div>);
};

export default ShortUrlForm;

