import React from 'react';
import axios from "axios";
import { useState, useEffect, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import ErrorElement from "../components/common/ui/ErrorElement";
import CompletedPanel from "../components/common/ui/CompletedPanel";
import FormButton from "../components/common/ui/FormButton";
import logoImage from "../images/logo-white.png";

export default function PasswordSet(props) {
    let title = props.title || 'Password Set Actual Title';
    const passwordField1 = useRef(null);
    const passwordField2 = useRef(null);

    const [autoSubmitEvent, setAutoSubmitEvent] = useState(null);
    const [errors, setError] = useState({});
    const [isComplete, setIsComplete] = useState(false);

    const [isWorking, setIsWorking] = useState(false);
    const { authCodeId } = useParams();
    
    useEffect(() => {
        
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                if (event.target.id === 'password') passwordField2.current.focus();
                else setAutoSubmitEvent(event);
                event.preventDefault();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);
    
    const clearErrors = (_) => {
        setError({});
    };

    const updatePasswordAsync = async (e) => {
        clearErrors(e);
        let password1 = passwordField1.current.value;
        let password2 = passwordField2.current.value;
        let payload = { password: password1, password2: password2 };

        if (!password1 || !password2) {
            console.log('password1 or password2 is empty');
            console.log('Payload: ' + JSON.stringify(payload));
            return;
        }

        if (password1 !== password2) {
            setError({main: 'Passwords do not match'});
            return;
        }

        if (password1.length < 8) {
            setError({main: 'Password must be at least 8 characters long'});
            return;
        }

        setIsWorking(true);
        let url = '/api/auth/password/' + authCodeId;

        await axios.post(url, payload).then(response => {
            setIsWorking(false);
            setIsComplete(true);
            console.log('Good: ' + JSON.stringify(response));
        }).catch(error => {
            setIsWorking(false);
            let message = error.response.data.message || error.message;
            setError({main: message});
        });

        console.log('Good: ' + JSON.stringify(payload))
    }

    if (autoSubmitEvent !== null) {
        let _ = updatePasswordAsync(autoSubmitEvent);
        setAutoSubmitEvent(null);

        console.log('AutoSubmit');
    }
    
    const imageElement = (<div className={"center logo"}><Link to={"/"}><img src={logoImage} alt={"Logo"} /></Link></div>);

    if (isComplete) {
        return (
            <div id="main">
                <div id="main-body-login" className={"complete"}>
                    { imageElement }
                    <div className="box form">
                        <CompletedPanel title={"Password Set Successfully"}>
                            <p>
                                You may now login to your account with no expected issues.
                                <br/><br/><br/>
                                <Link to={"/"}>Go Home</Link>
                            </p>
                        </CompletedPanel>
                    </div>
                </div>
            </div>
        );        
    }
    
    const createErrorElement = () => {
        if (!errors || errors.length === 0) return (<></>);
        const message = Array.isArray(errors) ? errors.join(". ") : errors.toString();
        return (<div className="error-box">{message}</div>);
    };

    const errorElement = createErrorElement();
    
    // const imageElement = (!!domainCompany?.imageUrl && domainCompany.imageUrl.length > 1) ?
    //     (<div className={"company-login-image"}><img src={domainCompany.imageUrl} alt={domainCompany.name} /></div>) : null;

    return (
        <div id="main">
            <div id="main-body-login">
                { imageElement }
                <div className="box form">
                    <div>
                        <div className={"form-group"}>
                            <label>Create a Password <ErrorElement message={errors.password} /></label>
                            <input onFocus={clearErrors} ref={passwordField1} id="password" type="password" />
                        </div>
                        
                        <label>Re-Enter Your Password  <ErrorElement message={errors.password2} /></label>
                        <input onFocus={clearErrors} ref={passwordField2} id="password2" type="password" />

                        <div className="buttons">
                            <ErrorElement message={errors.main} />
                            <FormButton onClick={updatePasswordAsync}>Set Password</FormButton>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
