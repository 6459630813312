import React from 'react';

const Dark = (props) => {
    
    return (<></>);
};

Dark.isReact = (component) => {
    if (!component) return false;
    return (typeof component.ref !== 'undefined' && typeof component.props === 'object');
};

Dark.suppressEvent = (e) => {
    if (typeof e?.stopPropagation === "function")
        e.stopPropagation();
    
    if (typeof e?.preventDefault === "function")
        e.preventDefault();
};

export default Dark;
