class SearchResultModel { 
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || "";
        this.entityId = json.entity_id || "";
        this.entityType = json.entity_type || "";
        this.entityTypeName = json.entity_type_name || "";

        this.title = json.title || "";
        this.description = json.description || "";
        this.path = json.path || "";
        this.imageUrl = json.image_url || "";
        this.preview = json.preview || "";
        this.score = json.score || 0;

        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        
        let results = [];
        
        jsonArray.map((json) => {
            results.push(new SearchResultModel(json));
        });
        
        return results;
    }
}

export default SearchResultModel;
