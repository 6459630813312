import UserModel from "../../people/models/UserModel";
import CompanyApiModel from "../../companies/models/CompanyApiModel";

class ApiAuthModel {
    static googleOAuthKey = "18";
    static shortcutKey = "14";
    static trelloKey = "16";
    static plaidKey = "22";
    static instagramOAuthKey = "24";
    
    static entityType = 16;

    static oauthTypeKeys = [ApiAuthModel.googleOAuthKey, ApiAuthModel.plaidKey, ApiAuthModel.instagramOAuthKey];
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.encType = json.enc_type;
        this.apiType = json.api_type;
        this.apiTypeName = json.api_type_name;
        this.name = json.name;
        this.userId = json.user_id;
        this.container = json.container;
        this.expires = json.expires;
        this.apiKey = json.api_key;
        this.companyId = json.company_id;
        this.entityType = json.entity_type;
        this.entityTypeName = json.entity_type_name;
        
        this.accountName = json.account_name;
        this.clientId = json.client_id;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ApiAuthModel(json));
    }
    
    static createKey(entityType, apiType, userId, companyId) {
        if (entityType === 4 || entityType?.entityType === 4 || entityType?.entity_type === 4)
            userId = null;
        
        if (typeof entityType === "number") {
            //return entityType + "-" + (userId || "") + "-" + (apiType || "") + "-" +  (companyId || "");
            userId = userId || "";
            apiType = apiType || "";
            companyId = companyId || "";
        } else if (entityType instanceof ApiAuthModel) {
            //return entityType.entityType + "-" + (entityType.userId || (userId || "")) + "-" + (entityType.apiType || (apiType || "")) + "-" + (entityType.companyId || (companyId || ""));
            userId = userId || (entityType.userId || "");
            apiType = apiType || (entityType.apiType || "");
            companyId = companyId || (entityType.companyId || "");
            entityType = entityType.entityType;
        } else if (entityType instanceof CompanyApiModel) {
            //return entityType.entityType + "-" + (userId || "") + "-" + (entityType.apiType || (apiType || "")) + "-" + (entityType.companyId || (companyId || ""));
            userId = userId || (entityType.userId || "");
            apiType = apiType || (entityType.apiType || "");
            companyId = companyId || (entityType.companyId || "");
            entityType = entityType.entityType;
        } else if (typeof entityType === "object") {
            userId = (userId || entityType.userId) || (entityType.user_id || "");
            apiType = (apiType || entityType.apiType) || (entityType.api_type || "");
            companyId = (companyId || entityType.companyId) || (entityType.company_id || "");
            entityType = entityType.entityType || entityType.entity_type;
        }

        return entityType + "-" + (userId || "") + "-" + (apiType || "") + "-" +  (companyId || "");
        
        //return (typeof entityType).toString() + "-invalid-key";
    };

    // static createKey(apiAuthId, entityType) {
    //     if (!apiAuthId) return null;
    //     return (apiAuthId || "") + "-" + (entityType || 0).toString();
    // };

    static toMap(models, removeUserId = false) { 
        let map = {};
        
        models.forEach(model => {
            const key = ApiAuthModel.createKey(model.entityType, model.apiType, removeUserId === true ? null : model.userId, model.companyId);
            //console.warn("Key: " + key);
            map[key] = model;
        });
        return map;
    }
}

export default ApiAuthModel;
