import React, {useState, useEffect} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import HeaderButton from "../../components/common/ui/HeaderButton";
import CompanyService from "../../components/companies/services/CompanyService";
import CompanyUserSubMenu from "../../components/companies/ui/CompanyUserSubMenu";
import BackButton from "../../components/common/ui/BackButton";
import UserMasterPage from "../../components/people/ui/UserMasterPage";
import UserModel from "../../components/people/models/UserModel";
import CompanyMasterPage from "../../components/companies/ui/CompanyMasterPage";
import NavigationHistoryService from "../../components/activity/services/NavigationHistoryService";
import ActivityTable from "../../components/activity/ui/ActivityTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp} from "@fortawesome/free-solid-svg-icons";

const UserDetailsScreen = () => {
    let _;
    const { userId, companyId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId || ""] || null);
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[user?.companyId || ""] || null);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    
    const getUserAsync = async () => {
        return await UserService.instance.getUserAsync(userId).then((user) => {
            onUser(user);
            return user;
        });
    };
    
    const saveNav = () => {
        if (!user) return;
        
        const title = !!company ? (company.name + ": " + user.fullName) : user.fullName
        NavigationHistoryService.instance.addDelayedItem(title, 4500);
    };
    
    const onUser = (user) => {
        if (!!user) {
            if (!!companyId && !!company) saveNav();
            else if (!companyId) saveNav();
            
            setUser(user);

            if (user.companyId !== companyId && !userCompany) {
                console.log("Getting User's Company: " + user.companyId);
                _ = CompanyService.instance.getCompanyAsync(user.companyId).then((c) => {
                    if (!!c) setUserCompany(c);
                });
            }
        }
    };
    
    const onCompany = (company) => {
        if (!!user && !!company) saveNav();
    };

    useEffect(() => {
        if (companyId) _ = getUserAsync();
    }, []);
    
    if (!!redirectUrl && redirectUrl.length > 0) return (<Navigate to={redirectUrl} />);
    
    let masterSelection = "users";
    let subSubMenu = (<></>);
    let backButton = null;

    let subtitle = (<>{backButton}
        { user?.fullName }
        <span className={"subtitle"}>Profile</span>
        <HeaderButton onClick={getUserAsync.bind(this, true)}>Refresh</HeaderButton></>);
    
    const errorElement = !!errorMessage ? (<div className={"error-box bottom"}>{errorMessage}</div>) : null;
    
    const defaultView = (<div className="view-items">
        <div>
            <strong>Responded to a Contact Request</strong> on <strong className="date">May 5 at 4:46am</strong>
            <p>
                Contact request came in on May 4, 11:38pm. Lag time is 5 hours and 8 minutes. <Link to={"/jobs/1234"}>View Thread</Link>
            </p>
        </div>
        <div>
            <strong>Google Calendar Integrated</strong> on <strong className="date">April 24 at 2:16pm</strong>
            <p>Account name /j********@p******.io has been authenticated via Google Console API</p>
        </div>
        <div>
            <strong>Instagram Account Integrated</strong> on <strong className="date">April 24 at 2:12pm</strong>
            <p>Account name /jadecharles has been authenticated via Meta API</p>
        </div>
        <div>
            <strong>Updated Profile Photo</strong> on <strong className="date">April 11 at 3:43pm</strong>
        </div>
        <div>
            <strong>Posted a Job</strong> on <strong className="date">April 3 at 2:05pm</strong>
            <p>
                Job Description: Technical Writer, with a pay of $25/hr. <Link to={"/jobs/1234"}>View Job</Link>

            </p>
        </div>
    </div>);

    if (companyId) {
        subSubMenu = (<CompanyUserSubMenu company={company} user={user} selection={"details"} />);
        backButton = (<BackButton to={"/companies/" + companyId + "/people"} />);
        
        const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
        const companyElement = (isDifferentCompany) ?
            (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
            null;

        subtitle = (<>{backButton}
            { user?.fullName }
            <span className={"subtitle"}>Profile</span></>);

        const onCompany = (c) => {
            setCompany(c);
        };
        
        return (
            <CompanyMasterPage selection={"people"} title={subtitle} onCompany={onCompany} subMenu={subSubMenu} emptyCompanyId={user?.companyId}>
                <p>
                    User profile for { user?.fullName }. Put some information about this user here...
                    {companyElement}
                </p>
                
                <ActivityTable emptyElement={defaultView} entityType={UserModel.entityType} entityId={userId} />
                
                
                { errorElement }
            </CompanyMasterPage>
        );
    }
    
    return (
        <UserMasterPage user={user} onUser={onUser} title={"Personnel"} onCompany={onCompany} subMenu={subSubMenu} subTitle={subtitle} backButton={backButton} masterSelection={masterSelection}
            entityId={userId} entityType={UserModel.entityType}>
            <p>
                User profile for { user?.fullName }. Put some information about this user here.
            </p>

            <ActivityTable emptyElement={defaultView} entityType={UserModel.entityType} entityId={userId} />

        </UserMasterPage>
    );
    
};

export default UserDetailsScreen;