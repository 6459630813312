import React, {useEffect, useRef, useState} from 'react';

const InvoicePaymentForm = (props) => {
    let _;
    const componentId = useState("invoice-payment-form-" + (Math.random() * 9999999999).toString(16).toLowerCase())[0];
    const {options, invoice, controller, onChange} = props;
    const [viewState, setViewState] = useState({id: 0, data: {}, trigger: null, items: null, componentId: componentId});
    
    const amountRef = useRef();
    const paymentTypeRef = useRef();
    const transactionRef = useRef();
    const messageRef = useRef();
    const orderIdentifierRef = useRef();
    
    const createJson = () => { 
        return {
            total: parseFloat(amountRef.current?.value || "0.0") || 0.0,
            payment_method: parseInt(paymentTypeRef.current?.value || "0") || 2,
            transaction_id: transactionRef.current?.value || null,
            message: messageRef.current?.value || null,
            order_identifier: orderIdentifierRef.current?.value || null,
        };
    };
    
    const onFormChange = (e, fieldId) => { 
        if (typeof onChange === "function") {
            const json = createJson();
            onChange(json);
        }
    };
    
    const setControllers = () => {
        if (typeof controller?.setCallback === "function") {
            controller.setCallback("setData", (key, data) => {
                if (key === InvoicePaymentForm.dataId) {
                    setViewState({...viewState, data: data});
                    return true;
                }
                return false;
            });
        }
    };
    
    useEffect(() => {
        //
    }, []);

    return (<div className={"invoice-payment-form"}>
        <div className={"form"}>
            <div className={"form-group"}>
                <label htmlFor={"payment_total"}>Amount:</label>
                <span className={"currency"}>
                    <input className={"payment-amount"} placeholder={"$"} ref={amountRef} type={"number"} name={"payment_total"} id={"payment_total"} defaultValue={invoice?.total || null} onChange={(e) => onFormChange(e, "payment_total")}/>
                    <span className={"symbol"}>$</span>
                </span>
            </div>

            <div className={"form-group double"}>
                <div>
                    <label htmlFor={"payment_type"}>Payment Method:</label>
                    <select ref={paymentTypeRef} name={"payment_method"} id={"payment_method"} onChange={(e) => onFormChange(e, "payment_method")}>
                        <option value={2}>ACH Transaction</option>
                        <option value={3}>Cash</option>
                    </select>
                </div>
                <div>
                    <label htmlFor={"payment_type"}>TransactionId:</label>
                    <input ref={transactionRef} type={"text"} name={"transaction_id"} id={"transaction_id"} defaultValue={""} onChange={(e) => onFormChange(e, "transaction_id")}/>
                </div>
            </div>

            <div className={"form-group"}>
                <label htmlFor={""}>Order Identifier (Optional):</label>
                <input ref={orderIdentifierRef} type={"text"} name={"order_identifier"} id={"order_identifier"} defaultValue={invoice?.orderIdentifier || ""} onChange={(e) => onFormChange(e, "order_identifier")}/>
            </div>

            <div className={"form-group"}>
                <label htmlFor={"message"}>Message or Note:</label>
                <input ref={messageRef} type={"text"} name={"message"} id={"message"} defaultValue={""} onChange={(e) => onFormChange(e, "message")}/>
            </div>
            
        </div>
    </div>);
};

InvoicePaymentForm.dataId = "invoice-payment-form";
InvoicePaymentForm.defaultOptions = () => {
    return {
        title: "",
        onClick: (e, options) => true,
        onComplete: (e, options) => true,
        getData: (e, options) => undefined,
        setData: (key, data) => false,
    };
};

export default InvoicePaymentForm;

