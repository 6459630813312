import React, {useEffect, useRef, useState} from 'react';
import FormButton from "../FormButton";
import Dark from "../../../Dark";
import "./DateRangeSelector.css";

const DateRangeSelector = (props) => {
    const { id, className, startDate, endDate, value, onChange, onClick, range, options, controller, buttonLabel } = props;

    const startDateRef = useRef();
    const endDateRef = useRef();
    
    const [dateState, setDateState] = useState({ startDate: startDate, endDate: endDate, id: 0, text: null });
    
    const onDateChange = async (e, id) => { 
        Dark.suppressEvent(e);

        if (typeof id === "string" && typeof controller?.setDates === "function") {
            const tz = (new Date()).getTimezoneOffset();
            
            let sd = startDateRef.current?.value || null;
            let ed = endDateRef.current?.value || null;

            if (!(sd instanceof Date)) { 
                sd = new Date(sd);
                
                if (isNaN(sd)) sd = null;
                else sd = sd.addMinutes(tz);
            }

            if (!(ed instanceof Date)) {
                ed = new Date(ed);
                
                if (isNaN(ed)) ed = null;
                else ed = ed.addMinutes(tz);
            }
            
            controller.setDates(sd, ed);
        }
        
        if (typeof onChange !== "function") return false;

        const rsp = onChange(e, id, e?.target?.value);
        const result = (typeof rsp?.then === "function") ? await rsp : rsp;
        
        return (result !== false);
    };
    
    const onDateClick = async (e) => {
        Dark.suppressEvent(e);
        if (typeof onClick !== "function") return false;

        const values = {
            startDate: startDateRef.current?.value,
            endDate: endDateRef.current?.value,
        }
        
        const rsp = onClick(e, values);
        const result = (typeof rsp?.then === "function") ? await rsp : rsp;
        
        return (result !== false);
    };
    
    const parseDate = (d) => {
        if (!d) return null;
        if (d instanceof Date) return d;
        
        if (typeof d !== "string") {
            const dv = Date.parse(d);
            if (isNaN(dv)) return null;
            return new Date(dv);
        }
        
        return null;
    };
    
    const getDateFromRange = () => {
        if (typeof range !== "string") return null;
        
        let now = new Date();
        let d = now.getDate();
        let m = now.getMonth();
        let y = now.getFullYear();

        const today = new Date(y, m, d);
        const dayOfWeek = today.getDay() || 7;
        
        const dr = {
            startDate: today,
            endDate: today.addDays(1),
            d: d,
            m: m,
            y: y,
        };
        
        switch (range) { 
            case "yesterday":
                dr.startDate = today.addDays(-1);
                break;
            case "this-week":
                dr.startDate = today.addDays(-dayOfWeek);
                break;
            case "last-week":
                dr.startDate = today.addDays(-(7 + dayOfWeek));
                break;
            case "this-month":
                dr.startDate = new Date(y, m, 1);
                break;
            case "last-month":
                dr.startDate = new Date(y, m, 1).addMonths(-1);
                break;
            case "this-year":
                dr.startDate = new Date(y, 0, 1);
                break;
            case "last-year":
                dr.startDate = new Date(y, 0, 1).addYears(-1);
                break;
            default:
                // today
                break;
        }
        
        return dr;
    };

    const setControllers = () => {
        if (typeof controller !== "object" || !controller)
            return false;
        
        if (typeof controller?.setDates === "function") { 
            const d = getDateFromRange();
            controller.setDates(d.startDate, d.endDate);
        }
        
        return true;
    };
    
    useEffect(() => {
        setControllers();
    }, []);
    
    const dateRange = getDateFromRange();
    const sd = parseDate(startDate) || dateRange.startDate;
    const ed = parseDate(endDate) || dateRange.endDate;
    
    return (<div className={"date-range-selector"}>
        <div className={"date-range-inputs"}>
            <span><input
                ref={startDateRef}
                type={"date"}
                id={"start-date"}
                defaultValue={sd.toFormDate()}
                onChange={(e) => onDateChange(e, "start-date")}
            /></span>

            <span><input
                ref={endDateRef}
                type={"date"}
                id={"end-date"}
                defaultValue={ed.toFormDate()}
                onChange={(e) => onDateChange(e, "end-date")}
            /></span>

            <span><FormButton onClick={onDateClick}>{buttonLabel || options?.buttonLabel || "Refresh" }</FormButton></span>
        </div>
        
        
    </div>);
};

export default DateRangeSelector;
