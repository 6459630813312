/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react';
import {Navigate, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import InvoiceItemForm from "../../../components/billing/ui/InvoiceItemForm";
import InvoiceModel from "../../../components/billing/models/InvoiceModel";
import NumberDisplay from "../../../components/common/ui/NumberDisplay";
import FormButton from "../../../components/common/ui/FormButton";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import InvoiceService from "../../../components/billing/services/InvoiceService";
import InvoicePreview from "../../../components/billing/ui/InvoiceSendPreview";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";

const InvoiceEditScreen = (props) => {
    let { companyId } = useParams();
    let [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    let [invoice, setInvoice] = useState(new InvoiceModel());
    let [redirectUrl, setRedirectUrl] = useState("");

    const [invoiceState, setInvoiceState] = useState({ viaPhone: false, viaEmail: true, id: 0 });

    const invoiceDate = useRef();
    const dueAmountRef = useRef();
    const dueDate = useRef();
    const invoiceDescription = useRef();
    const invoiceNumber = useRef();
    let dialogController = new DialogBoxController("Invoice Preview", (<>Are you sure you want to preview this invoice?</>));
    
    const createFormData = (invoiceItems = []) => {
        const json = {
            description: invoiceDescription.current?.value || "",
            invoice_date: invoiceDate.current?.value || "",
            invoice_number: invoiceNumber.current?.value || "",
            due_date: dueDate.current?.value || null,
            due_amount: parseFloat(dueAmountRef.current?.value || "0") || 0.0,
            items: invoiceItems,
        };

        if (!json.invoice_date) json.invoice_date = null;
        if (!json.due_date) json.due_date = null;
        if (!Array.isArray(json.items)) json.items = [];
        
        return json;
    };
    
    let formData = createFormData();
    
    const onRecipientChange = (sender, checkboxId) => {
        console.log("onCheck:");
        
        const newState = {...invoiceState};
        
        newState.viaPhone = (sender.viaPhone === true);
        newState.viaEmail = (sender.viaEmail === true);
        
        console.log(JSON.stringify(newState, null, 4));
        
        setInvoiceState(newState);
    };
    
    const onItemAdded = (item) => {
        let total = invoice.addItem(item);
        renderUpdate();
    };
    
    const onCompany = (c) => {
        setCompany(c);
    };
    
    const onInvoiceDetailsChange = (fieldId, e) => {
        formData[fieldId] = e.target.value;
        console.log("Change [" + fieldId + "] to: " + e.target.value);
    };
    
    const onSubmitInvoice = (e) => {
        formData.invoice_date = invoiceDate.current?.value || "";
        
        if (invoice.total === 0) return;
        
        dialogController.open();
    };
    
    const renderUpdate = () => { 
        const newState = { ...invoiceState, id: new Date().getTime() };
        setInvoiceState(newState);
    };

    /**
     * Remove item
     * @param index {int}
     * @param e {event}
     */
    const removeLineItem = (index, e) => {
        console.log('Press (' + invoice.total + '): ' + (typeof invoice).toString());

        if (invoice.removeItemAtIndex(index)) {
            if (invoice.items.length === 0) {
                console.log('Removed OK and is empty.');
                document.getElementById('invoice-item-form-item-name')?.focus();
            }

            renderUpdate();
        }
    };
    
    const setToday = (e) => {
        invoiceDate.current.value = new Date().toFormDate();
        formData["invoice_date"] = e.target.value;
    };

    const setOneMonth = (e) => {
        dueDate.current.value = new Date().addMonths(1).toFormDate();
        formData["due_day"] = e.target.value;
    };

    const saveInvoiceAsync = async (send, e) => {
        const data = createFormData(invoice.items);
        
        const em = invoiceState.viaEmail;
        const ph = invoiceState.viaPhone;
        
        const inv = await InvoiceService.instance.createInvoiceAsync(data, companyId, em, ph).catch((ex) => { 
            console.error(ex);
        });

        await InvoiceService.instance.getInvoicesByCompanyAsync(companyId, true);
        
        const red = '/companies/' + companyId + '/invoices/' + inv.id;
        
        setRedirectUrl(red);
        dialogController.close();
    };
    
    useEffect(() => {
        //
    }, []);

    if (!!redirectUrl) { 
        return (<Navigate to={redirectUrl} />);
    }
    
    const subtitle = "Create Invoice";
    let itemsTable = (<></>);

    if (invoice.items.length > 0) {
        let invoiceTotal = 0;
        
        let itemElements = invoice.items.map((item, index) => {
            const amount = item.qty * item.price;
            let tax = item.tax;
            
            if (item.is_tax_percent === true) {
                if (tax >= 1.0) tax = tax / 100;
                tax = amount * tax;
            }
            
            invoiceTotal += amount + (tax || 0);
            
            return (<tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.price.formatCurrency(2)}</td>
                    <td>{item.qty.formatNumber(0)}</td>
                    <td>{tax?.formatCurrency(2) || "Exempt"}</td>
                    
                    <td className={"remove-line-item"}>
                        <span>
                            <a onClick={removeLineItem.bind(this, index)}>-</a>
                            {(amount + tax).formatCurrency(2)}
                        </span>
                    </td>
                </tr>);
        });
        
        invoice.total = invoiceTotal;
        
        itemsTable = (<><h3 className={"total"}>
            Invoice Line Items
            <span className={"total"}>
                <label>Invoice Total:</label>
                <NumberDisplay value={invoice.total} type={"currency"} />
            </span>
        </h3>

        <div className={"padding-bottom-32"}>
            <table width={"100%"}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Tax</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {itemElements}
                </tbody>
            </table>
        </div></>);
    }
    
    let invoiceConfirmPanel = (
        <span>
            <a onClick={saveInvoiceAsync.bind(this, false)} className={"action-button"}>Save Draft</a>
        </span>
    );
    
    return (
        <BillingMasterPage onCompany={onCompany} selection={"invoices"} title={subtitle}>
            <p>
                You are invoicing <strong>{company?.name}</strong>.<br/>
                It will be sent to <a href={"mailto:" + company?.email}>{company?.email}</a>, if you choose to send it
            </p>

            <div className={"form large"}>
                <div className={"invoice-items-edit dark-section"}>
                    {itemsTable}

                    <InvoiceItemForm onItemAdded={onItemAdded} />
                </div>

                <div id={"invoice-details"}>
                    <h3>Additional Invoice Information</h3>
                    <label>Amount Due Now:</label>
                    <span>
                        <input type="number" ref={dueAmountRef} id={"due_amount"} onChange={onInvoiceDetailsChange.bind(this, 'due_amount')} />
                    </span>


                    <label>Invoice Reference Date</label>
                    <span>
                        <input type="date" ref={invoiceDate} onChange={onInvoiceDetailsChange.bind(this, 'invoice_date')} />
                        <a className={"button-link dark-link"} onClick={setToday}>Today</a>
                    </span>

                    <label>Optional Due Date</label>
                    <span>
                        <input type="date" ref={dueDate} onChange={onInvoiceDetailsChange.bind(this, 'due_date')} />
                        <a className={"button-link dark-link"} onClick={setOneMonth}>In a Month</a>
                    </span>

                    <label>
                        Optional Invoice Number
                        <label>For reference and/or accounting purposes</label>
                    </label>
                    <input className={""} type="text" ref={invoiceNumber} onChange={onInvoiceDetailsChange.bind(this, 'invoice_number')} />

                    <label>
                        Optional Description or Message
                        <label>This will be displayed on the invoice for the recipient to see, and may or may not be legally binding</label>
                    </label>
                    <input className={"description"} type="text" ref={invoiceDescription} onChange={onInvoiceDetailsChange.bind(this, 'description')} />
                </div>
            </div>

            <DialogBox controller={dialogController} onOkay={saveInvoiceAsync.bind(this, true)} okCaption={(<><FontAwesomeIcon icon={faPaperPlane} /> Save and Send</>)} actionPanel={invoiceConfirmPanel}>
                <InvoicePreview company={company} invoice={invoice} onChange={onRecipientChange} />
            </DialogBox>

            <div className={"buttons padded"}>
                <FormButton onClick={onSubmitInvoice}>Continue to Preview</FormButton>
            </div>
        </BillingMasterPage>            
    );
    
};


export default InvoiceEditScreen;
