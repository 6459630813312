/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {useParams} from "react-router-dom";
import BillingMasterPage from "../../../components/billing/ui/BillingMasterPage";
import AddNewHeaderButton from "../../../components/common/ui/AddNewHeaderButton";
import {faFileInvoiceDollar} from "@fortawesome/free-solid-svg-icons";
import PaymentService from "../../../components/finance/services/PaymentService";
import DateTime from "../../../components/common/ui/DateTime";
import NumberDisplay from "../../../components/common/ui/NumberDisplay";
import PagerController from "../../../components/common/pager/PagerController";
import Pager from "../../../components/common/pager/Pager";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import EmptyBox from "../../../components/common/ui/EmptyBox";

const TransactionsScreen = (props) => {
    const {} = props;
    const { companyId } = useParams();

    const [company, setCompany] = useState(null);
    const [filterState, setFilterState] = useState({ selectedId: 2 });
    const [transactions, setTransactions] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;
    
    const getPaymentsAsync = async (force = false) => {
        if (!force && transactions?.length >= 0) return transactions;
        
        await PaymentService.instance.getPaymentsByCompanyAsync(companyId).then((payments) => {
            if (Array.isArray(payments)) setTransactions(payments);
        });
    };
    
    const refreshAsync = async () => {
        setTransactions(null);
    };
    
    const selectPaymentStatus = (status) => { 
        const newState = {...filterState, selectedId: status };
        setFilterState(newState);
    }
    
    const onCompany = (c) => {
        if (!!c) setCompany(c);
    }

    useEffect(() => { 
        getPaymentsAsync();
    }, [transactions])

    const stati = [
        { id: 0, name: "All" },
        { id: 2, name: "Success" },
        { id: -2, name: "Failed" },
    ];

    const filterPanel = stati.map((st, index) => {
        const sn = st.id === filterState.selectedId ? "selected" : null;
        return (<span className={sn} key={"key-" + index}><a onClick={() => selectPaymentStatus(st.id)}>{st.name}</a></span>);
    });

    const subtitle = (<>Transactions <AddNewHeaderButton to={"/companies/" + companyId + "/billing/payments/000-000/edit"} icon={faFileInvoiceDollar}>Create Transaction</AddNewHeaderButton></>);

    const filteredTransactions = filterState.selectedId > 0 ?
        transactions?.filter((tr, i) => tr.paymentStatus === filterState.selectedId) :
        transactions;
    
    const elements = transactions === null ? 
        (<LoadingBox>Getting Transactions...</LoadingBox>) :
        pagerController.mapLineItems(filteredTransactions, (transaction, index) => {
            const accountElement = transaction.cardType > 0 || !!transaction.accountMask ? 
                transaction.cardType + " " + transaction.accountMask :
                (transaction.accountMask || transaction.paymentTypeName || "Nope");
            
            return (<tr key={"payment-" + index}>
                <td><NumberDisplay type={"currency"} value={transaction.total} /></td>
                <td>{accountElement}</td>
                <td><DateTime value={transaction.created} time={true} /></td>
            </tr>);
    });
    
    const table = !!transactions && transactions?.length > 0 ? (<table width={"100%"} className={"table table-x-large"}>
        <thead>
        <tr>
            <th>Amount</th>
            <th>Account</th>
            <th>Payment Date</th>
        </tr>
        </thead>
        <tbody>{ elements }</tbody>
    </table>) : (transactions === null ? <LoadingBox /> : <EmptyBox>No Payments Made</EmptyBox> );

    return (<BillingMasterPage onCompany={onCompany} selection={"transactions"} title={subtitle} onRefresh={(e) => refreshAsync(true)}>
            <div className={"filter-panel"}>
                {filterPanel}
            </div>
            <div>
                { table }
                <Pager controller={pagerController} items={transactions} />
            </div>
        </BillingMasterPage>
    );
};

export default TransactionsScreen;
