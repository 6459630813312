import HttpService from "../../common/services/HttpService";
import ShortUrlModel from "../models/ShortUrlModel";
import ShortUrlClickModel from "../models/ShortUrlClickModel";

class ShortUrlService { 
    static instance = new ShortUrlService();

    constructor() {
        this.shortUrlsMap = {};
        this.shortUrls = [];
        this.shortUrlMap = {};
    }

    async getShortUrlAsync(shortUrlId) {
        const path = '/api/short-url/' + shortUrlId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.shortUrlMap[shortUrlId] = new ShortUrlModel(response.data);
            return me.shortUrlMap[shortUrlId];
        });
    }

    async getShortUrlsAsync(domainId) {
        const path = "/api/company-domain/" + domainId + "/short-url";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.shortUrls = response.data.map((shortUrl) => {
                return new ShortUrlModel(shortUrl);
            });

            return me.shortUrls;
        });
    }

    async getShortUrlClicksAsync(shortUrlId) {
        const path = "/api/short-url/" + shortUrlId + "/click";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return ShortUrlClickModel.fromJsonArray(response.data);
        });
    }
    
    async createShortUrlAsync(companyId, domainId, shortUrlPath, redirectUrl) {
        const path = "/api/company/" + companyId + "/short-url";
        const data = {
            path: shortUrlPath,
            company_domain_id: domainId,
            redirect_url: redirectUrl
        };
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            if (!response?.data) {
                console.error(JSON.stringify(response));
                throw new Error(response);
            }
            return new ShortUrlModel(response.data);
        });
    }
    
    async deleteShortUrlAsync(id) { 
        const path = "/api/short-url/" + id;
        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response.data;
        });
    }
}

export default ShortUrlService;
