import SubscriptionModel from "./SubscriptionModel";
import UserModel from "../../people/models/UserModel";
import PaymentModel from "./PaymentModel";

class UserSubscriptionModel {
    static entityType = 34;
    static create = (json) => new UserSubscriptionModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.subscriptionId = json.subscription_id;
        this.userId = json.user_id;
        this.mrc = json.mrc;
        this.setupFee = json.setup_fee;
        this.annualDiscount = json.annual_discount;
        this.nextBillDate = !!json.next_bill_date ? new Date(json.next_bill_date) : null;
        this.metaData = json.meta_data || {};
        
        this.lastPaymentDate = !!json.last_payment_date ? new Date(json.last_payment_date) : null;
        this.lastPaymentId = json.last_payment_id || null;
        this.lastPayment = PaymentModel.create(json.last_payment);
        this.lastInvoiceDate = !!json.last_invoice_date ? new Date(json.last_invoice_date) : null;
        this.lastInvoiceId = json.last_invoice_id || null;
        
        this.user = UserModel.create(json.user);
        this.subscription = SubscriptionModel.create(json.subscription);
        this.name = this.subscription?.name || "Subscription";

        this.startDate = !!json.start_date ? new Date(json.start_date) : null;
        this.endDate = !!json.end_date ? new Date(json.end_date) : null;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            subscription_id: this.subscriptionId,
        user_id: this.userId,
        mrc: this.mrc,
        setup_fee: this.setupFee,
        annual_discount: this.annualDiscount,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => UserSubscriptionModel.create(json));
    }
}

export default UserSubscriptionModel;
