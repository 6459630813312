import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import ProjectService from "../../../components/projects/services/ProjectService";
import CompanyService from "../../../components/companies/services/CompanyService";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import CompanySubMenu from "../../../components/companies/ui/CompanySubMenu";
import ProjectSubMenu from "../../../components/projects/ui/ProjectSubMenu";
import CompanyProjectsSubMenu from "../../../components/companies/ui/CompanyProjectsSubMenu";
import ProjectItemSubMenu from "../../../components/projects/ui/ProjectItemSubMenu";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import ContentDropper from "../../../components/content/ui/ContentDropper";
import BackButton from "../../../components/common/ui/BackButton";
import BulletArrow from "../../../components/common/ui/BulletArrow";

const ProjectItemContentScreen = (props) => {
    let _;
    const { projectId, companyId, projectItemId } = useParams();

    const initialContent = {
        files: [],
        documents: ProjectService.instance.contentMap[projectId] || null,
        projectItem: ProjectService.instance.projectMap[projectItemId || "x"] || null,
        project: ProjectService.instance.projectMap[projectId] || null,
        textItems: []
    };

    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId]);
    const [projectData, setProjectData] = useState(initialContent);
    const [contentViewerDialogController, setContentViewerDialogController] = useState(new DialogBoxController("View Content"));

    
    const setProjectItem = (item) => { 
        const d = {...projectData};
        d.projectItem = item;
        setProjectData(d);
    };
    
    const setProject = (p) => {
        const d = {...projectData};
        d.project = p;
        setProjectData(d);
    };
    
    const setDocuments = (docs) => {
        const d = {...projectData};
        d.documents = docs;
        setProjectData(d);
    };
    
    const getProjectItemAsync = async (force = false, rerender = false) => {
        if (!projectItemId) return;
        if (!!projectData.projectItem && !force) 
            return projectData.projectItem;
        
        return await ProjectService.instance.getProjectItemAsync(projectItemId).then((pi) => {
            if (rerender === true) setProjectItem(pi);
            return pi;
        });
    };

    const getProjectAsync = async (force = false, rerender = false) => {
        if (!projectId) return;
        if (!!projectData.project?.id && !force) return projectData.project;
        
        return await ProjectService.instance.getProjectAsync(projectId).then((p) => {
            if (rerender === true) setProject(p);
            return p;
        });
    };

    const getProjectItemContentAsync = async (rerender = false) => {
        if (!projectItemId) {
            console.error("No ProjectItemId when getting Content");
            return [];
        }
        
        return await ProjectService.instance.getProjectItemContentAsync(projectItemId, companyId).then((docs) => {
            console.warn("Got Project Content Items: " + docs?.length);
            if (docs === null) return projectData.documents;
            
            if (rerender === true) setDocuments(docs);
            
            return docs;
        });
    };
    
    const getAsync = async (e, force = false) => { 
        await Promise.all([
            getProjectAsync(force),
            getProjectItemAsync(force),
            getProjectItemContentAsync()
        ]).then((responses) => { 
            const d = {...projectData};
            d.project = responses[0];
            d.projectItem = responses[1];
            d.documents = responses[2];
            
            setProjectData(d);
        });
    };

    const onRefreshAsync = async (e) => {
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        return await getAsync();
    };

    const onCompany = (c) => {
        setCompany(c);
    };

    const onFilesDrop = (files, items) => {
        // console.log('Files: ' + JSON.stringify(files));
        // console.log("Items: " + JSON.stringify(items));
    };

    const uploadAsync = async (files, textItems) => {
        console.log("Uploading ...");

        const formFiles = files.map((f) => f.file);
        
        await Promise.all([
            ProjectService.instance.uploadProjectItemContentAsync(projectItemId, formFiles),
            ProjectService.instance.createProjectItemContentAsync(projectItemId, textItems),
        ]).then((results) => {
            results = results.flat();
            console.log("Upload Results: " + JSON.stringify(results));
            setDocuments(results);
        });
    };

    const onContentUpdated = async (data, action) => { 
        console.log("onContentUpdated: " + action);
        if (action === "document") await getProjectItemContentAsync();
    };
    
    const onPasteImage = (files) => {
        console.log("Paste Image: ");
        console.log(files);
    }

    const onPasteText = (text) => {
        console.log("onPasteText:");
        console.log(text);
    }

    useEffect(() => {
        _ = getAsync();
    }, []);

    let backUrl = "/companies/" + companyId + "/projects/" + projectId;

    const companySubMenu = (<CompanySubMenu company={company} selection={"projects"} />);

    const project = projectData.project;
    const projectItem = projectData.projectItem;
    const documents = projectData.documents;
    
    let submenu = (<ProjectSubMenu project={project} projectId={projectId} company={company} companyId={companyId} selection={"content"} />);

    if (projectItemId) submenu = (<ProjectItemSubMenu project={project} projectId={projectId} projectItem={projectItem} company={company} companyId={companyId} selection={"content"} />);

    const subtitle = (<><BackButton to={backUrl} />
        <span className={"pre-subtitle"}>
            <Link to={"/companies/" + companyId + "/projects"}>Project:</Link>
        </span>
        <Link to={"/companies/" + companyId + "/projects/" + projectId}>{project?.name}</Link>
        <BulletArrow />
        <span className={"subtitle"}>{projectItem?.name}</span></>);

    const name = projectItem?.name;
    const pp = "All content for " + name + " ";

    const contentDropper = documents !== null ? 
        (<ContentDropper onChange={onContentUpdated} onDrop={onFilesDrop} items={documents} onUpload={uploadAsync} />) :
        null;

    return (<CompanyMasterPage subTitleClassName={"tree"} menuSelection="projects" onCompany={onCompany} title={subtitle} subMenuSelection={"content"} subMenu={submenu} onRefresh={onRefreshAsync}>
            <p className={"condensed x-large"}>
                {pp}
            </p>

            <div className={"x-large"}>
                { contentDropper }
            </div>

            <DialogBox controller={contentViewerDialogController} fullScreen={true} hasCancelButton={false} onOkay={() => 100}>
                <div className={"view-content"}></div>
            </DialogBox>

        </CompanyMasterPage>);

};

export default ProjectItemContentScreen;