import SearchResultCategoryModel from "../models/SearchResultCategoryModel";
import HttpService from "../../common/services/HttpService";

class SearchService { 
    static instance = new SearchService();
    
    async searchAsync(searchTerm, entityTypes = null, companyId = null) {
        if (!searchTerm || searchTerm.length < 3) return [];
        
        const entities = (!!entityTypes && Array.isArray(entityTypes) && entityTypes.length > 0) ?
            entityTypes.join(",") :
            "";
        
        const companyPath = !!companyId && companyId.length > 30 ? "/company/" + companyId : "";
        const path = "/api" + companyPath + "/search?term=" + encodeURI(searchTerm) + ((entities.length > 0) ? "&entities=" + entities : "");
        const me = this;
        
        console.warn(path);

        console.log(entityTypes);
        console.log(entities);
        
        return await HttpService.instance.getAsync(path).then((rsp) => { 
            return SearchResultCategoryModel.fromJsonArray(rsp.data);
        });
    }
    
}

export default SearchService;
