import React, { useEffect } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faShare } from "@fortawesome/free-solid-svg-icons";
import './HeaderButton.css';

const HeaderButton = (props) => {
    let {onClick, persist, icon, workingIcon, workingClassName} = props;
    
    const wcn = workingClassName || "working";
    const [workingState, setWorkingState] = React.useState(0);

    const onRefreshClick = (e) => {
        setWorkingState(1);
    };
    
    useEffect(() => { 
        if (typeof onClick !== "function") return;
        
        if (workingState === 1) {
            const rsp = onClick();
            
            if (!rsp || typeof rsp.then !== 'function') {
                setWorkingState(0);
                return;
            }

            rsp.then(() => {
                
                setWorkingState(0);
            }).catch((ex) => {
                console.error("Failed Header Button: " + ex);
                setWorkingState(0);
            });
        }
        
    }, [workingState]);
    
    const cn = (persist === true) ? " persist" : "";
    const buttonIcon = workingState === 0 ? (icon || faShare) : (workingIcon || faGear);
    const workingClass = workingState === 1 ? " " + wcn : "";
    
    return (
        <span className={"header-button" + cn + workingClass}>
            <a onClick={onRefreshClick}>
                <span className={"header-button-icon"}><FontAwesomeIcon icon={buttonIcon} /></span> 
                <span className={"header-button-label"}>{props.children || "Press Me"}</span>
            </a>
        </span>        
    );
};

export default HeaderButton;
