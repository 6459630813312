import React, {useEffect, useState} from 'react';
import LandingPageService from "../../landingpages/services/LandingPageService";
import UserModel from "../../people/models/UserModel";
import DateTime from "../../common/ui/DateTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCopy} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import CopyText from "../../common/ui/CopyText";

const LandingPagesTable = (props) => {
    const { companyId } = props;
    const [landingPages, setLandingPages] = useState(LandingPageService.instance.landingPagesMap?.[companyId || "x"] || null);
    
    const getLandingPagesAsync = async (force = false) => {
        if (Array.isArray(landingPages) && !force) return landingPages;
        
        const pages = await LandingPageService.instance.getLandingPagesAsync(companyId).catch((ex) => { 
            alert("Error getting landing pages: " + (ex?.data?.response?.message || ex?.message));
        });
        
        if (Array.isArray(pages)) {
            setLandingPages(pages);
        }
        
        return pages;
    };

    const openWindow = (url) => {
        window.open(url, "_qr_blocks");
    };
    
    useEffect(() => {
        getLandingPagesAsync();
    }, []);
    
    const elements = landingPages === null ? 
        (<div>Loading...</div>) :
        landingPages.map((landingPage, index) => {
            const user = landingPage.user || new UserModel({ first_name: "No", last_name: "Name" });
            const url = "https://" + landingPage.path + ".qrbk.io";
            
            return (<tr key={"item-" + index} title={JSON.stringify(landingPage, null, 4)}>
                <td className={"no-copy-padding copy-line-item"}>
                    <CopyText text={landingPage.id}><FontAwesomeIcon icon={faCopy} /></CopyText>
                    {" "}&nbsp;<Link to={"/companies/" + companyId + "/people/" + landingPage.userId}>{user.fullName || "No Name"}</Link>
                </td>
                <td><FontAwesomeIcon icon={faArrowUpRightFromSquare} /> &nbsp; <a onClick={(e) => openWindow(url)} href={url} target={"_qr_blocks"}>{landingPage.path}.qrbk.io</a></td>
                <td><DateTime useTime={true} value={landingPage.modified || landingPage.created} /></td>
            </tr>)
        });
    
    return (<div>
        <table width={"100%"}>
            <thead>
            <tr>
                <th>User Name</th>
                <th>Path</th>
                <th>Modified</th>
            </tr>
            </thead>
            <tbody>{elements}</tbody>
        </table>
    </div>);
};

export default LandingPagesTable;

