import React, {useEffect, useRef, useState} from 'react';
import ErrorsTable from "../../../components/activity/ui/ErrorsTable";
import {Link, useParams} from "react-router-dom";
import CompanyService from "../../../components/companies/services/CompanyService";
import PagerController from "../../../components/common/pager/PagerController";
import UserService from "../../../components/people/services/UserService";
import CompanyUserSubMenu from "../../../components/companies/ui/CompanyUserSubMenu";
import BackButton from "../../../components/common/ui/BackButton";
import DateTime from "../../../components/common/ui/DateTime";
import CompanyMasterPage from "../../../components/companies/ui/CompanyMasterPage";
import Pager from "../../../components/common/pager/Pager";
import ActivityService from "../../../components/activity/services/ActivityService";
import Controller from "../../../components/common/services/Controller";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp} from "@fortawesome/free-solid-svg-icons";

/**
 * Shows all the errors a user has made/experienced in a particular company context
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyUserErrorsScreen = (props) => {
    const { userId, companyId } = useParams();
    const [company, setCompany] = useState(CompanyService.instance.companyMap[companyId] || null);
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(currentPage, 24, setCurrentPage)).current;
    const refreshController = useRef(new Controller()).current;
    
    const key = (userId + "-" + companyId);
    const [user, setUser] = useState(UserService.instance.userMap[userId || ""]);
    const [userCompany, setUserCompany] = useState(CompanyService.instance.companyMap[user?.companyId || ""] || null);
    
    let _;

    const getUserAsync = async (force, e) => {
        if (!force && !!user?.id) return user;

        const uu = await UserService.instance.getUserAsync(userId).then((u) => {
            if (!!u) setUser(u);
            
            return u;
        });

        if (!uu || uu.companyId === companyId) return;
        
        _ = CompanyService.instance.getCompanyAsync(uu.companyId).then((c) => {
            if (!!c) setUserCompany(c);
        });

        return uu;
    };

    const onCompany = (c) => {
        setCompany(c);
    };
    
    const refreshAsync = async () => {
        return await Promise.all([
            getUserAsync(true),
            refreshController.refreshAsync()
        ]);
    };

    useEffect(() => {
        //
    }, [user]);

    useEffect(() => {
        _ = getUserAsync();
    }, []);

    const userName = !!user ? (user.firstName + " " + user.lastName).trim() : "User";
    const userSubMenu = user ? (<CompanyUserSubMenu user={user} company={company} selection={"errors"} />) : null;

    const subtitle = (<><BackButton to={"/companies/" + companyId + "/people"} />
        <span className={"pre-subtitle"}>Error Log:</span>
        { userName }</>);

    const errorsTable = !!companyId && companyId.length > 0 ? (<ErrorsTable controller={refreshController} userId={userId} company={company} companyId={companyId} />) : null;
    const isDifferentCompany = (user?.companyId && user?.companyId !== companyId);
    const companyElement = (isDifferentCompany) ?
        (<span className={"notice"}><FontAwesomeIcon icon={faHandPointUp} /> Primary Company is <Link to={"/companies/" + userCompany?.id}>{userCompany?.name }</Link></span>) :
        null;

    return (
        <CompanyMasterPage onCompany={onCompany} selection={"people"} title={subtitle} subMenu={userSubMenu} onRefresh={refreshAsync}>
            <p>
                All errors <strong>{user?.fullName}</strong> has seen, experienced, or otherwise caused
                {companyElement}
            </p>

            <div>
                {errorsTable}
            </div>
        </CompanyMasterPage>
    );


};

export default CompanyUserErrorsScreen;
