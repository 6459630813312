import HttpService from "../../common/services/HttpService";
import LandingPageModel from "../models/LandingPageModel";

class LandingPageService { 
    static instance = new LandingPageService();

    constructor() {
        this.landingPagesMap = {};
        this.landingPages = [];
        this.landingPageMap = {};
    }

    async getLandingPageAsync(landingPageId) {
        const path = '/api/landingpage/' + landingPageId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.landingPageMap[landingPageId] = new LandingPageModel(response.data);
            return me.landingPageMap[landingPageId];
        });
    }

    async getLandingPagesAsync(companyId = null) {
        const path = typeof companyId === "string" && companyId.length > 30 ?
            "/api/company/" + companyId + "/landing-page" :
            "/api/landing-page";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.landingPages = response.data.map((landingPage) => {
                return new LandingPageModel(landingPage);
            });

            return me.landingPages;
        });
    }
}

export default LandingPageService;
