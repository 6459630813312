import React, {useEffect, useRef, useState} from 'react';
import FormButton from "../../common/ui/FormButton";
import DocumentService from "../services/DocumentService";

const DocumentForm = (props) => {
    let _;
    const componentId = useState("collection-item-form-" + (Math.random() * 9999999999).toString(16).toLowerCase())[0];
    const {options, id, className, document, onClick, onComplete} = props;
    const [viewState, setViewState] = useState({id: 0, data: {}, trigger: null, items: null, componentId: componentId});
    const nameRef = useRef();
    const descriptionRef = useRef();

    const createJson = () => {
        const json = {
            name: nameRef.current?.value || "",
            description: descriptionRef.current?.value || "",
        };
        
        console.log(JSON.stringify(json, null, 4));
        
        return json;
    };
    
    const saveDocumentAsync = async (e, options) => {
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        const json = createJson();
        const rsp = typeof onClick === "function" ? onClick(e, json) : true;
        const result = (typeof rsp?.then === "function") ? await rsp : rsp;

        if (result === false) return;
        
        const newDocument = await DocumentService.instance.saveDocumentAsync(document?.id, json);
        
        const completeResponse = (!!newDocument?.id && typeof onComplete === "function") ?
            onComplete(newDocument) :
            true;
        
        const cr = (typeof completeResponse?.then === "function") ?
            await completeResponse :
            completeResponse;
        
        if (cr === false) return;
        
        const ns = {...viewState};
        
        ns.id = newDocument?.id || 0;
        setViewState(ns);
        
        return newDocument;
    };

    useEffect(() => {
        console.warn("Entering: " + componentId);
    }, []);

    return (<div className={("document-form form " + (className || "")).trim()} id={id || ""}>
        <div className={"form-header"}>
            <label>Title:</label>
            <input id={"collection-item-title"} defaultValue={document?.name || ""} type={"text"} ref={nameRef} />
        </div>

        <div className={"form-header"}>
            <label>Description:</label>
            <textarea id={"collection-item-description"} defaultValue={document?.description || ""} ref={descriptionRef}></textarea>
        </div>

        <div className={"buttons"}>
            <FormButton onClick={(e, options) => saveDocumentAsync(e, options)}>Save Info</FormButton>
        </div>
    </div>);
};

DocumentForm.defaultOptions = () => {
    return {
        title: "",
        onClick: (e, options) => true,
        onComplete: (e, options) => true,
        getData: (e, options) => undefined,
        setData: (key, data) => false,
    };
};

export default DocumentForm;

