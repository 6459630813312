import CompanyModel from "../../companies/models/CompanyModel";

class PaymentModel { 
    static entityType = 30;
    static create = (json) => new PaymentModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.userId = json.user_id;
        this.customerId = json.customer_id;
        this.total = json.total;
        this.subtotal = json.subtotal;
        this.shipping = json.shipping;
        this.tax = json.tax;

        this.paymentNumber = json.payment_number || 0;
        this.paymentStatus = json.payment_status;
        this.paymentStatusName = json.payment_status_name;
        
        this.paymentMethodType = json.payment_method_type;
        this.paymentMethodTypeName = json.payment_method_type_name || "";
        this.paymentTypeName = json.payment_method_type_name || "";
        this.paymentMethodName = json.payment_method_type_name || "";
        
        this.companyId = json.company_id;
        this.company = CompanyModel.create(json.company);

        this.payerCompanyId = json.payer_company_id || null;
        this.payerCompany = CompanyModel.create(json.payer_company);

        this.gatewayType = json.gateway_type;
        this.gatewayTypeName = json.gateway_type_name;
        
        this.authCode = json.auth_code;
        this.cardType = json.card_type;
        this.transactionId = json.transaction_id;
        this.customerVaultId = json.customer_vault_id;
        this.accountMask = json.account_mask;
        
        this.imageUrl = json.image_url || null;
        this.metaData = json.meta_data || null;
        this.message = json.message;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            company_id: this.companyId,
            customer_id: this.customerId,
            total: this.total,
            subtotal: this.subtotal,
            shipping: this.shipping,
            tax: this.tax,
            payment_status: this.paymentStatus,
            payment_method_type: this.paymentMethodType,
            gateway_type: this.gatewayType,
            transaction_id: this.transactionId,
            auth_code: this.authCode,
            card_type: this.cardType,
            customer_vault_id: this.customerVaultId,
            account_mask: this.accountMask,
            image_url: this.imageUrl,
            meta_data: this.metaData,
            message: this.message,
        };
    }

    /**
     * 
     * @param amount { number }
     * @param creditCard { CreditCardModel }
     * @returns {{credit_card: *, amount}}
     */
    static createPaymentJson(amount, creditCard) { 
        return { 
            credit_card: creditCard?.toJson(),
            amount: amount || 0
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new PaymentModel(json));
    }
}

export default PaymentModel;
