let prefix = "pi";

let piAzureIcon = {
    prefix: 'pi',
    iconName: 'azure-icon',
    icon: [576, 512, [], "g001", "M384 160C366.3 160 352 145.7 352 128C352 110.3 366.3 96 384 96H544C561.7 96 576 110.3 576 128V288C576 305.7 561.7 320 544 320C526.3 320 512 305.7 512 288V205.3L342.6 374.6C330.1 387.1 309.9 387.1 297.4 374.6L191.1 269.3L54.63 406.6C42.13 419.1 21.87 419.1 9.372 406.6C-3.124 394.1-3.124 373.9 9.372 361.4L169.4 201.4C181.9 188.9 202.1 188.9 214.6 201.4L320 306.7L466.7 159.1L384 160z"]
};

let piGoogleIcon = {
    prefix: 'pi',
    iconName: 'google-icon',
    icon: [488, 512, [], "g002", "M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"]
};

let piWindowsIcon = {
    prefix: 'pi',
    iconName: 'windows-icon',
    icon: [448, 512, [], "g003", "M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"]
};

let piGitHub = {
    prefix: 'pi',
    iconName: 'github-icon',
    icon: [496, 512, [], "g004", "M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"]
};

let piMarkdown =  {
    prefix: 'pi',
    iconName: 'markdown-icon',
    icon: [640, 512, [], "g005", "M593.8 59.1H46.2C20.7 59.1 0 79.8 0 105.2v301.5c0 25.5 20.7 46.2 46.2 46.2h547.7c25.5 0 46.2-20.7 46.1-46.1V105.2c0-25.4-20.7-46.1-46.2-46.1zM338.5 360.6H277v-120l-61.5 76.9-61.5-76.9v120H92.3V151.4h61.5l61.5 76.9 61.5-76.9h61.5v209.2zm135.3 3.1L381.5 256H443V151.4h61.5V256H566z"]
};

let piFigma = {
    prefix: 'pi',
    iconName: 'figma-icon',
    icon: [
        200,
        300,
        [],
        "g006",
        [
            "M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z",
            "M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
        ]
    ]
};

let piSketch = {
    prefix: 'pi',
    iconName: 'sketch-icon',
    icon: [
        394,
        356,
        [],
        "g007",
        [
            "m79.634 0 116.969 228.942L0 0z m313.571468 0-116.969 228.942L393.205468 0z M79.634 0h233.938L196.603 228.942z",
            "M196.603 0 85.79 11.715l-6.156 115.166z m196.602396 0 110.813 11.715 6.156 115.166z m393.205968 126.881-85.791-115.166 6.157 115.166zM0 126.881 85.79 11.715l-6.156 115.166z M196.603 0 79.634 126.881h233.938z"
        ]
    ]
}

let piFlutter = { 
    prefix: 'pi',
    iconName: 'flutter-icon',
    icon: [
        24,
        24,
        [],
        "g008",
        [
            "M14.314 0L2.3 12 6 15.7 21.684.012h-7.357L14.314 0zm.014 11.072l-6.471 6.457 6.47 6.47H21.7l-6.46-6.468 6.46-6.46h-7.371z"
        ]
    ]
}

let piTrello = {
    prefix: 'pi',
    iconName: 'trello-icon',
    icon: [
        24,
        24,
        [],
        "g009",
        [
            "M0 0h8v24H0zm10 0h8v24h-8zm10 0h8v24h-8z"
        ]
    ]
};

let piForbiddenWorm = {
    prefix: 'pi',
    iconName: 'forbidden-worm-icon',
    icon: [
        1000,
        1000,
        [],
        "g010",
        [
            "M141.3,488.1C68.8,488.1,10,546.9,10,619.4c0,72.5,58.8,131.3,131.3,131.3c25.6,0,49.4-7.5,69.6-20.1c-21.3-31.8-33.8-70-33.8-111.2c0-41.1,12.5-79.3,33.8-111.2C190.7,495.5,166.9,488.1,141.3,488.1z M430.9,472.1c-16.7-6-34.6-9.5-53.3-9.5c-86.6,0-156.8,70.2-156.8,156.8S291,776.2,377.5,776.2c35,0,67.2-11.6,93.2-30.9c-43.6-40-71.2-97.3-71.2-160.9C399.6,543.2,411.2,504.9,430.9,472.1z",
            "M552.8,422c-64.2,25.9-109.5,88.7-109.5,162.2c0,96.6,78.4,175,175,175c69.3,0,129-40.4,157.4-98.9C651.7,651.2,553.5,548.1,552.8,422z M793.2,223.8c-108.7,0-196.8,88.1-196.8,196.8c0,108.7,88.1,196.8,196.8,196.8c108.7,0,196.8-88.1,196.8-196.8C990,312,901.9,223.8,793.2,223.8z M876.8,388.7c-23.7,0-42.9-19.2-42.9-42.9c0-23.7,19.2-42.9,42.9-42.9c23.7,0,42.9,19.2,42.9,42.9C919.7,369.5,900.5,388.7,876.8,388.7z"
        ]
    ]
};

let piQrBlocks = { 
    prefix: 'pi',
    iconName: 'qr-blocks-icon',
    icon: [
        255,
        285,
        [],
        "g011",
        [
            "M218.745,338.961a14.44,14.44,0,0,1-15-15v-120a14.036,14.036,0,0,1,4.218-10.312,13.869,13.869,0,0,1,10.782-4.688h45a16.133,16.133,0,0,1,15,15v60h-37.5a7.673,7.673,0,0,0-5.625,2.344,8.729,8.729,0,0,0-1.875,5.625v14.531a6.629,6.629,0,0,0,7.5,7.5h37.5v30a13.869,13.869,0,0,1-4.688,10.782,14.036,14.036,0,0,1-10.312,4.218h-45Zm82.5,60a8.061,8.061,0,0,0,5.156-1.875,7.673,7.673,0,0,0,2.344-5.625v7.5a14.036,14.036,0,0,1,4.218-10.312,13.869,13.869,0,0,1,10.782-4.688h52.5a8.061,8.061,0,0,0,5.156-1.875,7.673,7.673,0,0,0,2.344-5.625v-30a8.067,8.067,0,0,0-7.5-7.5h-30a6.629,6.629,0,0,1-7.5-7.5,8.061,8.061,0,0,1,1.875-5.156,7.673,7.673,0,0,1,5.625-2.344,8.061,8.061,0,0,0,5.156-1.875,7.673,7.673,0,0,0,2.344-5.625v-195a8.067,8.067,0,0,0-7.5-7.5h-210a7.673,7.673,0,0,0-5.625,2.344,8.729,8.729,0,0,0-1.875,5.625V391.461a8.7,8.7,0,0,0,1.875,5.625,8.7,8.7,0,0,0,5.625,1.875h165Z"
        ]
    ]
};

let _iconsCache = {
    piAzureIcon: piAzureIcon,
    piGoogleIcon: piGoogleIcon,
    piWindowsIcon: piWindowsIcon,
    piGitHub: piGitHub,
    piMarkdown: piMarkdown,
    piFigma: piFigma,
    piSketch: piSketch,
    piFlutter: piFlutter,
    piTrello: piTrello,
    piForbiddenWorm: piForbiddenWorm,
    piQrBlocks: piQrBlocks
};

export {
    _iconsCache as pi,
    piAzureIcon,
    piGoogleIcon,
    piWindowsIcon,
    piGitHub,
    piMarkdown,
    piFigma,
    piSketch,
    piFlutter,
    piTrello,
    piForbiddenWorm,
    piQrBlocks
};
