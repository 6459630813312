import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCreditCardAlt,
    faDiagramProject, faFaceMeh,
    faIdCardClip, faPenFancy,
    faShieldCat, faUnlockKeyhole
} from "@fortawesome/free-solid-svg-icons";
import FileUploader from "../../common/ui/file-uploader/FileUploader";
import ImageModel from "../../media/models/ImageModel";
import UserService from "../services/UserService";

const UserSubMenu = (props) => {
    const { user, selection, onImageChange, isMe } = props;
    let [imageUrl, setImageUrl] = useState(user?.getImageUrl('256x256'));
    
    if (!user || !user.isValid()) return (<></>);
    let _;
    
    const userId = user.id;

    let sel = selection || 'main';
    let selections = {};
    selections[sel] = 'selected';
    
    const onImageUploadComplete = (json) => {
        user.image = new ImageModel(json);
        
        if (UserService.instance.userMap[userId])
            UserService.instance.userMap[userId].image = user.image;
        
        _ = UserService.instance.getUserAsync(userId);
        
        setImageUrl(user.getImageUrl('256x256'));
        
        if (typeof onImageChange === 'function') { 
            onImageChange(user.image);
        }
    };
    
    let myMenuElements = [];
    let myMenuItems = [];
    let basePath = isMe === true ? "/me" : '/people/' + userId;
    
    let profileMainElement = (<Link to={basePath}><FontAwesomeIcon icon={faIdCardClip} /> Main</Link>);
    
    if (isMe === true) {
        profileMainElement = (<Link to={basePath}><FontAwesomeIcon icon={faFaceMeh} /> My Account</Link>);
        
        myMenuItems = [
            {
                caption: "My Integrations",
                icon: faDiagramProject,
                link: "/me/integrations",
                selection: "integrations"
            },
        ];
    }

    if (user.companyId !== "" || isMe) { 
        myMenuItems.push(            {
                caption: "Edit Details",
                icon: faPenFancy,
                link: basePath + "/edit",
                selection: "edit"
            },
        );
    }

    myMenuElements = myMenuItems.map((item, index) => {
        return (<li key={"user-menu-item-" + index} className={selections[item.selection]}><Link to={item.link}><FontAwesomeIcon icon={item.icon} /> {item.caption}</Link></li>);
    });

    return (
        <div className="sub-column user-submenu" id={"user-submenu"}>
            <FileUploader url={"/api/user/" + userId + "/image"} imageUrl={imageUrl} onComplete={onImageUploadComplete} />

            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['main']}>{profileMainElement}</li>
                <li className={selections['card']}><Link to={basePath + "/card"}><FontAwesomeIcon icon={faCreditCardAlt} /> Card on File</Link></li>
                <li className={selections['activity']}><Link to={basePath + "/activity"}><FontAwesomeIcon icon={faShieldCat} /> Activity</Link></li>
                <li className={selections['access']}><Link to={basePath + "/access"}><FontAwesomeIcon icon={faUnlockKeyhole} /> Access</Link></li>

                {myMenuElements}
            </ul>

        </div>
    );

};

export default UserSubMenu;