class CalendarEventModel { 
    static jsonTemplate = {
        "anyoneCanAddSelf": null,
        "attachments": null,
        "attendees": null,
        "attendeesOmitted": null,
        "colorId": null,
        "conferenceData": null,
        "created": "2023-07-06T17:00:57.000Z",
        "creator": {
            "displayName": null,
            "email": "jade.charles@penumbralabs.io",
            "id": null,
            "self": true
        },
        "description": null,
        "end": {
            "date": null,
            "dateTime": "2023-07-07T05:30:00-07:00",
            "timeZone": "America/Los_Angeles",
            "eTag": null
        },
        "endTimeUnspecified": null,
        "etag": "\"3377325714612000\"",
        "eventType": "default",
        "extendedProperties": null,
        "gadget": null,
        "guestsCanInviteOthers": null,
        "guestsCanModify": null,
        "guestsCanSeeOtherGuests": null,
        "hangoutLink": null,
        "htmlLink": "https://www.google.com/calendar/event?eid=Nzg1a3NtbWg5czZlZjl1YWN2NTFuZGFqb3JfMjAyMzA3MDdUMTEzMDAwWiBqYWRlLmNoYXJsZXNAcGVudW1icmFsYWJzLmlv",
        "iCalUID": "785ksmmh9s6ef9uacv51ndajor@google.com",
        "id": "785ksmmh9s6ef9uacv51ndajor_20230707T113000Z",
        "kind": "calendar#event",
        "location": null,
        "locked": null,
        "organizer": {
            "displayName": null,
            "email": "jade.charles@penumbralabs.io",
            "id": null,
            "self": true
        },
        "originalStartTime": {
            "date": null,
            "dateTime": "2023-07-07T04:30:00-07:00",
            "timeZone": "America/Los_Angeles",
            "eTag": null
        },
        "privateCopy": null,
        "recurrence": null,
        "recurringEventId": "785ksmmh9s6ef9uacv51ndajor",
        "reminders": {
            "overrides": null,
            "useDefault": true
        },
        "sequence": 0,
        "source": null,
        "start": {
            "date": null,
            "dateTime": "2023-07-07T04:30:00-07:00",
            "timeZone": "America/Los_Angeles",
            "eTag": null
        },
        "status": "confirmed",
        "summary": "Workout",
        "transparency": null,
        "updated": "2023-07-06T17:00:57.306Z",
        "visibility": null
    };
    
    constructor(json) { 
        if (!json) json = {};

        this.id = json.id;
        this.statusName = json.status || "";
        
        this.creatorEmail = json.creator?.email || "";
        this.creatorName = json.creator?.displayName || this.creatorEmail;

        this.organizerEmail = json.organizer?.email || "";
        this.organizerName = json.organizer?.displayName || this.organizerEmail;
        
        this.originalStartTime = !!json.originalStartTime?.dateTime ? new Date(json.originalStartTime.dateTime) : null;
        this.startDate = !!json.start?.dateTime ? new Date(json.start.dateTime) : null;
        this.endDate = !!json.end ? new Date(json.end_date) : null;

        this.title = json.summary || (!!this.startDate ? this.startDate.toLocaleTimeString("en-US", {}) : "All Day Event");
        this.description = json.description || json.status;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) { 
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new CalendarEventModel(json));
    }
}
export default CalendarEventModel;
