import HttpService from "../../common/services/HttpService";

class InstagramService {
    static instance = new InstagramService();

    constructor() {
        this.initUrl = null;
        let _ = this.getOAuthInitAsync();
    }

    /**
     * Initializes the Google OAuth process with the Pi ApiKey/Secret
     * @param returnUrl { string }
     * @param companyApiId { string }
     * @param userId { string|null }
     * @returns {Promise<string|null|{data: {}, message: string}|AxiosResponse<any>>}
     */
    async getOAuthInitAsync(returnUrl, companyApiId, userId = null) {
        if (!companyApiId) {
            //console.error("No companyApiId was given to init Google OAuth");
            return "";
        }

        if (!!returnUrl) {
            returnUrl = "?return=" + encodeURI(returnUrl);
        } else {
            console.error("No return url");
            return null;
        }

        const url = !!userId && userId.length > 30 ?
            "/api/user/" + userId + "/instagram/" + companyApiId + "/oauth" + returnUrl :
            "/api/instagram/" + companyApiId + "/oauth" + returnUrl;
        const me = this;

        console.warn(url);
        return await HttpService.instance.getAsync(url).then((rsp) => {
            me.initUrl = rsp.data.init_url;
            return rsp.data.init_url;
        });
    }

}

export default InstagramService;
