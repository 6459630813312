import React, {useEffect, useState} from 'react';
import {faArrowTrendUp, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import MetricsMasterScreen from "./MetricsMasterScreen";
import ActivityService from "../../components/activity/services/ActivityService";
import DateTime from "../../components/common/ui/DateTime";
import {Link} from "react-router-dom";

const SystemErrorsScreen = (props) => {
    const {} = props;
    const [systemErrors, setSystemErrors] = useState([]);

    let extraTitle = "";
    
    const getSystemErrorsAsync = async (force = false) => { 
        
        const items = await ActivityService.instance.getSystemErrorsAsync(null, null, null);
        if (Array.isArray(items)) {
            setSystemErrors(items);
        }
        
        return items;
    };

    useEffect(() => {
        getSystemErrorsAsync();
    }, []);
    
    const elements = systemErrors.map((item, index) => {
        const key = "item-" + index + "";
        const json = item.message;
        
        let ip = item.ipAddress;
        if (!!ip && ip.indexOf(":") > -1) {
            ip = ip.split(":")[0];
        }
        
        let message = json || "";
        try { 
            //{"error_code":5,"error_code_name":"Server Error","messages":["Incorrect syntax near the keyword 'and'."],"objects":[{"index":0,"field_name":"general","message":"Incorrect syntax near the keyword 'and'."}],"meta_code":0,"message":"Incorrect syntax near the keyword 'and'.","stack_trace":"No StackTrack Available","source":"","Data":{},"InnerException":null,"HelpLink":null,"HResult":-2146233088}
            const obj = JSON.parse(json);
            const messages = obj.messages;
            
            message = obj?.message || "";
        } catch (ex) {
            message = ex?.message || "Failed to parse error message.";
        }
        
        return (<tr key={"error-item-" + index}>
            <td>
                <ul>
                   <li>
                       <span>
                           <strong>Path: </strong>{ item.domainName + item.requestPath }     
                       </span>
                       <span>
                           <strong>IP: </strong> <Link to={"/metrics/web-visitors/ip/" + ip}>{item.ipAddress}</Link>     
                       </span>
                   </li>
                   <li><p>{message}</p></li> 
                </ul>
            </td>
            <td><DateTime useRelative={true} time={true} value={item.created} /></td>
        </tr>);
    });
    
    return (<MetricsMasterScreen selector={"metrics"} icon={faArrowTrendUp} selection={"errors"} title={"Growth Metrics"} subTitle={"Platform Errors" + extraTitle} onRefresh={() => getSystemErrorsAsync(true)}>
        <p className={"no-bottom large"}>
            This area will show the analytics for all companies, demonstrating their performance.
        </p>
            
        <div>
            <table className={"table table-striped table-logs"} width={"100%"} cellSpacing={1}>
                <thead>
                <tr>
                    <th>Error Message</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {elements}
                </tbody>
            </table>
        </div>
    </MetricsMasterScreen>);

};

export default SystemErrorsScreen;

